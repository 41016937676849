commusoftCommon.directive('csmodal', ['prefix', '$http', '$modal', function(prefix, $http, $modal) {
    return {
        transclude: true,
        replace: true,
        restrict: 'E',
        scope: {
            title: '@',
            deleteUrl: '@',
            deleteParams: '@',
            textToConfirm: '@',
            buttonClass: '@',
            buttonValue: '@',
            validationMessage: '@',
            isDefaultValue: '=',
            selectedId: '=',
            shouldBeOpen: '=',
            deleteFunc: '&',
            mergeId: '=',
            mergeDescription: '=',
            certificateName: '=',
            questionName: '=',
            templateName: '=',
            serviceReminderName: '=',
            limit: '=',
            totalEntries: '=',
            contactName: '=',
            bodyText: '=',
            listData: '=',
            category: '=',
            jobId: '=',
            branchDetails : '='
        },
        controller: function($scope, $element, $attrs, $transclude) {
            $scope.preventDeleteAction = ($attrs['preventDelete'] == 'true') ? true : false;

            $scope.$watch('shouldBeOpen', function(newVal, oldVal, scope) {
                var modalInstance = null;
                if (newVal) {
                    var elem = $transclude($scope, function() {});
                    $scope.transData = elem;

                    modalInstance = $modal.open({
                        templateUrl: 'template/delete_modal.html',
                        scope: $scope,
                        controller: CSModalCtrl
                    })
                }
            });
        }
    }
}]);