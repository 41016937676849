'use strict';

function StockSidepanelCtrl($scope, $state, $stateParams, $timeout, stock_data, $http, prefix, tableCollection, $translate, $rootScope) {
    /*====================================================
     Set data on the scope
     ====================================================*/
    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.transferOptions = [];
    $scope.transferOptions.push(stock_data.getTransferOptions());
    $scope.quantity_remaining = $scope.sidepanelData['balance.available'];
    $scope.quantity_delivered = $scope.sidepanelData['balance.available'];
    if($scope.category == 'stock_due_in'){
        if($scope.sidepanelData.quantityArrived != '0'){
            $scope.quantity_delivered = $scope.sidepanelData.Quantity;
            $scope.disableQuantity = 1;
        }else{
            $scope.quantity_delivered = $scope.sidepanelData.Quantity;
            $scope.disableQuantity = 0;
        }
    }
    $scope.min_quantity_delivered = 0;
    $scope.able_to_add_another = true;
    $scope.total_added = 0;
    $scope.total_removed = 0;
    $scope.quantity_transferred = 0;
    var sidepanel_is_audit = false;
    $scope.priceValidate = [];
    $scope.priceErrorMessage = [];
    $scope.adjustButtonHide = true;
    $scope.adjustPriceButtonHide = true;
    $scope.reorderPo = true;
    $scope.validatePrice = function validatePrice(index) {
        var pricecheckregexp = /^[0-9][0-9]{0,9}(\.[0-9]{1,4})?$/;
        $scope.priceValidate[index] = false;
        $scope.priceErrorMessage[index] = '';
        if(angular.isNumber(parseFloat(this.option.value)) || this.option.value == ''){
            $scope.adjustPriceButtonHide = false;
            if(!pricecheckregexp.test(this.option.value)){
                $scope.priceValidate[index] = true;
                $scope.adjustPriceButtonHide = true;
                if(this.option.value == '' || this.option.value > 0){
                    $scope.priceErrorMessage[index] =  $translate('Please.enter.valid.price');
                }else{
                    $scope.priceErrorMessage[index] =  $translate('Please.enter.only.positive.numbers');
                }
            }
        }else{
            $scope.priceValidate[index] = true;
            $scope.adjustPriceButtonHide = true;
            if(this.option.value != '' && this.option.value < 0){
                $scope.priceErrorMessage[index] =  $translate('Please.enter.only.positive.numbers');
            }
        }
    };


    if ($scope.sidepanelData.reorderLocationId && $scope.sidepanelData.engineerId == null && $scope.sidepanelData.reorderLocationDeleted === 0) {
        let reorderDestination = [];
        reorderDestination.push(_.find($scope.transferOptions[0].destinations, function (x) {
            return x.id == $scope.sidepanelData.reorderLocationId
        }));
        $scope.transferOptions[0].destinations = reorderDestination;
    }

    $scope.showDestinations  = function (destination)
    {
       $scope.collection = _.map($scope.transferOptions, function(x){return parseInt(x.destination);});
       return $scope.collection.indexOf(destination) > -1;
    }

    /*====================================================
     Watch and update when quantity delivered is updated
     update all the scope variables for the view to use
     this is used in the mark as delivered panel
     ====================================================*/
    $scope.$watch('quantity_delivered', function(){
        var quantity_sum = 0;
        if($scope.category == 'stock_due_in'){
            for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                quantity_sum += parseFloat($scope.transferOptions[i].quantity);
            }
            $scope.quantity_delivered = ($scope.quantity_delivered === null || $scope.quantity_delivered === undefined)?0:$scope.quantity_delivered;
            $scope.min_quantity_delivered = quantity_sum;
            $scope.quantity_remaining = parseFloat($scope.quantity_delivered) - quantity_sum;
        }
    });

    /*====================================================
     Watch and update when quantity options are updated
     update all the scope variables for the view to use
     ====================================================*/
    $scope.$watch('option.quantity', function(){
        var quantity_sum = 0;
        if($scope.category == 'stock_due_in'){
            for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                quantity_sum += parseFloat($scope.transferOptions[i].quantity);
            }
            $scope.min_quantity_delivered = quantity_sum;
            $scope.quantity_remaining = parseFloat($scope.quantity_delivered) - quantity_sum;
        }
    });

    /*====================================================
     Watch and upate audit report when values change
     this is used in the stock adjust panel
     ====================================================*/
    $scope.handleAuditValidation = function handleAuditValidation() {
        sidepanel_is_audit = true;
    }

    /*====================================================
     Initialise transfer options
     ====================================================*/
    $timeout(function(){
        var transfer_container = document.querySelector('.stock-edit-option');
        if (transfer_container.classList){
            transfer_container.classList.remove('option-removed');
            var first_input = document.querySelector('#input-to-focus');
            first_input.focus();
        }
    }, 10, true);

    /*====================================================
     Watch and update remaining to allocate when
     amount delivered changes (used in the mark as
     arrived panel)
     ====================================================*/
    $scope.$watch('sidepanelData', function(){
        $scope.quantity_remaining = $scope.$parent.sidepanelData['balance.available'] ? parseFloat($scope.$parent.sidepanelData['balance.available'].replace(/[^\d\.\-]/g, "")) : parseFloat($scope.$parent.sidepanelData['balance.available']);
        if($scope.category == 'stock_due_in'){
            $scope.quantity_remaining = $scope.sidepanelData.Quantity;
        }
    });

    /*====================================================
     Handle removing a transfer option
     ====================================================*/
    $scope.removeOption = function removeOption(e) {

        var optionQuantity = this.option.quantity || 0;
        var index = this.$index,
            self = this,
            quantity_exists = this.option.quantity !== '';
        if(!angular.isNumber(optionQuantity)) {
            var optionQuantity = 0;
            var quantity_value = 0;
        }
        document.querySelectorAll('.stock-edit-option')[this.$index].classList.add('option-removed');
        $scope.adjustButtonHide = false;
        $scope.adjustPriceButtonHide = false;
        $timeout(function(){
            $scope.transferOptions.splice(index,1);
            if(sidepanel_is_audit){
                var total_added = 0,
                    total_removed = 0;

                for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                    $scope.transferOptions[i].sum
                    if($scope.transferOptions[i].sum == 'add'){
                        total_added += parseFloat($scope.transferOptions[i].quantity);
                    }
                    if($scope.transferOptions[i].sum == 'remove'){
                        total_removed += parseFloat($scope.transferOptions[i].quantity);
                    }
                }
                $scope.total_added = parseFloat(total_added);
                $scope.total_removed = parseFloat(total_removed);
                var balanceAvailable = $scope.$parent.sidepanelData['balance.available'] ? parseFloat($scope.$parent.sidepanelData['balance.available'].replace(/[^\d\.\-]/g, "")) : parseFloat($scope.$parent.sidepanelData['balance.available']);
                $scope.quantity_remaining = balanceAvailable + parseFloat((total_added - total_removed));
                if($scope.category == 'stock_due_in'){
                    $scope.quantity_remaining = parseFloat($scope.sidepanelData.Quantity) + parseFloat((total_added - total_removed));
                }

            }else{
                var quantityval = parseFloat(self.option.quantity);
                if(!isNaN(quantityval) && quantityval > 0) {
                    $scope.quantity_remaining += parseFloat(self.option.quantity);
                }else{
                    var total_added = 0;
                    for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                        $scope.transferOptions[i].sum
                        if($scope.transferOptions[i].sum == 'add'){
                            total_added += parseFloat($scope.transferOptions[i].quantity);
                        }
                    }
                    $scope.quantity_remaining = parseFloat($scope.quantity_delivered) - total_added;
                }
            }

            if ($scope.quantity_remaining >= 0) {
                $scope.able_to_add_another = true;
                $scope.locationHide = false;
                // this logics added for remove option time calculate the transfer quantity.com-5488
                var total_added = 0;
                for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                    $scope.transferOptions[i].sum
                    if($scope.transferOptions[i].sum == 'add'){
                        total_added += parseFloat($scope.transferOptions[i].quantity);
                    }
                }
                $scope.quantity_transferred = total_added;
            }

        }, 300, true);
    }

    /*====================================================
     Handle adding a transfer option
     ====================================================*/
    $scope.addOption = function addOption() {
        var option = stock_data.getTransferOptions();

        $scope.transferOptions.push(option);
        $scope.adjustButtonHide = true;
        $scope.adjustPriceButtonHide = true;
        $timeout(function(){
            var index_of_last_container = document.querySelectorAll('.stock-edit-option').length - 1,
                transfer_container = document.querySelectorAll('.stock-edit-option')[index_of_last_container];
            var select_to_focus = transfer_container.querySelectorAll('#input-to-focus')[0];

            transfer_container.classList.remove('option-removed');
            select_to_focus.focus();
            $scope.quantityValidate[$scope.transferOptions.length -1] = false;
            $scope.locationValidate[$scope.transferOptions.length -1] = false;

            if ($scope.sidepanelData.reorderLocationId && $scope.sidepanelData.engineerId == null) {
                let reorderDestination = [];
                reorderDestination.push(_.find($scope.transferOptions[index_of_last_container].destinations, function (x) {
                    return x.id == $scope.sidepanelData.reorderLocationId
                }));
                $scope.transferOptions[index_of_last_container].destinations = reorderDestination;
            }
        },100);
    }
    $scope.locationHide = true;
    $scope.locationValidate = [];
    $scope.validateLocation = function validateLocation(index){
        if(this.option.destination == null || this.option.destination == ''){
            $scope.locationValidate[index] = true;
            $scope.locationHide = true;
        }else {
            $scope.locationValidate[index] = false;
            $scope.locationHide = false;
        }
        if($scope.sidepanelData.engineerId == null && $scope.sidepanelData.reorderLocationId && this.option.destination != $scope.sidepanelData.reorderLocationId){
            $scope.locationValidate[index] = true;
            $scope.locationHide = false;
            $scope.reorderPo = false;
        }
    }
    $scope.quantityValidate = [];$scope.quantityErrorMessage = [];

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateOptions = function validateOptions(index) {
        var quantitycheckregexp = /^[0-9]{0,9}(\.[0-9]{1,2})?$/;
        $scope.quantityValidate[index] = false;
        $scope.quantityErrorMessage[index] = '';
        if(angular.isNumber(parseFloat(this.option.quantity)) && this.option.quantity != 0){
            $scope.adjustButtonHide = false;
            $scope.able_to_add_another = true;
            if(!quantitycheckregexp.test(this.option.quantity)){
                $scope.quantityValidate[index] = true;
                $scope.adjustButtonHide = true;
                $scope.able_to_add_another = false;
                if(this.option.quantity > 0){
                    $scope.quantityErrorMessage[index] =  $translate('Please.enter.valid.quantity');
                }else{
                    $scope.quantityErrorMessage[index] =  $translate('Please.enter.only.positive.numbers');
                }
            }
        }else{
            $scope.quantityValidate[index] = true;
            $scope.adjustButtonHide = true;
            $scope.able_to_add_another = true;
            if(this.option.quantity != ''){
                $scope.able_to_add_another = false;
                $scope.quantityErrorMessage[index] =  $translate('Please.enter.only.positive.numbers');
            }else{
                $scope.able_to_add_another = false;
                $scope.quantityErrorMessage[index] =  $translate('Please.enter.valid.quantity');
            }
        }

        if(quantitycheckregexp.test(this.option.quantity)) {
            var input_is_number = !isNaN(parseFloat(this.option.quantity)),
                one_option = $scope.transferOptions.length === 1,
                quantity_valid;
        }

        if(input_is_number) {
            if(one_option) {
                $scope.isQuantityInBounds(this.option.quantity);
            }else {
                var quantity_sum = 0;
                for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                    quantity_sum += parseFloat($scope.transferOptions[i].quantity);
                }
                $scope.isQuantityInBounds(quantity_sum);
            }
        }
    }

    $scope.isQuantityInBounds = function isQuantityInBounds(num) {
        var balanceAvailable = $scope.$parent.sidepanelData['balance.available'] ? parseFloat($scope.$parent.sidepanelData['balance.available'].replace(/[^\d\.\-]/g, "")) : parseFloat($scope.$parent.sidepanelData['balance.available']);
        var total = balanceAvailable;
        var num = parseFloat(num);
        //$timeout(function(){
            if(sidepanel_is_audit){
                var total_added = 0,
                    total_removed = 0;

                for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                    $scope.transferOptions[i].sum
                    if($scope.transferOptions[i].sum == 'add'){
                        total_added += parseFloat($scope.transferOptions[i].quantity);
                    }
                    if($scope.transferOptions[i].sum == 'remove'){
                        total_removed += parseFloat($scope.transferOptions[i].quantity);
                    }
                }

                $scope.total_added = parseFloat(total_added);
                $scope.total_removed = parseFloat(total_removed);
                $scope.quantity_remaining = balanceAvailable + parseFloat((total_added - total_removed));
                total = balanceAvailable + parseFloat((total_added - total_removed));
                if($scope.category == 'stock_due_in'){
                    $scope.quantity_remaining = parseFloat($scope.sidepanelData.Quantity) + parseFloat((total_added - total_removed));
                    total = parseFloat($scope.sidepanelData.Quantity) + parseFloat((total_added - total_removed));
                }
                var new_quantity_in_bounds = (total - (total_added - total_removed) ) >= 0;

                if(new_quantity_in_bounds) {
                    $scope.able_to_add_another = true;
                    $scope.quantity_remaining = total;
                }else {
                    $scope.able_to_add_another = false;
                }
            }else{
                if($scope.category == 'stock_due_in'){
                    total = parseFloat($scope.quantity_delivered);
                }

                var new_quantity_in_bounds = (total - num) >= 0

                if(new_quantity_in_bounds) {
                    $scope.able_to_add_another = true;
                    $scope.quantity_remaining = total - num;
                    $scope.quantity_transferred = num;
                    if($scope.$$childTail.option.destination != ''){
                        $scope.locationHide = false;
                    }else{
                        $scope.locationHide = true;
                    }
                }else {
                    $scope.able_to_add_another = false;
                    $scope.locationHide = true;
                    for(var i = 0, l = $scope.transferOptions.length; i < l; i++) {
                        $scope.quantityValidate[i] = true;
                        $scope.quantityErrorMessage[i] = $translate('Please.enter.valid.quantity');
                    }
                }
                $scope.quantity_remaining = total - num;
            }
        // },15);
    }

    /*====================================================
     Update the remaning quantity
     ====================================================*/
    var updateQuantityRemaining = function updateQuantityRemaining(){
        $timeout(function(){
            var current_transfer_options = $scope.transferOptions;
            for(var i = 0, l = current_transfer_options.length; i < l; i++) {
                var transfer_option_quantity_inputted = $scope.transferOptions[i].quantity;
            }
            $scope.quantity_remaining = parseFloat($scope.quantity_remaining) - parseFloat(transfer_option_quantity_inputted);
        },10);
    }

    $scope.saveAdjust = function saveAdjust(optionValues, locationId, partId, category){
        $scope.adjustData = [];
        angular.forEach(optionValues, function (val) {
            $scope.adjustData.push({
                "quantity": val.quantity,
                "alteration": val.sum,
                "add_action": val.add_reason,
                "remove_action": val.remove_reason,
                "wasted_reason": val.wasted_reason,
                "price": val.value
            });
        });
        $http.post(prefix + '/save_adjust_parts', 'adjustValues=' + angular.toJson($scope.adjustData) + '&locationId=' + locationId + '&partId=' + partId).success(function (data) {
            $scope.$emit('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchText': ''}), category : category}));
        });
    }

    $scope.saveAllocation = function saveAllocation(optionValues, partId, purchaseOrderDetailId, poNo, price){
        $scope.allocationData = [];
        angular.forEach(optionValues, function (val) {
            $scope.allocationData.push({
                    "quantity": val.quantity,
                    "locationId": val.destination
                });
        });
        var price = price.replace($rootScope.CustomCurrency, '');
        $http.post(prefix + '/save_allocation_parts', 'allocationValues=' + angular.toJson($scope.allocationData) + '&quantity_delivered=' + $scope.quantity_delivered + '&quantity_ordered=' + $scope.sidepanelData.Quantity + '&purchaseOrderDetailId=' + purchaseOrderDetailId + '&partId=' + partId + '&sendNotification=' + $scope.disableQuantity + '&price=' + price + '&poNo=' + poNo).success(function (data) {
            $scope.$emit('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchText': ''}), category : 'stock_due_in'}));
            $state.transitionTo('loggedin.stocks_view.stock', {'id':partId},{reload: true})

        });
    }

    $scope.saveTransfer = function saveTransfer(optionValues, locationId, partId, category){
        $scope.allocationData = [];
        angular.forEach(optionValues, function (val) {
            $scope.allocationData.push({
                "quantity": val.quantity,
                "locationId": val.destination
            });
        });
        $http.post(prefix + '/save_transfer_parts', 'transferValues=' + angular.toJson($scope.allocationData) + '&locationId=' + locationId + '&partId=' + partId).success(function (data) {
            $scope.$emit('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchText': ''}), category : category}));
        });
    }
    $scope.checkOptionValid = function(option){
        var t = _.map(option, function (x) {
            return x.destination;
        })
        $scope.locationHide = _.contains(t,'');
    };
}

function StockNavCtrl($scope, $state, $http, prefix, $rootScope, getIdData, stock_data) {
    $scope.$state = $state;
    stock_data.setStockData(getIdData.stockDetail);
    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.partId = $state.params.id;
    $scope.supplierModuleAccess = getIdData.moduleResults.Supplier;
    $scope.jobModuleAccess = getIdData.moduleResults.Jobs;
    $scope.stock = stock_data.getStockById($scope.partId);

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if ($state.current.name === 'loggedin.stocks_view.edit_internal_details') {
            $scope.hideSubNav = true;
            $scope.stock_is_link = true;
        } else {
            $scope.hideSubNav = false;
            $scope.stock_is_link = false;
        }

        //set up breadcrumbs
        if ($state.current.name === 'loggedin.stocks_view.history') {
            $scope.breadcrumbs_str = 'History';
        } else if ($state.current.name === 'loggedin.stocks_view.stock') {
            $scope.breadcrumbs_str = 'View ';
        } else if ($state.current.name === 'loggedin.stocks_view.suppliers') {
            $scope.breadcrumbs_str = 'Suppliers';
        } else if ($state.current.name === 'loggedin.stocks_view.reserve_list') {
            $scope.breadcrumbs_str = 'Reserve list'
        } else if ($state.current.name === 'loggedin.stocks_view.edit_internal_details') {
            $scope.breadcrumbs_str = 'Edit Internal Details';
        } else {
            $scope.breadcrumbs_str = '';
        }
    });

    /*==========================================================================================
     Filters
     ==========================================================================================*/
    $rootScope.$on('panel_with_form:filter:stock_history', function (e, data) {
        for (var i = 0, l = data.length; i < l; i++) {
            console.info('Filter stock history by: ' + data[i].model_name + ': ' + data[i].value);
        }
    });
}

function StocksCtrl($scope, $state, prefix, $http, defaultPagingValue, $rootScope, stock_data, tableCollection, getIdData) {
    $scope.selectedId = $state.params.id;
    stock_data.setData(getIdData.transferOption);
    tableCollection.setData('stock_due_in', getIdData.stock_due_in);
    tableCollection.setData('stock_current', getIdData.stock_current);
    $scope.moduleAcess = getIdData.moduleResults.Purchasing;
    $scope.searchCurrentStock = '';
    $scope.$watch('searchCurrentStock', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchCurrentStock=newVal;
            $scope.$broadcast('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchCurrentStock': $scope.searchCurrentStock}), category : 'stock_current'}));
        }
    });

    $scope.searchText = '';
    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchText=newVal;
            $scope.$broadcast('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchText': $scope.searchText}), category : 'stock_due_in'}));
        }
    });

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("StockDuePrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Stock Due Details';
            var excelRouteName = 'stockDueDownload';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchText;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Stock Due Details';
            var pdfRouteName = 'printStockDuePdf';
            var print = prefix + '/' + pdfRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchText;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
    $scope.$on("CurrentStockPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Current Stock Details';
            var excelRouteName = 'currentStockDownload';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchCurrentStock;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Current Stock Details';
            var pdfRouteName = 'printCurrentStockPdf';
            var print = prefix + '/' + pdfRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchCurrentStock;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

}

function StocksHistoryCtrl($scope, $state, prefix, $http, defaultPagingValue, $rootScope, stock_data, tableCollection, getIdData, formPanelCollection, dateRange) {
    //TODO: will remove this when adding backend
    $scope.selectedId = $state.params.id;
    formPanelCollection.setFilterData(getIdData.stock_history_side_panel);
    stock_data.setData(getIdData.transferOption);
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.destinations = getIdData.destinations;
    $scope.wastedReasons = getIdData.wastedreasons;
    tableCollection.setData('stock_history', getIdData.stock_history);
    $scope.startDate = '';
    $scope.endDate = '';

    $scope.filterData = {};
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
        }else{
            $scope.filterByDueDate = undefined;
            $scope.startDate = undefined;
            $scope.endDate = undefined;
        }
        $scope.filterData['selectedLocation'] = $scope.selectedLocation;
        $scope.filterData['selectedStatus'] = $scope.selectedStatus;
        $scope.filterData['filterByDueDate'] = $scope.filterByDueDate;
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
    });

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("StockHistoryPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Stock history';
            var excelRouteName = 'stockHistoryDownload';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Stock history';
            var pdfRouteName = 'printStockHistoryPdf';
            var print = prefix + '/' + pdfRouteName + '/' + $scope.selectedId + '?page=' + pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });

    // Any Local filter change, send to sidepanel so that the filters from the sidepanel can be aggregrated before its
    // sent to smart table fetchrecords.
    $scope.$watch('selectedLocation', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.$broadcast('tosidepanel:filtervalues',
                {
                    'model_name': 'selectedLocation',
                    'value': $scope.selectedLocation
                }
            );
        }
    });

    $scope.$watch('selectedStatus', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.$broadcast('tosidepanel:filtervalues',
                {
                    'model_name': 'selectedStatus',
                    'value': $scope.selectedStatus
                }
            );
        }
    });

    // This is required because the pagination sends data here, which it then sends back to the panelwithform to
    // collect data from the more filters. Add all the current page filter data in this.
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'selectedLocation',
                'value': $scope.selectedLocation
            }
        );
    });

    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'selectedStatus',
                'value': $scope.selectedStatus
            }
        );
    });

    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:stock_history', function(evt, data) {
        $scope.filterData = {};
        //Store the filters from the main page
        $scope.filterData['selectedLocation'] = $scope.selectedLocation;
        $scope.filterData['selectedStatus'] = $scope.selectedStatus;
        $scope.filterData['filterByDueDate'] = $scope.filterByDueDate;
        angular.forEach(data, function(val, key) {
            $scope.filterData[val.model_name] = val.value;
        });


        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.countActiveFilters();

    });

    $scope.$on('from_return_to_supplier', function(evt, data){
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
    });

    $scope.filterData = {};
    $scope.show_clear_filter_link = false;
    $scope.number_of_filters = 0;

    $scope.countActiveFilters = function() {
        $scope.show_clear_filter_link = false;
        $scope.number_of_filters = 0;

        for (var key in $scope.filterData) {
            var value = $scope.filterData[key],
                value_exists = value &&
                    value !== "all" &&
                    value.toString().length > 0

            if (value_exists) {
                $scope.show_clear_filter_link = true;
                $scope.number_of_filters ++;
            }
        }
    }

    $scope.clearFilters = function() {
        $scope.filterData = {};
        $scope.filterByDueDate = undefined;
        $scope.selectedLocation = '';
        $scope.selectedStatus = '';
        $scope.selectedPoDate = undefined;

        $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('event:reset_date_filter');
        $scope.countActiveFilters();
    }

}

function StockSuppliersCtrl($scope, $state, prefix, $http, defaultPagingValue, $rootScope, stock_data, getIdData, tableCollection) {
    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.selectedId = $state.params.id;
    tableCollection.setData('stock_suppliers', getIdData.stock_suppliers);
    $scope.searchText = '';
    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchText=newVal;
            $scope.$broadcast('smart_tablefilter:filtervalue', angular.toJson({'searchText': $scope.searchText}));
        }
    });

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("StockSupplierPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            var pageName = 'Stock Supplier Details';
            var excelRouteName = 'stockSupplierDetailsDownload';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchText;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Stock Supplier Details';
            var pdfRouteName = 'printStockSupplierPdf';
            var print = prefix + '/' + pdfRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchText;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
}

function StockReserveListCtrl($scope, $state, prefix, $http, defaultPagingValue, $rootScope, stock_data, getIdData, tableCollection) {

    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.selectedId = $state.params.id;

    tableCollection.setData('stock_reserve_list', getIdData.stock_reserve_list);

    $scope.searchText = '';

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchText = newVal;
            $scope.$broadcast('smart_tablefilter:filtervalue', angular.toJson({'searchText': $scope.searchText}));
        }
    });

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$on("ReserveListPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');
        if (action == "download") {
            var pageName = 'Reserve list';
            var excelRouteName = 'reserveListDownload';
            var download = prefix + '/' + excelRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchText;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            var pageName = 'Reserve list';
            var pdfRouteName = 'printReserveListPdf';
            var print = prefix + '/' + pdfRouteName + '/' + $scope.selectedId + '?page=' + pageName + '&searchText=' + $scope.searchText;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }
    });
}

function EditInternalDetailsCtrl($scope, $state, prefix, $http, $rootScope, datasets) {
    $scope.data = datasets;
    $scope.partId = $state.params.id;
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
            success(function (data, status) {
                $scope.editStockInternalDetailsForm.$setPristine();
                $scope.stockInternalDetails = data;
                $scope.saving = false;
                $state.transitionTo("loggedin.stocks_view.stock", { 'id': $state.params.id }, {reload:true});
            })
    }
}

function StockHistorySidepanelCtrl ($scope, $rootScope, $state, $stateParams, stock_data) {
    $scope.stockDetails = stock_data.getStockById($state.params.id);
}

function StockReturnToSupplier($scope, $rootScope, $state, $stateParams, stock_data, $http, prefix) {
    /*====================================================
     Set data on the scope
     ====================================================*/
    $scope.invalidQuantity = false;
    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.return_quantity = $scope.sidepanelData.canReturnQuantity;

    $scope.validateQuantity = function validateQuantity() {
        var return_quantity = parseFloat($scope.return_quantity),
            available_quantity = parseFloat($scope.sidepanelData.canReturnQuantity);

        if (return_quantity > available_quantity || return_quantity == 0 || return_quantity < 0) {
            $scope.invalidQuantity = true;
        }	else {
            $scope.invalidQuantity = false;
        }
    }

    $scope.returnToSupplier = function returnToSupplier() {
        var totalQuantity = $scope.sidepanelData.canReturnQuantity;
        $http.post(prefix + '/return_to_supplier', 'totalQuantity= ' + totalQuantity + '&returnQuantity=' + $scope.return_quantity + '&fulfillId=' + $scope.sidepanelData.fulfillid + '&partStatus=' + $scope.sidepanelData.fulfillPartStatus).success(function (data) {
            $scope.$emit('from_return_to_supplier');
        });
    }
}

function StockUnreserveCtrl($scope, $rootScope, $state, $stateParams, $timeout, stock_data, $http, prefix, $translate, warningModal) {
    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.reserveQty = $scope.sidepanelData.Quantity;
    $scope.quantity_remaining = parseFloat(0);
    $scope.valid = true;

    /*====================================================
     Initialise transfer options
     ====================================================*/
    $timeout(function(){
        var transfer_container = document.querySelector('.stock-edit-option');
        if (transfer_container.classList){
            transfer_container.classList.remove('option-removed');
        }
    }, 10, true);

    /*====================================================
     Validate the transfer options
     ====================================================*/
    $scope.validateOptions = function validateOptions() {
        var input_is_number = !isNaN(parseFloat(this.option.quantity));
        if(input_is_number && this.option.quantity >=    0) {
            $scope.isQuantityInBounds(this.option.quantity);
        }else {
            $scope.valid = false;
            $scope.quantityErrorMessage =  $translate('Please.enter.only.positive.numbers');
        }
    }

    $scope.isQuantityInBounds = function isQuantityInBounds(num) {
        var total = parseFloat($scope.reserveQty);
        var num = parseFloat(num);
        $timeout(function(){
            var new_quantity_in_bounds = (total - num) >= 0

            if(new_quantity_in_bounds) {
                $scope.valid = true;
                $scope.quantity_remaining = total - num;
            }else {
                $scope.valid = false;
                $scope.quantityErrorMessage =  $translate('Please.enter.valid.quantity');
            }
        },15);
    }

    $scope.showModalView = function showModalView() {
        var overlay = document.querySelector('#page-overlay');
        overlay.classList.add('darker');

        var panel = document.querySelector('#more-details-panel.smart-table-sidepanel');
        panel.classList.add('with-modal-transition');

        $timeout(function(){
            var panel = document.querySelector('#more-details-panel.smart-table-sidepanel');
            panel.classList.add('modal-view');
        },100)
    }

    $scope.hideModalView = function hideModalView() {
        var overlay = document.querySelector('#page-overlay');
        overlay.classList.remove('darker');

        var panel = document.querySelector('#more-details-panel.smart-table-sidepanel');
        panel.classList.remove('modal-view');

        $timeout(function(){
            var panel = document.querySelector('#more-details-panel.smart-table-sidepanel');
            panel.classList.remove('with-modal-transition');
        },100)
    }

    $scope.hideSidepanel = function hideSidepanel() {
        var panel = document.querySelector('#more-details-panel.smart-table-sidepanel');
        panel.classList.add('animate-modal-away');

        var overlay = document.querySelector('#page-overlay')
        overlay.classList.remove('darker');
        $timeout(function() {
          $rootScope.$broadcast('closeAllSidepanels');
        }, 100);
    }

    $scope.unreserveStock = function unreserveStock(transactionId) {
        var quantity = this.option.quantity;
        $http.post(prefix + '/unreserve_stock', 'transactionId= ' + transactionId + '&quantity= ' + quantity).success(function (data) {
            $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson({'searchText': $scope.searchText}), {resetPage: true});
        }).error(function(data,statusCode){
            if(statusCode == 400){
                var message = title = id = '';

                if(data.errorMsg == "stock_transaction_exceeds_actual_quantity" || data.errorMsg == "stock_transaction_exceeds_reserved_quantity"  || data.errorMsg == "allocation_exceeds_actual_quantity" ) {
                    id = data.errorMsg;
                    title = 'Error';
                    message = data.errorMsg;
                }
                warningModal.show(message, title, id);
            }
        });
    }
}

function StockLocationNavCtrl($scope, $state, $http, prefix, $rootScope, getIdData, stock_data) {
    $scope.$state = $state;
    stock_data.setStockData(getIdData.stockDetail);
    $scope.stockDetails = getIdData.stockDetail;
    $scope.stockLocationId = $state.params.id;
    $scope.supplierModuleAccess = getIdData.moduleResults.Supplier;
    $scope.jobModuleAccess = getIdData.moduleResults.Jobs;
    $scope.stock = getIdData.stockDetail;
    $scope.selectedReorderList = getIdData.selectedReorderLists;
    $scope.selectedReorderLists = getIdData.selectedReorderListsArray;
    $scope.selectedReorders = _.pluck(getIdData.selectedReorderListsArray, 'text').join(',');
    $scope.reorderLists = getIdData.reorderLists;

    PartsReorderListManager.call(this, $scope, $scope.reorderLists);

    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        //set up breadcrumbs
        if ($state.current.name === 'loggedin.stock_locations_view.history') {
            $scope.breadcrumbs_str = 'History';
        } else if ($state.current.name === 'loggedin.stock_locations_view.stock_location_details') {
            $scope.breadcrumbs_str = 'View ';
        } else if ($state.current.name === 'loggedin.stock_locations_view.reserve_list') {
            $scope.breadcrumbs_str = 'Reserve list'
        } else {
            $scope.breadcrumbs_str = '';
        }
    });

//     $scope.selectedReorderLists =[];

    $scope.reorderListChanged = function(selectedReorderLists) {
        var reorderLists = '';
        $scope.selectedReorderLists = selectedReorderLists;
        if($scope.selectedReorderLists && $scope.selectedReorderLists.length) {
            angular.forEach($scope.selectedReorderLists, function (val, key) {
                var concat = (reorderLists != '' ? "," : "");
                reorderLists += concat + val.id;
            });
        }
        // Todo: Need to fix this, as this is coming from twig, I'm using JQuery to set value
        $('#selectedReorderList').val(reorderLists);

        $http.post(prefix + '/save_stocklocation_reorderlist', 'stockLocationId= ' + $scope.stockLocationId + '&reorderList= ' + reorderLists).success(function (data) {

        });
    }
}

function StockLocationCtrl($scope, $state, prefix, $rootScope, stock_data, tableCollection, getIdData){
    $scope.selectedId = $state.params.id;
    stock_data.setData(getIdData.transferOption);
    tableCollection.setData('current_parts', getIdData.current_parts);
    $scope.moduleAcess = getIdData.moduleResults.Purchasing;
    $scope.searchCurrentStock = '';

    $scope.$watch('searchCurrentStock', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchCurrentStock=newVal;
            $scope.$broadcast('smart_tablefilter:newsearch', ({filter : angular.toJson({'searchCurrentStock': $scope.searchCurrentStock}), category : 'current_parts'}));
        }
    });
    ShortCutsFunctions.call(this, $scope, $rootScope);
}

function StockLocationHistoryCtrl($scope, $state, prefix, $http, defaultPagingValue, $rootScope, stock_data, tableCollection, getIdData, formPanelCollection, dateRange){
    $scope.selectedId = $state.params.id;
    formPanelCollection.setFilterData(getIdData.stock_location_history_side_panel);
    stock_data.setData(getIdData.transferOption);
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.wastedReasons = getIdData.wastedreasons;
    tableCollection.setData('stock_location_history', getIdData.stock_location_history);
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.selectedPartId = '';

    $scope.filterData = {};
    $scope.$on("dateRangeFilter", function (event, message) {
        if (message.startdate != undefined && message.enddate != undefined &&
            message.startdate != "" && message.enddate != "") {
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
            $scope.filterByDueDate = {'startDate': $scope.startDate, 'endDate': $scope.endDate};
        }else{
            $scope.filterByDueDate = undefined;
            $scope.startDate = undefined;
            $scope.endDate = undefined;
        }
        $scope.filterData['selectedPart'] =  $scope.selectedPartId;
        $scope.filterData['selectedStatus'] = $scope.selectedStatus;
        $scope.filterData['filterByDueDate'] = $scope.filterByDueDate;
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.countActiveFilters();
    });

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_parts',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    ShortCutsFunctions.call(this, $scope, $rootScope);

    $scope.$watch('selectedStatus', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.$broadcast('tosidepanel:filtervalues',
                {
                    'model_name': 'selectedStatus',
                    'value': $scope.selectedStatus
                }
            );
        }
    });
    $scope.$watch('selectedPart', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.$broadcast('tosidepanel:filtervalues',
                {
                    'model_name': 'selectedPart',
                    'value': $scope.selectedPart.id
                }
            );
            $scope.selectedPartId = $scope.selectedPart.id;
        }
    });

    // This is required because the pagination sends data here, which it then sends back to the panelwithform to
    // collect data from the more filters. Add all the current page filter data in this.
    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'selectedPart',
                'value': $scope.selectedPart
            }
        );
    });

    $scope.$on('fromsmarttable:filters', function(evt, data) {
        $scope.$broadcast('tosidepanel:filtervalues',
            {
                'model_name': 'selectedStatus',
                'value': $scope.selectedStatus
            }
        );
    });

    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:stock_location_history', function(evt, data) {
        $scope.filterData = {};
        //Store the filters from the main page
        $scope.filterData['selectedPart'] = $scope.selectedPartId;
        $scope.filterData['selectedStatus'] = $scope.selectedStatus;
        $scope.filterData['filterByDueDate'] = $scope.filterByDueDate;
        angular.forEach(data, function(val, key) {
            $scope.filterData[val.model_name] = val.value;
        });

        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.countActiveFilters();
    });

    $scope.filterData = {};
    $scope.show_clear_filter_link = false;
    $scope.number_of_filters = 0;

    $scope.countActiveFilters = function() {
        $scope.show_clear_filter_link = false;
        $scope.number_of_filters = 0;

        for (var key in $scope.filterData) {
            var value = $scope.filterData[key],
                value_exists = value &&
                    value !== "all" &&
                    value.toString().length > 0

            if (value_exists) {
                $scope.show_clear_filter_link = true;
                $scope.number_of_filters ++;
            }
        }
    }

    $scope.clearFilters = function() {
        $scope.filterData = {};
        $scope.filterByDueDate = undefined;
        $scope.selectedStatus = '';
        $scope.selectedPart = '';
        $scope.selectedPartId = '';

        $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('event:reset_date_filter');
        $scope.countActiveFilters();
    }

    $scope.$on('from_return_to_supplier', function(evt, data){
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
    });
}

function StockLocationReserveListCtrl($scope, $state, prefix, $http, defaultPagingValue, $rootScope, stock_data, getIdData, tableCollection) {

    $scope.stockDetails = stock_data.getStockById($state.params.id);
    $scope.selectedId = $state.params.id;

    tableCollection.setData('stock_location_reserve_list', getIdData.stock_location_reserve_list);

    $scope.searchText = '';

    $scope.$watch('searchText', function (newVal, oldVal) {
        if (newVal != undefined) {
            $scope.searchText = newVal;
            $scope.$broadcast('smart_tablefilter:filtervalue', angular.toJson({'searchText': $scope.searchText}));
        }
    });

    ShortCutsFunctions.call(this, $scope, $rootScope);
}
function GetAllStockLocationsCtrl($scope, $http, prefix, preselect,multiple) {
    $scope.select2stockLocations = {
        minimumInputLength: 2,
        'multiple': multiple,
        ajax: {
            url: prefix + '/get_all_stock_locations',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            if(preselect){
                var data = preselect;
                callback(data);
            }else{
                callback($(element).data('$ngModelController').$modelValue);
            }
        }
    }
}
function StockReturnsSidepanelController($scope, tableCollection, $state, $stateParams, $timeout, $compile, $http, prefix){
    GetAllStockLocationsCtrl.call(this, $scope, $http, prefix,false,false);
    // $scope.panel_content = $scope.$parent.panel_content;
    $scope.saving_text = 'Save';
    $scope.collapse = false;
    $scope.disable_saving = true;
    $scope.appendReturnsAllocation = function(index,cancelId,Type){
        if(Type != ''){
            var def = {type: Type,qty: 0,stock_location:tableCollection.collection['reporting_stock_control_returns_operational_list'].stock_location};
            $scope.panel_content[index].allocations.push(def);
        }
    };
    $scope.updateTotals = function(index){
        var allocation = $scope.panel_content[index].allocations;
        var qty = 0;
        angular.forEach(allocation, function(value, key) {
            angular.forEach(value, function(value, key) {
                if(!isNaN(value.qty) && value.qty != ''){
                    qty += parseFloat(value.qty);
                }
            });
        });
        qty = qty.toFixed(2);
        if(qty === $scope.panel_content[index].quantity || qty > $scope.panel_content[index].quantity){
            angular.forEach(allocation, function(allocationValue, allocationIndex){
                if(allocationIndex != 'engineer_van'){
                    angular.forEach(allocationValue, function(value, key) {
                        if(value.stock_location === '' || value.stock_location === 0 || value.qty === 0 || value.qty === ''){
                            if(key != 0){
                                $scope.panel_content[index].allocations[allocationIndex].splice(key,1);
                            }else{
                                value.stock_location = '';
                            }
                        }
                    });
                }
            });
            if(qty === $scope.panel_content[index].quantity){
                $scope.panel_content[index].valid = true;
            }else{
                $scope.panel_content[index].valid = false;
            }
        }else{
            angular.forEach(allocation, function(allocationValue, allocationIndex){
                if(allocationIndex != 'engineer_van'){
                    var emptyObjectSet = false;
                    angular.forEach(allocationValue, function(value, key) {
                        if(value.stock_location === '' || value.stock_location === 0) {
                            emptyObjectSet = true;
                        }
                    });
                    if(emptyObjectSet === false){
                        $scope.panel_content[index].allocations[allocationIndex].push({qty: '',stock_location:''});
                    }
                }
            });
            $scope.panel_content[index].valid = false;
        }
        $scope.panel_content[index].total = qty;
        $scope.checkSaveButton();
    };
    $scope.checkSaveButton = function(){
        $scope.disable_saving = false;
        angular.forEach($scope.panel_content, function(value, key) {
            if((typeof value.valid === 'undefined'  || value.valid === false) && value.selected === true){
                $scope.disable_saving = true;
                return true;
            }
        });
    }
    $scope.saveReturnsAction = function(parts){
        $scope.data = [];
        $scope.disable_saving = true;
        angular.forEach(parts, function (partsVal,partsIndex) {
            if(partsVal.selected === true && partsVal.valid === true){
                var part = {};part.cancel_part_id = partsVal.cancelId;var allocation = [];
                angular.forEach(partsVal.allocations, function(allocationValue, allocationIndex){
                    angular.forEach(allocationValue, function(value, key) {
                        if(value.qty != '' && value.qty != 0){
                            if(allocationIndex === 'engineer_van'){
                                allocation.push({'action': allocationIndex,'quantity': value.qty});
                            }else{
                                allocation.push({'action': allocationIndex,'quantity': value.qty,'destination':value.stock_location.id});
                            }
                        }
                    });
                });
                part.allocation = allocation;
                if(part.quantity === part.total){
                    $scope.data.push(part);
                }
            }
        });
        $http.post(prefix + '/stocks/warehouses/action_on_returned_parts', 'parts=' + angular.toJson($scope.data)).success(function (data) {
            if(data['processed_returns']){
                var processed_returns = data['processed_returns'];
                $scope.$emit('stock_operational_returns',processed_returns);
                $scope.panel_content = {};
                $scope.$emit('sidepanel:close');
            }
        });
    }
    $scope.expandAll=false;
    $scope.collapseAll = function(){
        $scope.expandAll = $scope.expandAll===true ? false : true;

        angular.forEach($scope.panel_content, function (val,index) {
            val.detail_showing = $scope.expandAll;
        });
    }
    $scope.collapsableToggle = function(part) {
      part.detail_showing = part.detail_showing===true ? false : true;
      var a = [];
      angular.forEach($scope.panel_content, function (val,index) {
        a.push(val.detail_showing )
      });
      $scope.expandAll = _.contains(a,true) ? true  : false;
    };

}

function StockLocationSpreadSheetCtrl($scope, $state, $http, prefix, $timeout, $rootScope, getIdData, confirmationBoxHelper,warningModal,toastBox, $translate, $filter, $compile){
    $scope.show_part_handsontable_spreadsheet = false;
    $scope.partHotId = "Addnewpart";
    $scope.isErrorReturn = false;
    $scope.colHeadersParts = getIdData.colHeaders;
    $scope.startColsParts = getIdData.startColsParts;
    $scope.spreadSheetParts = '';
    $scope.selectedPartsDetails = getIdData.selectedPartsDetails;
    $scope.selectedStockLocation = getIdData.locationId;
    $scope.selectedStockLocationName = getIdData.locationName;
    $scope.vatRateList = getIdData.vatRateList;
    $scope.breadcrumbs_str = 'Manually adjust stock'
    // $scope.colHeadersRequired[$scope.partHotId] = getIdData.colHeadersRequired;
    $scope.addUrl =  prefix + '/stocks/save_new_part_spreadsheet';


    $scope.addNewPart = function (event) {
        $scope.saving = true;
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&rowsToDelete=" + rowsToDelete + "&stockLocation=" + $scope.selectedStockLocation).
        success(function (data, status) {
            if (status == 200) {
                $scope.saving = false;
                if(data.error) {
                    warningModal.show(data.message, 'Error', 'add_part_error');
                    $scope.isErrorReturn = true;
                    $scope.spreadSheetErrorRows(data.partInvalidRows);

                } else {
                    $state.transitionTo("loggedin.stock_locations_view.stock_location_details", {'id': $scope.selectedStockLocation});
                }
            }
        });
    }

    /*** Loaded new spreadsheet ***/
    stockaddnewpartSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout,toastBox, $translate, $filter, $compile);
    $scope.listOfTaxItems = [];
    internalizationTaxSettings.call(this,$scope);
    $scope.getVatGroupByProvinces($scope.vatRateList);
}


function stockaddnewpartSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout,toastBox, $translate, $filter, $compile)
{
    $scope.spreadSheetParts = [];
    $scope.isPartSpreadSheetEmpty = true;
    $scope.displaySpreadsheet = false;
    $scope.instanceLoad=[];
    $scope.colHeadersRequired =[];
    $scope.partScope = [];

    spreadsheetScroll.call(this, $scope);

    /*** Spreadsheet default settings for both parts & supplier ***/
    $scope.rowHeaders = true;
    $scope.startRows = 1;
    /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
    $scope.minSpareRows = 0;
    $scope.minRows = 1;
    $scope.width = "auto";
    $scope.autoWrapCol = true;
    $scope.autoWrapRow = true;
    $scope.partRowCount = 0;
    var screenType = 'StockAddnewpartToLocation';
    /*** Load the default data into parts spreadsheet ***/
    $scope.partsAfterInit= function() {

        /*** Get the default handsontable settings ***/
        $scope.updateSettings = this.updateSettings;

            /*** Load the default value when new row created ***/
            $scope.partDataSchema= {id: '', partsId: '', quantity: '', unitCost: '', vatRate: '', deleteAction: ""};
            $scope.partColWidths = [1,350,200,200,200,185];

            /*** Assign the spreadsheet columns and its cell type ***/
            $scope.partColumns = [
                {data: 'id', type: 'numeric', readOnly: true},
                { renderer: $scope.partRenderer, data: 'partsId', editor: 'select2', placeholder: 'Please select',
                    select2Options: {
                        editable: true,
                        minimumInputLength:2,
                        ajax: { url: prefix + '/get_parts_dropdown',
                            data: function (query, page, partIds, supplierId) {
                                if(query == '') {
                                    return false;
                                }
                                partIds = $scope.selectedPartIds;
                                //This is for select2 js
                                if($(this).val()){
                                    var selected_part_id = parseInt($(this).val());
                                    partIds = _.without(partIds,selected_part_id)
                                }
                                supplierId = '';
                                return { 'query': encodeURIComponent(query), 'selectedPartIds': partIds, 'supplierId': supplierId, 'screenType' : screenType};
                            },
                            results: function (data, page) {
                                $scope.partScope = data.parts;
                                $scope.defaultPartsData = data.defaultPartsData;

                                var formatResult = _.groupBy(data['parts'],'id');

                                var parts = [];
                                angular.forEach(formatResult, function(groupedParts,key) {
                                    parts[key] = groupedParts[0];
                                    var supplierRef = [];
                                    angular.forEach(groupedParts, function(part) {
                                        if(part['supplierpartnumber'] != null) {
                                            supplierRef.push({'supplierpartnumber' : part['supplierpartnumber'], 'suppliername' : part['suppliername']});
                                        }
                                    });
                                    parts[key]['supplierpartnumber'] = supplierRef;
                                });
                                return { results: Object.values(parts) };
                            }
                        },
                        formatResult: function(item, container, query, escapeMarkup) {
                            var splitSearchTerm = query.term.split(" ");
                            angular.forEach(splitSearchTerm, function (v,k) {
                                splitSearchTerm[k] = "(" + v + ")";
                            });
                            let regex = new RegExp(splitSearchTerm.join("|"), 'gi');
                            var htmlresult = item.text.replace(regex, function (i) { return '<u>' + i + '</u>'; });

                            if(item.manufacturepartnumber){
                                htmlresult += ', '+ $translate('MPN') + ' - ' + item.manufacturepartnumber.replace(regex, function (i) { return '<u>' + i + '</u>'; });
                            }

                            if(item.supplierpartnumber.length > 0) {
                                var spns = item.supplierpartnumber;

                                htmlresult += '<br>';
                                angular.forEach(spns, function (spn) {
                                    if(spn['suppliername'] != null && regex.test(spn['supplierpartnumber'])) {
                                        spn['supplierpartnumber'] = spn['supplierpartnumber'].replace(regex, function(i) { return '<u>' + i + '</u>' });
                                        htmlresult += '<div class="select2 supplier-product-code cs-text-muted"><span>' + $filter('translate')('product.code', { supplierName : spn['suppliername'] }) + '</span>: ' + spn['supplierpartnumber']+ '</div>';
                                    }
                                });

                            }
                            return $compile('<div class="select2 parts-list-dropdown">' + htmlresult + '</div>')($scope);
                        },
                        dropdownCssClass: "handsontable-select",
                        dropdownAutoWidth: true, width: 'resolve'
                    },
                    strict: true, allowInvalid: false
                },
                {renderer: $scope.partRenderer, data: 'quantity', type: 'numeric', allowInvalid: false, validator: $scope.cellValidator},
                {renderer: $scope.partRenderer, data: 'unitCost', type: 'numeric', allowInvalid: false, validator: $scope.cellValidator},
                { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
                    data: 'vatRate',
                    editor: 'select2',
                    placeholder: 'Please select',
                    validator: $scope.cellValidator,
                    select2Options: {
                        editable: true,
                        data: $scope.taxItemsGroupByProvinces,
                        dropdownAutoWidth: true,
                        dropdownCssClass: "handsontable-select",
                        width: 'resolve'
                    }
                },
                {renderer: $scope.partRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true}
            ];

            $scope.hidePartColumns = {columns:[0]};
            /*** Load the new settings into the spreadsheet and load them ***/
            $scope.updateSettings({colHeaders: $scope.colHeadersParts, fillHandle: {direction: 'vertical', autoInsertRow: true},
                rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsParts,
                colWidths: $scope.partColWidths, columns: $scope.partColumns, minSpareCols: 0,stretchH: 'all',
                autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetParts,hiddenColumns:$scope.hidePartColumns,
                formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.partDataSchema, minRows: $scope.minRows, viewportColumnRenderingOffset: 10,beforeValidate: $scope.beforeValidate,afterSelection : $scope.afterSelection}); /*dataSchema: $scope.dataSchema,*/

            $scope.displaySpreadsheet = true;
            $scope.show_part_handsontable_spreadsheet = true;

    }

    $scope.beforeValidate = function(value, row, prop, source){
        if(value != '') {
            var activeEditor = this.getActiveEditor(),
                col = activeEditor.col,
                removeHighlightRow = [],
                hotId = this.getSettings().hotId,
                cellType = activeEditor.cellProperties.type;

            if (prop == 'quantity') {
                $scope.numericLimitValidator(value, row, col, prop, 3, cellType, hotId);
            }else if (prop == 'unitCost') {
                $scope.numericLimitValidator(value, row, col, prop, 5, cellType, hotId);
            }
        }
    }

    //# After scroll vertical without change need to update the class name
    $scope.scrollingSpreadsheet = function(hotId, instance) {
        //# Update the class name after spreadsheet loaded.
        instance.updateSettings({className: 'overflow_handsontable'});
        instance.render();
    }


    $scope.numericLimitValidator = function (value, row, col, prop, limit, cellType, hotId) {
        if(value != '') {
            var isInvalid = false;
            if(isNaN(value))
            {
                toastBox.show('Invalid data', 1000);
            }

            if(isInvalid)
            {
                $scope.instanceLoad[hotId].setCellMeta(row,col,"className","errorRowList");
            }else{
                $scope.instanceLoad[hotId].setCellMeta(row,col,"className","");
            }
            $scope.instanceLoad[hotId].render();
        }
    };

    $scope.selectedDropDownValues= [];
    $scope.selectedSupplierDropDownValues=[];
    /*** Renderer for parts spreadsheet ***/
    $scope.partRenderer = function(instance, td, row, col, prop, value, cellProperties) {
        var cellTitle = instance.getCellMeta(row, col);
        var cellCase = cellTitle.prop;

        //# Used to scroll to the last row
        if((typeof instance.getActiveEditor() != 'undefined') && (instance.getActiveEditor().row == row)) {
            $scope.scrollToLastRow(row, col, instance);
        }

        switch(cellCase) {

            case 'partsId':
                //# Check its active editor
                $scope.isPartActiveEditor = $scope.checkActiveEditor(instance, col, row, true, false);

                //# Avoid while delete line items, the previous part description stored in empty line item.
                if(value == '') {
                    instance.getCell(row,col).innerText = '';
                    instance.getCell(row,col).innerHTML = '';
                }

                var partsId;
                //# In edit during load didn't get this scope value
                if($scope.partScope.length && instance.getActiveEditor().row == row) {
                    for (var index = 0; index < $scope.partScope.length; index++)
                    {
                        if (parseInt(value) === $scope.partScope[index].id)
                        {
                            partsId = $scope.partScope[index].id;
                            value = $scope.partScope[index].text;
                            if($scope.isPartActiveEditor) {
                                /*** Load the default value into the row once added in parts table ***/
                                $scope.spreadSheetParts[row]['quantity'] = 1;
                                $scope.spreadSheetParts[row]['unitCost'] = $scope.defaultPartsData[partsId].price;
                                $scope.spreadSheetParts[row]['vatRate'] = $scope.defaultPartsData[partsId].taxItemId;


                                //# Remove the error message css class name
                                $scope.instanceLoad[instance.getSettings().hotId].setCellMeta(row, 2, "className", "");
                                $scope.instanceLoad[instance.getSettings().hotId].setCellMeta(row, 3, "className", "");
                                $scope.selectedDropDownValues[partsId] = value;
                            }

                            //Handsontable.cellTypes.text.renderer.apply(this, arguments);
                            break;
                        }
                    }

                    //# Will execute if selected different part.
                    if(typeof partsId == 'undefined') {
                        partsId = value;

                        if(typeof $scope.selectedDropDownValues[parseInt(value)] != 'undefined') {
                            value = $scope.selectedDropDownValues[parseInt(value)];
                        } else if($scope.selectedPartsDetails != null) { //# Execute during edit screen and part not selected in dropdown list.
                            value = $scope.selectedPartsDetails[value];
                        }
                    }

                }
                else if($scope.selectedDropDownValues.length) { //# Will execute only validation error from backend call
                    partsId = value;

                    if(typeof $scope.selectedDropDownValues[parseInt(value)] != 'undefined') {
                        value = $scope.selectedDropDownValues[parseInt(value)];
                    } else if($scope.selectedPartsDetails != null) { //# Execute during edit screen and having error rows.
                        value = $scope.selectedPartsDetails[value];
                    }

                    // Handsontable.cellTypes.text.renderer.apply(this, arguments);
                } else if($scope.selectedPartsDetails != null) { //# Else part work only in edit section
                    partsId = value;
                    value = $scope.selectedPartsDetails[value];
                    //Handsontable.cellTypes.text.renderer.apply(this, arguments);
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;

            case 'quantity':
                //# Check and update with two decimal point in spreadsheet
                $scope.neworderQuantity = value > 0 ? parseFloat(value) : 1;
                $scope.spreadSheetParts[row]['quantity'] = $scope.neworderQuantity.toFixed(2);
                value = $scope.neworderQuantity.toFixed(2);
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'unitCost':
                //# Check and update with two decimal point in spreadsheet
                $scope.newunitCost = value > 0 ? parseFloat(value) : 0;
                $scope.spreadSheetParts[row]['unitCost'] = $scope.newunitCost.toFixed(4);
                value = $scope.newunitCost.toFixed(4);
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'vatRate':
                var selectedId;
                for (var index = 0; index < $scope.vatRateList.length; index++)
                {
                    if (parseInt(value) === $scope.vatRateList[index].id)
                    {
                        selectedId = $scope.vatRateList[index].id;
                        value = $scope.vatRateList[index].text;
                    }
                }
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'deleteAction':
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                var div = document.createElement('DIV');
                var a = document.createElement('a');
                var linkText = document.createTextNode("Delete");
                a.appendChild(linkText);
                a.title = "Delete Action";
                a.style= "cursor:pointer;"
                div.appendChild(a);
                $(td).empty().append(div).append(a);
                $(a).on('click', function(e) {
                    $scope.deleteAction(row, col, $scope.partHotId);
                });
                return td;
                break;
        }
    }

    /*** After change action from parts spreadsheet ***/
    $scope.partsAfterChange=function(changes, src) {

        //# Load the spreadsheet Instance record to alter the spreadsheet
        $scope.instanceLoad[$scope.partHotId] = this;
        const getSelect = this.getSelected();
        const selectedRow = (typeof  getSelect !== 'undefined') ? getSelect[0] : 0;

        //# If the current src is edit mode, then call the insert function
        if(src === 'edit') {
            $scope.insertRow(this, changes, src);

            if($scope.isErrorReturn) {
                var row = this.getActiveEditor().row;
                $scope.removeErrorMessage(row, $scope.partHotId, 'errorRowList');
            }

        }
        //# Load an empty default data during edit section
        else if((src === 'loadData' && $scope.spreadSheetParts.length)) {
            this.alter('insert_row', this.countRows());
            $timeout(function() {
                $scope.updateSettings({className: 'overflow_handsontable'});
                $scope.scrollingSpreadsheet($scope.partHotId, $scope.instanceLoad[$scope.partHotId]);
                $scope.instanceLoad[$scope.partHotId].render();
            }, 1000);
            this.render();

        }

        this.scrollViewportTo(selectedRow - 1, 1);

        //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
        if($scope.spreadSheetParts.length > 1) {
            $scope.partsSaveRecord = this.getData();

            //# Validate each cell from the row to enable the save button
            for(var i=0; i< $scope.partsSaveRecord.length-1; i++) {

                //# Get the single row details
                var row = $scope.partsSaveRecord[i];

                //# j=1 assigned to avoid the first column of 'primaryID' of the row.
                //# length-1 used to avoid the last column of 'delete' of the row
                for(var j=1; j<row.length-1; j++ ) {

                    //# Cell all the cells are valid or not
                    if(row[j] === '' || row[j] == null ) {
                        $scope.validateCell=true;
                        break;
                    } else {
                        $scope.validateCell=false;
                    }
                }
                //# If any cols is invalid then break the loop.
                if($scope.validateCell == true) {
                    $scope.isPartSpreadSheetEmpty=true;
                    break;
                } else {
                    $scope.isPartSpreadSheetEmpty=false;
                }
            }
        }

        if(screenType == 'StockAddnewpartToLocation'){
            var validationCond = $scope.spreadSheetParts.length > 0;
            var emptyRow = false;
        }else{
            var validationCond = $scope.spreadSheetParts.length > 1;
            var emptyRow = true;
        }

        //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
        if(validationCond) {
            $scope.partsSaveRecord = this.getData();
            if(emptyRow == true){
                var limit = $scope.partsSaveRecord.length-1;
            }else if(emptyRow == false){
                var limit = $scope.partsSaveRecord.length;
            }

            //# Validate each cell from the row to enable the save button
            $scope.spreadSheetValidation($scope.partsSaveRecord, $scope.partHotId, limit);
            var colData = this.getDataAtCol(1);
            $scope.partRowCount = $scope.NonEmptyRowsCount(colData);
        }
    }
    $scope.NonEmptyRowsCount = function NonEmptyRowsCount(colData){
        var nonEmptyRowsCount = colData.reduce(function (count, data) {
            return $scope.isColEmpty(data) ? count : count + 1;
        }, 0);
        return nonEmptyRowsCount;
    }
    $scope.isColEmpty = function isColEmpty(value){
        return typeof value == 'undefined' || value === null || value.length === 0;
    }
    $scope.spreadSheetValidation = function(saveRecords, isSpreadSheetEmpty, limit) {
        $scope[isSpreadSheetEmpty] = true;

        //# Relax the cell validation.
        var releaseCells = [7];

        for(var i=0; i< limit; i++) {

            //# Get the single row details
            var row = saveRecords[i];

            //# j=1 assigned to avoid the first column of 'primaryID' of the row.
            //# length-1 used to avoid the last column of 'delete' of the row
            for(var j=1; j<row.length-1; j++ ) {

                //# Relax the validation for particular cell
                var cellRelax = releaseCells.indexOf(j);

                //# Cell all the cells are valid or not
                if((row[j] === '' || row[j] == null) && cellRelax == -1 ) {
                    $scope.validateCell=true;
                    break;
                } else {
                    $scope.validateCell=false;
                }
            }
            //# If any cols is invalid then break the loop.
            if($scope.validateCell == true) {
                $scope[isSpreadSheetEmpty]=true;
                break;
            } else {
                $scope[isSpreadSheetEmpty]=false;
            }
        }
    }

    $scope.deleteAction = function(r, c, hotId) {
        var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
        if(c === ($scope.instanceLoad[hotId].getInstance().countCols() -1)) {
            //# Before delete confirm
            confirmationBoxHelper.getConfirmation(confirmation_message, this)
                .then(function() {

                    //# Get the primary id of the edit screen, to delete from backend
                    var rowId=$scope.instanceLoad[hotId].getSourceDataAtRow(r);
                    if((typeof rowId != 'undefined') && rowId.id) {
                        $scope.rowToDelete.push(rowId.id);
                    }
                    var ColData = $scope.instanceLoad[hotId].getDataAtCol(1);
                    $scope.partRowCount = $scope.NonEmptyRowsCount(ColData);

                    $scope.instanceLoad[hotId].alter('remove_row', r);
                    $scope.instanceLoad[hotId].render();

                }, function() {
                    return false
                });
        }
    }

    $scope.tabMoves= function (event) {
        if(event.shiftKey) {
            return {row: 2, col: 1};
        }
        else {
            return {row: 0, col: 1};
        }
    }

    /** Check the cell validation ***/
    $scope.cellValidator = function (value, callback) {

        if(value != '') {
            if(this.type == 'numeric' && isNaN(value)) {
                callback(false);
            }
            callback(true);
        } else {
            callback(false);
        }
    }

    /*** If edit the last row then need to insert a new row with default row ***/
    $scope.insertRow = function(currentScope,changes, src) {
        var description = currentScope.getDataAtRow(currentScope.countRows()-1);
        if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && src === 'edit' && description[1] != '') {
            currentScope.alter('insert_row', currentScope.countRows());
            $scope.scrollToLastRow(currentScope.getActiveEditor().row, currentScope.getActiveEditor().col, currentScope);
            currentScope.render();
        }
    }

    /*** Check the valid html tag ***/
    $scope.strip_tags = function(input, allowed) {
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

        // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

        return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    }

    /*** Get the current active editor and validate ***/
    $scope.checkActiveEditor = function(instance, col, row, checkCol, checkRow) {
        var activeEditor = instance.getActiveEditor();

        if(typeof activeEditor != 'undefined') {
            var cellTitle = instance.getCellMeta(activeEditor.row, activeEditor.col);
            var cellCase = cellTitle.prop;

            //# Used to update the default quantity/unit cost for changing the parts.
            var data = instance.getData();
            if(data[row][0] === '' && cellCase === 'partsId') {
                return true;
            }
        }
        return false;
    }

    /*** Once select the value from dropdown update the value in spreadsheet line items ***/
    $scope.alterSave = function(value, dropDownType) {

        $scope.isPartSpreadsheet = false;
        $scope.newSelectedValue = '';

        if($scope.isPartSpreadsheet) {
            $scope.spreadSheetParts = $scope.reLoadSpreadsheet($scope.spreadSheetParts, dropDownType, $scope.newSelectedValue, $scope.partHotId);

            /*** Replace the default schema value, when new screen it will automatically update it ***/
            $scope.partDataSchema[dropDownType]=$scope.newSelectedValue;
            $scope.instanceLoad[$scope.partHotId].updateSettings({dataSchema: $scope.partDataSchema});
            $scope.instanceLoad[$scope.partHotId].render();
        }
    }

    /*** Reload the spreadsheet data with hotId ***/
    $scope.reLoadSpreadsheet = function(spreadsheet, key, newValue, hotId) {

        for(var i = 0, l = spreadsheet.length; i < l; i++) {
            if(typeof spreadsheet[i][key] != 'undefined') {
                spreadsheet[i][key] = newValue;
            }
        }
        return spreadsheet;
    }

    /*** Display the error rows ***/
    $scope.spreadSheetErrorRows = function(partInvalidRows) {

        for(var i=0; i<partInvalidRows.length; i++) {
            var currentRow = partInvalidRows[i];
            $scope.displayErrorRows(currentRow, $scope.partHotId);
        }
    }

    /** Display the error row line items in spreadsheet **/
    $scope.displayErrorRows = function(currentRow, hotId) {
        var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(currentRow);

        for(var j=0; j<currentMetaRow.length; j++ ) {
            $scope.instanceLoad[hotId].setCellMeta(currentRow,j,"className","errorRowList");
            $scope.instanceLoad[hotId].render();
        }
    }

    /** Remove the error class from the line item **/
    $scope.removeErrorMessage = function(row, hotId, toRemove) {
        var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(row);
        var reg = new RegExp('(\\s|^)'+toRemove+'(\\s|$)');

        for(var j=0; j<currentMetaRow.length; j++ ) {
            var newCssClass = currentMetaRow[j].className;

            if(newCssClass) {
                newCssClass=newCssClass.replace(reg,'');
                $scope.instanceLoad[hotId].setCellMeta(row,j,"className", newCssClass);
            }
        }
        $scope.instanceLoad[hotId].render();
    }

    $scope.afterSelection = function(value, row, prop, source){
        var instanceSettings = this.getSettings();
        if(row == 1)
        {
            instanceSettings.fillHandle = false;
        }else {
            instanceSettings.fillHandle = { direction: 'vertical', autoInsertRow: true};
        }

    }

    //# Update the column header with required field
    // $scope.afterGetColHeader = function(col, TH) {
    //     if($scope.colHeadersRequired[this.getSettings().hotId][col]) {
    //         TH.className='col_header_bg';
    //     }
    // }

}
