function SkillTypesCtrl($scope, $http, prefix, datasets, formService) {
    $scope.data = datasets;
    $scope.saving = false;

    $scope.performFormSubmit = function (url, formElem) {
        formService.partialPageSubmit(url, formElem).then(function (data) {
            if (data.error && data.error == 'Exists') {
                $scope.SkillForm['skill[description]'].$error.checkExists = true;
            } else {
                $scope.SkillForm.$setPristine();
                $scope.description = "";
                $scope.$broadcast("SKILL_ADDED", { 'id': data.id, 'description': data.description});
            }
            $scope.saving = false;
        });
    }
}

function SkillListEditCtrl($scope, $state, prefix, $http, skillsresolver, defaultPagingValue) {
    $scope.skills = skillsresolver.skills;
    $scope.skillsCount = skillsresolver.count;
    $scope.shouldBeOpen = false;
    $scope.confirmDeleteValidity = true;

    JustAddedUpdatedChanges.call(this, $scope, 'entity', $state, defaultPagingValue, 'skills');

    $scope.$on("SKILL_ADDED", function (event, message) {
        GetCurrentPage.call(this, $scope, $state, skillsresolver, message);
        if ($scope.currentPage == $scope.currentNewPage) {
            $scope.skills.push({'id': message.id, 'description': message.description, 'justadded': true});
            skillsresolver.skills = $scope.skills;
            $state.current.data.addedId = -1;
        }
        else {
            $scope.currentPage = $scope.currentNewPage;
        }
        skillsresolver.count = parseInt(skillsresolver.count) + 1
        $scope.skillsCount = skillsresolver.count;
    });

    $scope.editSkillType = function (id, description) {
        $state.transitionTo('loggedin.add_skill.edit', {'id': id, 'description': description, 'pageNum': $scope.currentPage, 'limit': $scope.limit})
    }

    $scope.triggerDelete = function (id, description) {
        $scope.selectedId = id;
        $scope.selectedDescription = description;
        $scope.shouldBeOpen = true;
    }

    $scope.fetchSkills = function (pageNum) {
        $http.get(prefix + '/list_skills?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.skills = data.skills;
            $scope.skillsCount = data.count;

            skillsresolver.skills = data.skills;
            skillsresolver.count = data.count;

            $scope.triggerJustAddedUpdatedTags();
        })
    }
}

function SkillEditCtrl($scope, $state, $http, prefix) {
    $scope.selectedSkill = $state.params.description;
    $scope.selectedId = $state.params.id;
    $scope.pageNum = $state.params.pageNum;
    $scope.limit = $state.params.limit;

    $scope.backTo = function () {
        $state.$current.parent.self.data.pageNum = $scope.pageNum;
        $state.$current.parent.self.data.limit = $scope.limit;
        $state.transitionTo("loggedin.add_skill");
    }

    $scope.editSkill = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_skill', "skill[id]=" + this.selectedId + "&skill[description]=" + encodeURIComponent(this.selectedSkill)).
            success(function (data, status) {
                if (status == 200) {
                    $scope.updating = false;
                    if (data.error && data.error == 'Exists') {
                        $scope.current.editSkillForm['selectedSkill'].$error.checkExists = true;
                    } else {
                        $state.$current.parent.self.data.pageNum = $scope.pageNum;
                        $state.$current.parent.self.data.limit = $scope.limit;
                        $state.$current.parent.self.data.editedId = $scope.selectedId;
                        $state.transitionTo("loggedin.add_skill");
                    }
                }
            });
    }
}