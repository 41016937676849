'use strict';

function ReportingCtrl($scope, $state, $http, prefix, datasets, $rootScope, reportNavSidebar, $stateParams, $timeout, paginationService) {
    $scope.operational = null;
    $scope.reportingWrapper = true;

    $scope.$on('is_operational', function(evt, value) {
	$scope.operational = value;
    });

    $scope.reportingInfiniteScroll = function() {
        $rootScope.$broadcast('operationalListInfiniteScroll');
        $rootScope.$broadcast('reportingInfiniteScroll');
    }

    $scope.$on('scrollEvent', function(evt, data) {
        $( "input.filter-search-field" ).autocomplete( "close" );
        //data.scrollTop > 110
        if (data.scrollTop > 41) {
            $scope.$apply(function() {
                $scope.stickyHeader = true;
                $rootScope.$broadcast('stickyHeader', true);
            });
        }
        else {
            $scope.$apply(function() {
                $scope.stickyHeader = false;
                $rootScope.$broadcast('stickyHeader', false);
            });
        }
    });

    /* To be used later after smarttable has been refactored */
    /*
     $scope.$on('reportingInfiniteScroll', function(evt, elem) {
     if (paginationService.currentPage < Math.ceil(paginationService.totalCount/paginationService.limit)) {
     paginationService.currentPage++;

     $rootScope.$broadcast('fetchRecordsWithFilters', paginationService.currentPage, true);
     }

     });
     */

    $scope.$on('scrollReportToTop', function(e, data) {
        $scope.scrollBackToTop(data);
//		$scope.searchbarId = data.id;
    });

    $scope.scrollBackToTop = function(settings) {
        var newtab = (settings) ? settings['newtab'] : undefined;
        var savePosition = (settings) ? settings['savePosition'] : undefined;
        var id = (settings) ? settings['id'] : undefined;
        var updateFiltersAfter = (settings) ? settings['updateFiltersAfter'] : false;

        var scrolltop = $('.infinite-scroll-wrapper').scrollTop();
        /*
         if (savePosition) {
         $scope.tabScrollPosition[$scope.mode] = scrolltop;
         }
         */
        if (scrolltop > 0 && scrolltop > 150) {
            $('.infinite-scroll-wrapper').scrollTop($('.infinite-scroll-wrapper').scrollTop() - 100);
            $timeout(function() {
                $scope.scrollBackToTop(settings);
            }, 10);
        }
        else if (scrolltop > 0 && scrolltop > 50) {
            $('.infinite-scroll-wrapper').scrollTop($('.infinite-scroll-wrapper').scrollTop() - 50);
            $timeout(function() {
                $scope.scrollBackToTop(settings);
            }, 10);
        }
        else if (scrolltop > 0 && scrolltop > 20) {
            $('.infinite-scroll-wrapper').scrollTop($('.infinite-scroll-wrapper').scrollTop() - 20);
            $timeout(function() {
                $scope.scrollBackToTop(settings);
            }, 10);
        }
        else if (scrolltop > 0 && scrolltop > 5) {
            $('.infinite-scroll-wrapper').scrollTop($('.infinite-scroll-wrapper').scrollTop() - 5);
            $timeout(function() {
                $scope.scrollBackToTop(settings);
            }, 10);
        }
        else if (scrolltop > 0 && scrolltop > 1) {
            $('.infinite-scroll-wrapper').scrollTop($('.infinite-scroll-wrapper').scrollTop() - 1);
            $timeout(function() {
                $scope.scrollBackToTop(settings);
            }, 10);
        }
        else {
            $rootScope.$broadcast('reportingTableSpinner', true);
            $rootScope.$broadcast('stickyHeader', false);
            if (newtab) {
                $state.go('loggedin.reporting.operational', {category: $state.params.category, subcategory: $state.params.subcategory, type: newtab});
            }
            else {
                $rootScope.$broadcast('updateFilters', settings);
            }
        }
    };

    $scope.$on('beforeAddTagAction', function(e, data) {
        /*
         if ($scope.stickyHeader) {
         $rootScope.$broadcast('scrollOperationalListToTop', {'updateFiltersAfter': true, 'id': data.id});
         }
         if (!$scope.stickyHeader) {
         */
        $rootScope.$broadcast('updateFilters', {id: data.id});
//	}
    });

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        if (toState.name === 'loggedin.reporting.dashboard') {
            $scope.in_dashboard = true;
            $scope.showBreadcrumbs = false;
             // Reset the breadcrumb
        }else{
            $scope.in_dashboard = false;
        }
    });

    $scope.data = datasets;
    $scope.hasTotalSummary = false;
    reportNavSidebar.getReportingSubnavItems().then(function(subnavItems) {
        $scope.firstTierItems = subnavItems;
        if(!$rootScope.accessDetails[657]){
            for ( let val = 0; val < $scope.firstTierItems.length; val++){
                if($scope.firstTierItems[val].id == "sales_proposals"){
                    let selectedindex = $scope.firstTierItems[val]['secondTier'].findIndex(name=>name.id==="wonloss");
                    if(selectedindex !== -1) {
                        $scope.firstTierItems[val]['secondTier'].splice(selectedindex,1)
                    }
                }
            }
        }
    });
    $scope.$on('toReportIndex:hasSummaryTotal', function (event, data) {
        $scope.hasTotalSummary = data;
    });
    $scope.state = $state;
    $scope.showBreadcrumbs = false;

    $scope.predefinedReport = function(item) {
        return !$scope.savedReport(item);
    }

    $scope.savedReport = function(item) {
        return item.hasOwnProperty('custom');
    }

    $scope.$on('reporting:refreshentries', function(evt, data) {
        reportNavSidebar.getReportingSubnavItems().then(function(subnavItems) {
            $scope.firstTierItems = subnavItems;
        }).then(function(d) {
            if (data.reload) {
                $state.transitionTo("loggedin.reporting");
            }
        })
    });

    $scope.$on('reporting:viewchanged', function(evt, data) {
        $scope.showBreadcrumbs = true;
        $scope.breadcrumb = '';
	if ($scope.operational) {
	    data.category = data.category.replace('reporting_operational_list_','')
		if (data['subcategory'] != 'parts') {
			data.subcategory = data['subcategory'];
		}else{
            data.subcategory = 'parts_operational_list';
        }
	}
        Object.keys(data).forEach(function (name) {
            let resultStr = '';
            if (name == 'category') {
                let category = data[name];
                 resultStr = category[0].toUpperCase()+category.substr(1);
            } else if (name == 'subcategory') {
                let nameStr = data[name];
                if(nameStr != "financerequest"){
                    let splittedData = nameStr.split("_");
                    let firstWord = splittedData[0];
                    let firstLetter = firstWord.substring(0, 1);
                    let restofletters = firstWord.substring(1);
                    let firstLetterCaps = firstLetter.toUpperCase();
                    let newFirstWord = firstLetterCaps + restofletters;
                    let stringExceptFirstWord = splittedData.slice(1);
                    resultStr = newFirstWord + ' ' + stringExceptFirstWord.join(' ')
                } else {
                    resultStr = "Finance request"
                }
            }
                return $scope.breadcrumb += ' / ' + resultStr;
        });
    });

    $scope.stateParams = $stateParams;
    $scope.toggleFirstTier = function toggleFirstTier(firstTierItem) {
        $scope.showSecondTier = true;
        $scope.selected = firstTierItem;
    }
    $scope.hideSubNavs = function hideSubNavs() {
        $scope.showFirstTier = false;
        $scope.showSecondTier = false;
    }

    $scope.goToCustomState = function(evt, customState, params) {
        evt.preventDefault();
        evt.stopPropagation();

        $state.transitionTo(customState, params);
        $scope.hideSubNavs();
    }
    $scope.is_schedule_view = false;
    $scope.$on('is_schedule_view_watch', function(evt, value) {
        $scope.is_schedule_view = value;
    });
}
function ReportingOperationalListViewCtrl($scope, $state, prefix, $http, tableCollection, $rootScope, $translate, $timeout, $filter, reportFilterSettings, jobData) {
    $scope.counts = {};
    $scope.selectedParts = {};
    $scope.selectedPartIds = [];

    $scope.tagText = "";
    $scope.tagLabel = "";
    $scope.tagValue = "";
    $scope.tagCondition = "";
    $scope.tagType = "";

    $scope.getSelectedParts = function() {
        return $scope.selectedParts;
    }

    $scope.$on('tabCounts', function(evt, tabcounts) {
        $scope.counts = tabcounts;
    });
    $scope.pdfvalue = $scope.category + '/' + $scope.subCategory;
    var baseRoute = prefix + '/' + $scope.category + '/' + $scope.subCategory;
    $scope.pdfRoute = baseRoute + '/printPdf?';
    $scope.excelRoute = baseRoute + '/downloadExcel?';

    $scope.$on("dateRangeFilter", function(event, message) {
        if (message.startdate != undefined && message.startdate != "" &&
            message.enddate != undefined && message.enddate != "") {
            $scope.dateValue = {
                'startDate': message.startdate + ' 00:00:00',
                'endDate': message.enddate + ' 23:59:59'
            };
            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
        } else {
            $scope.dateValue = undefined;
        }
        var filterData = {};
        if ($scope.dateValue) {
            filterData[$scope.tagValue] = $scope.dateValue;
        }

        var existingFilters = event.currentScope.filterData;
        angular.forEach(existingFilters, function(val, key) {
            if ($scope.dateRangeFilters.indexOf(key) == -1) {
                filterData[key] = val;
            }
        });
        $scope.pdfRoute = baseRoute + '/printPdf?&filter=' + angular.toJson(filterData);
        $scope.excelRoute = baseRoute + '/downloadExcel?&filter=' + angular.toJson(filterData);
        /*
         var toFilterValues = [];
         angular.forEach(filterData, function(val, key) {
         toFilterValues.push({
         'model_name' : key,
         'value' : val
         });
         });
         */
//		$rootScope.$broadcast('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, toFilterValues);
    });
};

function ReportingViewCtrl($scope, $state, $stateParams, prefix, $http, tableCollection, datasets, reportNavSidebar, formPanelCollection, dateRange, toastBox, $rootScope, $translate, $timeout,confirmationBoxHelper, reportFilterSettings, $filter, $cookies, temporaryCookies, $location,warningModal,$window) {
    if ($state.current.name.indexOf('operational') > -1 && ($state.params.category === 'reporting_operational_list_parts' || $state.params.category === 'incomplete_part_completed_closed_jobs' )) {
        $rootScope.$broadcast('is_operational', true);
        $scope.operational = true;
    }
    else {
        $rootScope.$broadcast('is_operational', false);
        $scope.operational = false;
    }

    if(datasets.smartTableActive){
        $scope.activeSmartTable= true;
        $scope.smartTableSettings = {
            metadata:datasets['reporting_'+$state.params.category+'_'+datasets.smartTableActive].active_columns,
            rowSelect: false,
            columnEdit: true,
            params: {},
            dataUrl: 'reporting/view/'+$state.params.category+'/'+$state.params.subcategory,
            category: 'reporting_'+$state.params.category+'_'+datasets.smartTableActive,
            subcategory: 'reporting_'+$state.params.category+'_'+$state.params.subcategory,
            actions: []
        };
        $scope.smartTableSettings.params = datasets.filterDate;
        $scope.setSmartTableInstance = function($event) {
            $scope.$smartTableInstance = $event;
        };
    }else{
        $scope.activeSmartTable= false;
    }

    if($state.params.category == "debtors"){
        $scope.smartTableSettings.rowSelect = true;
        $scope.smartTableSettings.actions = [
            {
                title: 'View invoices',
                visible: (data,index) => {
                    var pkey = data['pkeysInvoiceAddress'];
                    return (pkey != -1);
                },
                permissions: [],
                onClick: function(data, buttonProp, smartTableInstance, index) {
                    return $scope.handleDebtorSalesReportingMenu(data,'sales','sales_report');
                }
            },
            {
                title: 'View Invoiceaddress',
                visible: (data,index) => {
                    var pkey = data['pkeysInvoiceAddress'];
                    return (pkey != -1);
                },
                permissions: [],
                onClick: function(data, buttonProp, smartTableInstance, index) {
                    var Url = 'invoice_address/'+data.id+'/details/view';
                    $window.open(Url, '_blank');
                }
            },
            {
                title: 'View invoices',
                visible: (data,index) => {
                    var pkey = data['pkeysCustomerId'];
                    return (pkey != -1);
                },
                permissions: [],
                onClick: function(data, buttonProp, smartTableInstance, index) {
                    return $scope.handleDebtorSalesReportingMenu(data,'sales','sales_report');
                }
            },
            {
                title: 'View Property',
                visible: (data,index) => {
                    var pkey = data['pkeysCustomerId'];
                    return (pkey != -1);
                },
                permissions: [],
                onClick: function(data, buttonProp, smartTableInstance, index) {
                    var Url = 'customers/customer_list/'+data.customerId+'/view/property/view';
                    $window.open(Url, '_blank');
                }
            },

        ];
    }
    $scope.handleDebtorSalesReportingMenu = function handleDebtorSalesReportingMenu(data,buttonCategory,buttonSubCategory) {
        var url = '/reporting/view/'+buttonCategory+'/'+buttonSubCategory+'/viewSummary';
        var id = data['id'];
        $scope.filter = [
            {"model_name": "InvoiceAddressName", "value": id},
            {"model_name": "notpaid","value": true },
            {"model_name": "partiallypaid","value": true }];

        var putData = {
            'category': buttonCategory,
            'subcategory': buttonSubCategory,
            'filters': $scope.filter
        };
        var newWindow = window.open('about:blank', '_blank');
        $http.post(prefix + '/update_drill_down_filters', "data=" + encodeURIComponent(angular.toJson(putData))).then(function (response) {
            newWindow.location.href = url;
        });
    }

    $scope.initcall = datasets.initcall;
    $scope.datasets = datasets;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.category = $state.params.category;
    $scope.subCategory = $state.params.subcategory;
    $scope.filterDataInitialized = false;
    $scope.isSavedReport = false;
    $scope.originalType = null;
    $scope.selectedSubCategory = $scope.subCategory;
    $scope.deleteReport = false;
    $scope.hasTotalSummary = datasets.hasTotalSummary || false;
    var isFlag = _.has($stateParams,'flag') ? $stateParams['flag'] : false;
    var listUrl = "reporting/view/"+$scope.category+"/"+$scope.selectedSubCategory ;
    $scope.smartTableListUrl = isFlag ? listUrl + "?flag=1&donotshow=1" : listUrl ;

    $rootScope.$on('$stateChangeStart', function( event, to, toParams, from, fromParams) {
        if ($scope.operational===true || (fromParams['category'] !== toParams['category'] || fromParams['subcategory'] !== toParams['subcategory']) || ($state.params.category === 'stock_control' ) ) {
            $scope.inputTags = [];
            localStorage.removeItem('reportingOperationalListFilters_undefined');
            reportFilterSettings.stateChange(from, fromParams);
        }
    });

    $scope.recordCount = 0;
    reportFilterSettings.availableFilters = [{'label': 'Date', 'value': 'date'}];
    //$scope.availableFilterOptions = {};
    $scope.tagFilterPlaceholder = $filter('translate')("Filter.Instructions");
    $scope.currentDateRangeFilterName = "daterange";
    $scope.filterSettings = reportFilterSettings;
    $scope.filterSearchbarId = 'standard-report';

    if ($scope.operational) {
        var type = $scope.category + '_' + $scope.subCategory;
    }
    else {
        var type = 'reporting_' + $scope.category + '_' + $scope.subCategory;
        if (datasets[type].hasOwnProperty('from')) {
            var type = $scope.datasets[type]['from'];
        }
        $scope.recordCount = datasets[type].count;
    }

    $scope.sidepanelFilters = !!datasets.filter[type];
    // Filter configs, configurable from backend
    $scope.filtersConfig = {
        daterange: {
            dateRange: null,
            dropdownOptions: dateRange.getFilterOptions().map(function (op) { return op.string; }),
            customDateRangeView: 'default'
        }
    };

    if (!$scope.operational) {
        if(!datasets[type].hasOwnProperty('from')){
            if(datasets[type].hasOwnProperty('filtersConfig'))
                $scope.filtersConfig = mergeDeep($scope.filtersConfig, datasets[type].filtersConfig); // Applying filters on load
        }
        else {
            if(datasets[datasets[type].from].hasOwnProperty('filtersConfig'))
                $scope.filtersConfig = mergeDeep($scope.filtersConfig, datasets[datasets[type].from].filtersConfig); // Applying filters for saved report on load
        }
    }
    $scope.handleMassSendStatement = function handleMassSendStatement(){
        if($scope.recordCount > 5000){
            $scope.processMassSendStatement = false;
            var warningMessage = $translate('cannot.send.statement');
            warningModal.show(warningMessage, "Send statements", "mass_send_statement");
            $('#loading-indicator').hide();
        }else{
            $scope.processMassSendStatement = true;
        }
    }


    // Filtering date filter options to show only the required date filters, which configured from backend. Default: shows all date filters
    $scope.dateFilterOptions = dateRange.getFilterOptions().filter(function (option) {
        return $scope.filtersConfig.daterange.dropdownOptions.indexOf(option['string']) >= 0;
    }).map(function (option) {
        switch (option.string) {
            case "Date Range": {
                if ($scope.filtersConfig.daterange.customDateRangeView)
                    option.view = $scope.filtersConfig.daterange.customDateRangeView;
                break;
            }
        }

        return option;
    });
    $scope.$on('reportFilterLoading', function(evt, id, newvalue) {
        if (id == $scope.filterSearchbarId) {
            $scope.filterLoadingSpinner = newvalue;
        }

        else if(id == 'users_driver_trip'){
            $scope.filterLoadingSpinner = newvalue;
        }
    });

    $scope.$on('smarttable:record-count', function(evt, count) {
        $rootScope.$broadcast('reportFilterLoading', $scope.filterSearchbarId, false);
        $timeout(function() {
            $scope.filterLoadingSpinner = false;
        }, 0);
    });

    /*
     var filterStartRegexp = /^[^A-Za-z]+/;
     var filterEndRegexp = /[^A-Za-z]+$/;
     */

    if (datasets['filter']) {
        if ($scope.operational) {
            if (datasets['filter'][$scope.category + '_' + $scope.subCategory]) {
                if (reportFilterSettings.availableFilters = datasets.filter[$scope.category + '_' + $scope.subCategory][$state.params.type]) {
                    reportFilterSettings.availableFilters = datasets.filter[$scope.category + '_' + $scope.subCategory][$state.params.type]['fields'];
                    reportFilterSettings.availableTabFilters = datasets.filter[$scope.category + '_' + $scope.subCategory];
                }
            }
        }
        else {
            if (datasets['filter']['reporting_' + $scope.category + '_' + $scope.subCategory]) {
                reportFilterSettings.availableFilters = datasets.filter['reporting_' + $scope.category + '_' + $scope.subCategory]['fields'];
            }
        }
    }

    // Translating filter labels
    angular.forEach(reportFilterSettings.availableFilters, function(filter, key) {
        reportFilterSettings.availableFilters[key]['label'] = $filter('translate')(filter.label);
    });

    var type = 'reporting_' + $scope.category + '_' + $scope.subCategory;

    $scope.doesInputTagEqual = function(value, comparison) {
        var found = false;
        angular.forEach($scope.inputTags, function(tag, index) {
            if (tag['model_name'].toLowerCase() == value.toLowerCase()) {
                if (tag['value'] == comparison) {
                    found = true;
                }
            }
        });
        return found;
    };

    $scope.getTagLabelFromValue = function(value) {
        var label = value;
        angular.forEach(reportFilterSettings.availableFilters, function(filter, index) {
            if (filter.model == value) {
                label = filter.label;
            }
        });

        var capitalized = label[0];
        label = capitalized.toUpperCase() + label.slice(1);

        return label;
    };

    $scope.getAvailableFilters = function() {
        var targets = [];
        var dependentFilters = [];
        var limitedFilters = [];

        /* The code in this block isn't being used right now. Its purpose was to make it possible to show/hide specific filters in specific reports on page load. These settings would have been stored in $scope.filterSettings[$scope.category][$scope.subCategory]['conditionalTags']. For example, maybe you wanted to hide the 'address' filter on the Sales report for some reason. This would allow you to do that.
         */
        /*
         if ($scope.filterSettings[$scope.category]) {
         if ($scope.filterSettings[$scope.category][$scope.subCategory]) {
         if ($scope.filterSettings[$scope.category][$scope.subCategory]['conditionalTags']) {
         angular.forEach($scope.filterSettings[$scope.category][$scope.subCategory]['conditionalTags'], function(tag, id) {
         angular.forEach($scope.availableFilters, function(inputTag, inputTagIndex) {
         if (inputTag['value'] == id) {
         angular.forEach($scope.filterSettings[$scope.category][$scope.subCategory]['conditionalTags'][id]['dependsOn'], function(rule, index) {
         if ($scope.doesInputTagEqual(rule['key'],rule['value'])) {
         targets.push({'tag': inputTagIndex, 'visible': true});
         }
         else {

         targets.push({'tag': inputTagIndex, 'visible': false});
         }
         });
         }
         });
         });
         }
         }
         }
         */
        /* Process checkboxes and determine which ones should be checked (or not checked) in the dropdown menu */
        angular.forEach(reportFilterSettings.availableFilters, function(aftag, afindex) {
            if (aftag['type'] == 'checkbox') {
                if (aftag['checked']) {
                    var foundFilter = false;

                    angular.forEach($scope.inputTags, function(tag, tagIndex) {
                        if (tag['model'] == aftag['model']) {
                            foundFilter = true;
                        }
                    });
                    if (!foundFilter) {
                        aftag['checked'] = false;
                    }
                }
            }
        });
        if ($scope.inputTags.length > 0) {
            /* Adding filters which rely on a filter that has already been added. For example, the 'Not Paid' checkbox filter depends on the 'Type' filter being active. If this condition is met, then the 'Not Paid' filter is added to the list of visibile filters. */
            angular.forEach($scope.inputTags, function(tag, tagIndex) {
                if (tag['dependentFilters'] && !tag['dependentFiltersAdded']) {
                    angular.forEach(tag['dependentFilters'], function(dftag, dfindex) {
                        var pushFilter = true;

                        /* Double check that this filter hasn't been added already. This could happen when a user selects some checkboxes, then deletes a filter which those checkboxes depend on, re-adds the same filter, then tries to re-select the checkboxes. */
                        angular.forEach(reportFilterSettings.availableFilters, function(aftag, afindex) {
                            if (aftag.model == dftag.model) {
                                aftag['checked'] = false;
                                pushFilter = false;
                            }
                        });
                        if (pushFilter) {
                            reportFilterSettings.availableFilters.push(dftag);
                        }
                    });

                    tag['dependentFiltersAdded'] = true;
                }
            });

            /* Preventing duplicate filters from appearing in the filter dropdown */
            angular.forEach(reportFilterSettings.availableFilters, function(filter, index) {
                var showFilter = true;
                angular.forEach($scope.inputTags, function(tag, tagIndex) {
                    if (tag['model'] == filter['model']) {
                        // If this is true, it means this is a duplicate
                        reportFilterSettings.availableFilters[index]['checked'] = $scope.inputTags[tagIndex]['checked'];
                        reportFilterSettings.availableFilters[index]['inputTagsIndex'] = tagIndex;
                        $scope.inputTags[tagIndex]['inputTagsIndex'] = tagIndex;

                        if (tag['type'] !== 'checkbox') {
                            // Don't hide already-selected checkboxes. This is because they will be displayed as 'checked' or 'unchecked' in the UI depending on their state

                            // The reason why we're hiding non-checkboxes is because it's okay for already-selected checkbox filters to be shown, but it's not okay if filters of other types are shown twice (duplicates). The UI renders these filters differently.
                            showFilter = false;
                        }
                    }
                });

                /* Remove any filters that rely on another filter which hasn't been selected */
                if (filter['depends']) {
                    angular.forEach(filter['depends'], function(dependentarray, dependsonkey) {
                        angular.forEach(dependentarray, function(dependentarrayvalue, valueposition) {
                            var dav = dependentarrayvalue;
                            if (typeof dav == "string") {
                                dav = dav.toLowerCase();
                            }
                            filter['conditionFulfilled'] = false;
                            angular.forEach($scope.inputTags, function(dtag, dindex) {
                                if (dependsonkey.toLowerCase() == dtag.model.toLowerCase()) {
                                    dtagValue = dtag.value;
                                    if (typeof dtagValue == "string") {
                                        dtagValue = dtagValue.toLowerCase();
                                    }
                                    if (dav == dtagValue) {
                                        filter['conditionFulfilled'] = true;
                                    }
                                }
                            });
                            if (!filter['conditionFulfilled']) {
                                showFilter = false;
                            }
                        });
                    });

                }

                if (showFilter) {
                    filter['label'] = $filter('translate')(filter.label);
                    limitedFilters.push(filter);
                }
            });
        }
        else {
            limitedFilters = [];
            angular.forEach(reportFilterSettings.availableFilters, function(filter, index) {
                var showFilter = true;
                /* Remove any filters that rely on another filter which hasn't been selected */
                if (filter['depends']) {
                    angular.forEach(filter['depends'], function(dependsonvalue, dependsonkey) {
                        filter['conditionFulfilled'] = false;
                        angular.forEach($scope.inputTags, function(dtag, dindex) {
                            if (dependsonkey == dtag.model) {
                                filter['conditionFulfilled'] = true;
                            }
                        });
                        if (!filter['conditionFulfilled']) {
                            showFilter = false;
                        }
                    });

                }

                if (showFilter) {
                    filter['label'] = $filter('translate')(filter.label);
                    limitedFilters.push(filter);
                }
            });
        }
        return limitedFilters;
    };

    $scope.getFilterSettings = function() {
        if ($scope.filterSettings[$scope.category]) {
            if ($scope.filterSettings[$scope.category][$scope.subCategory]) {
                if ($scope.filterSettings[$scope.category][$scope.subCategory]['order']) {
                    return $scope.filterSettings[$scope.category][$scope.subCategory]['order'];
                }
            }
        }
        return [{'tags': true}, {'search': true}];
    };

    $scope.inputTags = [];
    $scope.settings = $scope.getFilterSettings();
    /*$scope.options = reportFilterSettings.availableFilterOptions;*/
    $scope.filterOrder = $scope.getFilterSettings();

    if ($scope.filterSettings[$scope.category]) {
        if ($scope.filterSettings[$scope.category][$scope.subCategory]) {
            var filterSettings = $scope.filterSettings[$scope.category][$scope.subCategory];
            if (filterSettings['order']) {
                angular.forEach(filterSettings['order'], function(settings, index) {
                    // Add daterange filter to the main filter array
                    if (filterSettings['daterange']) {
                        $scope.inputTags.push(filterSettings['daterange']);
                    }
                    // tags = filters
                    if (settings['tags']) {
                        $scope.inputTags = $scope.inputTags.concat(settings['tags']);
                    }
                });
            }
        }
    }

    var newTags = [];
    angular.forEach($scope.inputTags, function(tag, index) {
        if (tag !== "{}") {
            newTags.push(tag);
        }
    });
    /*
     if (reportFilterSettings.savedFilters) {
     newTags = reportFilterSettings.savedFilters;
     }
     */
//    if (reportFilterSettings.queryParamsExist() || reportFilterSettings.savedFilters) {
//        newTags = reportFilterSettings.getQueryParamsFull();
//    }

    reportFilterSettings.getAppliedFiltersWithMetadata({'category': $scope.category, 'subcategory': $scope.subCategory, 'filters': newTags, 'availableFilters': reportFilterSettings.availableFilters}).then(function(result) {
        $scope.inputTags = _.uniq(result,'model');
        reportFilterSettings.filtersFinishedLoading = true;
        $rootScope.$broadcast('reporting_filters:loaded', $scope.inputTags);
    }, function(error) {
        $scope.inputTags = angular.copy(newTags);
        reportFilterSettings.filtersFinishedLoading = true;
        $rootScope.$broadcast('reporting_filters:loaded', newTags);
    });

    $scope.$emit('toReportIndex:hasSummaryTotal', $scope.hasTotalSummary);

    if ($scope.operational) {
        var type = $scope.category + '_' + $scope.subCategory;
    }
    else {
        var type = 'reporting_' + $scope.category + '_' + $scope.subCategory;
    }

    // Emit before assigning the  real subcategory from which this report was taken.
    $scope.$emit('reporting:viewchanged', {
        'category': $scope.category,
        'subcategory': $scope.subCategory
    });

    if (!$scope.operational) {
        if (datasets[type].hasOwnProperty('from')) {

            $scope.isSavedReport = true;
            $scope.originalType = type;    // Save the original Type
            type = datasets[type]['from'];

            $scope.subCategory = type.substr(("reporting_" + $scope.category).length + 1);

            $scope.sidepanelFilters = !!datasets.filter['reporting_' + $scope.category + '_' + $scope.subCategory];
        }
    }

    $scope.categoryTitle = reportNavSidebar.getTitle($scope.category, 'firstTier', datasets.savedQueries);
    if ($scope.operational) {
        var category = (($scope.isSavedReport) ? $state.params.category : $scope.category).replace('reporting_operational_list_', '');
        var subCategory = $scope.isSavedReport? $state.params.subcategory: $scope.subCategory;
	    $scope.secondTier = reportNavSidebar.getSecondTierDetails(category, subCategory+'_operational_list', datasets.savedQueries, $scope.isSavedReport);
    }
    else {
	    $scope.secondTier = reportNavSidebar.getSecondTierDetails
		    ($scope.category, $scope.isSavedReport? $state.params.subcategory: $scope.subCategory, datasets.savedQueries, $scope.isSavedReport);
    }

    $scope.showDateFilter = true;
    if($scope.secondTier && ($scope.secondTier.state == 'creditors' || $scope.secondTier.state == 'debtors')) {
        $scope.showDateFilter = false;
    }

    $scope.$on('tabCustomer:successMessage', function (event, data) {
        $rootScope.successMessage = data;
        toastBox.show($rootScope.successMessage, 5000);

    });

    $scope.$on('countActiveFilters',function (event, data){
        $scope.filterData = data;
        $scope.countActiveFilters();
    });

    $scope.needsActionMenu = false;

    if (!$scope.operational) {
        if (datasets[type].hasOwnProperty("needsActionMenu")) {
            $scope.needsActionMenu = true;
        }
    }

    $scope.needsActionCol = false;
    if (!$scope.operational) {
        if (type === "reporting_finance_financerequest") {
            $scope.needsActionCol = true;
        }
    }

    $scope.tableCollection = tableCollection;
    tableCollection.summaryRowAdded = false;
    tableCollection.setData(type, datasets[type]);
    if(type === 'reporting_customers_asset'){
        tableCollection.setHeaders(type,datasets[type]);
    }
    formPanelCollection.setFilterData(datasets.filter);

    formPanelCollection.setMoreFilterData('new_saved_report', {
        'fields' : [{
            'label': 'Enter report name',
            'type': 'text',
            'error_message' : 'Please enter name for report',
            'required': true,
            'model' : 'saved_query'
        }]
    });

    $scope.getPdfPageName = function(categoryTitle, secondTierItem) {
        categoryTitle = $translate(categoryTitle);
        secondTierItem = $translate(secondTierItem);

        return categoryTitle + ' - ' + secondTierItem;
    }

    $scope.pdfvalue = $scope.category + '/' + $scope.subCategory;
    var baseRoute = prefix + '/' + $scope.category + '/' + $scope.subCategory;
    $scope.pdfRoute = baseRoute + '/printPdf?';
    $scope.excelRoute = baseRoute + '/downloadExcel?';
    if(datasets.filterDate){
        $scope.pdfRoute = baseRoute + '/printPdf?&filter=' + datasets.filterDate;
        $scope.excelRoute = baseRoute + '/downloadExcel?&filter=' + datasets.filterDate;
    }

    $scope.$on("dateRangeFilter", function(event, message) {
        if (message.startdate != undefined && message.startdate != "" &&
            message.enddate != undefined && message.enddate != "") {
            if($scope.subCategory === 'contracts'){
                let startDate,endDate;
                startDate = moment(message.startdate).toISOString();
                startDate = moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss');
                endDate = moment(message.enddate).set({hour:23,minute:59,second:59,millisecond:59}).toISOString();
                endDate = moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss');
                $scope.dateValue = {
                    'startDate': startDate,
                    'endDate': endDate,
                };
            }else{
                $scope.dateValue = {
                    'startDate': message.startdate + ' 00:00:00',
                    'endDate': message.enddate + ' 23:59:59'
                };
            }

            $scope.selectedRange = message.string;
            $scope.startDate = message.startdate;
            $scope.endDate = message.enddate;
        } else {
            $scope.dateValue = undefined;
        }
        var filterData = {};

        var existingFilters = event.currentScope.filterData;
        angular.forEach(existingFilters, function(val, key) {
            if (key != 'daterange') {
                filterData[key] = val;
            }
        });

        if($scope.mode === 'filter-key-select' && $scope.inputTags.length > 0 && $scope.dateValue){
            filterData['daterange'] = $scope.dateValue;
        }

        $scope.pdfRoute = baseRoute + '/printPdf?&filter=' + angular.toJson(filterData);
        $scope.excelRoute = baseRoute + '/downloadExcel?&filter=' + angular.toJson(filterData);
        /*
         var toFilterValues = [];
         angular.forEach(filterData, function(val, key) {
         toFilterValues.push({
         'model_name' : key,
         'value' : val
         });
         });
         */
//		$scope.$broadcast('tosidepanel:filtervalues', toFilterValues);
    });

    $scope.$on('fromsmarttable:filters', function(evt, data) {
        var extraData = [];
        extraData.push({
            'model_name': 'daterange',
            'value': $scope.dateValue
        });
        var storedEvent = evt;
        // The !$evt.targetScope.filterData is to make sure that the original filters arent applied when pagination happens
        // HACKY! but the whole sidepanel is!
        if (($scope.isSavedReport && !evt.targetScope.filterData) || $scope.filtersConfig) {
//            var filters = angular.copy($scope.filtersConfig);
            var filters = angular.copy($scope.inputTags);

            // Skipping the filter settings for the changed values
            if (evt.targetScope.filterData) angular.forEach(angular.fromJson(evt.targetScope.filterData), function (val, key) {
                if (filters[key]) delete filters[key];
            });
            var hasDateRangePreFilter = null;
            if(!$scope.isSavedReport)
                hasDateRangePreFilter = !!filters.daterange && filters.daterange.dateRange;

            if ($scope.secondTier && $scope.secondTier.filters) Object.assign(filters, $scope.secondTier.filters);

            angular.forEach(filters, function (val, key) {

                if (key == 'daterange') {
                    var selectedDateRange = _.filter($scope.dateFilterOptions, function (elem) {
                        return elem.string == val.dateRange;
                    });
                    if (selectedDateRange.length>0) {
                        if (selectedDateRange[0].string == 'Date Range') {
                            selectedDateRange[0].startdate = val.startDate;
                            selectedDateRange[0].enddate = val.endDate;
                        }
                        val = selectedDateRange[0]; // Reset the value so that it works properly for date-filters
                        var dateRegx = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');

                        val.startDate = dateRegx.test(val.startdate)? val.startdate + ' 00:00:00': val.startdate; // Backend needs it case-sensitive
                        val.endDate = dateRegx.test(val.enddate)? val.enddate + ' 23:59:59': val.enddate;

                        if (!hasDateRangePreFilter) {
                            $timeout(function () {
                                $scope.$emit('daterange:changed', selectedDateRange[0]);
                                $scope.$broadcast('daterange:changed', selectedDateRange[0]);
                            }, 600);
                        }
                        else {
                            $scope.selectedRange = val.string;
                            $scope.startDate = moment(val.startDate).format('YYYY-MM-DD');
                            $scope.endDate = moment(val.endDate).format('YYYY-MM-DD');

                            $scope.dateValue = {
                                'startDate': val.startDate,
                                'endDate': val.endDate
                            };
                        }
                    }

                    if(!val.startDate || !val.endDate) val = null;
                }

                if(val) extraData.push({
                    'model_name': key,
                    'value': val
                })
            });
        } else {
            angular.forEach(angular.fromJson(evt.targetScope.filterData), function (val, key) {
                extraData.push({
                    'model_name' : key,
                    'value': val
                })
            });
        }
        $scope.$broadcast('tosidepanel:filtervalues', extraData);
    });


    $scope.dateRangeClose = function(e) {
        var input = $(e.target).parents('.daterange_filter');
        var date_range_widget = $(e.target).parents('.daterange-container');
        if (input.length > 0 || date_range_widget.length > 0 || e.target.className == 'ng-binding muted') {
            e.preventDefault();
        } else {
            $scope.$broadcast('body:clicked', '');
        }
    }

    var off = $rootScope.$on('panelwithform:form_save_data', function(evt, data) {
        var savedQueryId = data.data.id;

        var filtersToSend = reportFilterSettings.getAppliedFilters({'category': $scope.category, 'subcategory': $scope.subCategory, 'filters': $scope.filterData, 'availableFilters': reportFilterSettings.availableFilters});

        if(datasets.smartTableActive){
            var activeColumn= datasets['reporting_'+$state.params.category+'_'+datasets.smartTableActive].active_columns;
        }else{
           var activeColumn = $scope.tableCollection.getOrderedColumns();
        }
        var postData = {
            'id': savedQueryId,
            'filters': filtersToSend,
            'columns': activeColumn
        };

        $http.post(prefix + '/add_saved_report_filters', "data=" + encodeURIComponent(angular.toJson(postData)))
            .success(function(res) {
                toastBox.show('Query saved');
                $scope.$emit('reporting:refreshentries', {});
            })
            .error(function(res) {
                toastBox.show('Query save failed');
            });
    });
    $scope.$on('$destroy', off);        // Turn off the listener when scope is unbound

    // This is required because the side panel filters need to be sent to smart table fetchRecords.
    // Gets emitted from SidePanel when the "Filter" button is pressed.
    $scope.$on('panel_with_form:filter:reporting_' + $scope.category + '_' + $scope.subCategory, function(evt, data, extraData) {
        $scope.filterData = {};
        var _extraData = _.isUndefined(extraData) ? {} : extraData;

        $scope.filterData = reportFilterSettings.simplifyFilters(data);

        $scope.filterData = reportFilterSettings.getAppliedFilters({'category': $scope.category, 'subcategory': $scope.subCategory, 'filters': $scope.filterData, 'availableFilters': reportFilterSettings.availableFilters});

        $scope.pdfRoute = baseRoute + '/printPdf?&filter=' + angular.toJson($scope.filterData);
        $scope.excelRoute = baseRoute + '/downloadExcel?&filter=' + angular.toJson($scope.filterData);

        /* Events fired after fetching new filtered results */
        if($scope.initcall!==true) {
            $rootScope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData), _extraData);
        }
        $scope.initcall=false;
        $scope.$broadcast('operational_table:filtervalues', angular.toJson($scope.filterData));

        if ($scope.hasTotalSummary === true) {
            $http.post(prefix + '/viewSummary?category=' + $scope.category+ '&subcategory=' + $scope.subCategory + '&filter=' +angular.toJson($scope.filterData)).
            success(function (data, status) {
                if(status == 200){
                    var result = data;
                    //$scope.$on('smarttable:record-count',function(event, data){ console.log('abac')});
                    $scope.$emit('toReporting:Summary', result);
                }
            });
        }

        $scope.countActiveFilters();
    });

    $scope.filterData = {};

    //# Handle new filter for timesheet
    if($scope.subCategory == 'timesheet_approval') {
        $scope.timesheetTab = '';
    }

    //# Function for to update the timesheet scope value for featching new data
    $scope.changeTimeSheetTab = function(data) {
        $scope.timesheetTab = data;
    }

    $scope.show_clear_filter_link = false;
    $scope.number_of_filters = 0;
    var testReport = 'reporting_'+$state.params.category+'_'+$scope.selectedSubCategory ;
    if((typeof $scope.datasets[testReport] != 'undefined') && ($scope.datasets[testReport]['storedFilter'])){
        $scope.filterData=$scope.datasets[testReport]['storedFilter'];
    }

    $scope.countActiveFilters = function() {
        $scope.show_clear_filter_link = false;
        $scope.number_of_filters = 0;

        for (var key in $scope.filterData) {
            var value = $scope.filterData[key],
                value_exists = value &&
                    value !== "all" &&
                    value.toString().length > 0

            if (value_exists) {
                $scope.show_clear_filter_link = true;
                $scope.number_of_filters ++;
            }
        }
        $scope.exportData = {'reportTypeCategory': $scope.category, 'reportTypeSubCategory': $scope.subCategory, 'filterData':(Object.keys($scope.filterData).length > 0)?$scope.filterData:null};

        if(datasets.smartTableActive){
            $scope.smartTableSettings.params = $scope.filterData;
            $scope.$smartTableInstance.reload();
            $scope.filterLoadingSpinner = false;
        }
    }

    $scope.clearFilters = function() {
        $scope.filterData = {};
        $scope.dateValue = undefined;
        $scope.secondTier.filters = {};

        $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('smarttable_and_sidepanel:filtervalues', angular.toJson($scope.filterData));
        $scope.$broadcast('event:reset_date_filter');
        $scope.countActiveFilters();
        $scope.pdfRoute = baseRoute + '/printPdf?&filter=' + angular.toJson($scope.filterData);
        $scope.excelRoute = baseRoute + '/downloadExcel?&filter=' + angular.toJson($scope.filterData);
        if($scope.hasTotalSummary === true){
            $http.post(prefix + '/viewSummary?category=' + $scope.category+ '&subcategory=' + $scope.subCategory).
            success(function (data, status) {
                if (status == 200) {
                    var result = data;
                    $scope.$on('smarttable:record-count', function(event, data){
                        $scope.$emit('toReporting:Summary', result);
                    });
                }
            });
        }
    }

    $scope.clearOperationalFilters = function(){
        $scope.$broadcast('clearOpertionalFilters', true);
    }


    $scope.massPrintConfirmModal = false;
    $scope.$on('smarttable:record-count', function(evt, value) {
        $scope.recordCount = value;
    });

    $scope.hasExport = false;
    if (!$scope.operational) {
        if(datasets[type]['exportPdf']) {
            $scope.hasExport = true;
        }
    }

    $scope.openExportReportModal = function() {
        if ($scope.recordCount <= 5000) {
            $scope.exportReportProps.modalOpen = true;
        }
        else{
            var warningMessage = $translate('Report.not.be.downloaded/printed');
            warningModal.show(warningMessage, "Report Download/Print", "report_warning_message");
            $('#loading-indicator').hide();
        }
    }

    $scope.closeExportReportModal = function() {
        $scope.exportReportProps.modalOpen = false;
        $scope.exportReportProps.exportHasFinished = false;
        $scope.exportReportProps.exportConfirmation = false;
        $scope.exportReportProps.exportCount = 0;
        $scope.exportReportProps.estimatedHours = 0;
    }

    $scope.exportReport = function() {
        $('#loading-indicator').show();
        $scope.exportReportProps.exportHasFinished = false;
        $scope.exportReportProps.exportIsProcessing = true;
    }
    $scope.completeFunc = function(){
        $('#loading-indicator').hide();
        $scope.exportReportProps.exportIsProcessing = false;
        $scope.exportReportProps.exportHasFinished = true;
    }


    $scope.confirmExportReport = function() {
        $scope.filterObjectLength = Object.keys($scope.filterData).length;

        $http.post(prefix + '/confirm_export_reporting_pdf', 'recordCount=' + $scope.recordCount).success(function (data, status) {
            $scope.postparams = '?recordCount=' + $scope.recordCount;
            $scope.exportCount = data.recordCount;
            $scope.exportConfirmation = data.exportConfirmation;
            $scope.estimatedHours = data.estimatedHours;
            $scope.Filtercategory = $scope.category;
            $scope.filterParams = encodeURIComponent(angular.toJson($scope.filterData));
            $scope.filterLength = $scope.filterObjectLength;
            $scope.CountGearmanProcess = data.CountGearmanProcess;
            $scope.CountSalesProcess = data.CountSalesProcess;
            $scope.CountDebtorsProcess = data.CountDebtorsProcess;
        });

        if($scope.recordCount >= 100 || $scope.filterObjectLength == 0 || $scope.CountGearmanProcess > 0)
        {
            $scope.massPrintConfirmModal = true;
        }else
        {
            $('#loading-indicator').show();
            $scope.exportReportProps.exportHasFinished = false;
            $scope.exportReportProps.exportIsProcessing = true;
            $http.post(prefix + '/export_reporting_pdf/' + $scope.category, 'filter=' + encodeURIComponent(angular.toJson($scope.filterData))).success(function (data, status) {
                if (status == 200) {
                    $('#loading-indicator').hide();
                    $scope.exportReportProps.exportIsProcessing = false;
                    $scope.exportReportProps.exportHasFinished = true;
                }
            });
        }

    }

    $scope.exportReportProps = {
        exportOptions: [
            {
                name: 'Mass print',
                description: "You can mass print the PDF's in this report. This will download all documents in this report into a zip file onto your computer, and allow you to view or print the PDF's."
            }
        ],
        modalOpen: false,
        exportIsProcessing: false,
        exportHasFinished: false,
        exportConfirmation: false,
        checkedConfirmation: false,
        exportCount: 0,
        estimatedHours: 0,
        onSubmit: $scope.confirmExportReport,
        onClose: $scope.closeExportReportModal,
        onConfirm: $scope.exportReport
    }
    $scope.downloadReport = function(type) {
        $('#loading-indicator').show();
        $http.post(baseRoute + '/download_reporting_excel?filter=' + encodeURIComponent(angular.toJson($scope.filterData)) + '&page=' + $scope.categoryTitle + ' - ' +$scope.secondTier.item + '&type=' + type + '&recordsCount=' + btoa($scope.recordCount) ).
            success(function (data, status) {
            if(status == 200 && data.data == "RecordsCount"){
                var warningMessage = $translate('Report.not.be.downloaded/printed');
                warningModal.show(warningMessage, "Report Download/Print", "report_warning_message");
                $('#loading-indicator').hide();
            }
            else if (status == 200) {
                    $scope.flashMessage = $translate('Report.download.message');
                    $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                    $('#loading-indicator').hide();
                }
            });
    }

    $scope.printReport = function(type) {
        $('#loading-indicator').show();
            $http.post(baseRoute + '/print_report_pdf?filter=' + encodeURIComponent(angular.toJson($scope.filterData)) + '&page=' + $scope.categoryTitle + ' - ' +$scope.secondTier.item + '&type=' + type + '&recordsCount=' + btoa($scope.recordCount) ).
            success(function (data, status) {
            if(status == 200 && data.data == "RecordsCount"){
                var warningMessage = $translate('Report.not.be.downloaded/printed');
                warningModal.show(warningMessage, "Report Download/Print", "report_warning_message");
                $('#loading-indicator').hide();
            }
            else if (status == 200) {
                $scope.flashMessage = $translate('Report.print.message');
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                $('#loading-indicator').hide();
            }
            });
    }

    $scope.$on('fromsidepanel:inited', function(evt, data) {
        if ($scope.originalType && !$scope.filterDataInitialized) {
            if ($scope.secondTier.filters) {
                angular.forEach($scope.secondTier.filters, function (val, key) {
                    evt.targetScope[key] = val;
                });
                evt.targetScope.storeFilterModelValues();
            }
            $scope.filterDataInitialized = true;
            //evt.targetScope['customerType'] = "1";
            //evt.targetScope['addressLine1'] = '45 Russell Road';
        }
    })

    $scope.triggerDeleteReport = function deleteReport() {
        $scope.deleteReport = true;
    }

    $scope.deleteSavedReport = function deleteSavedReport() {
        $scope.$emit('reporting:refreshentries', {reload: true});
        toastBox.show('Saved report deleted');
    }
    /*
     if($scope.category == 'customers' && $scope.subCategory == 'advertising_performance'){
     $timeout(function() {
     $scope.$broadcast('daterange:changed', $scope.dateFilterOptions[6]);
     }, 3000);
     }
     */
    $scope.integrations = datasets.integrations;
    $scope.exportData = {'reportTypeCategory': $scope.category, 'reportTypeSubCategory': $scope.subCategory, 'filterData':(Object.keys($scope.filterData).length > 0)?$scope.filterData:null};
    $scope.$on('smarttable:record-count',function(event, data){
        if($scope.hasTotalSummary === true){
            $state.go('loggedin.reporting.view.summary',{'category':$scope.category,'subcategory':$scope.selectedSubCategory});
        }
    });
    $scope.processAllSendStatements = function processAllSendStatements(){
        $scope.selectedRows =$scope.$smartTableInstance.getSelectedRows();
        if($scope.selectedRows.length > 0){
            $scope.$smartTableInstance.reload();
            $http.post(prefix + '/reporting/mass_send_statement', "selectedRows=" + encodeURIComponent(JSON.stringify($scope.selectedRows)) + "&filter=").
            success(function (data, status) {
                if (status == 200) {
                    $scope.flashMessage = $translate('statement.processing.background');
                    $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                    $('#loading-indicator').hide();
                }
            });
        }else{
            $scope.massSendStatementFilter = $scope.pdfRoute.split('filter=')[1];
            $http.post(prefix + '/reporting/mass_send_statement', "selectedRows="+$scope.selectedRows.length+"&filter=" +$scope.massSendStatementFilter).
            success(function (data, status) {
                if (status == 200) {
                    $scope.flashMessage = $translate('statement.processing.background');
                    $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
                    $('#loading-indicator').hide();
                }
            });
        }

    }


    // Performs a deep merge of objects and returns new object
    function mergeDeep(baseObj, newObj) {
        var isObject = function (obj) {
            return obj && typeof obj === 'object';
        };

        var objects = [baseObj, newObj];

        return objects.reduce(function (prev, obj) {
            Object.keys(obj).forEach(function (key) {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = oVal;
                }
                else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = mergeDeep(pVal, oVal);
                }
                else {
                    prev[key] = oVal;
                }
            });

            return prev;
        }, {});
    }
}
function DashboardCtrl($scope, $state, $http, $timeout, prefix, datasets, toastBox, serFormatwidgetData, $q, $rootScope){

    $rootScope.selectedTab = 'reporting';

    var setWidgetDataToDashboard = function (widgetList, savePreference){
        $scope.current_dashboard = [];
        $scope.user_selected_widgets = 0;

        var arr_service = [];
        angular.forEach(widgetList, function(widget){
            if(widget.selected){
                $scope.user_selected_widgets ++;
                var service = $http.get(prefix + '/get_' + widget.key+ '_data_from_' + $scope.start_date_iso + '_to_' + $scope.end_date_iso);
                arr_service.push(service);
            }
        });
        var arr_widget_without_permission = [];
        $q.all(arr_service).then(function(result){
            angular.forEach(result, function(individual_result, result_index){
                if($rootScope.hasPermission(individual_result.data.featureid, 'readaccess')){
                    var widget_data = serFormatwidgetData.getDataForWidgetDirective(individual_result.data);
                    widget_data.widget = individual_result.data.widget;
                    $scope.current_dashboard.push(widget_data);
                }else{
                    arr_widget_without_permission.push(individual_result.data.widget);
                }
            });
            if(arr_widget_without_permission.length >0){
                RemovePermissionDeniedWidgetFromUserPreference(arr_widget_without_permission);
            }
            if(savePreference){
                saveUserWidgetPositionPreference();
            }
        })

    };
    var RemovePermissionDeniedWidgetFromUserPreference = function (arrWidgetsToBeRemoved){
        $http.post(prefix + '/remove_perission_denied_widget_from_preference', "widgets_to_be_removed="+angular.toJson(arrWidgetsToBeRemoved));
    }
    var saveUserWidgetPositionPreference = function (){
        /*
        *
        *
        */
        var data_to_save = [];
        var noofcols = 3;
        if (window.innerWidth < 1500) {
            noofcols = 2;
        }
        var max_widget_postion = ($scope.current_dashboard.length -1) * noofcols +1;    // Position of max widget if all the widgets are placed one below one
        var arr_widget_position = Array.apply(null, {length: max_widget_postion}).map(Number.call, Number);

        for(i = 0; i < $scope.current_dashboard.length; i ++){
            var widget = $scope.current_dashboard[i];
            if( !widget.hasOwnProperty('row') || !widget.hasOwnProperty('col')  ){
                var position = arr_widget_position[0];
                var data_obj = getRowColForArrayIndex(widget.widget, position, noofcols);
                var index_of_position = arr_widget_position.indexOf(position);
                arr_widget_position.splice(index_of_position,1);
            }else{
                var data_obj = {widget: widget.widget, row:widget.row, col:widget.col};
            }
            data_to_save.push(data_obj);
        }
        $http.post(prefix + '/save_dashboard_widget_preference', "selectedWidgets="+angular.toJson(data_to_save));
    }
    var getRowColForArrayIndex = function (widget, index, noofcols){
        var row = Math.floor(index / noofcols );
        var col = index % noofcols ;
        return {
                    widget: widget, row:row, col:col
            };
    };
    function bootstrapDates(momStartObj, momEndObj){
        $scope.start_date_iso =  momStartObj.format('YYYY-MM-DD');
        $scope.end_date_iso = momEndObj.format('YYYY-MM-DD');
        $scope.dateRangeDisplay =   momStartObj.format('Do MMMM YYYY') + ' - ' + momEndObj.format('Do MMMM YYYY');
    }

    $scope.saveUserPreference = function(){
        setWidgetDataToDashboard(datasets.customization, true);
        $scope.showHideDashboardCustomization('hide');
    };


    $scope.showHideDashboardCustomization = function (action){
        var modal = document.querySelector('#customise-dashboard');
        if(action.toLowerCase() =='show'){
            $(modal).modal('show');
            $scope.widget_customisation_saving = false;
        }else if (action.toLowerCase() =='hide'){
            $(modal).modal('hide');
        }
    };


    $scope.show_dashboard = true;
    bootstrapDates ( moment().add('-6','month').startOf('month'), moment());
    $scope.widgets_list = datasets.customization;
    $scope.user_selected_widgets = datasets.selected_count;
    setWidgetDataToDashboard(datasets.customization, false);

    $scope.handleWidget = function(widget, show_toast) {
        //widget.selected = !widget.selected;
        $timeout(function() {$scope.handleSettingGraphMaxWidth();}, 10);
    }

    $scope.reflowDashboard = function() {
        //$scope.current_dashboard = [];
        for (i = 0; i< $scope.current_dashboard.length; i++){
            $scope.handleWidget($scope.current_dashboard[i], false);
        }
    }

    var widget_position_before_saving={};
    var widget_size,
        gridster_columns = 3;
    if (window.innerWidth < 1500) {
        gridster_columns = 2;
    }
    $scope.gridsterOpts = {
        columns: gridster_columns,
        pushing: true,
        floating: true,
        width: 'auto',
        colWidth: 'auto',
        rowHeight: 400,
        margins: [20, 20],
        outerMargin: false,
        isMobile: false,
        mobileBreakPoint: 600,
        mobileModeEnabled: false,
        minSizeX: 1,
        maxSizeX: 3,
        minSizeY: 1,
        maxSizeY: 2,
        minColumns: 3,
        minRows: 2,
        maxRows: 100,
        defaultSizeX: 1,
        defaultSizeY: 1,
        resizable: {
            enabled: true,
            handles: ['e', 's'],
            start: function(event, widget, elm_context) {
                // When the widget starts resizing, disable hover events
                // on all charts
                var widget_x = elm_context.sizeX || 1;
                widget_size = widget.context.clientWidth / widget_x;
                var widgets = document.querySelectorAll('.reporting-widget');
                for (var i = 0, x = widgets.length; i < x; i ++) {
                    widgets[i].classList.add('disable-hover');
                }
            },
            resize: function(event, widget, elm_context) {
                // When the widget is re-sized, add CSS class to show or hide expanded detail
                // This code does not rely solely on gridsters sizeX or sizeY properties
                // -- this is because we want to introduce extra content when the widget
                // -- is resized past it's midpoint, rather than past it's start point.
                var widget_x = 1;
                // Set the sizeX to -1 to adjust for gridster overcompensating the width
                if (elm_context.sizeX > 1) {
                    widget_x = elm_context.sizeX - 1;
                }
                var new_widget_size = widget.context.clientWidth;
                if (widget_x === 2) {
                    if (new_widget_size >= (widget_size * 2.5)) {
                        $scope.handleResizedWidget(widget, elm_context, 0);
                    } else if ((new_widget_size / 2.5) < widget_size) {
                        // Set the modifer to -1 to adjust for gridster overcompensating the width
                        $scope.handleResizedWidget(widget, elm_context, -1);
                    }
                } else if (widget_x === 1) {
                    if (new_widget_size >= (widget_size * 1.5)) {
                        $scope.handleResizedWidget(widget, elm_context, 0);
                    } else if ((new_widget_size / 1.5) < widget_size) {
                        // Set the modifer to -1 to adjust for gridster overcompensating the width
                        $scope.handleResizedWidget(widget, elm_context, -1);
                    }
                }
                // Make sure that highcharts reflows the chart when the widget is resized
                $scope.reflowCharts();
            },
            stop: function(event, widget, elm_context) {
                $scope.handleResizedWidget(widget, elm_context, 0);
                $scope.reflowCharts();
                // When the widget is finished resizing, re-enable hober events
                var widgets = document.querySelectorAll('.reporting-widget');
                for (var i = 0, x = widgets.length; i < x; i ++) {
                    widgets[i].classList.remove('disable-hover');
                }
            }
        },
        draggable: {
            enabled: true,
            start: function(event, $element,     uiWidget) {
                widget_position_before_saving.row=uiWidget.row;
                widget_position_before_saving.col= uiWidget.col;
            },
            stop: function(event, $element,     uiWidget) {
                if(widget_position_before_saving.row!==uiWidget.row || widget_position_before_saving.col!= uiWidget.col){
                    saveUserWidgetPositionPreference('after the drag');
                }
            }
        }
    }



    $scope.handleResizedWidget = _.debounce(function(widget, elm_context, modifer) {
      // Add CSS classes based on the widgets size (row or column) -
      // -- this is used to conditionally show or hide extra content based on the widget size
        var content = widget[0].querySelector('.content-wrapper');
        content.className = 'content-wrapper';

        if (elm_context.sizeY) {
            content.classList.add('y-' + elm_context.sizeY);
        }
        if (elm_context.sizeX) {
            content.classList.add('x-' + (elm_context.sizeX + modifer));
        }
    }, 10);

    // Make highcharts re-size all charts - debounced as this is called on widget resize
    $scope.reflowCharts = _.debounce(function() {
        for (var i = 0; i < Highcharts.charts.length; i++) {
            if (Highcharts.charts[i]) {
                Highcharts.charts[i].reflow();
            }
        }
    }, 10);


    $scope.handleSettingGraphMaxWidth = _.debounce(function() {
        // Set the max width of the graph, so that when the widget is made bigger
        // the graph does not change in width. This is to ensure a nice user experience
        // when introducing extra content
        //
        // In addition, prevent the widget from being made bigger than it's max constraints
        // this is a poly-fill for gridster maxSizeX and maxSizeY UX.
        if(! document.querySelector('.dashboard-inner') ) {
          return null;
        }
        var widget_inners = document.querySelectorAll('.reporting-widget .content');

        var width_of_whole_dashboard = document.querySelector('.dashboard-inner').clientWidth,
            width_of_whole_dashboard_without_padding = width_of_whole_dashboard - 20,
            no_gridster_columns = $scope.gridsterOpts.columns,
            gridster_widget_spacing = 20,
            width_of_widget = (width_of_whole_dashboard_without_padding / no_gridster_columns) - gridster_widget_spacing,
            widget_inner_padding = 34,
            width_of_widget_content = width_of_widget - widget_inner_padding,
            height_of_gridster_row = $scope.gridsterOpts.rowHeight;

        for (var i = 0, x = widget_inners.length; i < x; i ++) {
            var widget = widget_inners[i],
                gridster_elm = widget.parentNode.parentNode.parentNode.parentNode,
                gridster_elm_max_width = (parseFloat(gridster_elm.getAttribute("data-max-size-x")) * (width_of_widget + gridster_widget_spacing)) - 20,
                gridster_elm_max_height = (parseFloat(gridster_elm.getAttribute("data-max-size-y")) * height_of_gridster_row) - 20;

            if (widget.classList.contains('with-expanded-detail')) {
                widget.style.width = width_of_widget_content + 'px';
            }
            gridster_elm.style.maxWidth = gridster_elm_max_width + 'px';
            gridster_elm.style.maxHeight = gridster_elm_max_height + 'px';
        }

        $scope.reflowCharts();
    }, 10);


    window.onresize = function(event) {
        windowResize('window on resize');
          // When the browser is narrow, make the dashboard two columns wide

    }
    function windowResize(from){
        //console.log('i am resized, and called from ' + from);
        $scope.handleSettingGraphMaxWidth();
        if (window.innerWidth < 1600 && $scope.gridsterOpts.columns === 3) {
            $scope.gridsterOpts.columns = 2;
            location.reload();
        } else if (window.innerWidth >= 1600 && $scope.gridsterOpts.columns === 2) {
            $scope.gridsterOpts.columns = 3;
            location.reload();
        }
    }

    // DEVELOPER NOTE - this dashboard array should come from the DB. For dev purposes, this array gets pushed into using the function below
    //$timeout(function(){ windowResize('timeout at end'); },5000);
}

function ReportingExport($scope, $rootScope, $http, $timeout, prefix, toastBox, $filter)
{
    $scope.loading = false;
    $scope.send_distribution_list = true;
    $scope.reportList = '';
    $scope.reportLists = ['Customer', 'Contact', 'Job address'];
    $scope.hide_title_and_actions = false;
    $scope.active_slide = 1;

    $scope.slideInView = function slideInView(slide) {
        $scope.active_slide = slide;
    }
    $scope.hideTitleAndActions = function hideTitleAndActions(showing) {
        $scope.hide_title_and_actions = showing;
    }
    $scope.reportTypeCategory = $scope.panel_content['reportTypeCategory'];
    $scope.reportTypeSubCategory = $scope.panel_content['reportTypeSubCategory'];
    $scope.collectExportData = function() {
        $scope.loading = true;
        $scope.send_distribution_list=true;
        $scope.filterData = $scope.panel_content['filterData'];
        var methodName = (this.reportList=='Contact')?$filter('capitalize')(this.reportList):$filter('capitalize')($scope.reportTypeCategory);
        $http.post(prefix + '/reporting_export?filter=' + encodeURIComponent(angular.toJson($scope.filterData)) + '&reportTypeCategory=' + $scope.reportTypeCategory + '&reportTypeSubCategory=' + $scope.reportTypeSubCategory + '&reportList=' + $scope.reportList + '&methodName=exportEmails' + methodName).
            success(function (data, status) {
                if (status == 200) {
                    $scope.sidePanelData = data;
                    $scope.sidePanelData[0] = {'distributionList': '',
                        'listName': '',
                        'listFromEmail': '',
                        'listFromName': '',
                        'listSubject': '',
                        'listCompanyName': $scope.sidePanelData['client'][0]['Name'],
                        'listAddressLine1': $scope.sidePanelData['client'][0]['AddressLine1'],
                        'listTown': ($scope.sidePanelData['client'][0]['Town'])?$scope.sidePanelData['client'][0]['Town']:'',
                        'listCounty': ($scope.sidePanelData['client'][0]['County'])?$scope.sidePanelData['client'][0]['County']:'',
                        'listPostcode': ($scope.sidePanelData['client'][0]['Postcode'])?$scope.sidePanelData['client'][0]['Postcode']:''
                    }
                    $scope.loading = false;
                    $scope.active_slide = 2;
                }
            });
    }

    $scope.selectedReportList = function selectedReportList(reportList) {
        $scope.reportList = reportList;
    }

    $scope.saveDistributionList = function saveDistributionList(){
        $scope.hide_title_and_actions = true;
        $scope.send_distribution_list = false;
        $http.post(prefix + '/report_export', 'formData=' + encodeURIComponent(angular.toJson( $scope.sidePanelData))).
            success(function (data, status) {
                if (status == 200) {
                    $timeout(function() {
                        $scope.closePanel();
                        toastBox.show('Data send successfully into MailChimp', 2000);
                        $scope.hide_title_and_actions = false;
                    }, 2000);
                }
            });
    }

    $scope.closePanel = function closePanel() {
        $scope.active_slide = 1;
        $scope.loading = false;
        $scope.send_distribution_list = true;
        $scope.hide_title_and_actions = false;
        $scope.reportList = '';
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $rootScope.$on('parent:updated', function(evt) {
        $scope.active_slide = 1;
        $scope.loading = false;
        $scope.send_distribution_list = true;
        $scope.hide_title_and_actions = false;
        $scope.reportList = '';
    });

    $scope.button_disabled = true;
    $scope.listNameError = false;
    $scope.listFromEmailError = false;
    $scope.listFromNameError = false;
    $scope.listSubjectError = false;

    $scope.selectedDistributionList = function selectedDistributionList() {
        $scope.button_disabled = ($scope.sidePanelData[0]['distributionList'] == '')?true:false;
    }

    $scope.validateEmailField = function validateEmailField(value) {
        if(value){
            var input_pattern =/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})*$/i,
                is_valid = input_pattern.test(value);
        }
        else {
            is_valid = true;
        }
        return is_valid;
    }

    $scope.validationTimer = false;

    $scope.startValidationTimer = function startValidationTimer() {
        $timeout.cancel($scope.validationTimer);
        $scope.validationTimer = $timeout(function() {
            $scope.validateList();
        }, 500);
    }

    $scope.validateList = function validateList() {
        if($scope.sidePanelData[0]['listFromEmail']!= '') {
            var validEmail = $scope.validateEmailField($scope.sidePanelData[0]['listFromEmail']);
            $scope.listFromEmailError = (validEmail == false)?true:false;
        }

        if($scope.sidePanelData[0]['listName']!= ''
            && $scope.sidePanelData[0]['listFromEmail']!= ''
            && $scope.sidePanelData[0]['listFromName']!= ''
            && $scope.sidePanelData[0]['listSubject']!= ''
            && $scope.sidePanelData[0]['listCompanyName']!= ''
            && $scope.sidePanelData[0]['listAddressLine1']!= ''
            && $scope.sidePanelData[0]['listTown']!= ''
            && $scope.sidePanelData[0]['listCounty']!= ''
            && $scope.sidePanelData[0]['listPostcode']!= ''
            && $scope.listFromEmailError == false) {
            $scope.button_disabled = false;
        }
        else {
            $scope.button_disabled = ($scope.sidePanelData[0]['distributionList'] == '')?true:false;
        }
    }
}

function summaryViewCtrl($scope, datasets,$rootScope){
    $scope.summaryRow = datasets.result;
    $scope.hasTotalSummary = datasets.hasTotalSummary;
    $rootScope.$on('toReporting:Summary',function(event, data){
        $scope.summaryRow = data.result;
    })
}


function DriverTripViewCtrl($scope, $state, $rootScope, $http, prefix, $csMapper, $gpsTracker, $timeout, csPopUpPosition, gpsServerConfig, tableCollection, formPanelCollection, $diaryMapScope, $filter){
    $rootScope.selectedTab = 'reporting';
    $scope.$emit('fromsmarttable:filters', {});
    var csMapper = $csMapper.get();
    //Rendering Map
    csMapper.initialize('trip-map', 'vector');
    var overlay = csMapper.addOverlay('DriverTrip');
    $scope.deviceList  = tableCollection.collection.reporting_users_driver_trips.data
    $scope.deviceObj = [];
    angular.forEach($scope.deviceList, function (data, key) {
        $scope.deviceObj.push(data.deviceId);
    });

    $scope.$watch('filterData',function (new_value, old) {
        $scope.limit = 10;
        $scope.tripRecords = [];
        $scope.tripDetails = [];
        $scope.endOfDate = [];
        var isOldDateRange = JSON.stringify(new_value) === JSON.stringify(old)
        //To avoid duplicate data while filter apply two time on page load
        if(!isOldDateRange || !$scope.isSavedReport){
            //find default date range(last week)
            if (!new_value['daterange_last_week'])
                $scope.dateList = getDateArray(moment().day(-7)._d, moment().day(-1)._d);
            else
                $scope.dateList = getDateArray(new_value.daterange_last_week.startDate, new_value.daterange_last_week.endDate);

            csMapper.cleanOverlay('DriverTrip');
            var date = moment($scope.dateList[0]).format('YYYY-MM-DD');
            if (new_value['user']) {
                let index = $scope.deviceList.map(item => item['userId']).indexOf(parseInt(new_value.user));
                $scope.deviceId = [$scope.deviceList[index].deviceId];
                fetchDriverTrips($scope.deviceId, date);
                $rootScope.$broadcast('reportFilterLoading', 'users_driver_trip', false);
            }
            else {
                $scope.deviceId = $scope.deviceObj;
                fetchDriverTrips($scope.deviceId, date);
                $rootScope.$broadcast('reportFilterLoading', 'users_driver_trip', false);
            }

        }
    });

    $scope.formatDateTime = function (dateString, dateOnly) {
        return dateOnly ?
            $filter('dateShortFormat')(dateString) :
            $filter('dateShortFormat')(dateString)+' '+$filter('customTimeFormat')(dateString);
    };

    //To get list of date from dateRange
    function getDateArray(start_date, end_date){
        let startDate = moment(start_date);
        let endDate = moment(end_date);
        let dateObj = [];
        while (startDate <= endDate) {
            dateObj.push(startDate.format('YYYY-MM-DD'));
            startDate.add('days', 1);
        }
        return dateObj;
    }

    //Fetch Driver Trip DateWise
    function fetchDriverTrips(deviceIds, date) {
        var startDate = moment.utc(date, 'YYYY-MM-DD').set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        }).toISOString();
        var endDate = moment.utc(date, 'YYYY-MM-DD').set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
        }).toISOString();
        $scope.dateList = $scope.dateList.slice(1, $scope.dateList.length);
        $gpsTracker.getTrips(deviceIds, startDate, endDate).then(function (result) {
            if (result.length > 0 || $scope.dateList.length == 0) {
                $scope.tripRecords = $scope.tripRecords.concat(result)
                $scope.endOfDate.push($scope.tripRecords.length - 1)
                angular.forEach($scope.tripRecords, function (data, key) {
                    let index = $scope.deviceList.map(item => item['deviceId']).indexOf(data.deviceId);
                    data['User'] = $scope.deviceList[index].userName;
                });
                if ($scope.tripRecords.length >= $scope.limit) {
                    $scope.totalTripRecord = $scope.tripRecords.length;
                    //for first time to display data on screen
                    $scope.tripDetails = $scope.tripRecords.slice(0, $scope.limit);
                }
                else if ($scope.dateList.length > 0) {

                    fetchDriverTrips($scope.deviceId, $scope.dateList[0]);
                }
                else
                    $scope.tripDetails = $scope.tripRecords.slice(0, $scope.totalTripRecord);
            }
            else {

                fetchDriverTrips($scope.deviceId, $scope.dateList[0]);
            }
        });
    }

    //Fetch trips by triggring infinite-scroll event
    $scope.fetchRoute = function () {
        let start_index = $scope.tripDetails.length;
        let end_index = start_index + $scope.limit;
        if (end_index <= $scope.tripRecords.length)
            $scope.tripDetails = $scope.tripDetails.concat($scope.tripRecords.slice(start_index, end_index));
        else {
            if (start_index < $scope.tripRecords.length || $scope.dateList.length > 0) {
                let trip_end_index = $scope.tripRecords.length
                if ($scope.tripRecords.slice(start_index, trip_end_index).length == $scope.limit)
                    $scope.tripDetails = $scope.tripDetails.concat($scope.tripRecords.slice(start_index, end_index));
                else {
                    if ($scope.dateList.length > 0) {

                        fetchDriverTrips($scope.deviceId, $scope.dateList[0]);
                        if (end_index <= $scope.tripRecords.length)
                            $scope.tripDetails = $scope.tripDetails.concat($scope.tripRecords.slice(start_index, end_index));
                        else {
                            let trip_end_index = $scope.tripRecords.length
                            $scope.tripDetails = $scope.tripDetails.concat($scope.tripRecords.slice(start_index, trip_end_index))
                        }
                    }
                    else
                        $scope.tripDetails = $scope.tripDetails.concat($scope.tripRecords.slice(start_index, trip_end_index));
                }
            }
        }
    }


    //Fetch driver trip tracking details
    $scope.fetchTripTrackingReport = function (trip, index) {
        $scope.selectedTrip = index;
        csMapper.cleanOverlay('DriverTrip');
        let start_time = moment(trip['startTime'])._d.toISOString();
        let end_time = moment(trip['endTime'])._d.toISOString();
        $gpsTracker.getRoute([trip['deviceId']], start_time, end_time).then(function (result) {
            if (result instanceof Object) {
                drawUserRoutes(result, trip);
            } else {
                csMapper.adjustMapBounds();
            }
        });
    }

    // Drawing polyline for user's travelled route
    function drawUserRoutes(result, trip) {

        var tripInfo = [];

        Object.keys(result).forEach(function(deviceId){
            csMapper.addPoint('DriverTrip', [result[deviceId][0].latitude, result[deviceId][0].longitude], {
                opacity: 0,
                radius: 0.001
            });
            csMapper.addPoint('DriverTrip', [result[deviceId][result[deviceId].length - 1].latitude, result[deviceId][result[deviceId].length - 1].longitude], {
                opacity: 0,
                radius: 0.001
            });

            // Draw Engineer's home location
            var deviceDetails = $scope.deviceList.find(function (d) {
                return d.deviceId == deviceId;
            });
            if (csMapper.validateLatLng(deviceDetails.userLatitude, deviceDetails.userLongitude)) {
                csMapper.addUpdateDivInterpolatedMarker('DriverTrip', {
                        markerSuffix: deviceDetails.userId,
                        latitude: deviceDetails.userLatitude,
                        longitude: deviceDetails.userLongitude,
                        bindingData: {
                            color: '#b14b1a',
                            width: $diaryMapScope.mapElementSpecifications['outstanding_job_marker']['widthExtraLarge'],
                            height: $diaryMapScope.mapElementSpecifications['outstanding_job_marker']['heightExtraLarge'],
                            iconFont: 'fas fa-home'
                        }
                    },
                    $diaryMapScope.mapElementTemplates['outstanding_job_marker'],
                    $diaryMapScope.mapElementSpecifications['outstanding_job_marker'][['anchorExtraLarge']])
            }


            tripInfo = {
                'tripDetails': result[deviceId].map(function (p) {
                    return {'points': [p.latitude, p.longitude], 'speed': p.speed, 'altitude': p.altitude,
                        'deviceTime': p.deviceTime, 'driverBehaviour': p.attributes.drivingBehaviour,
                        'driverBehaviourValue': p.attributes.DrivingBehaviourValue, 'distance': p.attributes.distance,
                        'user': trip['User'], 'protocol': p.protocol, 'address': p.address, 'accuracy': p.accuracy,
                        'course': p.course, 'valid': p.valid, 'totalDistance': p.attributes.totalDistance,
                        'motion': p.attributes.motion, 'ignition': p.attributes.ignition
                    }
                }).filter(function (p) {
                    return p.points[0] !== 0 && p.points[1] !== 0;
                }),
                'points':  result[deviceId].map(function (p) {
                    return [p.latitude, p.longitude]
                }).filter(function (p) {
                    return p !== 0 && p !== 0;
                })
            }

        });

        csMapper.adjustMapBounds();


        // Waiting for view adjust
        setTimeout(function () {
            // Asynchronous rendering
            var content = '<div class="text-icon">' + trip['User'][0] + '</div>';
            var popupHtml = null;
            //Marker icon
            var icon = '<div class="trip-marker-icon" data-toggle="tooltip" data-pop-up-identifier="user-marker" ' +
                'data-event-data=""><div data-toggle="tooltip" data-pop-up-identifier="user-marker" class="marker"></div>'
            var myIcon = L.divIcon({html: icon, iconAnchor: [6, 9]});
            csMapper.addRoute('DriverTrip', tripInfo.points, "#0078A8", trip['User'], content, popupHtml);
            //Adding Marker
            angular.forEach(tripInfo.points, function (data, index) {
                var tripMarker = L.marker(data, {icon: myIcon});

                tripMarker.addTo(overlay)
                    .on('mouseover', function (event) {
                        event['currentTarget'] = event.originalEvent.target;
                        $scope.tripPopupDetails = tripInfo.tripDetails[index];
                        csPopUpPosition.init(event);
                        $timeout(function () {
                            $scope.showDriverTripMarkerPopup = true;
                        });

                    })
                    .on('mouseout', function () {
                        $timeout(function () {
                            $scope.showDriverTripMarkerPopup = false;
                        });

                    });
            })

        }, 2001);

    }

}



/*********************************************************
                    Advanced Reports
 *********************************************************/

// User's driving profile view controller
function UserDrivingProfileViewCtrl($scope, $rootScope, $location, datasets, $filter, $http, $timeout, $interpolate) {
    // console.log('profile data', datasets);

    var startDate = $location.$$search.date? moment($location.$$search.date, 'YYYY-MM-DD'): moment();
    startDate = startDate.startOf('week');
    $scope.dateRange = {
        startDate: startDate,
        endDate: startDate.clone().add('6', 'days')
    };
    $scope.dateRangeDisplay =    $scope.dateRange.startDate.format('Do MMMM YYYY') + ' - ' +  $scope.dateRange.endDate.format('Do MMMM YYYY')
    $scope.dataValidation = 'NO_DATA';
    $scope.deviceDetails = null;
    $scope.summary = null;
    $scope.deviceDetails = null;
    $scope.distanceChartData = null;
    $scope.speedChartData = null;
    $scope.scoreChartData = null;
    $scope.timeTrackingData = {};
    $scope.timeTrackingDates = [];
    $scope.timeTrackingStatus = null;
    $scope.timeTrackingEvents = {};
    $scope.diaryEventStausClasses = {
        accepted: 'accept',
        rejected: 'reject',
        travel: 'travel',
        arrived: 'arrive',
        no_access: 'no-access',
        aborted: 'abort',
        left: 'leave',
        cancelled: 'cancel',
        completed: 'leave',
        just_booked: ''
    };

    // Error handling
    if (!(datasets instanceof Object)) $scope.dataValidation = 'ERROR';
    else if (datasets instanceof Object && Object.keys(datasets).length === 0) $scope.dataValidation = 'NO_DATA';
    else {
        $scope.deviceDetails = datasets.gps_device_details;
        var deviceId =$scope.deviceDetails['deviceId'];

        $scope.summary = datasets.driving_data_summary[deviceId];
        [$scope.distanceChartData,$scope.speedChartData,$scope.scoreChartData] = prepareChartData(datasets.daily_driving_data, deviceId);

        $scope.dataValidation = 'VALID';

        var end = $scope.dateRange.endDate.clone().add('days', '1');
        for(var day = $scope.dateRange.startDate.clone(); day.isBefore(end); day.add('days', '1')){
            $scope.timeTrackingDates.push(day.clone());
        }

        getTimeTrackings();
    }

    // For breadcrumb
    $scope.$emit('reporting:viewchanged', $scope.dataValidation === 'VALID' ? {
        'category': 'user',
        'param': $scope.deviceDetails.userName,
        'subcategory': 'driving_profile'
    } : {'category': 'user', 'none': '-'});

    // Fetching time tracking data
    function getTimeTrackings() {
        $scope.timeTrackingStatus = 'LOADING';

        var url = Routing.generate('user_driving_time_trackings', {userId: $scope.deviceDetails.userId})
            + '?startDate=' + $scope.dateRange.startDate.format('YYYY-MM-DD') + '&endDate=' + $scope.dateRange.endDate.format('YYYY-MM-DD');

        $http.get(url)
            .success(function (data, status, headersObj) {
                $scope.timeTrackingData = data;
                $scope.timeTrackingStatus = 'DATA_LOADED';
            })
            .error(function (error, statur, headersObj) {
                console.log('timetrackings',error);
                $scope.timeTrackingData = {};
                $scope.timeTrackingStatus = 'ERROR';

            });
    }

    // Forming charts data
    function prepareChartData(dailyData, deviceId){
        var distanceUnit = $filter('appendLocalUnit')('', 'length').trim();
        var distanceChartData = {
            type: "column",
            graph_options: {
                options: {
                    height: 300
                },
                x_axis_categories: [],
                y_axis: {
                    labels: {
                        format: '{value} ' + distanceUnit
                    },
                    title: {text: "Travelled distance "},
                    opposite: false
                },
                series: [
                    {name: "Travelled distance", data: [], tooltip: {  valueSuffix: ' ' + distanceUnit }, pointPadding: 0.2 }
                ]
            }
        };

        var speedUnit = $filter('appendLocalUnit')('', 'speed').trim();
        var speedChartData = {
            type: "column",
            graph_options: {
                options: {
                    height: 300
                },
                x_axis_categories: [],
                y_axis: {
                    labels: {
                        format: '{value} ' + speedUnit
                    },
                    title: {text: "Average speed "},
                    opposite: false
                },
                series: [
                    {name: "Speed", data: [], tooltip: {  valueSuffix: ' ' + speedUnit }, pointPadding: 0.2}
                ]
            }
        };

        var scoreChartData = {
            type: "column",
            graph_options: {
                options: {
                    height: 400
                },
                x_axis_categories: [],
                y_axis: [
                    {   // Score
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        title: {
                            text: 'Score',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        opposite: false,
                        min: 0,
                        max: 100
                    },
                    { // Speeding
                        gridLineWidth: 0,
                        title: {
                            text: 'Excessive Speeding',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        opposite: true,
                        min: 0

                    },
                    { // Acceleration
                        gridLineWidth: 0,
                        title: {
                            text: 'Excessive Acceleration',
                            style: {
                                color: Highcharts.getOptions().colors[2]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[2]
                            }
                        },
                        opposite: true,
                        min: 0
                    },
                    { // Braking
                        gridLineWidth: 0,
                        title: {
                            text: 'Excessive Braking',
                            style: {
                                color: Highcharts.getOptions().colors[3]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[3]
                            }
                        },
                        opposite: true,
                        min: 0
                    },
                    { // Cornering
                        gridLineWidth: 0,
                        title: {
                            text: 'Excessive Cornering',
                            style: {
                                color: Highcharts.getOptions().colors[4]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[4]
                            }
                        },
                        opposite: true,
                        min: 0
                    }
                ],
                series: [
                    {
                        name: 'Score',
                        type: 'column',
                        data: [],
                        pointPadding: 0.2
                    },
                    {
                        name: 'Excessive Speeding',
                        yAxis:1,
                        type: 'spline',
                        data: [],
                        dashStyle: 'shortdot',
                        lineWidth: 4,
                        marker: {
                            enabled: false
                        }
                    },
                    {
                        name: 'Excessive Acceleration',
                        type: 'spline',
                        yAxis:2,
                        data: [],
                        dashStyle: 'longdash',
                        marker: {
                            enabled: false
                        },
                        lineWidth: 4
                    },
                    {
                        name: 'Excessive Braking',
                        type: 'spline',
                        yAxis: 3,
                        data: [],
                        dashStyle: 'ShortDashDotDot',
                        marker: {
                            enabled: false
                        },
                        lineWidth: 4
                    },
                    {
                        name: 'Excessive Cornering',
                        type: 'spline',
                        yAxis: 4,
                        data: [],
                        dashStyle: 'Solid',
                        marker: {
                            enabled: false
                        },
                        lineWidth: 4
                    }
                ]
            }
        };

        angular.forEach(dailyData, function(val, key) {
            var day = moment(key, 'YYYY/MM/DD');

            // For distance chart
            distanceChartData.graph_options.x_axis_categories.push(day.format('D') + ' - ' + day.format('ddd'));
            distanceChartData.graph_options.series[0].data.push(parseFloat(val[deviceId].distance.toFixed(2)));

            // For speed chart
            speedChartData.graph_options.x_axis_categories.push(day.format('D') + ' - ' + day.format('ddd'));
            speedChartData.graph_options.series[0].data.push(parseFloat(val[deviceId].averageSpeed.toFixed(2)));

            // For behaviour chart
            scoreChartData.graph_options.x_axis_categories.push(day.format('D') + ' - ' + day.format('dddd'));
            scoreChartData.graph_options.series[0].data.push(parseFloat(val[deviceId].score.toFixed(2)));

            scoreChartData.graph_options.series[1].data.push(parseFloat(val[deviceId].excessiveSpeeding.toFixed(2)));

            scoreChartData.graph_options.series[2].data.push(parseFloat(val[deviceId].excessiveAcceleration.toFixed(2)));

            scoreChartData.graph_options.series[3].data.push(parseFloat(val[deviceId].excessiveBraking.toFixed(2)));

            scoreChartData.graph_options.series[4].data.push(parseFloat(val[deviceId].excessiveCornering.toFixed(2)));
        });

        return [distanceChartData, speedChartData, scoreChartData];
    }

}
/*
This isn't being used right now but it might be used again in the future. I am keeping it here for reference. */
/*
function PartsOperationalListCtrl($scope, $state, datasets, $http, prefix, $rootScope, formPanelCollection){
    $scope.counts = datasets.recordList.counts;
    $scope.jobs = datasets.recordList.jobs;
    $scope.stockLocations = datasets.recordList.stockLocations;
    $scope.industries = datasets.industries;
    $scope.categoryList = datasets.categoryList;
    $scope.status = datasets.status;
    $rootScope.clientJobType = datasets.clientJobType;
    $rootScope.clientJobTodos = datasets.clientJobTodos;
    $scope.availableFilters = datasets.availableFilters;
    $scope.selectedParts = [];
    $scope.selectedPartIds = [];
    $scope.selectedJob = '';
    $scope.PartPage = 1;
    $scope.limit = 20;
    $scope.recordCount = datasets.recordList.recordCount;

    // Set available filter to panel with form
    formPanelCollection.setFilterData($scope.availableFilters);

    // Set selected as false for all he jobs and parts returned
    angular.forEach($scope.jobs, function (value, key) {
        value.selected = false;
        angular.forEach(value.parts, function(part) {
            part.selected = false;
        });
    });

    $scope.$emit('reporting:viewchanged', {
        'category': 'Parts',
        'subcategory': 'Parts operational list'
    });

    // Function to get full address
    $scope.getAddressLine = function(job) {
        var addressArray = [job.waddressname, job.waddressline1, job.waddressline2, job.waddressline3, job.wcounty, job.wtown, job.wpostcode];
        var address = "";

        angular.forEach(addressArray, function(addressPart, index) {
            if (typeof addressArray[index] == "string") {
                if (addressArray[index].length > 0) {
                    if (address.length > 0) {
                        address += ", " + addressArray[index];
                    }
                    else {
                        address += addressArray[index];
                    }
                }

            }
        });

        return address;
    };

    $scope.monthLookup = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    $scope.dayOfWeekLookup = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    $scope.getOrdinalIndicator = function(date) {
        date = String(date);
        if (date.length) {
            var lastDigit = parseInt(date[date.length-1]);
            date = parseInt(date);
            if (lastDigit !== "NaN") {
                if (lastDigit == 0 || lastDigit >= 4 || (date >= 10 && date <= 19)) {
                    return date + "th";
                }
                else if (lastDigit == 1) {
                    return date + "st";
                }
                else if (lastDigit == 2) {
                    return date + "nd";
                }
                else if (lastDigit == 3) {
                    return date + "rd";
                }
            }
        }
        return false;
    };

    // Function to get delivery date
    $scope.transformDate = function(date) {
        var transformedDate = new Date(date);
        transformedDate = $scope.dayOfWeekLookup[transformedDate.getDay()] + ', ' + $scope.getOrdinalIndicator(transformedDate.getDate()) + ' ' + $scope.monthLookup[transformedDate.getMonth()] + ', ' + transformedDate.getFullYear();
        return transformedDate;
    };

    // Function to toggle the selected jobs and parts
    $scope.toggleSelectAllParts = function(job) {
        if(job.selected == false){
            job.selected = true;
            angular.forEach(job.parts, function(part) {
                if (part.selected == false) {
                    part.selected = true;
                    part.customerID = job.customerID;
                    part.customerType = job.customerType;
                    part.jobnumber = job.jobnumber;
                    part.waddressID = job.waddressID;
                    part.waddressType = job.waddressType;
                    $scope.selectedPartIds.push(part.settingspartid);
                    $scope.selectedParts.push(part);
                }
            });
        }else if(job.selected == true){
            job.selected = false;
            angular.forEach(job.parts, function(part) {
                var idx = $scope.selectedPartIds.indexOf(part.settingspartid);
                if(idx > -1){
                    $scope.selectedParts.splice(idx, 1);
                    $scope.selectedPartIds.splice(idx, 1);
                    part.selected = false;
                }
            });
        }
        if($scope.selectedParts.length > 0){
            $http.get(prefix + '/getStockLocationAvailable' + '?partId='+ $scope.selectedPartIds).success(function (data) {
                var count = 0;
                angular.forEach($scope.selectedParts, function(part){
                    angular.forEach(data, function(location){
                        if(location.part_id == part.settingspartid){
                            $scope.selectedParts[count].locations = location;
                        }
                    });
                    count++;
                });
            });
        }
    }

    $scope.toggleSelectPart = function(job, part){
        if(part.selected == true){
            var idx = $scope.selectedPartIds.indexOf(part.settingspartid);
            if(idx > -1){
                $scope.selectedParts.splice(idx, 1);
                $scope.selectedPartIds.splice(idx, 1);
                part.selected = false;
                if(job.selected == true){
                    job.selected = false;
                    angular.forEach($scope.jobs, function (value, key) {
                        angular.forEach(value.parts, function(part) {
                            if(part.selected == true){
                                job.selected = true;
                            }
                        });
                    });
                }
            }
        }else{
            job.selected = true;
            part.selected = true;
            part.customerID = job.customerID;
            part.customerType = job.customerType;
            part.jobnumber = job.jobnumber;
            part.waddressID = job.waddressID;
            part.waddressType = job.waddressType;
            $scope.selectedPartIds.push(part.settingspartid);
            $scope.selectedParts.push(part)
        }
        if($scope.selectedParts.length > 0){
            $http.get(prefix + '/getStockLocationAvailable' + '?partId='+ $scope.selectedPartIds).success(function (data) {
                var count = 0;
                angular.forEach($scope.selectedParts, function(part){
                    angular.forEach(data, function(location){
                        if(location.part_id == part.settingspartid){
                            $scope.selectedParts[count].locations = location;
                        }
                    });
                    count++;
                });
            });
        }
    }

    $scope.filterData = {};
    $scope.show_clear_filter_link = false;
    $scope.number_of_filters = 0;

    // Function to count active filters
    $scope.countActiveFilters = function() {
        $scope.show_clear_filter_link = false;
        $scope.number_of_filters = 0;

        for (var key in $scope.filterData) {
            var value = $scope.filterData[key],
                value_exists = value &&
                    value !== "all" &&
                    value.toString().length > 0

            if (value_exists) {
                $scope.show_clear_filter_link = true;
                $scope.number_of_filters ++;
            }
        }
    }

    // For applying filters
    $scope.$on('status:toOperationalList', function(evt, data) {
        // console.log("in filter");
        $scope.filterData = {};
        angular.forEach(data, function(val, key) {
            if(val && val.value != undefined) {
                $scope.filterData[val.model_name] = val.value;
            }
        });
        $scope.fetchParts($scope.PartPage, $scope.status);
        $scope.countActiveFilters();
    });

    // Function for handling change tab
    $scope.changeTab = function(status){
        $scope.$emit('panelwithform:status_fromOperationalList', $scope.status);
        $state.transitionTo('loggedin.reporting.parts_operational_view_list', {status: $scope.status});
        $scope.selectedParts = [];
        $scope.selectedPartIds = [];
        $scope.selectedJob = '';
    }

    // Function to clear all the filters
    $scope.clearFilters = function() {
        $scope.filterData = {};
        $scope.countActiveFilters();

        $scope.fetchParts($scope.PartPage,$scope.status);
        $scope.selectedParts = [];
        $scope.selectedPartIds = [];
        $scope.selectedJob = '';
    }

    $scope.selectJob = function(job){
        var jobId = job.jobnumber;
        if(job.selected == true){
            job.selected = false;
            $scope.selectedJob = '';
        }else{
            job.selected = true;
            $scope.selectedJob = jobId;
        }
    }

    $scope.redirectToDiary = function(selectedJob){
        localStorage.setItem('operational_part_diary_event', true);
        $state.go('loggedin.diary_from_job_estimate',{'type':'job','typeId':selectedJob});
    }

    $scope.isCheckboxDisabled = function(job){
        if($scope.selectedJob != '' && $scope.selectedJob != job.jobnumber){
            return true;
        }else{
            return false
        }
    }

    $scope.toggleSelectAllOrderParts = function(date){
        if(date.selected == false){
            date.selected = true;
            angular.forEach(date.parts, function(part) {
                if (part.selected == false) {
                    part.selected = true;
                    part.stockLocations = $scope.stockLocations;
                    $scope.selectedPartIds.push(part.fulfillmentid);
                    $scope.selectedParts.push(part);
                }
            });
        }else if(date.selected == true){
            date.selected = false;
            angular.forEach(date.parts, function(part) {
                var idx = $scope.selectedPartIds.indexOf(part.fulfillmentid);
                if(idx > -1){
                    $scope.selectedParts.splice(idx, 1);
                    $scope.selectedPartIds.splice(idx, 1);
                    part.selected = false;
                }
            });
        }
    }

    $scope.toggleSelectOrderPart = function(date, part){
        if(part.selected == true){
            var idx = $scope.selectedPartIds.indexOf(part.fulfillmentid);
            if(idx > -1){
                $scope.selectedParts.splice(idx, 1);
                $scope.selectedPartIds.splice(idx, 1);
                part.selected = false;
            }
        }else{
            part.selected = true;
            part.stockLocations = $scope.stockLocations;
            $scope.selectedPartIds.push(part.fulfillmentid);
            $scope.selectedParts.push(part);
        }
    }

    $scope.markAsAvailable = function(selectedPartIds){
        $http.post(prefix + '/mark_as_available', "id=" + selectedPartIds)
            .success(function(res) {
                $scope.fetchParts($scope.PartPage,'on_order');
                $scope.countActiveFilters();
            });
        $scope.selectedPartIds = [];
        $scope.selectedParts = [];
    }

    $scope.fetchParts = function(page, status){
        $http.get(prefix + '/parts_operational_view_list/'+ $scope.status + '?page=' + page + '&limit=' + 20 + '&status='+ status + '&filter=' + angular.toJson($scope.filterData)).success(function (data) {
            $scope.counts = data.recordList.counts;
            $scope.jobs = data.recordList.jobs;
            $scope.industries = data.industries;
            $scope.categoryList = data.categoryList;
            $scope.status = data.status;
            $rootScope.clientJobType = data.clientJobType;
            $rootScope.clientJobTodos = data.clientJobTodos;
            $scope.availableFilters = data.availableFilters;
            $scope.recordCount = data.recordList.recordCount;
            formPanelCollection.setFilterData($scope.availableFilters);

            angular.forEach($scope.jobs, function (value, key) {
                value.selected = false;
                angular.forEach(value.parts, function(part) {
                    part.selected = false;
                });
            });
        });
    }

    $scope.$on('parts_view_list:refresh', function (event, data) {
       $scope.fetchParts($scope.PartPage, data);
    });
}
*/

function OperationalMarkAsArrive($scope, $http, prefix){
    $scope.selectedStockLoc = '';
    $scope.processAllocation = function(){
        var partsToSubmit = [];
        var locationId = $scope.selectedStockLoc;
        angular.forEach($scope.panel_content, function(part, index) {
            partsToSubmit.push({
                quantity_delivered: part.quantity,
                quantity_ordered: part.quantityordered,
                purchaseOrderDetailId: part.podetailid,
                price: part.unitprice,
                poNo: part.purchaseorderno,
                partId: part.settingspartid,
                allocationValues: [
                    {quantity: part.quantity, locationId: locationId}
                ]
            });
        });
        $http.get(prefix + '/mark_as_available?parts=' + angular.toJson(partsToSubmit))
            .then(function(resp) {
               $scope.cancelSidePanel();
                $scope.$emit('parts_view_list:refresh', 'on_order');
    });
    }

    $scope.cancelSidePanel = function cancelSidePanel(){
        $scope.$emit('sidepanel:close');
    }

    $scope.validateDeliverQuantity = function(part) {
        var quantity = part.quantity.replace(/[^0-9.]/g,'');
        if (part.quantity == "" || isNaN(parseFloat(quantity)) || part.quantity == 0) {
            $scope.deliverQuantityInvalid = true;
        }else{
            $scope.deliverQuantityInvalid = false;
        }
    }

    $scope.setStockLocation = function(location){
        $scope.selectedStockLoc = location;
    }
}

function OperationalFulfillCtrl($scope, $state, $http, prefix, $timeout, jobData, $rootScope){

    $scope.calculateFulfillQuantity = function calculateFulfillQuantity(amount, part_id, location_index, part_index){
        amount = amount.replace(/[^0-9.]/g,'');
        var input_is_number = !isNaN(parseFloat(amount));

        var chosen_parts = $scope.panel_content[part_index];

        $timeout(function(){
            var allocation = 0,
                quantity = 0,
                part_allocation_total = 0,
                current_part_quantity_needed = 0,
                part_locations,
                order_from_supplier_amount = parseFloat(chosen_parts.order_from_supplier.amount);

            $scope.disable_saving = true;

            // check if each allocation is valid against its stock location
            part_locations = chosen_parts.locations.locations;
            for(var x = 0, c = part_locations.length; x < c; x++) {

                allocation = parseFloat(part_locations[x].allocation);
                quantity = parseFloat(part_locations[x].quantity);
                part_allocation_total += allocation;

                if (allocation <= quantity) {
                    $scope.panel_content[part_index].locations.locations[x].valid = true;
                }else{
                    $scope.panel_content[part_index].locations.locations[x].valid = false;
                }
                if (part_locations[x].location === 'General') {
                    $scope.panel_content[part_index].locations.locations[x].valid = true;
                }
            }
            // check if the sum of these allocations exceeds the total required for the part
            part_allocation_total += order_from_supplier_amount;

            var part_required_total = $scope.panel_content[part_index].quantity;
            if (part_allocation_total == part_required_total) {
                $scope.panel_content[part_index].valid = true;
            } else {
                $scope.panel_content[part_index].valid = false;
            }

            $scope.updateChosenPartsAllocationTotals(part_index);
            $scope.updateSavingButtonText(part_index);
            $scope.checkIfAnyPartsAreInvalid(part_index);
        },15);
    }

    /*=========================================================================================================
     Check if all inputs are valid
     =========================================================================================================*/
    $scope.updateChosenPartsAllocationTotals = function updateChosenPartsAllocationTotals(part_index){
        var chosen_parts = $scope.panel_content;

        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var  part_locations = chosen_parts[i].locations.locations;
            var current_part_quantity_needed = parseFloat(chosen_parts[i].quantity);
            var current_part_allocation_total = 0;
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);

            for(var x = 0, c = part_locations.length; x < c; x++) {
                var  allocation = parseFloat(part_locations[x].allocation);
                current_part_allocation_total += (allocation)?allocation:0;
            }

            current_part_allocation_total += order_from_supplier_amount;
            $scope.panel_content[i].part_allocation_total = current_part_allocation_total;
            if(isNaN($scope.panel_content[i].part_allocation_total)){
                $scope.panel_content[i].part_allocation_total = 0;
            }
        }
    }

    $scope.updateSavingButtonText = function updateSavingButtonText() {
        var chosen_parts = $scope.panel_content;

        $scope.saving_text = 'Save';
        $scope.show_purchase_order_button = false;
        $scope.hide_fulfill_all_parts = false;
        // update saving button text
        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var order_from_supplier_amount = parseFloat(chosen_parts[i].order_from_supplier.amount);

            for(var j = 0, ll = chosen_parts[i].locations.length; j < ll; j++) {
                var location_allocation = parseFloat(chosen_parts[i].locations[j].allocation);
                if (location_allocation > 0) {
                    $scope.hide_fulfill_all_parts = true;
                }
            }

            if (order_from_supplier_amount > 0) {
                if($rootScope.clientJobType == 1){
                    $scope.saving_text = 'Save';
                    $scope.show_purchase_order_button = false;
                    $scope.hide_fulfill_all_parts = true;
                }else{
                    $scope.saving_text = 'Save and create purchase order';
                    $scope.show_purchase_order_button = true;
                }
            }
        }
    }

    $scope.checkIfAnyPartsAreInvalid = function checkIfAnyPartsAreInvalid() {
        $scope.disable_saving = false;
        var chosen_parts = $scope.panel_content;

        for(var i = 0, l = chosen_parts.length; i < l; i++) {
            var entire_part_valid = chosen_parts[i].valid === true;
            if (entire_part_valid) {
                var current_part_allocation_total = 0;
                var part_locations = chosen_parts[i].locations.locations;

                for(var x = 0, c = part_locations.length; x < c; x++) {
                    var allocation = parseFloat(part_locations[x].allocation);
                    current_part_allocation_total += allocation;

                    var part_location_invalid = part_locations[x].valid === false;

                    if (part_location_invalid) {
                        $scope.disable_saving = true;
                        $scope.panel_content[i].valid = false;
                        return
                    }
                }
                // check if the part allocation total matches the required total
                current_part_allocation_total += parseFloat(chosen_parts[i].order_from_supplier.amount);

                var current_part_quantity_needed = parseFloat(chosen_parts[i].quantity);

                if (current_part_allocation_total !== current_part_quantity_needed) {
                    $scope.disable_saving = true;
                    $scope.panel_content[i].valid = false;
                    return
                }

            }else{
                $scope.disable_saving = true;
                $scope.panel_content[i].valid = false;
                return
            }

        }
        if (current_part_allocation_total !== current_part_quantity_needed && $scope.disable_saving === false) {
            $scope.disable_saving = true;
            $scope.chosenParts[i].valid = false;
        } else{
            $scope.disable_saving = false;
        }
    }
    var featureName = 'Purchaseorder', featureAccess = 'writeaccess', featureId = features[featureName];
    var purchaseOrderPermissions = $rootScope.hasPermission(featureId, featureAccess);

    if(purchaseOrderPermissions || $rootScope.clientJobType == 1 ){
        $scope.purchaseOrderAccess = true;
    }else{
        $scope.purchaseOrderAccess = false;
    }

    /*=========================================================================================================
     When clicking order all from supplier, set all the other inputs to 0 and order from supplier input
     to the quantity required
     =========================================================================================================*/
    $scope.orderAllFromSupplier = function orderAllFromSupplier(part_index) {
        var stock_locations = $scope.panel_content[part_index].locations.locations;

        for(var i = 0, l = stock_locations.length; i < l; i++) {
            stock_locations[i].allocation = 0;
            stock_locations[i].valid = true;
        }

        $scope.panel_content[part_index].valid = true;
        $scope.panel_content[part_index].order_from_supplier.amount = $scope.panel_content[part_index].quantity;

        $scope.handlePartValidation(0);
        $scope.updateSavingButtonText();
    }

    $scope.handleSupplierOrderBlur = function handleSupplierOrderBlur(event, part_index) {
        if (event.target.value.length === 0) {
            $scope.panel_content[part_index].order_from_supplier.amount = 0;
            $scope.handlePartValidation(part_index);
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();
        }
    }

    /*=========================================================================================================
     Check if the inputted value is valid
     =========================================================================================================*/
    $scope.handlePartValidation = function handlePartValidation(part_index) {
        var stock_locations = $scope.panel_content[part_index].locations.locations;
        $timeout(function(){
            var allocation = 0,
                quantity = 0,
                part_allocation_total = 0,
                current_part_quantity_needed = 0,
                order_from_supplier_amount = parseFloat($scope.panel_content[part_index].order_from_supplier.amount);

            $scope.disable_saving = true;

            for(var x = 0, c = stock_locations.length; x < c; x++) {

                allocation = parseFloat(stock_locations[x].allocation);
                quantity = parseFloat(stock_locations[x].quantity);
                part_allocation_total += (allocation)?allocation:0;

                $scope.panel_content[part_index].locations.locations[x].valid = allocation <= quantity;

                if (stock_locations[x].location === 'General') {
                    $scope.panel_content[part_index].locations.locations[x].valid = true;
                }
            }

            // check if the sum of these allocations exceeds the total required for the part
            var part_required_total = parseFloat($scope.panel_content[part_index].quantity);
            part_allocation_total += order_from_supplier_amount;

            $scope.panel_content[part_index].part_allocation_total = part_allocation_total;

            if(isNaN($scope.panel_content[part_index].part_allocation_total)){
                $scope.panel_content[part_index].part_allocation_total = 0;
            }

            if($scope.panel_content[part_index].part_allocation_total == $scope.panel_content[part_index].quantity){
                $scope.disable_saving = false;
                $scope.panel_content[part_index].valid = true;
            }

            $scope.valid = part_allocation_total <= part_required_total;
            $scope.showError = part_allocation_total > part_required_total;

            $scope.updateChosenPartsAllocationTotals(part_index);
            $scope.updateSavingButtonText();
            $scope.checkIfAnyPartsAreInvalid();

        },15);
    }

    $scope.cancelSidePanel = function cancelSidePanel(){
        $scope.$emit('sidepanel:close');
        $scope.updateSavingButtonText();
        $scope.disable_saving = true;
    }
/*
    $scope.goToPurchaseOrder = function goToPurchaseOrder(partsData, fulfil) {

        //$state.params.prepopulatePurchaseOrder = true;
        $scope.totalPartData = [];
        if(fulfil === 'OrderFromSupplier' && $rootScope.clientJobType != 1) {
            $scope.show_purchase_order_button = true;
        }
        angular.forEach(partsData, function (key, value){
            var orderToSupplier = (fulfil === 'OrderFromSupplier')?key.quantity:key.order_from_supplier.amount;
            _.find(key.locations.locations, function (location, lkey) {
                if (location && (location.location === fulfil)) {
                    key.locations.locations[lkey].allocation = key.quantity;
                }
            });
            $scope.customerID = key.waddressID;
            $scope.jobID = key.jobnumber;
            $scope.customerType = key.waddressType;
            $scope.totalPartData.push({'orderToSupplier':orderToSupplier, 'totalQuantity':key.quantity, 'availableParts': key.locations.locations, 'jobPartId': key.jobpartid, 'partId':key.settingspartid, 'salesPrice': key.lastPaidPrice, 'costPrice': key.lastPaidPrice})
        });
        jobData.setSelectedPart($scope.totalPartData);
        if($scope.show_purchase_order_button){
            //scope.populateItemsForPurchaseOrder();
            $scope.closePanel();
            localStorage.setItem('operational_part_add_po', true);
            $state.transitionTo("loggedin.customer_list.view.job.details.new_job_part_purchase_order", { id: $scope.customerID, jobId: $scope.jobID, type: $scope.customerType });

        }else{
            $http.post(prefix + '/save_part_fulfill', "selectedParts=" + angular.toJson($scope.totalPartData) + "&clientJobType=" + $rootScope.clientJobType + "&jobToDos=" + $rootScope.clientJobTodos + "&jobId=" + $scope.jobID).
            success(function (data, status) {
                if (status == 200) {
                    $scope.$emit('parts_view_list:refresh', 'requested');
                    $scope.closePanel();
                }
            });
        }
    }
*/
}


function TimecardApprovalViewCtrl($scope, $state, $rootScope, $http, prefix, $csMapper, $gpsTracker, $timeout, csPopUpPosition, gpsServerConfig, tableCollection, formPanelCollection, $diaryMapScope, $modal, dfConfirmationToast, toastBox, warningModal){

    $scope.showTimecardTable  = false;
    $scope.isVisible = false;
    $scope.shouldBeOpen = false;
    $scope.archiveShouldBeOpen = false;
    $scope.approveShouldBeOpen = false;
    $scope.show_date_picker = false;
    $scope.fsInstance = null;
    $scope.currentPage = 1;
    $scope.activeMapData = null;
    $scope.csMapper = null;
    $scope.isTimeSheetEdited = false;
    $scope.quickFilter = 'unapproved';

    $scope.limit = 20;

    $scope.setLimit = function(value){
        $scope.limit = value;
    };

    // Get Timecard details
    $scope.fetchTimecardDetails = function(pageNum){

        pageNum = (pageNum == undefined) ? 1 : pageNum;
        $scope.pageNumber = pageNum;

        $scope.shouldBeOpen = false;
        $scope.approveShouldBeOpen = false;
        $scope.approveShouldBeOpen = false;
        $scope.timeCardLoading = true;
        $scope.addClick = false;
        $scope.csMapper = null;

        if($scope.fsInstance != null){
            $scope.fsInstance.close();
        }

        if($scope.filterData){
            $http.get(prefix + '/list_timehseet?page=' + pageNum + '&limit=' + $scope.limit + '&quickTab=' + $scope.quickFilter + '&filter=' +  encodeURIComponent(angular.toJson($scope.filterData)))
                .then(function(response) {
                    $scope.setResponseData(response);
                    $scope.timecardFilterCount = $scope.timesheetData.length;
                    $rootScope.$broadcast('reportFilterLoading', 'standard_report_users_timesheet_approval', false);
                });
        }else{
            $http.get(prefix + '/list_timehseet?page=' + pageNum + '&limit=' + $scope.limit + '&quickTab=' + $scope.quickFilter ).then(function(response) {
                $scope.setResponseData(response);
            });
        }
    };

    $scope.setResponseData = function(response){
        $scope.timesheetData = response.data.timesheet;
        $scope.timecardCount = response.data.count;
        $scope.showTimecardTable = response.data.count !=0 ? true: false;
        $scope.userList = response.data.userdetails.users;
        $scope.timeCardLoading = false;
        $scope.dateFormat = response.data.regionalSettings['short_date'];
        $scope.meridianFormat = response.data.regionalSettings['time'] == 0? "true" : "false";
        $scope.hourFormat = response.data.regionalSettings['time'] == 0? "h:mm a" : "HH:mm";
        $scope.browserDateFormat = response.data.browserDateFormat;
        setIndicationIconCount();
    };

    // This function is used for finding the icon count for setting the width of time-card dropdown
    function setIndicationIconCount() {
        if ($scope.timesheetData) {
            $scope.timesheetData.forEach(lineItem => {
                if (lineItem['weeksResult']) {
                    lineItem['weeksResult'].forEach(weekItem => {
                        if (weekItem['count'] > 1) {
                            let iconCount = 0;
                            Object.keys(weekItem).find(key => {
                                if (typeof +key === 'number') {
                                    if (weekItem[key]['status'] == 1 && (weekItem[key]['clockInManually'] === 'Yes' || weekItem[key]['clockOutManually'] === 'Yes')) {
                                        iconCount = 2;
                                        return true;
                                    } else if (weekItem[key]['status'] == 1 || (weekItem[key]['clockInManually'] === 'Yes' || weekItem[key]['clockOutManually'] === 'Yes')) {
                                        iconCount = 1;
                                    }
                                }
                            });

                            weekItem['iconCount'] = iconCount;
                        }
                    });
                }
            });
        }
    }

    // Add sidepanel user sidebar search
    $scope.select2Options = {
        placeholder: "Select some users",
        'multiple': false,
        id: function (obj) {
            return obj.id;
        },
        'data': $scope.userList,
        formatResult: function (item) {
            return item.text;
        },
        formatSelection: function (item) {
            return item.text;
        },
        formatNoMatches: function (item) {
            if (item != '') {
                return 'No results match "' + item + '"';
            } else {
                return "";
            }
        },
        onSelect: function(data, options) {
            $scope.onSelected(data)
        },
        opened: function(data, options) {
            console.log('selected', data)
        }
    };

    // Map marker
    function markerIconHtml2(content, mClass) {
        var colors = {
            red: ' #844C4C',
            green: '#4C8455',
            blue: '#477596',
            brown: ' #b14b1a',
            lightgreen: '#949647',
        };
        return '<svg  data-pop-up-identifier="user-marker"' +
            'xmlns="http://www.w3.org/2000/svg"' +
            'xmlns:xlink="http://www.w3.org/1999/xlink"' +
            'xmlns:xhtml="http://www.w3.org/1999/xhtml" ' +
            'class="cs-marker-round" width="50" height="50" viewBox="0 0 30 30">\n' +
            '    <defs>\n' +
            '        <filter id="svg_2_blur">\n' +
            '            <feGaussianBlur stdDeviation="0.5" in="SourceGraphic"/>\n' +
            '        </filter>\n' +
            '    </defs>\n' +
            '    <g>\n' +
            '        <title>background</title>\n' +
            '        <rect fill="none" id="canvas_background" height="402" width="582" y="-1" x="-1"/>\n' +
            '    </g>\n' +
            '    <g>\n' +
            '        <ellipse filter="url(#svg_2_blur)" opacity="0.2" ry="1.4" rx="3.7" id="svg_2" cy="27.51494" cx="15.032382" fill-opacity="null" stroke-opacity="null" stroke-width="null" stroke="null" fill="null"/>\n' +
            '        <path id="svg_1" fill="' +
            (colors[mClass] || colors['blue']) +  // -------------------- Marker color
            '" d="m15,0.834197c-6.075,0 -11,4.925 -11,11c0,7.234 7.152,10.697 8.048,11.503c0.915,0.823 1.671,2.668 1.976,3.714c0.148,0.508 0.564,0.765 0.976,0.776c0.413,-0.012 0.828,-0.269 0.976,-0.776c0.305,-1.046 1.061,-2.89 1.976,-3.714c0.896,-0.806 8.048,-4.269 8.048,-11.503c0,-6.075 -4.925,-11 -11,-11z"/>\n' +
            '    </g>\n' +
            '</svg>';
    }

    $scope.setFullScreenInstance = function($event) {
            $scope.fsInstance = $event;
        };

    // call after add sidepanel save
    $rootScope.$on("CallParentMethod", function(){
        var pageNum = 1;
        $scope.fetchTimecardDetails(pageNum);
    });

    //

    // $rootScope.$on("CallAddSidepanelClose", function(){
    //
    // });

    // call after edit sidepanel close
    $rootScope.$on("CallEditSidepanel", function(){
        $scope.activeEditSidepanel = false;

    });

    // Reload map after edit
    $rootScope.$on("CallMapReload", function(){
        $scope.isTimeSheetEdited = true;
        $scope.activeEditSidepanel = false;
        $scope.csMapper.cleanOverlay('Timecard');

        if($scope.csMapper) {
            $scope.csMapper.map.off();
            $scope.csMapper.map.remove();
        }

        $scope.triggerMap($scope.timesheetId);
    });

    $scope.$watch('timesheetTab', function(newValue, oldValue) {
        if(newValue != '' && (newValue != oldValue)) {
            var pageNum = 1;
            $scope.quickFilter = newValue;
            $scope.fetchTimecardDetails(pageNum);
        }
    });

    $scope.$watch('filterData',function (new_value, old) {
        var pageNum = 1;
        var isOldDateRange = JSON.stringify(new_value) === JSON.stringify(old)
        //To avoid duplicate data while filter apply two time on page load
        if(!isOldDateRange){
            if(new_value['daterange_last_week'] !== undefined){
                new_value['daterange_last_week'] = getDateArray(new_value.daterange_last_week.startDate, new_value.daterange_last_week.endDate);
            }

        }

        $scope.fetchTimecardDetails(pageNum);
    });

    //To get list of date from dateRange
    function getDateArray(start_date, end_date){
        let startDate = moment(start_date);
        let endDate = moment(end_date);
        let dateObj = [];
        while (startDate <= endDate) {
            dateObj.push(startDate.format('YYYY-MM-DD'));
            startDate.add('days', 1);
        }
        return dateObj;
    }

    // Show mapmodal
    $scope.showMapModalView = function(data, status) {
        if(data) {
            $scope.activeMapData = data;
            $scope.activeMapStatus = status;
            $scope.fsInstance.open();
            $scope.fsInstance.map
        }

    }

    $scope.$on('$destroy', function(){
       $('ng-fs-modal').remove();
    });

    //Open fullscreen modal
    $scope.shownFSModal = function(){
            $scope.triggerMap($scope.activeMapData);
        };

    //Close fullscreen modal
    $scope.hiddenFSModal = function(){
        $scope.activeMapData = null;
        $scope.activeMapStatus = '';
        $scope.fbreakStart = "";
        $scope.fbreakEnd = "";

        $scope.onClockIn = null;
        $scope.onClockOut = null;
        $scope.firstOnBreakStart = null;
        $scope.firstOnBreakEnd = null;

        if($scope.csMapper != null) {
            $scope.csMapper.map.off();
            $scope.csMapper.map.remove();
        }
        if($scope.isTimeSheetEdited) {
            $scope.fetchTimecardDetails($scope.pageNumber);
            $scope.isTimeSheetEdited=false;
        }
    };

    //Approve and close fullscreen modal
    $scope.approveAndCloseFSModal = function() {
        var timesheetId = $scope.activeMapData;
        $http.get(prefix + '/check_singletimesheet_approved/' + timesheetId).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.approveSingleTimesheet(timesheetId)
            }
        });
    };

    //Approve single timesheet
    $scope.approveSingleTimesheet = function(timesheetId){
        $scope.fbreakStart = "";
        $scope.fbreakEnd = "";
        $http.put(prefix + '/approve/single_timesheet/' + timesheetId).then(function (resp) {
            if (resp.data !== "true") {
                warningModal.show("This timecard already deleted", "Timecard Deleted");
                $scope.fsInstance.close();
                $scope.fetchTimecardDetails();
            } else if (resp.data == "true") {
                toastBox.show('Timecard data approved successfully', 2000);
                $scope.fetchTimecardDetails();
            }else{
                toastBox.show('Failed to approve', 2000);
            }
        });
        // $scope.activeMapData = null;
        $scope.hiddenFSModal();

    };

    //Delete and close fulscreen modal
    $scope.deleteAndCloseFSModal = function () {
        var timesheetId = $scope.activeMapData;


        if (timesheetId == null) {
            warningModal.show("This timecard already deleted", "Timecard Deleted");
            $scope.fsInstance.close();
            $scope.fetchTimecardDetails();
        }

        if (parseInt($scope.activeMapStatus)) {
            $scope.deleteUrl = '/archive/single_timesheet/' + timesheetId;
            if ($scope.archiveShouldBeOpen != true) {
                $scope.archiveShouldBeOpen = true;
                $scope.archive = 'single_archive';
                $scope.title = 'Archive.timecard.details';
                $scope.validation = 'ARCHIVE\' to remove the timecard';
            }
        } else {
            $scope.deleteUrl = '/delete/single_timesheet/' + timesheetId;
            if ($scope.shouldBeOpen != true) {
                $scope.shouldBeOpen = true;
                $scope.delete = 'single_delete';
                $scope.title = 'Delete.timecard.details';
                $scope.validation = 'DELETE\' to remove the timecard';
            }
        }
    };

    // Delete Timecard
    $scope.triggerDelete = function (startweek,endweek, userid) {
        $scope.shouldBeOpen = true;
        $scope.deleteUrl = '/timesheet/' + userid + '/delete/' + startweek+  '/' + endweek;
        $scope.delete = 'multiple_delete';
        $scope.title = 'Delete.timesheet.details';
        $scope.validation = 'DELETE\' to remove the timesheet';
    };

    // Archive Timecard
    $scope.triggerArchive = function (startweek,endweek, userid) {
        $scope.archiveShouldBeOpen = true;
        $scope.deleteUrl = '/timesheet/' + userid + '/archive/' + startweek+  '/' + endweek;
        $scope.archive = 'multiple_archive';
        $scope.title = 'Archive.timesheet.details';
        $scope.validation = 'ARCHIVE\' to remove the timesheet';
    };


    //Approve Timecard
    $scope.triggerApprove = function (startEndWeeks, startweek,endweek, userid, username, time){
        $http.get(prefix + '/is_timesheet_approved?userid=' + userid + '&startDate=' + startweek+ '&endDate=' + endweek).success(function(data) {
            if (data.warning === true) {
                warningModal.show(data.message, data.title, data.id);
            }  else {
                $scope.approveShouldBeOpen=true;
                $scope.approveUrl = '/timesheet/'+ userid +'/approve/'+startweek+ '/' + endweek;
            }
        });

        $scope.approveUsername = username;
        $scope.$parent.seweek = startEndWeeks;
        $scope.$parent.totalTime = time;
    };

    // Add sidepanel
    $scope.openTimecardSidepanel = function(data) {
        var modalInstance = $modal.open({
            windowClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel',
            templateUrl: '/template/side_panels/add_timecard_approval_modal.html',
            controller: 'TimecardApprovalSaveCtrl',
            keyboard: false,
            resolve: {
                getFieldOptions: function(){
                    return {
                        data: data,
                        userlist: $scope.userList,
                        dateFormat: $scope.dateFormat,
                        timeFormat: $scope.hourFormat,
                        meridianFormat: $scope.meridianFormat,
                        browserDateFormat: $scope.browserDateFormat
                    };
                }
            }
        });
        modalInstance.result.then(
            function(){
                scope.button_clicked=false;
            }
        );
    };

    //Edit sidepanel
    $scope.editTimecardSidepanel = function(data, timesheetId){

        if ($scope.activeEditSidepanel !== true) {
            $scope.activeEditSidepanel = true;
            $http.get(prefix + '/get_timesheet_details/' + timesheetId).success(function (res) {
                if (res != null) {
                    $scope.editTimesheet = res.timesheet;
                    $scope.editTimesheetBreak = res.timesheetBreak;
                }

                if ($scope.editTimesheet && $scope.editTimesheet == "Timesheet not found") {
                    $scope.activeEditSidepanel = false;
                    warningModal.show("This timecard already deleted", "Timecard Deleted");
                    $scope.fetchTimecardDetails();
                    $scope.fsInstance.close();
                } else {
                    var modalInstance = $modal.open({
                        windowClass: 'df-action df-action-add_dependant-modal df-action-modal sidepanel',
                        templateUrl: '/template/side_panels/edit_timecard_approval_modal.html',
                        controller: 'TimecardApprovalEditCtrl',
                        keyboard: false,
                        resolve: {
                            getFieldOptions: function () {
                                return {
                                    data: data,
                                    editTimecard: $scope.editTimesheet,
                                    editTimecardBreaks: $scope.editTimesheetBreak,
                                    activeEditSidepanel: $scope.activeEditSidepanel,
                                    dateFormat: $scope.dateFormat,
                                    timeFormat: $scope.hourFormat,
                                    meridianFormat: $scope.meridianFormat,
                                    browserDateFormat: $scope.browserDateFormat

                                };
                            }
                        }
                    });
                    modalInstance.result.then(
                        function () {
                            scope.button_clicked = false;
                        }
                    );
                }
            });
        }
    };

    //Open fullscreen map view
    $scope.triggerMap = function (timesheetid) {

        $scope.onClockIn = null;
        $scope.onClockOut = null;
        $scope.firstOnBreakStart = null;
        $scope.firstOnBreakEnd = null;
        $scope.activeEditSidepanel = false;
        $scope.totalDistance = 0;

        // addPointingMarker
        var csMapper = $csMapper.get();
        $scope.csMapper = csMapper;

        // //Rendering Map
        csMapper.initialize('timecard-map', 'vector');
        var overlay = csMapper.addOverlay('Timecard');

        $scope.isVisible = true;

        // $scope.showTimecardTable = false;
        $scope.toolPic = false;
        $scope.timesheetId = timesheetid;

        // get timesheet map data
        $http.get(prefix + '/timesheet_map_view/' + timesheetid).success(function(data) {
            $scope.startTime = data['timesheetMapData']['startTime'];
            $scope.endTime = data['timesheetMapData']['endTime'];
            $scope.date = data['timesheetMapData']['clockIn'];
            $scope.isSameDay = data['timesheetMapData']['isSameDay'];
            $scope.startTimeClockIn = data['timesheetMapData']['startTimeClockIn'];
            $scope.endTimeClockOut = data['timesheetMapData']['endTimeClockOut'];
            $scope.isSameDay = data['timesheetMapData']['isSameDay'];
            $scope.payableHours = ((data['timesheetMapData']['payableHours'][0] != '') ? Math.round(data['timesheetMapData']['payableHours'][0]) : 0) + " hrs";
            $scope.payableMinutes = (data['timesheetMapData']['payableHours'][1] != undefined ? data['timesheetMapData']['payableHours'][1] : 0) + " mins";
            $scope.clockInLattitude = data['timesheetMapData']['clockInLattitude'];
            $scope.clockInLongitude = data['timesheetMapData']['clockInLongitude'];
            $scope.clockOutLattitude = data['timesheetMapData']['clockOutLatitude'];
            $scope.clockOutLongitude = data['timesheetMapData']['clockOutLongitude'];

            $scope.clockInShortDate = data['timesheetMapData']['clockInShortDate'];
            $scope.clockOutShortDate = data['timesheetMapData']['clockOutShortDate'];
            $scope.totalEstimatesAndJobs = data['timesheetMapData']['totalEstimatesAndJobs'];
            $scope.totalTravelTimeHours = ((data['timesheetMapData']['totalTravelTime'][0] != '') ? data['timesheetMapData']['totalTravelTime'][0] : 0) + " hrs";
            $scope.totalTravelTimeMinutes = ((data['timesheetMapData']['totalTravelTime'][1] != undefined) ? data['timesheetMapData']['totalTravelTime'][1] : 0) + " mins";
            $scope.totalTimeOnSiteHours = ((data['timesheetMapData']['totalTimeOnSite'][0] != '') ? data['timesheetMapData']['totalTimeOnSite'][0] : 0) + " hrs";
            $scope.totalTimeOnSiteMinutes = ((data['timesheetMapData']['totalTimeOnSite'][1] != undefined) ? data['timesheetMapData']['totalTimeOnSite'][1] : 0) + " mins";

            $scope.onClockIn = data['timesheetMapData']['onClockIn'];
            $scope.onClockOut = data['timesheetMapData']['onClockOut'];

            $scope.clockInManualEntry = data['timesheetMapData']['clockInManualEntry'];
            $scope.clockOutManualEntry = data['timesheetMapData']['clockOutManualEntry'];
            $scope.clockInManualEntryReason = data['timesheetMapData']['clockInManualEntryReason'];
            $scope.clockOutManualEntryReason = data['timesheetMapData']['clockOutManualEntryReason'];
            $scope.gpsDevice = data['timesheetMapData']['gpsDeviceId'] == 'Yes'? true: false;
            $scope.deviceId = data['timesheetMapData']['DeviceId'] == null? "Device not available" : data['timesheetMapData']['DeviceId'];
            // var mapScreenTitle = string.toUpperCase(data['timesheetMapData']['engineerName']);
            // var mapScreenSubTitle =  data['timesheetMapData']['dayofweek'];
            $scope.status = data['timesheetMapData']['status'];
            $scope.engineerName = data['timesheetMapData']['engineerName'];

            $scope.fullScreenModal = {
                title: data['timesheetMapData']['engineerName'],
                subTitle: data['timesheetMapData']['dayofweek'],
                subText:"timecard for",
            };


            $scope.breakCount = data['timesheetBreakCount'];
            $scope.breakData = data['timesheetMapBreakData'];
            $scope.arriveData = data['timesheetArriveData'];
            $scope.arriveCount = data['timesheetArriveDataCount'];


            if($scope.breakCount !== 0){
                // first break
                var first = _.first($scope.breakData);
                $scope.fbreakStart = first.breakStart;
                $scope.fbreakEnd = first.breakEnd;
                $scope.fbreakStartDateFormat = first.breakStartShortDate;
                $scope.fbreakEndDateFormat = first.breakEndShortDate;
                $scope.fbreakTimeDiff = first.breakTimeDiff;
                $scope.firstOnBreakEnd = first.onBreakEnd;
                $scope.firstOnBreakStart = first.onBreakStart;
                $scope.firstBreakStartManualReason = first.breakStartManualReason;
                $scope.firstBreakEndManualReason = first.breakEndManualReason;


                $scope.breaks = data.timesheetMapBreakData.slice(1);
                $scope.breaksCount = $scope.breaks.length;

            }


        });

        $scope.showToolPic= function(){
            $scope.toolPic = !$scope.toolPic;
        };

        $scope.tripDistance = function(tracker, deviceId, csMapper, content, popupHtml){

            if(tracker != undefined && tracker.length != 0){
                if(tracker[deviceId].length !== 0){

                    var tripInfo = [];
                    tripInfo = {
                        'tripDetails': tracker[deviceId].map(function (p) {
                            return {'points': [p.latitude, p.longitude], 'speed': p.speed, 'altitude': p.altitude, 'distance': p.attributes['distance'], 'totalDistance': p.attributes['distance']
                            }
                        }).filter(function (p) {
                            return p.points[0] !== 0 && p.points[1] !== 0;
                        }),
                        'points':  tracker[deviceId].map(function (p) {
                            return [p.latitude, p.longitude]
                        }).filter(function (p) {
                            return p !== 0 && p !== 0;
                        }),
                        'distance': tracker[deviceId].map(function (p) {
                            return p.attributes['distance']
                        }).filter(function (p) {
                            return p !== 0 && p !== 0;
                        })
                    };

                    var sum = tripInfo.distance.reduce(function (a, b) {
                        return a + b;
                    }, 0);

                    $scope.totalDistance = sum!== undefined ? sum : 0;
                    csMapper.addNavigation('Timecard', 'navigation_', tripInfo.points, '#0078A8', content, popupHtml);
                }

            }

        };

        setTimeout(function () {
            var clockInCoordinates = $scope.clockInLattitude != null? [[$scope.clockInLattitude, $scope.clockInLongitude]] : null;
            var clockOutCoordinates = $scope.clockOutLattitude != null? [[$scope.clockOutLattitude, $scope.clockOutLongitude]]: null;
            var arriveCoordinates = [];
            var breakCoordinates = [];
            var bStartCoordinates = [];
            var bEndCoordinates = [];
            var arCoordinates = [];

            // break coordinate
            if($scope.breakCount !== 0){
                angular.forEach($scope.breakData, function(filter, key) {
                    if((filter.breakStartLatitude) != null || (filter.breakEndlatitude) != null){
                        bStartCoordinates = [[filter.breakStartLatitude, filter.breakStartLongitude], filter.breakStartShortDate, filter.breakStart];
                        bEndCoordinates = [[filter.breakEndlatitude, filter.breakEndlongitude],filter.breakEndShortDate, filter.breakEnd];
                        breakCoordinates.push(bStartCoordinates);
                        breakCoordinates.push(bEndCoordinates);
                    }


                });
            }

            // arrive coordinate
            if($scope.arriveCount !== 0){
                angular.forEach($scope.arriveData, function(filter, key) {
                    if((filter.arriveLatitude) != null || (filter.arriveLongitude) != null){
                        arCoordinates =[[filter.arriveLatitude, filter.arriveLongitude], filter.arriveDate, filter.arriveTime];
                        arriveCoordinates.push(arCoordinates);
                    }
                });

            }


            var content = '<div class="text-icon"></div>';
            var popupHtml = null;

            //Marker icon
            var icon = '<div class="trip-marker-icon" data-toggle="tooltip" data-pop-up-identifier="user-marker" ' +
                'data-event-data=""><div data-toggle="tooltip" data-pop-up-identifier="user-marker" class="marker"></div>'
            // var myIcon = L.divIcon({html: icon, iconAnchor: [6, 9]});
            var greenMarker = L.divIcon({html: markerIconHtml2(content, 'green'), iconAnchor: [24, 47], className: 'user-route-start-end-marker'});
            var redMarker = L.divIcon({html: markerIconHtml2(content, 'red'), iconAnchor: [24, 47], className: 'user-route-start-end-marker'});
            var lightGreenMarker = L.divIcon({html: markerIconHtml2(content, 'lightgreen'), iconAnchor: [24, 47], className: 'user-route-start-end-marker'});
            var blueMarker = L.divIcon({html: markerIconHtml2(content, 'blue'), iconAnchor: [24, 47], className: 'user-route-start-end-marker'});


            if($scope.gpsDevice == true){
                var date = $scope.date;
                var deviceId = parseInt($scope.deviceId);
                var startDate = moment.utc(date, 'YYYY-MM-DD').set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0
                }).toISOString();
                var endDate = moment.utc(date, 'YYYY-MM-DD').set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999
                }).toISOString();
                var tracker;

                let arr = new Array();
                arr[0] = deviceId;
                $gpsTracker.getRoute(arr, startDate, endDate).then(function (result) {
                    if (result instanceof Object) {
                        tracker = result;
                        console.log("tracke", tracker);
                        $scope.tripDistance(tracker, deviceId, csMapper, content, popupHtml);
                    } else {
                        csMapper.adjustMapBounds();
                        }
                });

            }

            // Marker set

                if(clockInCoordinates !== null){
                    angular.forEach(clockInCoordinates, function (data, index) {
                        var tripMarker = L.marker(data, {icon: greenMarker});

                        tripMarker.addTo(overlay)
                            .on('mouseover', function (event) {
                                event['currentTarget'] = event.originalEvent.target;
                                $scope.coordinate = data;
                                $scope.mapDate = $scope.clockInShortDate;
                                $scope.time =  $scope.startTime;
                                csPopUpPosition.init(event, true);
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = true;
                                });
                            })
                            .on('mouseout', function (event) {
                                // csPopUpPosition.init(event);
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = false;
                                });
                            });
                    });

                }

                 //break popup
                if($scope.breakCount !== 0){
                    angular.forEach(breakCoordinates, function (data1, index) {
                        var Marker = L.marker(data1['0'], {icon: lightGreenMarker});
                        Marker.addTo(overlay)
                            .on('mouseover', function (event) {
                                event['currentTarget'] = event.originalEvent.target;
                                $scope.coordinate = data1['0'];
                                $scope.mapDate = data1['1'];
                                $scope.time =  data1['2'];
                                csPopUpPosition.init(event, true);
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = true;
                                });
                            })
                            .on('mouseout', function (event) {
                                //csPopUpPosition.init(event);
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = false;
                                });
                            });
                    });
                }

                //clockout popup
                if(clockOutCoordinates !== null){
                    angular.forEach(clockOutCoordinates, function (data2, index) {
                        var clockOutMarker = L.marker(data2, {icon: redMarker});
                        clockOutMarker.addTo(overlay)
                            .on('mouseover', function (event) {
                                event['currentTarget'] = event.originalEvent.target;
                                $scope.coordinate = data2;
                                csPopUpPosition.init(event, true);
                                $scope.mapDate = $scope.clockOutShortDate;
                                $scope.time =  $scope.endTime;
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = true;
                                });
                            })
                            .on('mouseout', function (event) {
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = false;
                                });
                            });
                    });
                }

                //arrive popup
                if(arriveCoordinates.length !== 0){
                    angular.forEach(arriveCoordinates, function (data3, index) {
                        var arriveMarker = L.marker(data3['0'], {icon: blueMarker});
                        arriveMarker.addTo(overlay)
                            .on('mouseover', function (event) {
                                event['currentTarget'] = event.originalEvent.target;
                                $scope.coordinate = data3['0'];
                                $scope.mapDate = data3['1'];
                                $scope.time =  data3['2'];
                                csPopUpPosition.init(event, true);
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = true;
                                });
                            })
                            .on('mouseout', function (event) {
                                $timeout(function () {
                                    $scope.showTimeCardpMarkerPopup = false;
                                });
                            });
                    });
                }

            csMapper.adjustMapBounds();

        }, 3000);

    }

    $scope.renderTooltipContent = function(reason, clockIn) {
        var content = '<span>';
        if(reason) {
            content += '"<strong>'+reason+'</strong>"<br/><br/>';
        }
        content += 'Actual button tap was at ' + clockIn + '</span>';
        return content;
    };

}


function TimecardApprovalEditCtrl($scope,$modalInstance, getFieldOptions, $state, $rootScope, $http, prefix, $modal, toastBox){

    $scope.addtimecard = getFieldOptions.editTimecard;
    $scope.addtimecard['clockOut'] = $scope.addtimecard['clockOut'] ? $scope.addtimecard['clockOut'] : moment();
    $scope.addTimesheetBreak = getFieldOptions.editTimecardBreaks;
    $scope.activeEditSidepanel = getFieldOptions.activeEditSidepanel;
    $scope.selectedStartDate =  $scope.addtimecard['clockIn'];
    $scope.selectedEndDate =  $scope.addtimecard['clockOut'];
    $scope.userId = $scope.addtimecard['userId'];
    var timesheetId =  $scope.addtimecard['id'];

    $scope.addBreakLength =  $scope.addTimesheetBreak.length;
    $scope.data_loaded = false;
    $scope.error = false;
    $scope.error1 = false;
    $scope.defaultDateFormat = getFieldOptions.dateFormat;
    $scope.defaultTimeFormat = getFieldOptions.timeFormat;
    $scope.defaultMeridianFormat = getFieldOptions.meridianFormat;
    $scope.browserDateFormat = getFieldOptions.browserDateFormat;

    if($scope.addBreakLength == 0) {
        $scope.newBreaks = [];
    } else{
        $scope.newBreaks = $scope.addTimesheetBreak;

        angular.forEach( $scope.newBreaks, function(val, key) {
            $scope.newBreaks[key]['breakStartDate'] =  moment(val['breakStart']).format('YYYY-MM-DD');
            $scope.newBreaks[key]['breakEndDate'] =  moment(val['breakEnd']).format('YYYY-MM-DD');
            $scope.newBreaks[key]['breakStartTime'] =  moment(val['breakStart']).format('YYYY-MM-DD HH:mm');
            $scope.newBreaks[key]['breakEndTime'] =  moment(val['breakEnd']).format('YYYY-MM-DD HH:mm');
        });
    }

    $scope.addtimecard = {
        userid: $scope.userId,
        timeSheetId: timesheetId,
        selectedStartDate: $scope.selectedStartDate,
        selectedEndDate: $scope.selectedEndDate,
        breaks: $scope.newBreaks
    };

    $scope.inputs = {};
    $scope.selectedStartTime = moment($scope.selectedStartDate).format('YYYY-MM-DD HH:mm');
    $scope.selectedEndTime = moment($scope.selectedEndDate).format('YYYY-MM-DD HH:mm');

    $scope.addtimecard['selectedStartDate'] = moment($scope.addtimecard.selectedStartDate).format('YYYY-MM-DD');
    $scope.addtimecard['selectedEndDate'] = moment($scope.addtimecard.selectedEndDate).format('YYYY-MM-DD');
    $scope.addNewTimecard = false;



    TimecardCommonControls.call(this, $scope, $http, prefix);

    $scope.onTimeCardChangeDate();

    $scope.updateTime = function(name, value){
        $scope[name] = moment(value).format('YYYY-MM-DD HH:mm');
    };


    //Update timecard
    $scope.editTimecard = function () {
        $scope.data_loaded = true;

        $scope.addtimecard.selectedStartDate = $scope.selectedStartDate;
        $scope.addtimecard.selectedEndDate = $scope.selectedEndDate;

        let selectedStartTime = moment($scope.selectedStartTime);
        let selectedEndTime = moment($scope.selectedEndTime);
        let startDateTime = moment($scope.selectedStartDate).clone().hours(selectedStartTime.hours()).minutes(selectedStartTime.minutes());
        let endDateTime = moment($scope.selectedEndDate).clone().hours(selectedEndTime.hours()).minutes(selectedEndTime.minutes());

        if(($scope.addtimecard.breaks).length != 0){
            angular.forEach($scope.addtimecard.breaks, function(val, key) {
                $scope.addtimecard.breaks[key]['breakStart'] = $scope.mergeBreakDateTime(val['breakStartDate'], moment(val['breakStartTime']));
                $scope.addtimecard.breaks[key]['breakEnd'] = $scope.mergeBreakDateTime(val['breakEndDate'], moment(val['breakEndTime']));
                val['breakStartTime'] = '';
                val['breakEndTime'] = '';
            });
        }


        var data = $scope.addtimecard;
        data.selectedStartDate = moment(startDateTime).format('YYYY-MM-DD HH:mm');
        data.selectedEndDate = moment(endDateTime).format('YYYY-MM-DD HH:mm');
        $http.post(prefix + '/update_timesheet_details/' + timesheetId, 'formData=' + encodeURIComponent(angular.toJson(data))).success(function (data, status) {
            if (data !== "true") {
                $modalInstance.dismiss();
                warningModal.show("This timecard already deleted", "Timecard Deleted");
                $scope.fetchTimecardDetails();
            } else if (data == "true") {
                $modalInstance.dismiss();
                toastBox.show('Timecard data updated successfully', 2000);
                $scope.data_loaded = false;
                $rootScope.$emit("CallMapReload", {});
            } else {
                toastBox.show('Failed to updated', 2000);
            }
        });

    }

    // Close sidepanel
    $scope.onClose = function() {
        $modalInstance.dismiss();
        $rootScope.$emit("CallEditSidepanel", {});
    };

    // Delete break row
    $scope.deleteItem = function(index) {
        $scope.newBreaks.splice(index, 1);
    };


}

function TimecardApprovalSaveCtrl($scope,$modalInstance, getFieldOptions, $state, $rootScope, $http, prefix, $modal, toastBox, $filter){

    $scope.breakLength = 0;
    $scope.data_loaded = false;
    $scope.btnDisabled = true;
    $scope.userList = getFieldOptions.userlist;
    $scope.error = false;
    $scope.error1 = false;
    $scope.defaultDateFormat = getFieldOptions.dateFormat;
    $scope.defaultTimeFormat = getFieldOptions.timeFormat;
    $scope.defaultMeridianFormat = getFieldOptions.meridianFormat;
    $scope.browserDateFormat = getFieldOptions.browserDateFormat;

    /*$scope.maxDate = new Date();
    $scope.minDate = $scope.maxDate.setDate($scope.maxDate.getDate() - 30);*/

    $scope.newBreaks = [];

    $scope.deleteItem = function(index) {
        $scope.newBreaks.splice(index, 1);
    };

    $scope.addtimecard = {
        userid:'',
        selectedStartDate:'',
        selectedEndDate:'',
        breaks: $scope.newBreaks
    };
    $scope.inputs = {};
    //$scope.inputs.datepickerOpened = false;
    $scope.selectedStartTime = moment().format('YYYY-MM-DD HH:mm');
    $scope.selectedEndTime = moment().format('YYYY-MM-DD HH:mm');
    $scope.addNewTimecard = true;

    TimecardCommonControls.call(this, $scope, $http, prefix);

    $scope.onClose = function() {
        $modalInstance.dismiss();
        // $rootScope.$emit("CallAddSidepanelClose", {});
    };

    $scope.saveTimecard = function () {

        $scope.data_loaded = true;

        //# Convert the date and time format
        $scope.addtimecard.selectedStartDate = $scope.selectedStartDate;
        $scope.addtimecard.selectedEndDate = $scope.selectedEndDate;
        $scope.addtimecard.selectedStartTime = $scope.selectedStartTime;
        $scope.addtimecard.selectedEndTime = $scope.selectedEndTime;

        if(($scope.addtimecard.breaks).length != 0){
            angular.forEach($scope.addtimecard.breaks, function(val, key) {
                $scope.addtimecard.breaks[key]['breakStart'] = $scope.mergeBreakDateTime(val['breakStartDate'], moment(val['breakStartTime']));
                $scope.addtimecard.breaks[key]['breakEnd'] = $scope.mergeBreakDateTime(val['breakEndDate'], moment(val['breakEndTime']));
                val['breakStartTime'] = '';
                val['breakEndTime'] = '';
            });
        }

        $http.post(prefix + '/save_timesheet_details', 'formData=' + encodeURIComponent(angular.toJson( $scope.addtimecard)))
            .success(function (data, status) {
            if (data == "true") {
                $modalInstance.dismiss();
                toastBox.show('Timecard data saved successfully', 2000);
                $scope.data_loaded = false;
                $rootScope.$emit("CallParentMethod", {});
            }
        });
    }

}

function TimecardCommonControls($scope, $http, prefix) {

    $scope.addRow = function() {
        $scope.newBreaks.push({
            breakStart:null,
            breakEnd:null,
            breakStartDate: null,
            breakEndDate: null,
            breakStartTime: moment().format('YYYY-MM-DD HH:mm'),
            breakEndTime: moment().format('YYYY-MM-DD HH:mm')
        });
        $scope.btnDisabled = true;
    };

    $scope.minDate = moment().subtract(1, 'month');
    $scope.maxDate = moment().add(1, 'day');
    $scope.savebtndisabled = false;

    $scope.mergeStartEndDateTime = function() {
        $scope.startTemp = moment($scope.selectedStartTime);
        $scope.endTemp = moment($scope.selectedEndTime);
        $scope.selectedStartDate = $scope.addtimecard.selectedStartDate;
        $scope.selectedEndDate = $scope.addtimecard.selectedEndDate;
        $scope.nextDayDateTime = moment();

        //# Start date/time
        $scope.selectedStartDate = moment($scope.selectedStartDate).clone().hours($scope.startTemp.hours()).minutes($scope.startTemp.minutes());
        $scope.selectedStartDate = moment($scope.selectedStartDate).format('YYYY-MM-DD HH:mm');

        //# End date/time
        $scope.selectedEndDate = moment($scope.selectedEndDate).clone().hours($scope.endTemp.hours()).minutes($scope.endTemp.minutes());
        $scope.selectedEndDate = moment($scope.selectedEndDate).format('YYYY-MM-DD HH:mm');

        //# Get the one day from start date
        $scope.nextDayDateTime = moment($scope.selectedStartDate).add(1, 'days').format('YYYY-MM-DD HH:mm');
    }

    $scope.validations = function() {
        //console.log("entered", $scope.addtimecard.userid);
        $scope.backEndCheck = $scope.addtimecard;
        if(($scope.addtimecard.selectedStartDate != '') && ($scope.addtimecard.selectedEndDate != '')) {
                $scope.error = false;
                $scope.error1 = false;

                $scope.mergeStartEndDateTime();

                if(($scope.selectedEndDate <= $scope.nextDayDateTime) && ($scope.selectedEndDate > $scope.selectedStartDate) && ($scope.addtimecard.userid != null)) {

                    $scope.backEndCheck.selectedStartDate = $scope.selectedStartDate;
                    $scope.backEndCheck.selectedEndDate = $scope.selectedEndDate;

                    $scope.addtimecard.selectedStartDate = $scope.addtimecard.selectedStartDate;
                    $scope.addtimecard.selectedEndDate = $scope.addtimecard.selectedEndDate;

                $http.get(prefix + '/check_timesheet_exits?data=' + encodeURIComponent(angular.toJson($scope.backEndCheck)))
                    .then(function (resp) {
                        if (resp.data == "true") {
                            $scope.btnDisabled = false;
                            $scope.savebtndisabled = $scope.btnDisabled;
                            $scope.onBreakChangeDate($scope);
                        } else {
                            $scope.error = "Timecard already exists";
                            $scope.btnDisabled = true;
                            $scope.savebtndisabled = $scope.btnDisabled;
                        }
                    });
            } else {
                if ($scope.addtimecard.userid == null) {
                    $scope.btnDisabled = true;
                    $scope.error1 = "";
                } else {
                    $scope.error1 = "Invalid end date/time";
                    $scope.btnDisabled = true;
                }

                }
            }

        //# Validate the breaks times
        if($scope.btnDisabled == false) {
            $scope.onBreakChangeDate();
        }
    };

    $scope.timeCardDatePickerOpen = function($event, pickerOpen, key) {
        $event.preventDefault();
        $event.stopPropagation();
        let scopeValue = pickerOpen+key;
        $scope.inputs[scopeValue] = true;
    }

    $scope.onTimeCardChangeDate = function() {
        $scope.validations();
    }

    $scope.changeEventDateTime = function(name, value) {
        $scope[name] = value;
        if($scope.addNewTimecard == false){
            $scope.updateTime(name, value);
        }
        $scope.validations();
    }

    $scope.breakChangeEventDateTime = function(name, value) {
        $scope[name] = value;
        $scope.onBreakChangeDate();
    }

    $scope.onBreakChangeDate = function() {
        $scope.mergeStartEndDateTime();
        $scope.breakErrors = [];
        if ($scope.savebtndisabled == false) {
            if (($scope.addtimecard.selectedStartDate != '') && ($scope.addtimecard.selectedEndDate != '') && (($scope.addtimecard.breaks).length != 0)) {
                let startBreakDateTime = '', endBreakDateTime = '', compareStartDateTime = '', compareEndDateTime = '';

            //# Check the entire date time break validation
            angular.forEach($scope.addtimecard.breaks, function(val, key) {

                if(key === 0) {
                    compareStartDateTime = $scope.selectedStartDate;
                    compareEndDateTime = $scope.selectedEndDate;
                } else {
                    compareStartDateTime = startBreakDateTime;
                    compareEndDateTime = endBreakDateTime;
                }

                if(val['breakStartDate'] != '' && val['breakEndDate'] != '') {
                    //# Merge the break date and time
                    startBreakDateTime = $scope.mergeBreakDateTime(val['breakStartDate'], moment(val['breakStartTime']));
                    endBreakDateTime = $scope.mergeBreakDateTime(val['breakEndDate'], moment(val['breakEndTime']));

                        //# If the key = 0 then need to check with start and end date time
                        //# else check the condition with previous start and end break date time
                        if (key === 0) {
                            if (((startBreakDateTime < compareStartDateTime) || (compareEndDateTime < endBreakDateTime)) || (startBreakDateTime >= endBreakDateTime)) {
                                $scope.breakErrors[key] = true;
                                $scope.btnDisabled = true;
                            } else {
                                $scope.breakErrors[key] = false;
                                $scope.btnDisabled = false;
                            }
                        } else {
                            if (((startBreakDateTime < compareStartDateTime) || (endBreakDateTime < compareEndDateTime)) || (startBreakDateTime >= endBreakDateTime) || ($scope.selectedEndDate < endBreakDateTime)) {
                                $scope.breakErrors[key] = true;
                                $scope.btnDisabled = true;
                            } else {
                                $scope.breakErrors[key] = false;
                                $scope.btnDisabled = false;
                            }
                        }
                    }
                });
            }
            if ($scope.addtimecard.userid == null) {
                $scope.btnDisabled = true;
            }
        }
    }

    $scope.mergeBreakDateTime = function(breakStartDate, breakStartTime) {
        let breakDateTime = moment(breakStartDate).clone().hours(breakStartTime.hours()).minutes(breakStartTime.minutes());
        return moment(breakDateTime).format('YYYY-MM-DD HH:mm');
    }
}






