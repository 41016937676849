csmodule.controller('addDiaryEvent', ['$scope', '$rootScope', '$q', '$filter', '$timeout', 'addDiaryEvent', 'diaryRightBarHelper', 'prefix', '$window', '$state', '$http', 'canLoad','confirmationBoxHelper', 'checkEngineerShift','warningModal', function($scope, $rootScope, $q, $filter, $timeout, addDiaryEvent, diaryRightBarHelper, prefix, $window, $state, $http, canLoad, confirmationBoxHelper, checkEngineerShift,warningModal) {
  /*==========================================================================================
   Initial Variables
   ==========================================================================================*/
  $scope.diary_ctrl_scope = $scope.$parent.$parent.$parent;
    $scope.estimateJobDetails = $scope.diary_ctrl_scope.estimateJobDetails;
    $scope.milestones = $scope.diary_ctrl_scope.milestones;
    $scope.invoiceAccess = $scope.diary_ctrl_scope.invoiceAccess;
    $scope.skillsData = $scope.diary_ctrl_scope.skills_data;
    $scope.searching = false;
    $scope.newJob = false;
    $scope.newEstimate = false;
    $scope.showOverlappingError = false;
    $scope.saving_diary_event = false;
    $scope.defaultEmailFooter = '';
    $scope.selectedContactId = 0;
    $scope.isCompany = false;
    $scope.jobConfirmationTemplate = '';
    $scope.showEngineerWarninig = false;
    $scope.selectedSkills = [];
    $scope.partiallyMatching = false;
    $scope.skillsToCompare = $scope.diary_ctrl_scope.jobSkills;
    $scope.skillsModulesAccess = $scope.diary_ctrl_scope.skillsModulesAccess;
    SkillsManager.call(this, $scope, $scope.skillsData);
    // For google places
    $scope.placesOptions = {
        types: ['(cities)'],
    };


    $scope.checkAndUpdateSkills = function (event_type) {
        if (event_type == 'job') {
            $scope.skillsChanged();
        }
    }

    $scope.skillsChanged = function() {
        if($scope.skillsData && !$scope.skillsData.length) {
            return;
        }
        $scope.showEngineerWarninig = false;
        $scope.partiallyMatching = false;
        var isUserHasSkill = true;
        var skills = '';
        var selectedSkills;
        if($scope.adding_event_from_previous_page) {
            selectedSkills = $scope.skillsToCompare;
        } else {
            selectedSkills = angular.element('#job_skills_section').scope().selectedSkills;
        }

        if(selectedSkills && selectedSkills.length) {
            angular.forEach(selectedSkills, function (val, key) {
                var concat = (skills != '' ? "," : "");
                skills += concat + val.id;
            });
        }
        $scope.add_job_event_inputs.job.skills = skills;
        if(skills != '') {
            isUserHasSkill = false;
            $scope.isSkilledPermission = true;
            var user = $scope.add_diary_event_form_inputs.user;
            if (user && user.skills) {
                var userSkills = user.skills;
                var skillsMatched = 0;
                angular.forEach(userSkills, function (value, key) {
                    var isAvailable = _.where(selectedSkills, {id: value.id});
                    if(isAvailable.length) {
                        isUserHasSkill = true;
                        skillsMatched ++;
                    }
                });
            }
        }
        $scope.showEngineerWarninig = !isUserHasSkill;
        if(isUserHasSkill && selectedSkills.length && skillsMatched != selectedSkills.length) {
            $scope.partiallyMatching = true;
        }
        if($rootScope.hasPermission(features['SetupSkills'], 'readaccess') && $scope.previous_event_type == 'job'){
            $scope.isSkilledPermission = false;
        }
    }

    var canceler = null;
    $scope.getSkillsForJobDescription = function(jobDescriptionId) {
        canLoad.setLoadValue(false);
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        $http.get(prefix + '/get_assigned_skills?type=job_description&primaryId=' +jobDescriptionId, {timeout: canceler.promise}).success(function (data) {
            $scope.selectedSkills = data.skills;
            angular.element('#job_skills_section').scope().selectedSkills = data.skills;
            $scope.skillsChanged();
        });
        $scope.add_job_event_inputs.job.engineernotes='';
        $scope.add_job_event_inputs.job.quotedamount='';
        $scope.add_job_event_inputs.job.invoicecategoryid='';
        $scope.add_job_event_inputs.job.isservicejob=false;
        $scope.add_job_event_inputs.job.servicereminderinstances='';
        $scope.notificationMessageServiceReminder=false;
        $http.get(prefix + '/default_job_values/' + jobDescriptionId, {timeout: canceler.promise}).success(function (data) {
            if(data['id']){
                $scope.add_job_event_inputs.job.engineernotes=(data['jobnotes']!='')?data['jobnotes']:'';
                $scope.add_job_event_inputs.job.quotedamount=(data['quotedamount']!='')?data['quotedamount']:'';
                $scope.add_job_event_inputs.job.invoicecategoryid=(data['invoicecategory']!='')?data['invoicecategory']:'';
                $scope.add_job_event_inputs.job.priority=(data['priority']!='')? data['priority']:'';
                if(data['servicereminder1']){
                    $scope.service_types
                    let serviceType1= _.findWhere($scope.service_types, {servicereminderid: data['servicereminder1']});
                    if(serviceType1['available']==true){
                        $scope.notificationMessageServiceReminder=false;
                        $scope.add_job_event_inputs.job.isservicejob=true;
                        $scope.add_job_event_inputs.job.servicereminderinstances=serviceType1['id'].toString();
                    }else{
                        $scope.notificationMessageServiceReminder=true;
                    }
                }
            }
        });
    }

    //# Get the arrive leave questions when select job description id
    $scope.getArriveLeaveQuestions = function(jobDescriptionId) {
        $http.get(prefix + '/getArriveLeaveQuestions?jobDescriptionId=' + jobDescriptionId).success(function (data) {
            $scope.arriveQuestions = data.arriveData;
            $scope.add_job_event_inputs.job.arriveQuestions = data.arriveDefaultValue;
            $scope.leaveQuestions = data.leaveData;
            $scope.add_job_event_inputs.job.leaveQuestions = data.leaveDefaultValue;
        });
    }

    //#Load custom field templates

    $scope.getCustomFieldTemplates = function(jobDescriptionId, propertyId){
        if($scope.checkCustomFieldsPermission == 1){
            let objects = 'Jobs',objectTypes='JobDescription';
            $http.get(prefix + '/listCustomConfigTemplates?screenMode=addCustomTemplates&action=add&objects='+objects+'&objectTypes='+objectTypes+'&objectTypeID='+jobDescriptionId+'&propertyID='+propertyId+'&tablePKID=0').success(function (data) {
                let customFieldsArray = [];
                data['templatesList'].forEach((val, key) => {
                    customFieldsArray[val.ID]=true;
                });
                $scope.customFieldsValidate=customFieldsArray;
                $scope.formCustomerCustomFieldsData=data.templatesList;
            });
        }


    }
    $scope.getCustomFieldFormValueChanges = function getCustomFieldFormValueChanges(formValue,event){
        $scope.formValue[event.ID] = formValue;
    }

    $scope.getCustomFieldJobFormStatusChanges = function getCustomFieldJobFormStatusChanges(isFormValid){
        $scope.customFieldsValidate[isFormValid.ID]=isFormValid.formValidStatus;
        let isValid=true;
        $scope.formCustomerCustomFieldsData.forEach((val, key) => {
            if($scope.customFieldsValidate[val.ID]==false){
                isValid=false;
            }
        });
        $scope.isFormValid = isValid;
    }

    // To get opportunity templates list if its not loaded already
    $scope.opportunityTemplates = [];
    $scope.reportingCategories = [];
    $scope.salesPersons = [];
    $scope.userGroups = [];
    $scope.siteContacts = [];
    $scope.opportunityAccessMethods = [];
    $scope.getOpportunityAddData = function(propertyId) {
        $http.get(prefix + '/sales/getOpportunityAddData/' + propertyId).success(function (data) {
            $scope.opportunityTemplates = data.oppurtunityTemplates;
            $scope.reportingCategories = data.reportingCategories;
            $scope.salesPersons = data.salesPersons;
            $scope.siteContacts = data.contacts.siteContacts;
            $scope.userGroups = data.userGroups;
            $scope.opportunityAccessMethods = data.accessMethods;
            $scope.isWorkaddress = data.contacts.isWorkAddress;
        });
    }

    $scope.getOpportunityTemplateConfirmations = function (templateId, fromBackend) {
        if (!fromBackend) {
            let selectedTemplate = _.where($scope.opportunityTemplates, { id: parseInt(templateId) });
            let opportunityNote = (selectedTemplate && selectedTemplate.length) ? selectedTemplate[0].opportunityNotes : null;
            $scope.add_opportunity_event_inputs.opportunity.engineernotes = opportunityNote != null ? opportunityNote : '';
        }
        let propertyId = $scope.selected_property.id;
        let opportunityId = null;
        if ($scope.event_type == "opportunity" && $scope.add_diary_event_form_inputs.hasOwnProperty('opportunityid')) {
            opportunityId = $scope.add_diary_event_form_inputs['opportunityid'];
            if($scope.add_diary_event_form_inputs.hasOwnProperty('loadNotification')){
                let value = $scope.add_diary_event_form_inputs;
                $http.post(prefix + '/getOpportunityDiaryNotificationConfirmations', "data=" + encodeURIComponent(JSON.stringify(value))).success(function (data) {
                    $scope.notificationData['opportunity'] = data.confirmations;
                    $scope.opportunityNotificationData = data.confirmations;
                    $scope.leaveQuestions = data.arriveLeave.leaveData;
                    $scope.arriveQuestions = data.arriveLeave.arriveData;
                    $scope.add_opportunity_event_inputs.opportunity.arriveQuestions = data.arriveLeave.arriveDefaultValue;
                    $scope.add_opportunity_event_inputs.opportunity.leaveQuestions = data.arriveLeave.leaveDefaultValue;
                });

            }
            else {
                $http.get(prefix + '/getOpportunityTemplateConfirmations/' + templateId + '?propertyId=' + propertyId + '&opportunityId=' + opportunityId).success(function (data) {
                    $scope.notificationData['opportunity'] = data.confirmations;
                    $scope.opportunityNotificationData = data.confirmations;
                    $scope.leaveQuestions = data.arriveLeave.leaveData;
                    $scope.arriveQuestions = data.arriveLeave.arriveData;
                    $scope.isWorkaddress = data.isWorkaddress
                    $scope.add_opportunity_event_inputs.opportunity.arriveQuestions = data.arriveLeave.arriveDefaultValue;
                    $scope.add_opportunity_event_inputs.opportunity.leaveQuestions = data.arriveLeave.leaveDefaultValue;
                });
            }
        }
        else {
            let value = $scope.add_diary_event_form_inputs;
            $http.post(prefix + '/getNotificationOpportunityTemplateConfirmations', "data=" + encodeURIComponent(JSON.stringify(value))).success(function (data) {
                $scope.notificationData['opportunity'] = data.confirmations;
                $scope.opportunityNotificationData = data.confirmations;
                $scope.leaveQuestions = data.arriveLeave.leaveData;
                $scope.arriveQuestions = data.arriveLeave.arriveData;
                $scope.add_opportunity_event_inputs.opportunity.arriveQuestions = data.arriveLeave.arriveDefaultValue;
                $scope.add_opportunity_event_inputs.opportunity.leaveQuestions = data.arriveLeave.leaveDefaultValue;
            });
        }
    }

    //# If redirect from job screen to diary screen to create new diary
    if((typeof $scope.estimateJobDetails != 'undefined') && ($scope.estimateJobDetails.type == 'job') && $scope.estimateJobDetails.id) {
        $scope.getArriveLeaveQuestions($scope.estimateJobDetails.jobDescriptionId);
    }

    // setup the notification models used in all event types
    $scope.setNotificationModels = function setNotificationModels() {
        $scope.add_diary_event_form_inputs.notifications = {
            customer: {
                method: null,
                recipient: null,
                email: {
                    subject: null,
                    message: null
                },
                sms: {
                    message: null
                },
                letter: {
                    message: null
                }
            },
            work_address: {
                method: null,
                recipient: null,
                email: {
                    subject: null,
                    message: null
                },
                sms: {
                    message: null
                },
                letter: {
                    message: null
                }
            },
            engineer: {
                send_on: "",
                method: null,
                recipient: null,
                email: {
                    subject: null,
                    message: null
                },
                sms: {
                    message: null
                },
                print: {
                    message: null
                }
            }
        }
    }

    $scope.setDefaultFormModels = function setDefaultFormModels() {
        $scope.add_normal_event_inputs = {description: null, engineer_notes: null};
        $scope.add_estimate_event_inputs = {
            estimate: {
                property: null,
                contactid: "",
                templateid: null,
                engineernotes: "",
                description: "",
                customerreference: "",
                workaddressaccessmethod: "",
                accessnotes: ""
            }
        };
        $scope.add_job_event_inputs = {
            job: {
                property: null,
                contactid: "",
                description: "",
                engineernotes: "",
                isservicejob: false,
                servicereminderinstances: "",
                quotedamount: "",
                ponumber: "",
                priority: "",
                invoicecategoryid: "",
                additionalcontactid: "",
                workaddressaccessmethod: "",
                accessnotes: "",
                skills: "",
                arriveQuestions: "",
                leaveQuestions: "",
            }
        };
        $scope.add_opportunity_event_inputs = {
            opportunity: {
                property: null,
                opportunityTemplateId: null,
                contactid: "",
                description: "",
                engineernotes: "",
                priority: "",
                invoicecategoryid: "",
                additionalcontactid: "",
                workaddressaccessmethod: "",
                accessnotes: "",
                salesPerson: "",
                customerreference: "",
                reportingTemplate: ""
            }
        };
        $scope.add_estimate_event_inputs = {estimate:{ property: null, contactid: "", templateid: null, engineernotes: "", description: "", customerreference: "", workaddressaccessmethod: "", accessnotes: "" }};
        $scope.add_job_event_inputs = {job: { property: null, contactid: "", description: "", engineernotes: "", isservicejob: false, servicereminderinstances: "", quotedamount: "", ponumber: "", priority: "", invoicecategoryid: "", additionalcontactid: "", workaddressaccessmethod: "" , accessnotes: "", skills: "" }};
        $scope.add_rebook_or_reserve_list_event_inputs = { property: null, description: null, engineer_notes: null };
        // set this to default view - it's value will change based on event_type
        if ($scope.estimateJobDetails) {
            var inputs_ref;
            var property = $scope.estimateJobDetails.property;
            var isWorkAddress = (property.parentpropertiesid_i > 0) ? true : false;
            var primaryId = $scope.estimateJobDetails.type + 'id';
            $scope.isCompany = (property.companyname_s && property.companyname_s != '');
            $scope.event_type = $scope.estimateJobDetails.type;
            if ($scope.estimateJobDetails.type == 'estimate') {
                inputs_ref = 'add_estimate_event_inputs';
                if (isWorkAddress) {
                    $scope.add_estimate_event_inputs.estimate.accessnotes = $scope.estimateJobDetails.accessNote || '';
                    $scope.add_estimate_event_inputs.estimate.workaddressaccessmethod = $scope.estimateJobDetails.accessMethod || '';
                }
            } else if ($scope.estimateJobDetails.type == 'job') {
                inputs_ref = 'add_job_event_inputs';
                if (isWorkAddress) {
                    $scope.add_job_event_inputs.job.accessnotes = $scope.estimateJobDetails.accessNote || '';
                    $scope.add_job_event_inputs.job.workaddressaccessmethod = $scope.estimateJobDetails.accessMethod || '';
                }
            }
            else if ($scope.estimateJobDetails.type == 'opportunity') {
                inputs_ref = 'add_opportunity_event_inputs';
                if (isWorkAddress) {
                    $scope.add_opportunity_event_inputs.opportunity.accessnotes = $scope.estimateJobDetails.accessNote || '';
                    $scope.add_opportunity_event_inputs.opportunity.workaddressaccessmethod = $scope.estimateJobDetails.accessMethod || '';
                }
            }
            $scope.add_diary_event_form_inputs = $scope[inputs_ref];
            $scope.add_diary_event_form_inputs[primaryId] = $scope.estimateJobDetails.id;
            $scope.add_diary_event_form_inputs.property = $scope.estimateJobDetails.property.id;
        } else {
            $scope.add_diary_event_form_inputs = $scope.add_normal_event_inputs; // needs to be the same type as $scope.event_type in new_diary_event_container
        }

    // set this to default view - it's value will change based on event_type
      if($scope.estimateJobDetails){
          var inputs_ref;
          var property = $scope.estimateJobDetails.property;
          var isWorkAddress = (property.parentpropertiesid_i > 0) ? true : false;
          var primaryId = $scope.estimateJobDetails.type + 'id';
          $scope.isCompany = (property.companyname_s && property.companyname_s != '');
          $scope.event_type = $scope.estimateJobDetails.type;
          if($scope.estimateJobDetails.type == 'estimate') {
              inputs_ref = 'add_estimate_event_inputs';
              if(isWorkAddress) {
                  $scope.add_estimate_event_inputs.estimate.accessnotes = $scope.estimateJobDetails.accessNote || '';
                  $scope.add_estimate_event_inputs.estimate.workaddressaccessmethod = $scope.estimateJobDetails.accessMethod || '';
              }
          } else if($scope.estimateJobDetails.type == 'job') {
              inputs_ref = 'add_job_event_inputs';
              if(isWorkAddress) {
                  $scope.add_job_event_inputs.job.accessnotes = $scope.estimateJobDetails.accessNote || '';
                  $scope.add_job_event_inputs.job.workaddressaccessmethod = $scope.estimateJobDetails.accessMethod || '';
              }
          }
          $scope.add_diary_event_form_inputs = $scope[inputs_ref];
          $scope.add_diary_event_form_inputs[primaryId] = $scope.estimateJobDetails.id;
          $scope.add_diary_event_form_inputs.property = $scope.estimateJobDetails.property.id;
      } else {
          $scope.add_diary_event_form_inputs = $scope.add_normal_event_inputs; // needs to be the same type as $scope.event_type in new_diary_event_container
      }

    $scope.previous_event_type = null;
    // Added lock event input type
    $scope.add_diary_event_form_inputs.lock_event = $scope.diary_ctrl_scope.lock_events_options[0]['id'];
    // shared for all event_types
    $scope.add_diary_event_form_inputs.user = $scope.diary_ctrl_scope.user || $scope.diary_ctrl_scope.users[0] || null;
    $scope.add_diary_event_form_inputs.is_special_event = false;
    $scope.add_diary_event_form_inputs.special_event_duration = null;
    $scope.add_diary_event_form_inputs.is_recurring_event = false;
    $scope.add_diary_event_form_inputs.notify_customer = false;
    $scope.add_diary_event_form_inputs.notify_work_address = false;
    $scope.add_diary_event_form_inputs.notify_engineer = false;
      $scope.add_diary_event_form_inputs.milestoneId = '';
      if($scope.milestones.length) {
          $scope.add_diary_event_form_inputs.milestoneId = $scope.milestones[0].id;
      }
    $scope.setNotificationModels();
  }

  // Default form models
  $scope.setDefaultFormModels();

  $scope.start = $scope.diary_ctrl_scope.start;
  $scope.end = $scope.diary_ctrl_scope.end;
  $scope.start_time = $scope.diary_ctrl_scope.start.format('h:mma');
  $scope.end_time = $scope.diary_ctrl_scope.end.format('h:mma');
  $scope.all_day = $scope.diary_ctrl_scope.allDay;
  $scope.event_date = $scope.diary_ctrl_scope.start.format('Do MMMM');
  $scope.search_existing_property = true;
  $scope.property_selected = false;
  $scope.selected_property_type = null;
  $scope.show_property_previews = false;

    $scope.special_event_durations = $scope.diary_ctrl_scope.timeDiffArray;
    $scope.actualTime = 0;
    $scope.handleSpecialEventValues = function () {
        var arrayLength = $scope.special_event_durations.length;
        if(!arrayLength) {
            $scope.add_diary_event_form_inputs.is_special_event = false;
        } else {
            var maxValue = arrayLength * 30;
            if($scope.add_diary_event_form_inputs.special_event_duration > maxValue) {
                $scope.add_diary_event_form_inputs.special_event_duration = maxValue;
            } else {
                $scope.add_diary_event_form_inputs.special_event_duration = 30;
                $scope.actualTime = 30;
            }
        }
    }

    $scope.disableStartDateTime = false;
    DiaryDateTimeInputs.call(this, $scope, $timeout);
    $scope.initializeValues('format');

  // shared between all
  $scope.repeating_event_options = ['Daily', 'Weekly', 'Monthly'];
  $scope.repeating_events = {
    "Daily": {
      id: 1,
      name: 'Daily',
      intervals: [1, 2, 3, 4, 5, 6]
    },
    "Weekly": {
      id: 2,
      name: 'Weekly',
      intervals: [1, 2, 3],
      days_of_week: [
        {
          name: 'Monday',
          short_hand: 'M',
          selected: null,
            value: 1
        },
        {
          name: 'Tuesday',
          short_hand: 'T',
          selected: null,
            value: 2
        },
        {
          name: 'Wednesday',
          short_hand: 'W',
          selected: null,
            value: 3
        },
        {
          name: 'Thursday',
          short_hand: 'T',
          selected: null,
            value: 4
        },
        {
          name: 'Friday',
          short_hand: 'F',
          selected: null,
            value: 5
        },
        {
          name: 'Saturday',
          short_hand: 'S',
          selected: null,
            value: 6
        },
        {
          name: 'Sunday',
          short_hand: 'S',
          selected: null,
            value: 0
        }
      ]
    },
    "Monthly": {
      id: 3,
      name: 'Monthly',
      intervals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    }
  }

  $scope.customer_notification_methods = [ { value: 'email', display_name: 'Email' }, { value: 'letter', display_name: 'Letter' }];
  $scope.engineer_notification_methods = [ { value: 'email', display_name: 'Email' }, { value: 'print', display_name: 'Print' }];

  /* Checking the SMS module permission */
  if($rootScope.moduleDetails && $rootScope.moduleDetails.Sms == 1) {
      $scope.customer_notification_methods.unshift({ value: 'sms', display_name: 'SMS' });
      $scope.engineer_notification_methods.unshift({ value: 'sms', display_name: 'SMS' });
  }

  $scope.notification_recipients = [];
  $scope.selectedRecipientDetails = {mobile:false, email: false};
  $scope.users = $scope.diary_ctrl_scope.users;

  $scope.lock_events_options = $scope.diary_ctrl_scope.lock_events_options;

  // shared between estimate / job
  $scope.customer_contacts = [];
  $scope.work_address_contacts = [];
  $scope.access_methods_job = $scope.diary_ctrl_scope.jobAccessMethods;
  $scope.access_methods_estimate = $scope.diary_ctrl_scope.estimateAccessMethods;
  $scope.jobDescriptions = $scope.diary_ctrl_scope.jobDescriptions;
  $scope.copyDescription = $scope.diary_ctrl_scope.copyDescription;
  $scope.copyEngineerNote = $scope.diary_ctrl_scope.copyEngineerNote;
  // estimate
  $scope.estimate_templates = $scope.diary_ctrl_scope.estimateTemplates;
  // job
  $scope.service_types = [];
  $scope.priority_levels = ['Not Important', 'Medium Importance', 'Important', 'Urgent'];
  $scope.invoice_categories = $scope.diary_ctrl_scope.invoiceCategories;
  $scope.additional_contacts = [];

    $scope.getCustomFieldInvoiceCategoryTemplates = function() {
        if ($scope.checkCustomFieldsPermission == 1) {
            let objects = 'Jobs', objectTypes = 'InvoicesCategory';
            $http.get(prefix + '/listCustomConfigTemplates?screenMode=addCustomTemplates&action=add&objects=' + objects + '&objectTypes=' + objectTypes + '&objectTypeID=' + $scope.add_job_event_inputs.job.invoicecategoryid + '&propertyID=0&tablePKID=0').success(function (data) {
                let customFieldsArray = [];
                data['templatesList'].forEach((val, key) => {
                    customFieldsArray[val.ID] = true;
                });
                $scope.customFieldsInvoiceCategoryValidate = customFieldsArray;
                $scope.formCustomerCustomInvoiceCategoryFieldsData = data.templatesList;
            });
        }
    }
    $scope.getCustomFieldInvoiceCategoryFormValueChanges = function getCustomFieldInvoiceCategoryFormValueChanges(formValue,event){
        $scope.formInvoiceCategoryValue[event.ID] = formValue;
        $scope.formValue[event.ID] = formValue;
    }

    $scope.getCustomFieldInvoiceCategoryFormStatusChanges = function getCustomFieldInvoiceCategoryFormValueChanges(isFormValid){
        $scope.customFieldsInvoiceCategoryValidate[isFormValid.ID]=isFormValid.formValidStatus;
        let isValid=true;
        $scope.formCustomerCustomInvoiceCategoryFieldsData.forEach((val, key) => {
            if($scope.customFieldsInvoiceCategoryValidate[val.ID]==false){
                isValid=false;
            }
        });
        $scope.isFormValidInvoiceCategory = isValid;
    }
    $scope.handleLoadingContacts = function (propertyId, fromWorkAddress) {
        var inputRef;
        if($scope.event_type == 'job') {
            inputRef = 'add_job_event_inputs'
        }else if($scope.event_type == 'estimate') {
            inputRef = 'add_estimate_event_inputs'
        } else if($scope.event_type == 'opportunity') {
            inputRef = 'add_opportunity_event_inputs'
        }
        $scope[inputRef][$scope.event_type].contactid = '';
        addDiaryEvent.loadContactsForSelectedCustomer(propertyId).then(function(response) {
            if(!fromWorkAddress) {
                if(response.data.contactList && response.data.contactList.length) {
                    var contactList = response.data.contactList;
                    var mainContact = _.where(contactList, {maincontact: true});
                    var mainContactId = (mainContact && mainContact.length) ? mainContact[0].contactsid : null;
                    $scope.add_job_event_inputs.job.contactid = mainContactId;
                    $scope.add_estimate_event_inputs.estimate.contactid = mainContactId;
                    $scope.add_opportunity_event_inputs.opportunity.contactid = mainContactId;
                    $scope.isCompany = contactList[0].customerType == 'company';
                    if(mainContactId > 0 && !$scope.isCompany) {
                        $scope.selectedContactId = mainContactId;
                    }
                    $scope.notification_recipients = contactList;
                    if($scope.isCompany) {
                        $scope.additional_contacts = $scope.customer_contacts = _.without(contactList, _.findWhere(contactList, {maincontact: true}));
                        if($scope.customer_contacts && $scope.customer_contacts.length) {
                            //This section handles the company contacts - remove the main contact
                            $scope.selectedContactId = $scope.customer_contacts[0].contactsid;
                            $scope.customer_contacts[0].maincontact = 1;
                        } else {
                            $scope.selectedContactId = '';
                        }
                    } else {
                        $scope.additional_contacts = $scope.customer_contacts = contactList;
                    }
                } else {
                    $scope.selectedContactId = ''
                    $scope.notification_recipients = $scope.additional_contacts = $scope.customer_contacts = [];
                }

            } else {
                $scope.work_address_contacts = response.data.contactList;
            }
        });
    }

  /*==========================================================================================
   Change the event type
   ==========================================================================================*/
  $scope.setEventType = function setEventType(event_type, inputs_ref) {
    this.add_diary_event_form.$invalid = true;
    $scope.setDefaultFormModels();
    $scope.add_diary_event_form_inputs = $scope[inputs_ref];
    $scope.setNotificationModels();
    $scope.event_type = event_type;
    $scope.property_selected = false;

        /*var preserve_property = ($scope.previous_event_type == 'estimate' && event_type == 'job') ||
            ($scope.previous_event_type == 'job' && event_type == 'estimate') ||
            ($scope.previous_event_type == null && event_type == 'estimate') ||
            ($scope.previous_event_type == null && event_type == 'job');*/

        let optionsWithoutProperty = ['normal', 'reserve_list', 'rebook'];
        let preserve_property = optionsWithoutProperty.indexOf($scope.previous_event_type) == -1 && optionsWithoutProperty.indexOf(event_type) == -1;
        if (preserve_property) {
            $scope.preserveProperty();
        } else {
            $scope.resetView();
        }
        $scope.$broadcast('event:close-side-panel-date-picker', {name: 'diaryDate'});
        if ((event_type == 'estimate' || event_type == 'job') && $scope.diary_ctrl_scope.jobDurationSettings != null) {
            $scope.initializeValues('setDuration');
            if(event_type == 'job') {
                $scope.newJob = true;
            } else {
                $scope.newEstimate = true;
            }
        }
        else
            $scope.initializeValues();

        $scope.add_diary_event_form_inputs.lock_event = $scope.diary_ctrl_scope.lock_events_options[0]['id'];
        $scope.add_diary_event_form_inputs.user = $scope.diary_ctrl_scope.user || $scope.diary_ctrl_scope.users[0] || null;
        addDiaryEvent.focusInput('#top-input-in-event-type');
        $scope.previous_event_type = event_type;
  }

    if($scope.estimateJobDetails) {
        var property = $scope.estimateJobDetails.property;
        var propertyIdToLoadContacts = property.id;
        if(property.parentpropertiesid_i > 0) {
            $scope.handleLoadingContacts(propertyIdToLoadContacts, true);
            $scope.selected_property_type = 'work address';
            propertyIdToLoadContacts = property.parentpropertiesid_i;
        } else {
            $scope.selected_property_type = 'customer';
        }
        $scope.handleLoadingContacts(propertyIdToLoadContacts, false);
    }

  /*==========================================================================================
   When switching between estimate / job event types keep the selected property if it exists
   ==========================================================================================*/
    $scope.preserveProperty = function preserveProperty() {
        $scope.advanced_options_open = false;
        $scope.notifications_open = false;

        if ($scope.selected_property) {
            if ($scope.property_animation_complete) {
                $scope.add_diary_event_form_inputs.property = $scope.selected_property.id;
                if ($scope.event_type == 'job') {
                    $scope.add_diary_event_form_inputs.job.contactid = $scope.selectedContactId;
                    $scope.removeAdditionalParameters();
                    addDiaryEvent.loadServiceTypesForSelectedCustomer($scope.selected_property.id).then(function (response) {
                        $scope.service_types = response.data.serviceTypes;
                    });
                } else if ($scope.event_type == 'estimate') {
                    $scope.add_diary_event_form_inputs.estimate.contactid = $scope.selectedContactId;
                } else if ($scope.event_type == 'opportunity') {
                    $scope.getOpportunityAddData($scope.selected_property.id);
                    $scope.add_diary_event_form_inputs.opportunity.contactid = $scope.selectedContactId;
                }
                $scope.property_selected = true;
                addDiaryEvent.focusInput('#top-input-in-details-step');
            }
        }
    }

  $scope.resetView = function resetView() {
    $scope.show_empty_box = true;
    $scope.show_property_previews = false;
    $scope.show_property_results = false;
    $scope.show_no_results_ui = false;
    $scope.properties_results_set = [];

    $scope.close_details_step = false;
    $scope.selected_property_type = null;

    $scope.current_open_step = 'details';
    $scope.details_valid = false;
    $scope.property_animation_complete = false;
    $scope.advanced_options_open = false;
    $scope.notifications_open = false;

    if($scope.selected_property) {
      $scope.selected_property.checked = false;
    }
  }

  /*==========================================================================================
   Handle existing property search query (estimate || job)
   ==========================================================================================*/
  $scope.handlePropertySearchQuery = function handlePropertySearchQuery(event) {
      //This is to handle the Esc, when we press Esc the search text will be cleared
      if(event.keyCode == 27) {
          $scope.show_property_previews = false;
          $scope.properties_results_set = [];
          $timeout(function() {
              $scope.show_empty_box = true;
              $scope.show_no_results_ui = false;
          }, 250);
          return;
      }
    var self = this,
      query_server = this.propertySearchQuery.length > 2;
      $scope.searching = false;
      $scope.waitForSearch = false

    if(query_server) {
        $scope.properties_results_set = [];
        $scope.waitForSearch = true;
        $timeout(function() {
            $scope.searching = true;
            $scope.waitForSearch = false;
        }, 250);

      addDiaryEvent.getProperties(this.propertySearchQuery).then(function(response) {
          if(self.propertySearchQuery.length > 2) {
              $scope.properties_results_set = (response.data.properties) ? response.data.properties.list : [];
          }
          $timeout(function() {
              $scope.searching = false;
          }, 100);
        // handle view
        $scope.handlePropertyView(self.propertySearchQuery.length);
      });
    }else {
      $scope.properties_results_set = [];
    }

    // handle view
    $scope.handlePropertyView(this.propertySearchQuery.length);
  }

  $scope.show_empty_box = true;
  $scope.show_property_previews = false;
  $scope.show_property_results = false;
  $scope.show_no_results_ui = false;

  /*==========================================================================================
   Handle the existing property results UI
   ==========================================================================================*/
  $scope.handlePropertyView = function handlePropertyView(query_length) {
    $scope.show_empty_box = query_length < 1;
    $scope.show_property_previews = query_length > 0 && query_length < 3;
    $scope.show_property_results = $scope.properties_results_set && $scope.properties_results_set.length > 0;

    // timeout stops the no results ui flickering into view when transitioning from the preview
    // ui into the results  view
    $timeout(function() {
      $scope.show_no_results_ui = query_length > 2 && $scope.properties_results_set && $scope.properties_results_set.length < 1;
    }, 100);
  }

  $scope.$on('add_new_event:property_added', function(e, propertyObj) {
      addDiaryEvent.getProperty(propertyObj.id).then(function(response) {
          $scope.properties_results_set = (response.data.properties) ? response.data.properties.list : [];
          if($scope.properties_results_set.length) {
              $scope.selectExistingProperty($scope.properties_results_set[0], 'fromAddProperty');
          }
      });
  });

    $scope.$on('add_new_event:contact_added', function(e, contact) {
        var new_name = !_.contains($scope.customer_contacts, contact.name);

        if(new_name) {
            $scope.customer_contacts.push(contact.name);
            $scope.additional_contacts = $scope.customer_contacts;
        }
        if(!_.contains($scope.notification_recipients, contact.name)) {
            $scope.notification_recipients.push(contact.name);
        }
        $timeout(function() {
            $scope.add_diary_event_form_inputs[$scope.event_type].contactid = contact.name.contactsid;
        }, 250);
        addDiaryEvent.focusInput('#top-input-in-details-step');
    });

  /*==========================================================================================
   When a property in the property search is selected
   ==========================================================================================*/
  $scope.selectExistingProperty = function selectExistingProperty(property) {
    property.checked = true;
    $scope.property_selected = true;
    $scope.add_diary_event_form_inputs.property = property.id;
    $scope.selected_property = property;
      $scope.isCompany = (property.companyname_s && property.companyname_s != '');
      var propertyIdToLoadContacts = property.id;
      if(property.parentpropertiesid_i > 0) {
          $scope.selected_property_type = 'work address';
          $scope.handleLoadingContacts(propertyIdToLoadContacts, true);
          propertyIdToLoadContacts = property.parentpropertiesid_i;
      } else {
          $scope.selected_property_type = 'customer';
      }
      $scope.removeAdditionalParameters();

      $scope.handleLoadingContacts(propertyIdToLoadContacts, false);

      if($scope.event_type == 'job') {
          addDiaryEvent.loadServiceTypesForSelectedCustomer(property.id).then(function(response) {
              $scope.service_types = response.data.serviceTypes;
          });
      }

        if ($scope.event_type == 'opportunity') {
            $scope.getOpportunityAddData(property.id);
        }

        if (arguments[1] == 'fromAddProperty') {
            $scope.current_open_step = 'details';
            $scope.property_animation_complete = true;
            addDiaryEvent.focusInput('#top-input-in-details-step');
        } else {
            addDiaryEvent.handlePropertySelectedAnimation(this.$index).then(function () {
                $scope.current_open_step = 'details';
                $scope.property_animation_complete = true;
                addDiaryEvent.focusInput('#top-input-in-details-step');
            });
        }
    }

    $scope.removeAdditionalParameters = function(){
        if($scope.selected_property_type == 'work address') {
            if(typeof $scope.add_job_event_inputs.job.workaddressaccessmethod == undefined){
                $scope.add_job_event_inputs.job.workaddressaccessmethod = '';
                $scope.add_job_event_inputs.job.accessnotes = '';
            }
        } else {
            if(typeof $scope.add_job_event_inputs.job.workaddressaccessmethod != undefined) {
                delete $scope.add_job_event_inputs.job.workaddressaccessmethod;
                delete $scope.add_job_event_inputs.job.accessnotes;
            }
        }

        if(!$scope.invoiceAccess && $scope.event_type  == 'job') {
            delete $scope.add_job_event_inputs.job.quotedamount;
        }
    }

  /*==========================================================================================
   Open the first step (choose property in estimate / job) after a property has already
   been selected
   ==========================================================================================*/
  $scope.editSelectedProperty = function editSelectedProperty() {
      $scope.resetNotificationValues();
    $scope.close_details_step = true;
    $scope.advanced_options_open = false;
    $scope.notifications_open = false;
    $scope.property_animation_complete = false;

    addDiaryEvent.handlePropertyUnSelectedAnimation().then(function() {
      $scope.property_selected = false;
      $scope.close_details_step = false;
      $scope.selected_property.checked = false;
      $scope.selected_property_type = null;
      addDiaryEvent.focusInput('#top-input-in-event-type');
    });
  }

    $scope.resetNotificationValues = function() {
        $scope.selectedContactId = 0;
        $scope.add_diary_event_form_inputs.notify_engineer = false;
        $scope.add_diary_event_form_inputs.notify_work_address = false;
        $scope.add_diary_event_form_inputs.notify_customer = false;
    }

  $scope.openDiaryStep = function openDiaryStep(step) {
    if($scope.current_open_step == step) {
      return;
    }else {
      $scope.current_open_step = step;
      $scope.advanced_options_open = false;
      $scope.notifications_open = false;
      $scope[step + '_open'] = true;

      $scope.add_diary_event_form_inputs.notify_customer = false;
      $scope.add_diary_event_form_inputs.notify_work_address = false;
      $scope.add_diary_event_form_inputs.notify_engineer = false;
      addDiaryEvent.focusInput('#top-input-in-event-type');
    }
  }

  /*==========================================================================================
   Go back to the add new event view
   ==========================================================================================*/
  $scope.returnToAddDiaryEventForm = function returnToAddDiaryEventForm() {
    $scope.property_selected = false;
  }

  /*==========================================================================================
   When save button in the add new event view is clicked
   ==========================================================================================*/
    $scope.setDiaryEventFormValues = function(){
        if($scope.event_type  == 'job' || $scope.event_type == 'estimate') {
            var selectedContact = $scope.add_diary_event_form_inputs[$scope.event_type].contactid;
            if((!selectedContact || selectedContact == '') && $scope.selectedContactId > 0) {
                $scope.add_diary_event_form_inputs[$scope.event_type].contactid = $scope.selectedContactId;
            }
        }
        if($scope.isAllDay){
            $scope.changeDateTime($scope.isAllDay);
        }
        var startDate = moment($scope.startDateTime);
        var endDate = moment($scope.endDateTime);
        var allDay = ($scope.isAllDay) ? $scope.allDayValue : false;
        $scope.add_diary_event_form_inputs.event_start = startDate.format('YYYY-MM-DD HH:mm:ss');
        $scope.add_diary_event_form_inputs.event_end = endDate.format('YYYY-MM-DD HH:mm:ss');
        $scope.add_diary_event_form_inputs.resourceId = $scope.diary_ctrl_scope.resourceId;
        $scope.add_diary_event_form_inputs.allDay = allDay;
        if ($scope.diary_ctrl_scope.jobDurationSettings != null && ($scope.newJob || $scope.newEstimate)) {
            $scope.add_diary_event_form_inputs.job_start = $scope.jobStartDateTime.format('YYYY-MM-DD HH:mm:00');
            $scope.add_diary_event_form_inputs.job_end = $scope.jobEndDateTime.format('YYYY-MM-DD HH:mm:00');
        }
        if($state.$current.name == 'loggedin.diary_from_milestone') {
            $scope.add_diary_event_form_inputs.milestoneId = $state.params.milestoneId;
        }
    }


    $scope.saveDiaryEvent = function saveDiaryEvent() {
        $scope.saving_diary_event= true;
        $scope.setDiaryEventFormValues();
        var notificationObject = addDiaryEvent.flattenNotificationsForSave($scope.add_diary_event_form_inputs);
        var diaryEventInput = angular.copy($scope.add_diary_event_form_inputs);
        diaryEventInput.notifications = notificationObject;
        var event_start = $scope.add_diary_event_form_inputs.event_start;
        var event_end = $scope.add_diary_event_form_inputs.event_end;
        var engineerId = $scope.add_diary_event_form_inputs.user.id;
        var confirmation_message = 'This event falls outside of the working hours and into overtime. Please confirm';
        var shiftModule = ($rootScope.moduleDetails!='undefined') ? (($rootScope.moduleDetails.Shifts) !='undefined' ? $rootScope.moduleDetails.Shifts : 0 ) : 0;
        var allDay = $scope.add_diary_event_form_inputs.allDay;
        var continue_overtime = checkEngineerShift.checkShift(event_start, event_end, engineerId,shiftModule,allDay);
        continue_overtime.then(function (re) {
            if (re == 'overtime' || re == 'nobreaktime' || re == 'overtime_nobreak') {
                if(re == 'nobreaktime'){
                    confirmation_message = 'This event falls into engineer break time. Please confirm';
                }else if(re == 'overtime_nobreak'){
                    confirmation_message = 'This event falls into engineer break time and outside of the working hours and into overtime. Please confirm';
                }
                confirmationBoxHelper.getConfirmation(confirmation_message, $scope)
                    .then(function () {
                        $scope.confirmAddEvent(this, diaryEventInput, notificationObject);
                    });
            }else if(re == 'overlappingshift'){
                warningModal.show('The.shift.with.engineer.have.any.pattern.selected.time', 'Diary.event', 'diary_overlapping_message');
                $scope.saving_diary_event= false;
            } else {
                $scope.confirmAddEvent(this, diaryEventInput, notificationObject);
            }
        //    Note : $scope.saving_diary_event= false; should be in every function to avoid COM-14974
      })
  }

    $scope.confirmAddEvent = function confirmAddEvent(self, diaryEventInput, notificationObject) {
        var deferred = addDiaryEvent.saveDiaryEvent($scope.event_type, diaryEventInput);
        $scope.$emit('add_new_event:pending', deferred);
        deferred.then(function (response) {
            $scope.saving_diary_event= false;
            if (response.status == 200 && response.data.error === true) {
                return false;
            }

            var event = (response.status == 200 && response.data.events) ? response.data.events : {};
            var responseEvent = {};
            responseEvent.propertyType = $scope.selected_property_type;
            responseEvent.event = event;
            $scope.$emit('diary_event:created', responseEvent);

            // Remove from outstanding job list
            if ($scope.estimateJobDetails) {

                if($scope.event_type == 'estimate') {
                    $rootScope.$broadcast('refresh:outstanding-jobs');
                } else {
                    $scope.$broadcast('removeOutstandingJob', {eventInfo: $scope.estimateJobDetails});
                }

                $scope.estimateJobDetails = null;
            }

            var communications = response.data.communications;
            var customizedId = response.data.customizedId;
            var diaryId = response.data.diaryId;
            if (communications.length) {
                for (var index = 0; index < communications.length; index++) {
                    var communicationId = communications[index]['id'];
                    var communicationType = communications[index]['type'];
                    if ($scope.add_diary_event_form_inputs.estimateid) {
                        $scope.customizeId = $scope.add_diary_event_form_inputs.estimateid;
                    } else if ($scope.add_diary_event_form_inputs.jobid) {
                        $scope.customizeId = $scope.add_diary_event_form_inputs.jobid;
                    } else {
                        $scope.customizeId = customizedId;
                    }
                    if (communicationType == 'notify_customer') {
                        var dynamicType = 'customer';
                    } else if (communicationType == 'notify_work_address') {
                        var dynamicType = 'workaddress';
                    }
                    var urlToOpen = prefix + '/diary/notification/' + dynamicType + '/' + communicationId + '/' + $scope.event_type + '/' + $scope.customizeId;
                    if (communicationId > 0) {
                        $window.open(urlToOpen, '_blank');
                    }
                }
            }
            if (diaryId && $scope.add_diary_event_form_inputs.notifications.engineer.method == 'print') {
                if ($scope.add_diary_event_form_inputs.notify_engineer == true) {
                    var urlToOpen = prefix + '/diary/view_job_sheet/print/' + diaryId;
                    if (diaryId > 0) {
                        $window.open(urlToOpen, '_blank');
                    }
                }
            }
        });
    }


  /*==========================================================================================
   When the sidepanel closes
   ==========================================================================================*/
    $rootScope.$on('sidepanel:add_new_diary_event:closed', function() {
    // reset form models and close side panel
        var datePickerScope = angular.element('#diary_side_panel_date_picker').scope();
        if(datePickerScope) {
            datePickerScope.$destroy();
        }
    $scope.setDefaultFormModels();
    $scope.setNotificationModels();
  });

    $scope.setSelectedRecipient = function(notification_type) {
        var contactArrayToCheck = (notification_type == 'customer') ? $scope.notification_recipients : $scope.work_address_contacts;
        var contactId = parseInt($scope.add_diary_event_form_inputs.notifications[notification_type].recipient);
        var contactObj = (contactId > 0) ? _.where(contactArrayToCheck, {contactsid: contactId})[0] : false;
        var hasMobileNumber = false;
        var hasEmail = false;
        if(contactObj) {
            var phoneNumbers = contactObj.numbers_collection;
            hasEmail = contactObj.emailaddress ? true : false;
            if(phoneNumbers && phoneNumbers.length) {
                for(var i = 0; i < phoneNumbers.length; i++) {
                    if(phoneNumbers[i].hasOwnProperty('type_id') && phoneNumbers[i].type_id == 1) {
                        if(phoneNumbers[i].phone_number){
                            hasMobileNumber = true;
                        }
                        break;
                    }
                }
            }
        }
        $scope.selectedRecipientDetails[notification_type] = {mobile:hasMobileNumber, email: hasEmail};
    }

  /*==========================================================================================

   ==========================================================================================*/
  if(diaryRightBarHelper.adding_event_from_previous_page) {
      var property = diaryRightBarHelper.event_from_previous_page.property;
      var propertyType = '';
      if(property.parentpropertiesid_i > 0) {
          propertyType = 'work address';
      } else {
          propertyType = 'customer';
      }
    $scope.property_selected = true;
    $scope.property_animation_complete = true;
    $scope.event_type = diaryRightBarHelper.event_from_previous_page.type;
    $scope.selected_property = diaryRightBarHelper.event_from_previous_page.property;
    $scope.selected_property_type = propertyType;
    $scope.adding_event_from_previous_page = diaryRightBarHelper.adding_event_from_previous_page;
    $scope.job_or_estimate_already_has_event = diaryRightBarHelper.job_or_estimate_already_has_event;
    $scope.notificationMessageServiceReminder =false;
    if($scope.event_type == 'opportunity') {
        $scope.opportunityAccessMethods = $scope.estimateJobDetails['commonAccessMethod'];
        $scope.getOpportunityTemplateConfirmations($scope.estimateJobDetails['opportunityTemplateId'], true);
    }
  }

    if(!$scope.diary_ctrl_scope.overlapping) {
        $scope.$watch('add_diary_event_form_inputs.user', function(newVal){
            if(newVal) {
                $scope.showOverlappingError = $scope.diary_ctrl_scope.validateDiaryEvent(newVal.id);
            }
        });

        $scope.$watchCollection('[startDateTime, endDateTime]', function (newVal, oldVal) {
            $scope.diary_ctrl_scope.start = $scope.startDateTime
            $scope.diary_ctrl_scope.end = $scope.endDateTime
            $scope.showOverlappingError = $scope.diary_ctrl_scope.validateDiaryEvent(newVal.id);
        });
    }

    $scope.setEstimateDescription = function() {
        var templateId = parseInt($scope.add_estimate_event_inputs.estimate.templateid);
        var template = _.where($scope.estimate_templates, {id: templateId});
        if(template && template.length) {
            $scope.add_estimate_event_inputs.estimate.description = template[0].description;
        }
    }

    $scope.notificationData = [];
    $scope.jobConfirmationTemplates = [];
    addDiaryEvent.loadAllNotificationData().then(function(response) {
        $scope.notificationData = (response.data.notificationData) ? response.data.notificationData : [];
        $scope.defaultEmailFooter = response.data.defaultEmailFooter;
        $scope.jobConfirmationTemplates = (response.data.templates) ? response.data.templates : [];
        $scope.jobConfirmationData = [];
        $scope.jobConfirmationTemplate = '';
        $scope.hasConfirmationTemplate = false;
        if($scope.event_type  == 'opportunity') {
            $scope.notificationData['opportunity'] = $scope.opportunityNotificationData;
        }
    });

    $scope.loadNotificationData = function(){
        if($scope.event_type  == 'job' || $scope.event_type == 'estimate') {
            $scope.setDiaryEventFormValues();
            var inputValues = $scope.add_diary_event_form_inputs;
            addDiaryEvent.loadAllNotificationData(inputValues).then(function (response) {
                $scope.notificationData = (response.data.notificationData) ? response.data.notificationData : [];
                $scope.jobConfirmationTemplates = (response.data.templates) ? response.data.templates : [];
                $scope.jobConfirmationData = [];
                $scope.jobConfirmationTemplate = '';
                $scope.hasConfirmationTemplate = false;
            });
        }
        if ($scope.event_type == 'opportunity') {
            if($scope.isAllDay){
                $scope.changeDateTime($scope.isAllDay);
            }
            var startDate = moment($scope.startDateTime);
            var endDate = moment($scope.endDateTime);
            var allDay = ($scope.isAllDay) ? $scope.allDayValue : false;
            $scope.add_diary_event_form_inputs.event_start = startDate.format('YYYY-MM-DD HH:mm:ss');
            $scope.add_diary_event_form_inputs.event_end = endDate.format('YYYY-MM-DD HH:mm:ss');
            $scope.add_diary_event_form_inputs.resourceId = $scope.diary_ctrl_scope.resourceId;
            $scope.add_diary_event_form_inputs.allDay = allDay;
            var inputValues = $scope.add_diary_event_form_inputs;
            $scope.add_diary_event_form_inputs['opportunity']['opportunityNotification'] = true;
            if( $scope.add_diary_event_form_inputs.hasOwnProperty('opportunityid')){
                var templateId = $scope.estimateJobDetails['opportunityTemplateId']; 
            }
            else{
                var templateId = inputValues['opportunity']['description']
            }
            $scope.add_diary_event_form_inputs['loadNotification'] = true;
            $scope.getOpportunityTemplateConfirmations(templateId, true);
        }
    }

    $scope.resetNotificationModels = function resetNotificationModels(notificationType) {
        if(notificationType == 'customer') {
            $scope.add_diary_event_form_inputs.notifications.customer.email = { subject : '', message: '' };
            $scope.add_diary_event_form_inputs.notifications.customer.letter = { message: '' };
            $scope.add_diary_event_form_inputs.notifications.customer.sms = { message : '' };
        } else if(notificationType == 'engineer') {
            $scope.add_diary_event_form_inputs.notifications.engineer.email = { subject : '', message: '' };
            $scope.add_diary_event_form_inputs.notifications.engineer.sms = { message : '' };
            $scope.add_diary_event_form_inputs.notifications.engineer.letter = { message : '' };
        } else if(notificationType == 'work_address') {
            $scope.add_diary_event_form_inputs.notifications.work_address.email = { subject : '', message: '' };
            $scope.add_diary_event_form_inputs.notifications.work_address.sms = { message : '' };
            $scope.add_diary_event_form_inputs.notifications.work_address.letter = { message : '' };
        }
    }

    $scope.setNotificationValues = function(notificationType) {
        if($scope.event_type == 'normal') {
            //Todo: need to set default email footer for engineer notification
            return false;
        }
        var method = $scope.add_diary_event_form_inputs.notifications[notificationType].method;
        $scope.resetNotificationModels(notificationType);
        if(method != '') {
            var eventType = $scope.event_type;
            var dataFromDB = $scope.notificationData[eventType][notificationType][method];
            if($scope.hasConfirmationTemplate) {
                dataFromDB = $scope.jobConfirmationData[method];
            }
            var notificationForm = $scope.add_diary_event_form_inputs.notifications[notificationType][method];
            notificationForm.message = (dataFromDB.message) ? dataFromDB.message : '';
            if(method == 'email') {
                notificationForm.subject = (dataFromDB.subject) ? dataFromDB.subject : '';
                notificationForm.message += ($scope.defaultEmailFooter) ? $scope.defaultEmailFooter : '';
            }
        }
    }

    $scope.jobConfirmationData = [];
    $scope.hasConfirmationTemplate = false;
    $scope.setConfirmationTemplate = function(templateId) {
        if(templateId) {
            var template = _.where($scope.jobConfirmationTemplates, { id: parseInt(templateId)})[0]
            $scope.jobConfirmationData = template.confirmationTemplates;
            $scope.hasConfirmationTemplate = true;
        } else {
            $scope.jobConfirmationData = [];
            $scope.hasConfirmationTemplate = false;
        }
    }

    $scope.tinymceSmsOptions = {
        menubar: false,
        toolbar: false,
        statusbar: false,
        content_css: "../../css/public/stylesheets/mce-editor.css",
        plugins: "paste",
        paste_as_text: true,
        paste_block_drop: false,
        convert_urls: false,
        relative_urls: false,
        remove_script_host: false
    };

    $scope.setSelectedContactIdValue = function() {
        if($scope.add_job_event_inputs.job.contactid > 0) {
            $scope.selectedContactId = $scope.add_job_event_inputs.job.contactid;
        }
    }

}]);
