'use strict';

function supplierSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, screenType, warningModal, $timeout, $compile, CS_LANGUAGE)
{
  $scope.spreadSheetParts = [];
  $scope.spreadSheetItems = [];
  $scope.isPartSpreadSheetEmpty = true;
  $scope.isItemSpreadSheetEmpty = true;
  $scope.displaySpreadsheet = false;
  $scope.instanceLoad=[];
  $scope.colHeadersRequired =[];
  $scope.supplier_Id = $state.params.id;
  

  $scope.listOfTaxItems = [];
  $scope.default_tax_item_id = "1"; // default tax item id

  spreadsheetScroll.call(this, $scope);
  internalizationTaxSettings.call(this, $scope);

  /*** Spreadsheet default settings for both parts & supplier ***/
  $scope.rowHeaders = true;
  $scope.startRows = 1;
  /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
  $scope.minSpareRows = 0;
  $scope.minRows = 1;
  $scope.width = "auto";
  $scope.autoWrapCol = true;
  $scope.autoWrapRow = true;
  $scope.partRowCount = 0;
  $scope.itemRowCount = 0;
  $scope.defaultPartsData = {};
  $scope.supplierIntegrationStatus = !!(typeof $scope.supplierthirdpartyreference !== 'undefined' && $scope.supplierthirdpartyreference !== '' && $scope.supplierthirdpartyreference !== null);
  $scope.supplierPricesLoading = [];
  $scope.supplierPriceFetchError = '';
  
  if(screenType == 'new_purchase_order_parts' || screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order') {
    $scope.sidePanelHelpText = $translate('Supplier.default.list.sidepanel.help.text.purchase.order');
  } else if(screenType == 'new_supplier_invoice' || screenType == 'edit_supplier_invoice') {
    $scope.sidePanelHelpText = $translate('Supplier.default.list.sidepanel.help.text.invoice');
  } else if(screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note') {
    $scope.sidePanelHelpText = $translate('Supplier.default.list.sidepanel.help.text.credit.note');
  }


  /*** Load the default data into parts spreadsheet ***/
  $scope.partsAfterInit= function() {

    /*** Get the default handsontable settings ***/
    Handsontable.hooks.add('afterRender', function() {
        $('[data-toggle="tooltip"]').tooltip();
      $('.handsont_unicode_action_name').off().on('click', function(){
        var partId = $(this).data('partid');
        var partName = $(this).data('partname');

        if($scope.selectedSupplierId) {
          var supplierName = ($scope.selectedTab === 'Costs') ? $scope.selectedSupplier.supplierId['text'] : $scope.selectedSupplierName;
          /*var part = _.find($scope.partScope, {id: partId});
          console.log(part['text']);*/

          $scope.data_to_append = angular.toJson({
            'panelId' : 'add_spc',
            'part_id' : partId,
            'supplierId' : $scope.selectedSupplierId,
            'supplierName' : supplierName,
            'partName' : partName
          });


          var side_panel_tpl = $compile("<span id='sidepanel_po_add_part_product_code' sidepanel remove_on_close='true' template='sidepanel_po_add_part_product_code' title='add.supplier.product.code' data='{{ data_to_append }}'></span>")($scope);

          angular.element('body').append(side_panel_tpl);
          $timeout(function() {
            angular.element('#sidepanel_po_add_part_product_code').scope().$$childTail.initialise();
          }, 600);
        }
      });
    });
    $scope.updateSettings = this.updateSettings;

    if(typeof $scope.getPartSpreadsheetURL != 'undefined'){
      $http.post($scope.getPartSpreadsheetURL,'selectedParts=' + angular.toJson($scope.selctedParts)).success(function(response) {

        $scope.colHeadersParts = response.colHeaders;
        $scope.colHeadersRequired[$scope.partHotId] = response.colHeadersRequired;
        $scope.startColsParts = response.startColsParts;
        $scope.nominalCodeList = response.nominalCodeList;
        $scope.defaultNominalId = response.defaultNominalId;
        $scope.vatRateList = response.vatRateList;
        //$scope.defaultVatRate = response.defaultVatRate;
        $scope.deliveryMethodList = response.deliveryMethodList;
        //$scope.readAccess = response.readAccess;
        $scope.spreadSheetParts = response.purchaseOrderParts;
        $scope.selectedPartsDetails = response.selectedPartsDetails;
        $scope.currentDate = response.currentDate;
        $scope.partScope = [];
        if(CS_LANGUAGE === 'en-GB'){
            $scope.date_format = 'DD/MM/YYYY';
        }else if(CS_LANGUAGE === 'en-US'){
            $scope.date_format = 'MM/DD/YYYY';
        }else{
            $scope.date_format = 'DD/MM/YYYY';
        }

      /**Get part ids**/
      $scope.selectedPartIds = [];
      if(screenType == 'new_supplier_return_credit_note' || screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note'){
        for(var row=0; row < $scope.spreadSheetParts.length; row++){
          $scope.selectedPartIds.push($scope.spreadSheetParts[row]['returnId']);
        }
      }else{
        for(var row=0; row < $scope.spreadSheetParts.length; row++){
          $scope.selectedPartIds.push($scope.spreadSheetParts[row]['partsId']);
        }
      }


        /**Getting values of invoice numbers to show in invoice no column in credit note screens**/
        $scope.selectedInvoiceDetails = response.selectedInvoiceDetails;
        $scope.invoiceNumberDropDown = _.map(response.invoiceNumberDropDown, function(x){
          return {id: x.id, text: x.text};
        });
        $scope.defaultInvoiceDetails = [];

        /*** Load the default values to load the side panel ***/
        if(typeof $scope.sidePanelData != 'undefined'){
          $scope.sidePanelData['showDeliveryDetails'] = $scope.showDeliveryDetails;
          $scope.sidePanelData['deliveryMethodList'] = $scope.deliveryMethodList;
          $scope.sidePanelData['sidePanelHelpText'] = $scope.sidePanelHelpText;
        } else {
          $scope.sidePanelData = {'nominalCodeList' : $scope.nominalCodeList, 'showDeliveryDetails': $scope.showDeliveryDetails,
            'deliveryMethodList': $scope.deliveryMethodList, 'sidePanelHelpText': $scope.sidePanelHelpText};
        }

        /*** get tax rates with cost based on breakdown by tax type ***/
        $scope.deletedTaxItems = response.existTaxLists;
        $scope.getVatGroupByProvinces(response.taxItemList,$scope.deletedTaxItems);

        /*** Calculation the Total section ***/
        $scope.sumTotal();

        /*** Load the default value when new row created ***/
        $scope.partDataSchema= {id: '', partsId: '', quantity: 1, unitCost: 0,taxItemId: $scope.default_tax_item_id, vatRate: 0, nominalCodeId: $scope.defaultNominalId,
          deliveryDate: $scope.currentDate, format:$scope.date_format, deliveryMethod: '', totalCost: 0.00, invoiceNo: '', totalCostVat: 0.00, deleteAction: ""};
        $scope.partColWidths = [1,500,100,150,200,100,150,150,150,150,150,1,70];

        /*** Assign the spreadsheet columns and its cell type ***/
        $scope.partColumns = [
          {data: 'id', type: 'numeric', readOnly: true},
          { renderer: $scope.partRenderer, data: 'partsId', editor: 'select2', placeholder: 'Please select',
            select2Options: {
              editable: true,
              minimumInputLength:2,
              ajax: { url: prefix + '/get_parts_dropdown',
                data: function (query, page, partIds, supplierId) {
                  if(query == '') {
                    return false;
                  }
                    partIds = $scope.selectedPartIds;
                    //This is for select2 js
                    if($(this).val()){
                        var selected_part_id = parseInt($(this).val());
                        partIds = _.without(partIds,selected_part_id)
                    }
                    supplierId = ($scope.selectedTab === 'Costs') ? $scope.selectedSupplierId : $scope.supplier_Id;
                  return { 'query': encodeURIComponent(query), 'selectedPartIds': partIds, 'supplierId': supplierId, 'screenType' : screenType};
                },
                results: function (data, page) {
                  $scope.partScope = data.parts;
                  $scope.defaultPartsData = Object.assign({},$scope.defaultPartsData,data.defaultPartsData);
                  _.forEach(data.invoiceNumberDropDown, function(x){
                    $scope.invoiceNumberDropDown.push(x);
                  });

                  // grouping parts
                  var formatResult = _.groupBy(data['parts'],'id');

                  var parts = [];
                  angular.forEach(formatResult, function(groupedParts,key) {
                    parts[key] = groupedParts[0];
                    var supplierRef = [];
                    angular.forEach(groupedParts, function(part) {
                      if(part['supplierpartnumber'] != null) {
                        supplierRef.push({'supplierpartnumber' : part['supplierpartnumber'], 'suppliername' : part['suppliername']});
                      }
                    });
                    parts[key]['supplierpartnumber'] = supplierRef;
                  });
                  return { results: Object.values(parts) };
                }
              },
              formatResult: function(item, container, query, escapeMarkup) {
                var splitSearchTerm = query.term.split(" ");
                angular.forEach(splitSearchTerm, function (v,k) {
                  splitSearchTerm[k] = "(" + v + ")";
                });
                let regex = new RegExp(splitSearchTerm.join("|"), 'gi');
                var htmlresult = item.text.replace(regex, function (i) { return '<u>' + i + '</u>'; });

                if(item.manufacturepartnumber){
                  htmlresult += ', '+ $translate('MPN') + ' - ' + item.manufacturepartnumber.replace(regex, function (i) { return '<u>' + i + '</u>'; });
                }

                if(item.supplierpartnumber.length > 0) {
                  var spns = item.supplierpartnumber;

                  htmlresult += '<br>';
                  angular.forEach(spns, function (spn) {
                    if(spn['suppliername'] != null && regex.test(spn['supplierpartnumber'])) {
                      spn['supplierpartnumber'] = spn['supplierpartnumber'].replace(regex, function(i) { return '<u>' + i + '</u>' });
                      htmlresult += '<div class="select2 supplier-product-code cs-text-muted"><span>' + $translate('product.code', { supplierName : spn['suppliername'] }) + '</span>: ' + spn['supplierpartnumber']+ '</div>';
                    }
                  });

                }
                return $compile('<div class="select2 parts-list-dropdown">' + htmlresult + '</div>')($scope);
              },
              dropdownCssClass: "handsontable-select",
              dropdownAutoWidth: true, width: 'resolve'
            },
            strict: true, allowInvalid: false
          },
          {renderer: $scope.partRenderer, data: 'quantity', type: 'numeric', allowInvalid: true, forceNumeric: true, allowEmpty: false},
          {renderer: $scope.partRenderer, data: 'unitCost', type: 'numeric', allowInvalid: true, forceNumeric: true, allowEmpty: false},
          {renderer: $scope.partRenderer,  className: 'overflow_handsontable select_icon',
              data: 'taxItemId',
              editor: 'select2',
              placeholder: 'Please select',
              validator:$scope.cellValidator,
              select2Options: {
                  editable: true,
                  data: $scope.taxItemsGroupByProvinces,
                  dropdownAutoWidth: false,
                  dropdownCssClass: "handsontable-select",
                  width: 'resolve'
              }
          },
          {data:"vatRate",type:"numeric",readOnly:true,format:"0.00"},
          { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
            data: "nominalCodeId",
            editor: 'select2',
            placeholder: 'Please select',
            validator: $scope.cellValidator,
            select2Options: {
              editable: true,
              data: $scope.nominalCodeList,
              dropdownAutoWidth: true,
              dropdownCssClass: "handsontable-select",
              width: 'resolve'
            }
          },
          {data: 'deliveryDate', type: 'date', dateFormat: $scope.date_format, validator: $scope.cellValidator,
            datePickerConfig : {
              // First day of the week (0: Sunday, 1: Monday, etc)
              // minDate: new Date(),
              firstDay: 0,
              numberOfMonths: 1,
              setDefaultDate: true,
              defaultDate: $scope.currentDate,
              disableEntry: true
            }},
          { renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
            data: 'deliveryMethod',
            editor: 'select2',
            placeholder: 'Please select',
            validator: $scope.cellValidator,
            select2Options: {
              editable: true,
              data: $scope.deliveryMethodList,
              dropdownAutoWidth: true,
              dropdownCssClass: "handsontable-select",
              width: 'resolve'
            }
          },
          {data: 'totalCost', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
          {
            renderer: $scope.partRenderer,
            className: 'overflow_handsontable select_icon',
            data: 'invoiceNo',
            editor: 'select2',
            placeholder: 'Please select',
            select2Options: {
              editable: true,
              formatLoadMore: function(){
                return ' Loading...';
              },
              dropdownCssClass: "bigdrop",
              width: 'resolve',
              ajax: {
                url: prefix + '/get_supplier_invoices_dropdown',
                quietMillis: 250,
                delay: 250,
                dataType: 'json',
                data: function(query, page){
                  if(_.isUndefined(query)) {
                    query='';
                  }
                  var selectedInvoices=[];
                  for(var row=0; row < $scope.spreadSheetParts.length; row++){
                    selectedInvoices.push($scope.spreadSheetParts[row]['invoiceNo']);
                  }
                  var params = {
                    'query': encodeURIComponent(query),
                    'supplierId': $scope.supplier_Id,
                    'selectedInvoices': selectedInvoices,
                    'page': page,
                    'noOverDue': 1
                  };
                  if($state.params.creditNoteId) {
                    params.creditNoteId = $state.params.creditNoteId;
                  }
                  return params;
                },
                results: function(data, page) {
                  $scope.$apply(function(){
                    $scope.defaultInvoiceDetails = data.invoiceDetails;
                  });
                  var items = _.map(data.invoiceDetails, function(x){
                    return {id: x.id, text:x.text};
                  });
                  if( !data.needLazyLoad && (page * 10) < data.invoiceTotalCount ) {
                    items.push({id: '0', text: 'Type to load more', disabled: true});
                  }
                  return {
                    results: items,
                    more: data.needLazyLoad && (page * 10) < data.invoiceTotalCount
                  };
                }
              }
            }
          },
          /*{renderer: $scope.partRenderer, className: 'overflow_handsontable select_icon',
            data: 'invoiceNo',
            editor: 'select2',
            placeholder: 'Please select',
            validator: $scope.cellValidator,
            select2Options: {
              editable: true,
              data: $scope.invoiceNumberDropDown,
              dropdownAutoWidth: true,
              dropdownCssClass: "handsontable-select",
              width: 'resolve'
            }
          },*/
          {data: 'totalCostVat', type: 'numeric', readOnly: true, format: '0.00'},
          {renderer: $scope.partRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true}
        ];

        /*** Column to hide in spreadsheet ***/
        $scope.hidePartColumns = {columns:[0,5,10]};

        /*** For complete PO didn't have delivery method & delivery date ***/
        if(screenType != 'new_purchase_order_parts' && screenType != 'edit_purchase_order_parts' && screenType != 'new_purchase_order_parts_prepopulated') {

          // For complete PO we didn't use the delivery date & method.
          delete $scope.partDataSchema['deliveryDate'];
          delete $scope.partDataSchema['deliveryMethod'];

          $scope.partColWidths.splice(7, 2);
          $scope.partColumns.splice(7, 2);

        /*** Column to hide in spreadsheet ***/
        $scope.hidePartColumns = {columns:[0,5,9]};

        /**Hide invoice no column from screens other than credit notes**/
        if(screenType != 'new_supplier_return_credit_note' && screenType != 'new_supplier_credit_note' && screenType != 'edit_supplier_credit_note'){
            delete $scope.partDataSchema['invoiceNo'];
            $scope.partColWidths.splice(8, 1);
            $scope.partColumns.splice(8, 1);
            $scope.hidePartColumns = {columns:[0,5,8]};
        }
      }else if(screenType == 'edit_purchase_order_parts' && $scope.selectedTypes == "customers"){
          delete $scope.partDataSchema['deliveryAction'];

          $scope.partColWidths.splice(10, 1);
          $scope.partColumns.splice(10, 1);

          /*** Column to hide in spreadsheet ***/
          $scope.hidePartColumns = {columns:[5,10]};
      }else{
          /**Hide invoice no column from screens other than credit notes**/
          if(screenType != 'new_supplier_return_credit_note' && screenType != 'new_supplier_credit_note' && screenType != 'edit_supplier_credit_note'){
            delete $scope.partDataSchema['invoiceNo'];
            $scope.partColWidths.splice(10, 1);
            $scope.partColumns.splice(10, 1);
            $scope.hidePartColumns = {columns:[0,5,10]};
          }
        }

        /*** Need to hide actions column when adding PO from fulfillment screen***/
        if(screenType == 'new_purchase_order_parts_prepopulated'){
          $scope.hidePartColumns = {columns:[0,5,10,11]};
          $http.get(prefix + '/get_parts_dropdown?partIds='+ $scope.selectedPartIds.join(',') + '&screenType='+ $scope.screenType)
              .success(function (data) {
                $scope.partScope =  data.parts;
                $scope.defaultPartsData = data.defaultPartsData;
                $scope.updateSupplierPrices();
              });
        }

        var updateSettingsParams = {colHeaders: $scope.colHeadersParts, fillHandle: {direction: 'vertical', autoInsertRow: true},
          rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsParts,
          colWidths: $scope.partColWidths, columns: $scope.partColumns, minSpareCols: 0, stretchH: 'all',
          autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetParts,hiddenColumns:$scope.hidePartColumns,
          formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.partDataSchema, minRows: $scope.minRows,
          beforeValidate: $scope.beforeValidate, afterGetColHeader: $scope.afterGetColHeader, comments: true};

        /*** Last empty row not needed when adding PO through fulfillment, editing and completing PO from cost tab***/
        if(screenType == 'new_purchase_order_parts_prepopulated' || ((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice') && $scope.selectedTypes == 'customers')){
          updateSettingsParams.maxRows = $scope.spreadSheetParts.length;
        }

        /*** Load the new settings into the spreadsheet and load them ***/
        $scope.updateSettings(updateSettingsParams); /*dataSchema: $scope.dataSchema,*/

        $scope.displaySpreadsheet = true;
        $scope.show_part_handsontable_spreadsheet = true;
        if(((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice' || screenType == 'edit_purchase_order_parts_estimatedCost') && $scope.selectedTypes == 'customers')){
          if($scope.spreadSheetParts.length == 0 || !$scope.spreadSheetParts[0].id){
            $scope.show_part_handsontable_spreadsheet = false;
          }
        }
      });
    }
    $scope.part_section_open = true;
  }


  /*** Load the default data into items spreadsheet ***/
  $scope.itemsAfterInit = function() {

    /*** Get the default handsontable settings ***/
    $scope.itemUpdateSettings = this.updateSettings;    

    if(typeof $scope.getItemSpreadsheetURL != 'undefined') {
      $http.get($scope.getItemSpreadsheetURL).success(function(response) {

      $scope.colHeadersItems = response.colHeaders;
      $scope.colHeadersRequired[$scope.itemHotId] = response.colHeadersRequired;
      $scope.startColsItems = response.startColsItems;
      $scope.nominalCodeListItem = response.nominalCodeList;
      $scope.defaultNominalId = response.defaultNominalId;
      //$scope.vatRateList = response.vatRateList;
      //$scope.defaultVatRate = response.defaultVatRate;
      $scope.readAccess = response.readAccess;
      $scope.spreadSheetItems = response.purchaseOrderItems;
      $scope.invoiceItemNumberDropDown = _.map(response.invoiceNumberDropDown, function(x){
        return {id: x.id, text: x.text};
      });
      $scope.defaultInvoiceItemDetails = {};
          /*** get tax rates with cost based on breakdown by tax type ***/
          $scope.deletedTaxItems = response.existTaxLists;
          $scope.getVatGroupByProvinces(response.taxItemList,response.existTaxLists);

      /*** Calculation the Total section ***/
      $scope.sumTotal();

      /*** Load the default values to load the side panel in new supplier invoice screen(cost tab) ***/
      if(typeof $scope.sidePanelData == 'undefined'){
        $scope.sidePanelData = {'nominalCodeList' : $scope.nominalCodeListItem, 'readAccess' : $scope.readAccess, 'sidePanelHelpText': $scope.sidePanelHelpText};
      } else {
        $scope.sidePanelData['readAccess'] = $scope.readAccess;
      }

      /*** Load the default value when new row created ***/
      $scope.itemDataSchema= {id: '', itemDescription: '', quantity: 1, unitCost: 0.00, salesPrice: 0.00,taxItemId: $scope.default_tax_item_id, vatRate: 0,
        nominalCodeId: $scope.defaultNominalId, totalCost: 0.00, invoiceNo: '', totalCostVat: 0.00, deleteAction: ''};
      $scope.itemColWidths = [1,400,100,100,100,200,100,150,150,150,1,70];

      /*** Assign the spreadsheet columns and its cell type ***/
      $scope.itemColumns = [
        {data: 'id', type: 'numeric', readOnly: true},
        {data: 'itemDescription', type: 'text', allowInvalid: false},
        {renderer: $scope.itemRenderer, data: 'quantity', type: 'numeric', allowInvalid: true, forceNumeric: true, allowEmpty: false},
        {renderer: $scope.itemRenderer, data: 'unitCost', type: 'numeric', allowInvalid: true, forceNumeric: true, allowEmpty: false},
        {renderer: $scope.itemRenderer, data: 'salesPrice', type: 'text', allowInvalid: $scope.readAccess, forceNumeric: true, validator: $scope.cellValidator,},
        {renderer: $scope.itemRenderer,  className: 'overflow_handsontable select_icon',
            data: 'taxItemId',
            editor: 'select2',
            placeholder: 'Please select',
            validator:$scope.cellValidator,
            select2Options: {
                editable: true,
                data: $scope.taxItemsGroupByProvinces,
                dropdownAutoWidth: false,
                dropdownCssClass: "handsontable-select",
                width: 'resolve'
            }
        },
        {data:"vatRate",type:"numeric",readOnly:true,format:"0.00"},
        { renderer: $scope.itemRenderer,
          data: "nominalCodeId", className: 'overflow_handsontable select_icon',
          editor: 'select2',
          placeholder: 'Please select',
          validator: $scope.cellValidator,
          select2Options: {
            editable: true,
            data: $scope.nominalCodeListItem,
            dropdownAutoWidth: true,
            dropdownCssClass: "handsontable-select",
            width: 'resolve'
          }
        },
        {data: 'totalCost', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
        {
          renderer: $scope.itemRenderer,
          className: 'overflow_handsontable select_icon',
          data: 'invoiceNo',
          editor: 'select2',
          placeholder: 'Please select',
          select2Options: {
            editable: true,
            formatLoadMore: function(){
              return ' Loading...';
            },
            dropdownCssClass: "bigdrop",
            width: 'resolve',
            ajax: {
              url: prefix + '/get_supplier_invoices_dropdown',
              quietMillis: 250,
              delay: 250,
              dataType: 'json',
              data: function(query, page){
                if(_.isUndefined(query)) {
                  query='';
                }
                var selectedInvoices=[];
                for(var row=0; row < $scope.spreadSheetItems.length; row++){
                  selectedInvoices.push($scope.spreadSheetItems[row]['invoiceNo']);
                }
                var params = {
                  'query': encodeURIComponent(query),
                  'supplierId': $scope.supplier_Id,
                  'selectedInvoices': selectedInvoices,
                  'page': page,
                  'noOverDue': 1
                };
                if($state.params.creditNoteId) {
                  params.creditNoteId = $state.params.creditNoteId;
                }
                return params;
              },
              results: function(data, page) {
                $scope.$apply(function(){
                  $scope.defaultInvoiceItemDetails = data.invoiceDetails;
                });
                var items = _.map(data.invoiceDetails, function(x){
                  return {id: x.id, text:x.text};
                });
                if( !data.needLazyLoad && (page * 10) < data.invoiceTotalCount ) {
                  items.push({id: '0', text: 'Type to load more', disabled: true});
                }
                return {
                  results: items,
                  more: data.needLazyLoad && (page * 10) < data.invoiceTotalCount
                };
              }
            }
          }
        },
        /*{renderer: $scope.itemRenderer, className: 'overflow_handsontable select_icon',
            data: 'invoiceNo',
            editor: 'select2',
            placeholder: 'Please select',
            select2Options: {
                editable: true,
                data: $scope.invoiceNumberDropDown,
                dropdownAutoWidth: true,
                dropdownCssClass: "handsontable-select",
                width: 'resolve'
            }
        },*/
        {data: 'totalCostVat', type: 'numeric', readOnly: true, format: '0.00'},
        {renderer: $scope.itemRenderer, data: 'deleteAction', readOnly: true, allowHtml: true, disableVisualSelection: true}
      ];

        /**Hide invoice no column from screens other than credit notes**/
        if(screenType != 'new_supplier_credit_note' && screenType != 'new_supplier_return_credit_note' && screenType != 'edit_supplier_credit_note'){
          delete $scope.itemDataSchema['invoiceNo'];

          $scope.itemColWidths.splice(9, 1);
          $scope.itemColumns.splice(9, 1);
        }

      // For PO from Estimated cost should disable item description and quantity fields
        if(screenType == 'new_job_purchase_order_items_prepopulated' || screenType == 'edit_purchase_order_parts_estimatedCost'){
            $scope.itemColumns[1].readOnly = true;
            $scope.itemColumns[1].disableVisualSelection = true;
        }

        /**Hide invoice no column from screens other than credit notes**/
        if($scope.readAccess) {
          if(screenType == 'new_job_purchase_order_items_prepopulated' || screenType == 'edit_purchase_order_parts_estimatedCost'){
            $scope.hideItemColumns = {columns:[0,6,10,11]}
          }else{
            (screenType == 'new_supplier_credit_note' || screenType == 'new_supplier_return_credit_note' || screenType == 'edit_supplier_credit_note') ? $scope.hideItemColumns = {columns:[0,6,10]} : $scope.hideItemColumns = {columns:[0,6,9]};
          }
        } else {
          if(screenType == 'new_job_purchase_order_items_prepopulated' || screenType == 'edit_purchase_order_parts_estimatedCost'){
            $scope.hideItemColumns = {columns:[0,4,6,10,11]}
          }else{
            (screenType == 'new_supplier_credit_note' || screenType == 'new_supplier_return_credit_note' || screenType == 'edit_supplier_credit_note') ? $scope.hideItemColumns = {columns:[0,4,6,10]} : $scope.hideItemColumns = {columns:[0,4,6,9]};
          }
        }


     // var newHeight = $scope.spreadSheetItems.length?($scope.spreadSheetItems.length * 24) + 24 : 200;

      //contextMenu: ['row_above', 'row_below', 'remove_row'],
      /*** Load the new settings into the spreadsheet and load them ***/
      var itemUpdateSettingsParams = {colHeaders: $scope.colHeadersItems, currentColClassName: 'selectedCol',currentRowClassName: 'selectedRow',
        rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsItems, minSpareRows: $scope.minSpareRows,
        colWidths: $scope.itemColWidths, columns: $scope.itemColumns, minSpareCols: 0, fillHandle: {direction: 'vertical', autoInsertRow: true},
        autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetItems,hiddenColumns:$scope.hideItemColumns, afterGetColHeader: $scope.afterGetColHeader,
        formulas: true, minRows: $scope.minRows, dataSchema: $scope.itemDataSchema, beforeValidate: $scope.beforeValidate, stretchH: 'all'};

      if(screenType == 'new_job_purchase_order_items_prepopulated' || screenType == 'edit_purchase_order_parts_estimatedCost'){
        itemUpdateSettingsParams.maxRows = $scope.spreadSheetItems.length;
      }

      $scope.itemUpdateSettings(itemUpdateSettingsParams); /*dataSchema: $scope.dataSchema*/

      $scope.displaySpreadsheet = true;
      $scope.item_section_open = true;
      $scope.show_item_handsontable_spreadsheet = true;
    });
    }
  }

  //# Stored the selected part dropdown values to check with backend validation.
  $scope.selectedDropDownValues= [];

  /*** Renderer for parts spreadsheet ***/
  $scope.partRenderer = function(instance, td, row, col, prop, value, cellProperties) {
    var cellTitle = instance.getCellMeta(row, col);
    var cellCase = cellTitle.prop;

    //# Used to scroll to the last row
    if((typeof instance.getActiveEditor() != 'undefined') && (instance.getActiveEditor().row == row)) {
      $scope.scrollToLastRow(row, col, instance);
    }

    switch(cellCase) {

      case 'partsId':
          td.className='overflow_handsontable select_icon';
            //# Check its active editor
            $scope.isPartActiveEditor = $scope.checkActiveEditor(instance, col, row, true, false);

          //# Avoid while delete line items, the previous part description stored in empty line item.
          if(value == '') {
            instance.getCell(row,col).innerText = '';
            instance.getCell(row,col).innerHTML = '';
          }

          /*** Need to make part cell readonly when raising PO from fulfillment, edit and complete PO(associated with job)***/
            if(screenType == 'new_purchase_order_parts_prepopulated' || ((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice') && $scope.selectedTypes == 'customers')){
              cellProperties.readOnly=true;
              cellProperties.disableVisualSelection= true;
            }
          if(screenType == 'edit_purchase_order_parts' && $rootScope.moduleDetails['Stock'] === 1){
              if($scope.spreadSheetParts[row]['markedAsArrived'] === 1){
                  cellProperties.readOnly=true;
                  cellProperties.disableVisualSelection= true;
              }
          }

            var partsId;
            //# In edit during load didn't get this scope value
            if($scope.partScope.length && typeof instance.getActiveEditor() != 'undefined' && instance.getActiveEditor().row == row) {
              for (var index = 0; index < $scope.partScope.length; index++)
              {
                if (parseInt(value) === $scope.partScope[index].id)
                {
                  partsId = $scope.partScope[index].id;
                  value = $scope.partScope[index].text;
                  if($scope.isPartActiveEditor) {
                    /*** Load the default value into the row once added in parts table ***/
                    if($scope.vatType == 'inc_vat'){
                        var vat =  $scope.defaultPartsData[partsId].vat ? parseFloat($scope.defaultPartsData[partsId].vat) : parseFloat($scope.spreadSheetParts[row]['vatRate']);
                        var tax = roundFloat($scope.defaultPartsData[partsId].price * (vat / 100),2) ;
                        $scope.spreadSheetParts[row]['unitCost'] = parseFloat($scope.defaultPartsData[partsId].price) + tax;
                    }else{
                        $scope.spreadSheetParts[row]['unitCost'] = $scope.defaultPartsData[partsId].price;
                    }

                    var taxItemId = $scope.defaultPartsData[partsId].taxItemId ? $scope.defaultPartsData[partsId].taxItemId.toString() : "1";
                    selectedTax = _.where($scope.listOfTaxItems, {id: taxItemId} );

                    if(selectedTax.length != 0)
                    {
                      $scope.spreadSheetParts[row]['vatRate'] = selectedTax[0]['effective_tax'];
                      $scope.spreadSheetParts[row]['taxItemId'] = selectedTax[0]['id'];
                    }else
                    {
                      $scope.spreadSheetParts[row]['vatRate'] = 0;
                      $scope.spreadSheetParts[row]['taxItemId'] = 1;
                    }

                    if(screenType == 'new_supplier_credit_note' || screenType == 'new_supplier_return_credit_note' || screenType == 'edit_supplier_credit_note'){
                      $scope.spreadSheetParts[row]['invoiceNo'] = $scope.defaultPartsData[partsId].invoiceNo;
                      $scope.spreadSheetParts[row]['returnId'] = $scope.defaultPartsData[partsId].returnId;
                      $scope.spreadSheetParts[row]['nominalCodeId'] = $scope.defaultPartsData[partsId].settingsNominalcodeid;
                    }

                    //# Remove the error message css class name
                    $scope.instanceLoad[instance.getSettings().hotId].setCellMeta(row, 2, "className", "");
                    $scope.instanceLoad[instance.getSettings().hotId].setCellMeta(row, 3, "className", "");
                    $scope.selectedDropDownValues[partsId] = value;
                  }

                  //Handsontable.cellTypes.text.renderer.apply(this, arguments);
                  break;
                }
              }

              //# Will execute if selected different part in add PO screen and edit other columns.
              if(typeof partsId == 'undefined') {
                partsId = value;

                if(typeof $scope.selectedDropDownValues[parseInt(value)] != 'undefined') {
                  value = $scope.selectedDropDownValues[parseInt(value)];
                } else if($scope.selectedPartsDetails != null) { //# Execute during edit screen and part not selected in dropdown list.
                  value = $scope.selectedPartsDetails[value];
                }
              }

            } else if($scope.selectedDropDownValues.length) { //# Will execute only validation error from backend call
              partsId = value;

              if(typeof $scope.selectedDropDownValues[parseInt(value)] != 'undefined') {
                value = $scope.selectedDropDownValues[parseInt(value)];
              } else if($scope.selectedPartsDetails != null) { //# Execute during edit screen and having error rows.
                value = $scope.selectedPartsDetails[value];
              }

             // Handsontable.cellTypes.text.renderer.apply(this, arguments);
            } else if($scope.selectedPartsDetails != null) { //# Else part work only in edit section
              partsId = value;
              value = $scope.selectedPartsDetails[value];
              //Handsontable.cellTypes.text.renderer.apply(this, arguments);
            }

            //# Display with tool tip
            var percentage  = (document.documentElement.clientWidth / 1366) * 100;
            var length = parseInt((30/100) * percentage);
            var toolTip = value;
            if((typeof toolTip != 'undefined') && (toolTip != '') && toolTip != null && (toolTip.length > length)) {
              //value = toolTip.substring(0, length).trim() + '...';
              cellProperties.comment={value: toolTip};
            }
            if(screenType == 'new_supplier_return_credit_note' || screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note'){
              if($scope.spreadSheetParts[row]['returnId'] != '' && typeof $scope.spreadSheetParts[row]['returnId'] != 'undefined' && inArray($scope.spreadSheetParts[row]['returnId'], $scope.selectedPartIds) == false && _.isNumber(partsId)){
                $scope.selectedPartIds.push($scope.spreadSheetParts[row]['returnId']);
              }
            }else{
              if(partsId != '' && typeof partsId != 'undefined' && inArray(partsId, $scope.selectedPartIds) == false && _.isNumber(partsId)){
                $scope.selectedPartIds.push(partsId);
              }
            }
            Handsontable.cellTypes.text.renderer.apply(this, arguments);
            break;
      case 'quantity':

        /*** Need to make quantity cell readonly when raising PO from fulfillment, edit and complete PO(associated with job)***/
        if(screenType == 'new_purchase_order_parts_prepopulated' || (screenType == 'edit_purchase_order_parts' && $scope.selectedTypes == 'customers')){
              cellProperties.readOnly=true;
              cellProperties.disableVisualSelection= true;
            }
          if(screenType == 'edit_purchase_order_parts' && $rootScope.moduleDetails['Stock'] === 1){
              if($scope.spreadSheetParts[row]['markedAsArrived'] === 1){
                  cellProperties.readOnly=true;
                  cellProperties.disableVisualSelection= true;
              }
          }
            //# Check and update with two decimal point in spreadsheet
            $scope.newQuantity = value > 0 ? parseFloat(value) : 1;
            $scope.spreadSheetParts[row]['quantity'] = round($scope.newQuantity,2);
            value = round($scope.newQuantity,2);
            Handsontable.cellTypes.text.renderer.apply(this, arguments);
            break;
      case 'unitCost' :

            //# Check and update with two decimal point in spreadsheet
          if(screenType == 'new_supplier_invoice' || screenType == 'edit_supplier_invoice' ||  screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note')
              $scope.newUnitCost = parseFloat(value);
            else
              $scope.newUnitCost = value > 0 ? parseFloat(value) : 0;

            value = $scope.getTotal(instance, row, round($scope.newUnitCost,4), 'parts');

            var id = instance.getDataAtRowProp(row,'id');
            if(typeof $scope.defaultPartsData != 'undefined' && (screenType == 'new_purchase_order_parts' || screenType == 'new_purchase_order_parts_prepopulated' || (screenType == 'edit_purchase_order_parts' && id == ''))) {
                var spreadSheetRow = $scope.spreadSheetParts[row];
                if(spreadSheetRow['partsId']){
                  var part = $scope.defaultPartsData[spreadSheetRow['partsId']];
                  var priceupdatedon, diffDays;
                  var supplierIntegerationSym = false;
                  var tooltipText = '';

                  if($scope.supplierIntegrationStatus && typeof ($scope.selectedSupplierId) !== "undefined"){

                    // show the realtime price icon if the price is from integration
                    if(typeof(part['realtimepricing']) !== "undefined"){
                      if(typeof(part['realtimepricing']) === "boolean") {
                        supplierIntegerationSym = true;
                        if(part['realtimepricing'] && parseFloat(part['price']) === $scope.newUnitCost) {
                          tooltipText = $translate('live.price.description');
                          value += '<span class="handsontable-icon-wrap cs-text-green" data-toggle="tooltip" data-placement="bottom" title="'+ tooltipText + '"><i class="fas fa-star"></i></span>';
                        }
                      } else if(part['realtimepricing'] !== '') {
                        value += '<span class="handsontable-icon-wrap cs-text-red" data-toggle="tooltip" data-placement="bottom" title="'+ part['realtimepricing'] +'"><i class="fas fa-exclamation-triangle"></i></span>';
                      }
                    } else {
                      // can't fetch real time prices
                      if($scope.supplierPriceFetchError !== '' && part['supplierpartnumber'] && $scope.supplierPricesLoading.indexOf(part['supplierpartnumber']) <= -1){
                        value += '<span class="handsontable-icon-wrap cs-text-red" data-toggle="tooltip" data-placement="bottom" title="'+ $scope.supplierPriceFetchError +'"><i class="fas fa-exclamation-triangle"></i></span>';
                      }

                      // Realtime prices loading
                      if($scope.supplierPricesLoading.indexOf(part['supplierpartnumber']) > -1){
                        value += '<span class="handsontable-icon-wrap"><img src="/images/loading/ajax-loader.gif"/></span>';
                      }
                    }
                  }

                  if(!supplierIntegerationSym && typeof ($scope.selectedSupplierId) !== "undefined") {
                    if(!_.has(part, 'priceupdatedon') || part['priceupdatedon'] == null || parseFloat(part['price']) !== parseFloat(spreadSheetRow['unitCost'])) {
                      tooltipText = $translate('price.not.applied.from.supplier.catalog');
                      value += '<span class="handsontable-icon-wrap cs-text-default" data-toggle="tooltip" data-placement="bottom" title="'+ tooltipText +'"><i class="fas fa-exclamation-circle"></i></span>';
                    } else {
                      tooltipText = $translate('supplier.price.updated.on')+ '\n' + moment.utc(part['priceupdatedon']).zone(moment().format('Z')).format('L (LT)');

                      if (part['supplierinvoiceno']) {
                        tooltipText += ' ' + $translate('by') + ' '+ $translate('Invoice').toLowerCase() +' ' + part['supplierinvoiceno'];
                      }

                      if(_.has(part, 'priceupdatedon') && part['priceupdatedon'] != null) {
                        priceupdatedon = moment.utc(part['priceupdatedon']).zone(moment().format('Z'));
                        diffDays = moment(new Date()).diff(priceupdatedon)/(1000 * 3600 *24);
                      }

                      if(diffDays <= 30){
                        value += '<span class="handsontable-icon-wrap cs-text-green" data-toggle="tooltip" data-placement="bottom" title="'+ tooltipText +'"><i class="fas fa-clock"></i></span>';
                      } else if(diffDays <= 60) {
                        value += '<span class="handsontable-icon-wrap cs-text-orange" data-toggle="tooltip" data-placement="bottom" title="'+ tooltipText +'"><i class="fas fa-clock"></i></span>';
                      } else if(diffDays > 60) {
                        value += '<span class="handsontable-icon-wrap cs-text-red" data-toggle="tooltip" data-placement="bottom" title="'+ tooltipText +'"><i class="fas fa-clock"></i></span>';
                      }
                    }
                  }

                  // Supplier part number not found
                  if(_.has(part, 'supplierpartnumber') && !part['supplierpartnumber'] && typeof ($scope.selectedSupplierId) !== "undefined") {
                    let partName = instance.getCell(row,1).innerText;
                    tooltipText = $translate('spn.not.found');
                    value += '<a class="handsont_unicode_action_name" style="cursor:pointer;" data-partname="'+ partName +'" data-partid="'+ spreadSheetRow['partsId'] +'"><span class="handsontable-icon-wrap cs-text-red" data-toggle="tooltip" data-placement="bottom" title="'+ tooltipText +'"><i class="fas fa-exclamation-triangle"></i></span></a>';
                  }
                }
            }
            td.innerHTML = value;
            break;
      case 'taxItemId':

            var selectedId,effective_tax;
            value = (value != null) ? value.toString() : value;
            var selectedTax = _.where($scope.listOfTaxItems, {id: value});

            if (selectedTax.length == 0) {
               selectedTax = _.where($scope.listOfTaxItems, {id: $scope.default_tax_item_id});
            }

            selectedId = selectedTax[0].id;
            value = selectedTax[0].text;
            effective_tax = selectedTax[0].effective_tax;

            $scope.spreadSheetParts[row]['vatRate'] = effective_tax;
            $scope.spreadSheetParts[row]['taxItemId'] = selectedId;

            $scope.getTotal(instance, row, round($scope.spreadSheetParts[row]['unitCost'],4), 'parts');
            if(effective_tax != null) {
               $scope.getTotalVat(instance, row, col, effective_tax ,'parts');
            }
            Handsontable.cellTypes.text.renderer.apply(this, arguments);
            break;
      case 'nominalCodeId':
            var selectedId;
            for (var index = 0; index < $scope.nominalCodeList.length; index++)
            {
              if (parseInt(value) === $scope.nominalCodeList[index].id)
              {
                selectedId = $scope.nominalCodeList[index].id;
                value = $scope.nominalCodeList[index].text;
                break;
              }
            }
            Handsontable.cellTypes.text.renderer.apply(this, arguments);
            break;
      case 'deliveryMethod':
            var selectedId;
            for (var index = 0; index < $scope.deliveryMethodList.length; index++)
            {
              if(value !=1 && value != '' && (typeof $scope.deliveryMethodList[index].children != 'undefined')) {
                var children = $scope.deliveryMethodList[index].children;
                for(var i =0; i < children.length; i++) {
                  if (value === children[i].id)
                  {
                    selectedId = children[i].id;
                    value = children[i].text;
                  }
                }
              } else {
                if (parseInt(value) === $scope.deliveryMethodList[index].id)
                {
                  selectedId = $scope.deliveryMethodList[index].id;
                  value = $scope.deliveryMethodList[index].text;
                }
              }
            }
            Handsontable.cellTypes.text.renderer.apply(this, arguments);
            break;
        case 'invoiceNo':
          if((screenType == 'new_supplier_return_credit_note' || (screenType == 'new_supplier_credit_note' && $scope.fromSupplierInvoice) || screenType == 'edit_supplier_credit_note') && value){
            cellProperties.readOnly=true;
            cellProperties.disableVisualSelection= true;
          }
          if(_.has($scope.defaultInvoiceDetails, value) ) {
            $scope.invoiceNumberDropDown.push($scope.defaultInvoiceDetails[value]);
          }

          var selectedInvoice;
          _.forEach($scope.invoiceNumberDropDown, function(x){
            if(x.id == parseInt(value)) {
              selectedInvoice = x.text;
              value = x.text;
            }
          });

          // var selectedInvoice;
          // for (var index = 0; index < $scope.invoiceNumberDropDown.length; index++)
          // {
          //     if (parseInt(value) === $scope.invoiceNumberDropDown[index].id)
          //     {
          //         selectedInvoice = $scope.invoiceNumberDropDown[index].id;
          //         value = $scope.invoiceNumberDropDown[index].text;
          //     }
          // }
          Handsontable.cellTypes.text.renderer.apply(this, arguments);
          break;
      case 'deleteAction':
            Handsontable.cellTypes.text.renderer.apply(this, arguments);
            var div = document.createElement('DIV');
            var a = document.createElement('a');
            var linkText = document.createTextNode("Delete");
            a.appendChild(linkText);
            a.title = "Delete Action";
            a.style= "cursor:pointer;"
            div.appendChild(a);
            $(td).empty().append(div).append(a);
            $(a).on('click', function(e) {
              $scope.deleteAction(row, col, $scope.partHotId, 'part');
            });
            return td;
            break;
    }
  }
  $scope.confirmSupplierPriceUpdate = function () {
    if($scope.spreadSheetParts.length > 0 && $scope.spreadSheetParts[0]['partsId'] !== '') {
      $('#supplier-price-change-confirmation').modal('show');
    }
  }

  $rootScope.$on('parent:updated', function(evt) {
    if (angular.element('#sidepanel_po_add_part_product_code')) {
      angular.element('#sidepanel_po_add_part_product_code').remove();
    };
  });
  $scope.updateSupplierPrices = function () {
    if($scope.supplierIntegrationStatus) {
      $scope.updateRealTimePartPrices();
    } else {
      angular.forEach($scope.spreadSheetParts, function(lineItem, key){
        if(lineItem['partsId'] !== ''){
          var partsData = $scope.defaultPartsData[lineItem['partsId']];
          $scope.spreadSheetParts[key]['unitCost'] = partsData['price'];
          $scope.spreadSheetParts[key]['taxItemId'] = partsData['taxItemId'];
          $scope.spreadSheetParts[key]['vatRate'] = partsData['vat'];
        }
      });
      $scope.instanceLoad[$scope.partHotId].render();
    }
  }
  $scope.cancelSupplierPriceUpdate = function () {
    $scope.closeModal('#supplier-price-change-confirmation');
    angular.forEach($scope.defaultPartsData, function (v,k) {
      $scope.defaultPartsData[k]['realtimepricing'] = false;
      $scope.defaultPartsData[k]['priceupdatedon'] = null;
    });
    $scope.instanceLoad[$scope.partHotId].render();
  }
  $scope.acceptNewSupplierPrices = function () {
    $scope.closeModal('#supplier-price-change-confirmation');
    var partIds = _.pluck($scope.spreadSheetParts,'partsId');
    partIds = _.compact(partIds);
    if(typeof $scope.selectedSupplierId !== 'undefined'){

      $http.get(prefix + '/get_parts_dropdown?partIds='+ partIds +'&supplierId='+ $scope.selectedSupplierId +'&screenType='+ $scope.screenType)
          .success(function (data) {
            $scope.partScope =  data.parts;
            $scope.defaultPartsData = data.defaultPartsData;
            $scope.updateSupplierPrices();
          });
    }
  }

  $scope.closeModal = function(modalName) {
    $(modalName).modal('hide');
  }

  /*** Renderer for items spreadsheet ***/
  $scope.itemRenderer = function(instance, td, row, col, prop, value, cellProperties) {
    var cellTitle = instance.getCellMeta(row, col);
    var cellCase = cellTitle.prop;

    //# Used to scroll to the last row
    if((typeof instance.getActiveEditor() != 'undefined') && (instance.getActiveEditor().row == row)) {
      $scope.scrollToLastRow(row, col, instance);
    }

    switch(cellCase) {

      case 'quantity':
        if(screenType == 'edit_purchase_order_parts_estimatedCost' || screenType == 'new_job_purchase_order_items_prepopulated'){
          cellProperties.readOnly=true;
          cellProperties.disableVisualSelection= true;
        }

        //# Check and update with two decimal point in spreadsheet
        $scope.newQuantity = value > 0 ? parseFloat(value) : 1;
        $scope.spreadSheetItems[row]['quantity'] = round($scope.newQuantity,2);
        value = round($scope.newQuantity,2);
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'unitCost' :

        //# Check and update with two decimal point in spreadsheet
          if(screenType == 'new_supplier_invoice' || screenType == 'edit_supplier_invoice' ||  screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note')
              $scope.newUnitCost = parseFloat(value);
          else
              $scope.newUnitCost = value > 0 ? parseFloat(value) : 0;

        value = $scope.getTotal(instance, row, round($scope.newUnitCost,4), 'items');
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'salesPrice':
          if(value == null) {
            $scope.spreadSheetItems[row]['salesPrice'] = "0.0000";
            value = "0.0000";
          }

          //*** Calculate if they enter with (%) in cell.
          var toStringValue = value.toString();
          var splitValue = toStringValue.split("%");
          if(splitValue.length == 2) {
            $scope.calculation = (parseFloat($scope.spreadSheetItems[row]['unitCost']) * parseFloat(splitValue[0]))/100;
            $scope.spreadSheetItems[row]['salesPrice'] = round($scope.calculation,4);
            value = round($scope.calculation,4);
          } else if(isNaN(value) || value == 0) {
            $scope.spreadSheetItems[row]['salesPrice'] = "0.0000";
            value = "0.0000";
          } else {
            $scope.calculation = parseFloat(value);
            $scope.calculation = round($scope.calculation, 4);
            $scope.spreadSheetItems[row]['salesPrice'] = $scope.calculation;
            value = $scope.calculation;
          }

          Handsontable.cellTypes.text.renderer.apply(this, arguments);
            break;
      case 'taxItemId':

        var selectedId,effective_tax;
        value = (value != null) ? value.toString() : value;
        var selectedTax = _.where($scope.listOfTaxItems, {id: value });

        if (selectedTax.length == 0) {
          selectedTax = _.where($scope.listOfTaxItems, {id: $scope.default_tax_item_id} );
        }

        selectedId = selectedTax[0].id;
        value = selectedTax[0].text;
        effective_tax = selectedTax[0].effective_tax;

        $scope.spreadSheetItems[row]['vatRate'] = effective_tax;
        $scope.spreadSheetItems[row]['taxItemId'] = selectedId;

        $scope.getTotal(instance, row, round($scope.spreadSheetItems[row]['unitCost'],4), 'items');
        if(effective_tax != null) {
           $scope.getTotalVat(instance, row, col, effective_tax,'items');
        }
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'nominalCodeId':
        var selectedId;
        for (var index = 0; index < $scope.nominalCodeListItem.length; index++)
        {
          if (parseInt(value) === $scope.nominalCodeListItem[index].id)
          {
            selectedId = $scope.nominalCodeListItem[index].id;
            value = $scope.nominalCodeListItem[index].text;
            break;
          }
        }
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'invoiceNo':
        if((screenType == 'new_supplier_return_credit_note' || (screenType == 'new_supplier_credit_note' && $scope.fromSupplierInvoice) || screenType == 'edit_supplier_credit_note') && value){
          cellProperties.readOnly=true;
          cellProperties.disableVisualSelection= true;
        }
        if(_.has($scope.defaultInvoiceItemDetails, value) ) {
          $scope.invoiceItemNumberDropDown.push($scope.defaultInvoiceItemDetails[value]);
        }

        var selectedInvoice;

        _.forEach($scope.invoiceItemNumberDropDown, function(x){
          if(x.id == parseInt(value)) {
            selectedInvoice = x.text;
            value = x.text;
          }
        });
        // for (var index = 0; index < $scope.invoiceNumberDropDown.length; index++)
        // {
        //     if (parseInt(value) === $scope.invoiceNumberDropDown[index].id)
        //     {
        //         selectedInvoice = $scope.invoiceNumberDropDown[index].id;
        //       value = $scope.invoiceNumberDropDown[index].text;
        //     }
        // }
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'deleteAction':
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        var div = document.createElement('DIV');
        var a = document.createElement('a');
        var linkText = document.createTextNode("Delete");
        a.appendChild(linkText);
        a.title = "Delete Action";
        a.style= "cursor:pointer;"
        div.appendChild(a);
        $(td).empty().append(div).append(a);
        $(a).on('click', function(e) {
          $scope.deleteAction(row, col, $scope.itemHotId, 'item');
        });
        return td;
        break;
    }
  }

  /*** After change action from parts spreadsheet ***/
  $scope.partsAfterChange=function(changes, src) {

    //# Load the spreadsheet Instance record to alter the spreadsheet
    $scope.instanceLoad[$scope.partHotId] = this;

    //# If the current src is edit mode, then call the insert function
    if(src === 'edit') {
      $scope.insertRow(this, changes, src);

      if($scope.isErrorReturn) {
        var row = this.getActiveEditor().row;
         $scope.removeErrorMessage(row, $scope.partHotId, 'errorRowList');
      }
    }
    //# Load an empty default data during edit section
    else if((src === 'loadData' && $scope.spreadSheetParts.length)) {
      var recordLength = $scope.spreadSheetParts.length-1;
      //# Check the last record having id value
      if($scope.spreadSheetParts[recordLength].id != '') {
        this.alter('insert_row', this.countRows());
        this.render();
       }
      else if((screenType == 'new_supplier_credit_note' || screenType == 'new_supplier_return_credit_note') && $scope.spreadSheetParts[recordLength].partsId != ''){
        this.alter('insert_row', this.countRows());
        this.render();
      }

      $timeout(function() {
        $scope.scrollingSpreadsheet($scope.partHotId, $scope.instanceLoad[$scope.partHotId]);
      }, 1000);
    }

    if(screenType == 'new_purchase_order_parts_prepopulated' || ((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice') && $scope.selectedTypes == 'customers')){
      var validationCond = $scope.spreadSheetParts.length > 0;
      var emptyRow = false;
    }else{
      var validationCond = $scope.spreadSheetParts.length > 1;
      var emptyRow = true;
    }

    //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
    if(validationCond) {
      $scope.partsSaveRecord = this.getData();
    if(emptyRow == true){
        var limit = $scope.partsSaveRecord.length-1;
    }else if(emptyRow == false){
        var limit = $scope.partsSaveRecord.length;
    }

      //# Validate each cell from the row to enable the save button
      $scope.spreadSheetValidation($scope.partsSaveRecord, $scope.partHotId, limit);
      var colData = this.getDataAtCol(1);
      $scope.partRowCount = $scope.NonEmptyRowsCount(colData);
    }

    // update supplier integration price
    if($scope.supplierIntegrationStatus && (screenType == 'new_purchase_order_parts' || screenType == "edit_purchase_order_parts") && changes) {

      var spn = [];
      var supplierId = $scope.selectedSupplierId ? $scope.selectedSupplierId : $scope.supplier_Id;
      angular.forEach(changes, function(change) {
        if(change[1] === 'partsId') {
          if(change[3] !== '') {
            var currPart = $scope.defaultPartsData[change[3]];
            if(currPart['supplierpartnumber'] !== '' && currPart['supplierpartnumber'] !== null) {
              spn.push(currPart['supplierpartnumber']);
            }
          }
        }
      });

      spn = _.uniq(spn);
      if(spn.length > 0) {
        $scope.supplierPricesLoading = spn;
        $scope.instanceLoad[$scope.partHotId].render();

        $http.get(prefix + '/get_integration_parts_prices?supplierId='+supplierId+'&spn='+spn.join(',')).success(function(resp) {
          var livePrices = resp.productPrice;
          $scope.supplierPriceFetchError = '';
          angular.forEach(changes, function(change) {
            if(change[1] === 'partsId') {
              if(change[3] !== '') {
                var currPart = $scope.defaultPartsData[change[3]];
                if(currPart['supplierpartnumber'] !== '' && currPart['supplierpartnumber'] !== null) {
                  // applying supplier integration price on the spreadsheet
                  var suppPrice = _.find(livePrices,{productCode: currPart['supplierpartnumber']});

                  if(suppPrice['sellingPrice']) {
                    $scope.spreadSheetParts[change[0]]['unitCost'] = suppPrice['sellingPrice'];
                    $scope.defaultPartsData[change[3]]['price'] = suppPrice['sellingPrice'];
                    $scope.defaultPartsData[change[3]]['realtimepricing'] = true;
                  }

                  if(suppPrice['reasonForNoSellingPrice']) {
                    $scope.defaultPartsData[change[3]]['realtimepricing'] = suppPrice['reasonForNoSellingPrice'];
                  }

                }
              }
            }
          });
          setTimeout(function(){
            $scope.supplierPricesLoading = [];
            $scope.instanceLoad[$scope.partHotId].render();
          }, 100);
        }).error(function(error){
          $scope.supplierPriceFetchError = error.error;
          $scope.supplierPricesLoading = [];
          $scope.instanceLoad[$scope.partHotId].render();
        });
      }
    }
  }

  $scope.$on('sidepanel:process_data', function(evnt, data) {
    if(data['panelId'] === 'add_spc') {
      $scope.savePartProductCode(data);
    }
  });

  $scope.savePartProductCode = function (data) {
    var postData = {
      'id' : null,
      "partId" : data["part_id"],
      "price" : 0,
      "supplierId": data["supplierId"],
      "supplierName" : data["supplierName"],
      "supplierPartNumber": data["productcode"]
    };
    $http.post(prefix + '/parts_and_prices/supplier_prices/addOrEdit','supplierprice=' + angular.toJson(postData)).success(function (respdata) {
      $rootScope.$emit('sidepanel:close');
      toastBox.show('Saved', 1000);

      // applying part number on local data
      $scope.defaultPartsData[data["part_id"]]["supplierpartnumber"] = data["productcode"];

      $scope.updateRealTimePartPrices(data["part_id"]);

      $scope.instanceLoad[$scope.partHotId].render();
    });
  }

  $scope.updateRealTimePartPrices = function(partsId = '') {
    var supplierId = $scope.selectedSupplierId ? $scope.selectedSupplierId : $scope.supplier_Id;
    if($scope.supplierIntegrationStatus && supplierId) {
      var spn = [];
      if(partsId) {
        var part = $scope.defaultPartsData[partsId];

        if (part['supplierpartnumber'] !== '' && part['supplierpartnumber'] !== null) {
          spn.push(part['supplierpartnumber']);
        }
      } else {
        angular.forEach($scope.spreadSheetParts, function (item) {
          if (item['partsId']) {
            var part = $scope.defaultPartsData[item['partsId']];

            if (part['supplierpartnumber'] !== '' && part['supplierpartnumber'] !== null) {
              spn.push(part['supplierpartnumber']);
            }
          }
        });
      }
      spn = _.uniq(spn);
      if (spn.length > 0) {
        $scope.supplierPricesLoading = spn;
        $scope.instanceLoad[$scope.partHotId].render();

        $http.get(prefix + '/get_integration_parts_prices?supplierId='+supplierId+'&spn='+spn.join(',')).success(function(resp) {
          var livePrices = resp.productPrice;
          $scope.supplierPriceFetchError = '';

          angular.forEach($scope.spreadSheetParts, function (item, row) {
          var partsId = item['partsId'];
          var part = $scope.defaultPartsData[partsId];

          if (part && part['supplierpartnumber'] !== '' && part['supplierpartnumber'] !== null) {
            // applying supplier integration price on the spreadsheet
            var suppPrice = _.find(livePrices,{productCode: part['supplierpartnumber']});

            if(suppPrice['sellingPrice']) {
              $scope.spreadSheetParts[row]['unitCost'] = suppPrice['sellingPrice'];
              $scope.defaultPartsData[partsId]['price'] = suppPrice['sellingPrice'];
              $scope.defaultPartsData[partsId]['realtimepricing'] = true;
            }

            if(suppPrice['reasonForNoSellingPrice']) {
              $scope.defaultPartsData[partsId]['realtimepricing'] = suppPrice['reasonForNoSellingPrice'];
            }
          }

        });
        $scope.supplierPricesLoading = [];
        $scope.instanceLoad[$scope.partHotId].render();

        }).error(function(error){
          $scope.supplierPriceFetchError = error.error;
          $scope.supplierPricesLoading = [];
          $scope.instanceLoad[$scope.partHotId].render();
        });

      }
    }
  }

  $scope.spreadSheetValidation = function(saveRecords, isSpreadSheetEmpty, limit) {
    $scope[isSpreadSheetEmpty] = true;

    //# Relax the cell validation.
    if(screenType == 'new_supplier_return_credit_note' || screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note'){
      var releaseCells = [5,8,9];
    }else if((screenType == 'new_purchase_order_parts' || screenType == 'edit_purchase_order_parts' || screenType == 'new_purchase_order_parts_prepopulated') && $rootScope.moduleDetails['Stock'] == 1){
      var releaseCells = [];
    }else{
      var releaseCells = [8];
    }


    for(var i=0; i< limit; i++) {

      //# Get the single row details
      var row = saveRecords[i];

      //# j=1 assigned to avoid the first column of 'primaryID' of the row.
      //# length-1 used to avoid the last column of 'delete' of the row
      for(var j=1; j<row.length-1; j++ ) {

        //# Relax the validation for particular cell
        var cellRelax = releaseCells.indexOf(j);

        //# Cell all the cells are valid or not
        if((row[j] === '' || row[j] == null) && cellRelax == -1 ) {
          $scope.validateCell=true;
          break;
        } else {
          $scope.validateCell=false;
        }
      }
      //# If any cols is invalid then break the loop.
      if($scope.validateCell == true) {
        $scope[isSpreadSheetEmpty]=true;
        break;
      } else {
        $scope[isSpreadSheetEmpty]=false;
      }
    }
  }

  /*** After change action from item spreadsheet ***/
  $scope.itemsAfterChange = function(changes, src) {

    /*** Load the spreadsheet Instance record to alter the spreadsheet ***/
    $scope.instanceLoad[$scope.itemHotId] = this;

    /*** If the current src is edit mode, then call the insert function. ***/
    if(src === 'edit') {
      $scope.insertRow(this, changes, src);

      if($scope.isErrorReturn) {
        var row = this.getActiveEditor().row;
        $scope.removeErrorMessage(row, $scope.itemHotId, 'errorRowList');
      }
    }
    //# Load an empty default data during edit section
    else if((src === 'loadData' && $scope.spreadSheetItems.length)) {
      var recordLength = $scope.spreadSheetItems.length-1;
      //# Check the last record having id value
      if($scope.spreadSheetItems[recordLength].id != '') {
        this.alter('insert_row', this.countRows());
        this.render();
       }
       else if((screenType == 'new_supplier_credit_note' || screenType == 'new_supplier_return_credit_note') && $scope.spreadSheetItems[recordLength].itemDescription != ''){
        this.alter('insert_row', this.countRows());
        this.render();
      }

      $timeout(function() {
        $scope.scrollingSpreadsheet($scope.itemHotId, $scope.instanceLoad[$scope.itemHotId]);
      }, 1000);
    }

    if(screenType == 'new_job_purchase_order_items_prepopulated'){
        var validationCondition = $scope.spreadSheetItems.length > 0;
        var emptyRow = false;
    }else{
        var validationCondition = $scope.spreadSheetItems.length >= 1;
        var emptyRow = true;
    }

    //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
    if(validationCondition) {

      $scope.itemsSaveRecord = this.getData();
        if(emptyRow == true){
            var limit = $scope.itemsSaveRecord.length-1;
        }else if(emptyRow == false){
            var limit = $scope.itemsSaveRecord.length;
        }

      //# Validate each cell from the row to enable the save button
      $scope.spreadSheetValidation($scope.itemsSaveRecord, $scope.itemHotId, limit);
    }
    var colData = this.getDataAtCol(1);
    $scope.itemRowCount = $scope.NonEmptyRowsCount(colData);
  }

  /*** Get the total ***/
  $scope.getTotal = function(instance, row, value, spreadSheetType) {
    var calculation = 0;
    if(spreadSheetType == 'parts') {
      $scope.qty = $scope.spreadSheetParts[row]['quantity'];
      calculation = parseFloat(value * $scope.qty);

      //# Re-assign the unit cost with two decimal point
      //# Re-assign the unit cost with two decimal point
      $scope.spreadSheetParts[row]['unitCost'] = value;
      if($scope.vatType == 'inc_vat'){
          $scope.spreadSheetParts[row]['totalCost'] = round(parseFloat(calculation/(1 + ($scope.spreadSheetParts[row]['vatRate']/100))),2);
      }else{
          $scope.spreadSheetParts[row]['totalCost'] = round(calculation,2);
      }

      if(typeof $scope.spreadSheetParts[row]['totalCostVat'] == 'undefined') {
        $scope.spreadSheetParts[row]['totalCostVat'] = 0.00;
      }
    }
    else if(spreadSheetType == 'items') {
      $scope.qty = $scope.spreadSheetItems[row]['quantity'];
      calculation = parseFloat(value * $scope.qty);

      //# Re-assign the unit cost with two decimal point
      $scope.spreadSheetItems[row]['unitCost'] = value;
      if($scope.vatType == 'inc_vat'){
        $scope.spreadSheetItems[row]['totalCost'] = round(parseFloat(calculation/(1 + ($scope.spreadSheetItems[row]['vatRate']/100))),2);
      }else{
        $scope.spreadSheetItems[row]['totalCost'] = round(calculation,2);
      }

      if(typeof $scope.spreadSheetItems[row]['totalCostVat'] == 'undefined') {
        $scope.spreadSheetItems[row]['totalCostVat'] = 0.00;
      }
    }
    $scope.sumTotal();
    return value;
  }

  /*** Get the total vat details ***/
  $scope.getTotalVat = function(instance, row, col, value, spreadSheetType) {
    var vatCalculation = 0;

    $scope.totalCost = instance.getDataAtRowProp(row, "totalCost");
    var totalCost = round(parseFloat($scope.totalCost),2);
    $scope.value = parseFloat(value);
    var unitCost = parseFloat(instance.getDataAtCell(row,3));
    var quantity = parseFloat(instance.getDataAtCell(row,2));

    if($scope.vatType == 'inc_vat'){
        vatCalculation = round(parseFloat(unitCost*quantity),2) - totalCost;
    }else{
        vatCalculation = (totalCost * $scope.value)/100;
    }

    if(spreadSheetType == 'parts') {
      $scope.spreadSheetParts[row]['totalCostVat'] = round(parseFloat(vatCalculation),2);
    }
    else if(spreadSheetType == 'items') {
      $scope.spreadSheetItems[row]['totalCostVat'] = round(parseFloat(vatCalculation),2);
    }

    $scope.sumTotal();
    return value;
  }

  /*** Calculate the total spreadsheet records ***/
  $scope.sumTotal = function() {
    var breakdown_by_vat_rates = {}, vat_cost;
    $scope.total_cost = 0.00, $scope.partSpreadsheetSubTotal = 0.00, $scope.itemSpreadsheetSubTotal = 0.00;
    $scope.total_VAT = 0.00;
    $scope.grand_total = 0.00;

    //# Calculate the parts spreadsheet
    for(var i = 0, l = $scope.spreadSheetParts.length; i < l; i++) {
      if(typeof $scope.spreadSheetParts[i]["totalCost"] != 'undefined') {
        $scope.partSpreadsheetSubTotal += parseFloat($scope.spreadSheetParts[i]["totalCost"]);
        $scope.total_VAT += parseFloat($scope.spreadSheetParts[i]["totalCostVat"]);

        if($scope.spreadSheetParts[i]["taxItemId"] != 0 && $scope.spreadSheetParts[i]["partsId"])
        {
            vat_cost = (typeof breakdown_by_vat_rates[$scope.spreadSheetParts[i]["vatRate"]] == 'undefined') ? 0 : breakdown_by_vat_rates[$scope.spreadSheetParts[i]["vatRate"]];
            breakdown_by_vat_rates[$scope.spreadSheetParts[i]["vatRate"]]  = vat_cost +  parseFloat($scope.spreadSheetParts[i]["totalCostVat"]) ;
        }
      }
    }

    //# Calculate the items spreadsheet
    for(var j = 0, m = $scope.spreadSheetItems.length; j < m; j++) {
      if(typeof $scope.spreadSheetItems[j]["totalCost"] != 'undefined') {
        $scope.itemSpreadsheetSubTotal += parseFloat($scope.spreadSheetItems[j]["totalCost"]);
        $scope.total_VAT += parseFloat($scope.spreadSheetItems[j]["totalCostVat"]);

        if($scope.spreadSheetItems[j]["taxItemId"] != 0 && $scope.spreadSheetItems[j]["itemDescription"])
        {
            vat_cost = (typeof breakdown_by_vat_rates[$scope.spreadSheetItems[j]["vatRate"]] == 'undefined') ? 0 : breakdown_by_vat_rates[$scope.spreadSheetItems[j]["vatRate"]];
            breakdown_by_vat_rates[$scope.spreadSheetItems[j]["vatRate"]]  = vat_cost +  parseFloat($scope.spreadSheetItems[j]["totalCostVat"]) ;
        }
      }
    }

    $scope.total_cost = round(parseFloat($scope.partSpreadsheetSubTotal) + parseFloat($scope.itemSpreadsheetSubTotal),2);
    $scope.total_VAT = round(parseFloat($scope.total_VAT),2);
    $scope.grand_total = round((parseFloat($scope.total_VAT) + parseFloat($scope.total_cost)),2);
    $scope.total_VAT = round($scope.total_VAT, 2);
    $scope.partSpreadsheetSubTotal = round($scope.partSpreadsheetSubTotal,2);
    $scope.itemSpreadsheetSubTotal = round($scope.itemSpreadsheetSubTotal,2);
    $scope.breakdown_by_vat_rates = breakdown_by_vat_rates;

  }

  $scope.deleteAction = function(r, c, hotId, spreadSheetType) {
    if(hotId == 'part_edit_purchase_order' && $scope.selectedTypes == 'customers'){
      /** check whether part line item can be deleted **/
      var partid = $scope.spreadSheetParts[r].partsId;
      var poId = $scope.poData.poid;

      $scope.checkPartDelete(poId, partid).then(function (response){

        var statusWarning = response.data.warning;
        var lastItemWarning = $scope.spreadSheetParts.length == 1 &&  $scope.spreadSheetItems.length <= 1;
        var warning = statusWarning || lastItemWarning;
        var message;

        if(statusWarning){
          message = response.data.message;
        }else if(lastItemWarning){
          message = $translate('Cannot.Delete.Lastitem.in.Po');
        }

        if(warning == true){
          warningModal.clean('supplier_po_error');
          warningModal.show(message, 'Delete purchase order', 'supplier_po_error');
        }else{
          var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
          if(c === ($scope.instanceLoad[hotId].getInstance().countCols() -1)) {
            //# Before delete confirm
            confirmationBoxHelper.getConfirmation(confirmation_message, $scope)
                .then(function() {

                  //# Get the primary id of the edit screen, to delete from backend
                  var rowId=$scope.instanceLoad[hotId].getSourceDataAtRow(r);
                  if((typeof rowId != 'undefined') && rowId.id) {
                    $scope.rowToDelete.push(rowId.id);
                  }

                    if(typeof $scope.selectedPartIds != 'undefined' && Array.isArray($scope.selectedPartIds) &&  $scope.selectedPartIds.length > 0)
                    {
                        if(screenType == 'new_supplier_return_credit_note' || screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note'){
                            var partIndex = $scope.selectedPartIds.indexOf(parseInt(rowId.returnId));
                            $scope.selectedPartIds.splice(partIndex,1);
                        }else{
                            var partIndex = $scope.selectedPartIds.indexOf(parseInt(rowId.partsId));
                            $scope.selectedPartIds.splice(partIndex,1);
                        }
                    }

                  $scope.instanceLoad[hotId].alter('remove_row', r);
                  $scope.instanceLoad[hotId].render();

                  // count number of non empty rows to show
                  var ColData = $scope.instanceLoad[hotId].getDataAtCol(1);

                  //# Get the scope name for its spreadsheet.
                  if(spreadSheetType == 'part') {
                    var scopeValue = 'partsSaveRecord', emptySpreadsheetScope = hotId;
                    $scope.partRowCount = $scope.NonEmptyRowsCount(ColData);
                    if(screenType == 'new_purchase_order_parts_prepopulated' || ((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice') && $scope.selectedTypes == 'customers')){
                      var emptyRow = false;
                    }else{
                      var emptyRow = true;
                    }
                  } else {
                    if(screenType == 'new_job_purchase_order_items_prepopulated'){
                      var emptyRow = false;
                    }else{
                      var emptyRow = true;
                    }
                    var scopeValue = 'itemsSaveRecord', emptySpreadsheetScope = hotId;
                    $scope.itemRowCount = $scope.NonEmptyRowsCount(ColData);
                  }
                  //# Assign with dynamic scope value
                  $scope[scopeValue] = $scope.instanceLoad[hotId].getData();
                  if(emptyRow == true){
                    var limit = $scope[scopeValue].length-1;
                  }else if(emptyRow == false){
                    var limit = $scope[scopeValue].length;
                  }

                  //# Validate each cell from the row to enable the save button
                  $scope.spreadSheetValidation($scope[scopeValue], emptySpreadsheetScope, limit);
                  $scope.sumTotal();

                  //# Check if last row is not empty, then create an empty row.
                  var lastRow = $scope.instanceLoad[hotId].countRows() - 1;
                  if($scope[scopeValue][lastRow][1] != '' && (hotId != 'part_edit_purchase_order' || (hotId == 'part_edit_purchase_order' && $scope.selectedTypes != 'customers'))) {
                    $scope.instanceLoad[hotId].alter('insert_row', $scope.instanceLoad[hotId].countRows());
                    $scope.instanceLoad[hotId].render();
                  }
                }, function() {
                  return false
                });
          }
        }
      });
    }else if(screenType == 'edit_purchase_order_parts' && $rootScope.moduleDetails['Stock'] === 1 && $scope.spreadSheetParts.length && $scope.spreadSheetParts[r]['markedAsArrived'] === 1 &&  $scope.selectedTypes == 'suppliers'){
      warningModal.clean('purchase_order_part_marked_as_available');
      warningModal.show('Cannot.Delete.Available.Part.in.Po', 'Delete purchase order', 'purchase_order_part_marked_as_available');
    }else{
      var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
      if(c === ($scope.instanceLoad[hotId].getInstance().countCols() -1)) {
        //# Before delete confirm
        confirmationBoxHelper.getConfirmation(confirmation_message, this)
            .then(function() {

              //# Get the primary id of the edit screen, to delete from backend
              var rowId=$scope.instanceLoad[hotId].getSourceDataAtRow(r);
              if((typeof rowId != 'undefined') && rowId.id) { console.log('del')
                $scope.rowToDelete.push(rowId.id);
              }

                if(typeof $scope.selectedPartIds != 'undefined' && Array.isArray($scope.selectedPartIds) &&  $scope.selectedPartIds.length > 0)
                {
                    if(screenType == 'new_supplier_return_credit_note' || screenType == 'new_supplier_credit_note' || screenType == 'edit_supplier_credit_note'){
                        var partIndex = $scope.selectedPartIds.indexOf(parseInt(rowId.returnId));
                        $scope.selectedPartIds.splice(partIndex,1);
                    }else{
                        var partIndex = $scope.selectedPartIds.indexOf(parseInt(rowId.partsId));
                        $scope.selectedPartIds.splice(partIndex,1);
                    }
                }

              $scope.instanceLoad[hotId].alter('remove_row', r);
              //$scope.selectedInvoices.splice(r,1);
              $scope.instanceLoad[hotId].render();

              // count number of non empty rows to show
              var ColData = $scope.instanceLoad[hotId].getDataAtCol(1);

              //# Get the scope name for its spreadsheet.
              if(spreadSheetType == 'part') {
                var scopeValue = 'partsSaveRecord', emptySpreadsheetScope = hotId;
                $scope.partRowCount = $scope.NonEmptyRowsCount(ColData);
                if(screenType == 'new_purchase_order_parts_prepopulated' || ((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice') && $scope.selectedTypes == 'customers')){
                  var emptyRow = false;
                }else{
                  var emptyRow = true;
                }
              } else {
                if(screenType == 'new_job_purchase_order_items_prepopulated'){
                  var emptyRow = false;
                }else{
                  var emptyRow = true;
                }
                var scopeValue = 'itemsSaveRecord', emptySpreadsheetScope = hotId;
                $scope.itemRowCount = $scope.NonEmptyRowsCount(ColData);
              }
              //# Assign with dynamic scope value
              $scope[scopeValue] = $scope.instanceLoad[hotId].getData();
              if(emptyRow == true){
                var limit = $scope[scopeValue].length-1;
              }else if(emptyRow == false){
                var limit = $scope[scopeValue].length;
              }

              //# Validate each cell from the row to enable the save button
              $scope.spreadSheetValidation($scope[scopeValue], emptySpreadsheetScope, limit);
              $scope.sumTotal();

              //# Check if last row is not empty, then create an empty row.
              var lastRow = $scope.instanceLoad[hotId].countRows() - 1;
              if($scope[scopeValue][lastRow][1] != '' && (hotId != 'part_edit_purchase_order' || (hotId == 'part_edit_purchase_order' && $scope.selectedTypes != 'customers'))) {
                $scope.instanceLoad[hotId].alter('insert_row', $scope.instanceLoad[hotId].countRows());
                $scope.instanceLoad[hotId].render();
              }
            }, function() {
              return false
            });
      }
    }

  }

  $scope.tabMoves= function (event) {
    if(event.shiftKey) {
      return {row: 2, col: 1};
    }
    else {
      return {row: 0, col: 1};
    }
  }

  /** Check the cell validation ***/
  $scope.cellValidator = function (value, callback) {
    if(value != '') {
      callback(true);
    } else {
      toastBox.show('Empty cell not allowed', 1000);
      callback(false);
    }
  }

  //# Check the validation once hit enter from cell.
  $scope.beforeValidate = function(value, row, prop, source) {
    if(value != '') {
      var activeEditor = this.getActiveEditor(),
          col= activeEditor.col,
          hotId = this.getSettings().hotId,
          cellType = activeEditor.cellProperties.type;

      switch(prop) {
        case 'quantity':
              $scope.validationWithLimit(value, row, col, prop, hotId, 6, cellType);
              break;
        case 'unitCost':
              $scope.validationWithLimit(value, row, col, prop, hotId, 10, cellType);
              break;
        case 'salesPrice':
          $scope.validationWithLimit(value, row, col, prop, hotId, 10, cellType);
          break;
      }
    }
  }

  //# Check validation with limit
  $scope.validationWithLimit = function(value, row, col, prop, hotId, limit, cellType) {

    if(cellType == 'numeric' && isNaN(value)) {
      toastBox.show('Invalid data', 1000);

      //# Disable the save button.
      $scope[hotId] = true; return false;
    } else {
      var convertToString = value.toString();
      var splitToArray = convertToString.split(".");
      if (splitToArray[0].length > limit) {
        $scope.instanceLoad[hotId].setCellMeta(row, col, "className", "errorRowList");
        toastBox.show('Invalid data length', 1000);

        //# Disable the save button.
        $scope[hotId] = true; return false;
      } else {
        $scope.instanceLoad[hotId].setCellMeta(row, col, "className", "");
        //# Disable the save button.
        $scope[hotId] = false;
      }
    }
    $scope.instanceLoad[hotId].render();
  }

  /*** If edit the last row then need to insert a new row with default row ***/
  $scope.insertRow = function(currentScope,changes, src) {
    var description = currentScope.getDataAtRow(currentScope.countRows()-1);
    if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && (src === 'edit') && (description[1] != '')) {
      currentScope.alter('insert_row', currentScope.countRows());
      $scope.scrollToLastRow(currentScope.getActiveEditor().row, currentScope.getActiveEditor().col, currentScope);
    }
  }

  /*** Check the valid html tag ***/
  $scope.strip_tags = function(input, allowed) {
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

    // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

    return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
      return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
  }

  /*** Get the current active editor and validate ***/
  $scope.checkActiveEditor = function(instance, col, row, checkCol, checkRow) {
    var activeEditor = instance.getActiveEditor();

    if(typeof activeEditor != 'undefined' && activeEditor.row == row) {
      var cellTitle = instance.getCellMeta(activeEditor.row, activeEditor.col);
      var cellCase = cellTitle.prop;

      //# Used to update the default quantity/unit cost for changing the parts.
      var data = instance.getData();
      if(data[row][0] === '' && cellCase === 'partsId') {
        return true;
      }
    }
    return false;
  }

  /*** Once select the value from dropdown update the value in spreadsheet line items ***/
  $scope.alterSave = function(value, dropDownType) {
    
    $scope.isItemSpreadsheet = false;
    $scope.isPartSpreadsheet = false;
    $scope.newSelectedValue = '';
    
    switch(dropDownType) {
      case "nominalCodeId" :
        if(screenType == 'new_purchase_order_parts_prepopulated'){
            $scope.isItemSpreadsheet = false;
        }else{
            $scope.isItemSpreadsheet = true;
        }
        if((screenType == 'new_supplier_invoice' && $scope.selectedTypes == 'customers') || screenType == 'new_job_purchase_order_items_prepopulated'){
          $scope.isPartSpreadsheet = false;
        }else{
          $scope.isPartSpreadsheet = true;
        }
        $scope.newSelectedValue = value;
      break;

      case "deliveryDate" :

        $scope.isPartSpreadsheet = true;

          if(CS_LANGUAGE === 'en-GB') {
              $scope.newSelectedValue = moment(value).format("DD/MM/YYYY");
          }else if(CS_LANGUAGE === 'en-US'){
              $scope.newSelectedValue = moment(value).format("MM/DD/YYYY");
          }else {
              $scope.newSelectedValue = moment(value).format("DD/MM/YYYY");
          }

      break;

      case "deliveryMethod" :
        $scope.isPartSpreadsheet = true;
        $scope.newSelectedValue = value;
      break;

      case "salesPrice" :
          if(typeof value != 'undefined') {
            $scope.isItemSpreadsheet = true;
            $scope.newSelectedValue = value != ''? value : 0.00;
          }
      break;
    }

    if($scope.isPartSpreadsheet) {
      $scope.spreadSheetParts = $scope.reLoadSpreadsheet($scope.spreadSheetParts, dropDownType, $scope.newSelectedValue, $scope.partHotId);

      //# Validate and enable the save button
      $scope.partsSaveRecord = $scope.instanceLoad[$scope.partHotId].getData();
      if(screenType == 'new_purchase_order_parts_prepopulated' || ((screenType == 'edit_purchase_order_parts' || screenType == 'complete_purchase_order' || screenType == 'edit_supplier_invoice') && $scope.selectedTypes == 'customers')){
        var limit = $scope.partsSaveRecord.length;
      }else{
        var limit = $scope.partsSaveRecord.length-1;
      }
      $scope.spreadSheetValidation($scope.partsSaveRecord, $scope.partHotId, limit);

      /*** Replace the default schema value, when new screen it will automatically update it ***/
      $scope.partDataSchema[dropDownType]=$scope.newSelectedValue;

      $scope.instanceLoad[$scope.partHotId].updateSettings({dataSchema: $scope.partDataSchema});
      $scope.instanceLoad[$scope.partHotId].render();
    }

    if($scope.isItemSpreadsheet) {
      $scope.spreadSheetItems = $scope.reLoadSpreadsheet($scope.spreadSheetItems, dropDownType, $scope.newSelectedValue, $scope.itemHotId);

      //# Validate and enable the save button
      $scope.itemsSaveRecord = $scope.instanceLoad[$scope.itemHotId].getData();
      if(screenType == 'new_job_purchase_order_items_prepopulated' || screenType == 'edit_purchase_order_parts_estimatedCost'){
        var limit = $scope.itemsSaveRecord.length;
      }else{
        var limit = $scope.itemsSaveRecord.length-1;
      }

      $scope.spreadSheetValidation($scope.itemsSaveRecord, $scope.itemHotId, limit);

      /*** Replace the default schema value, when new screen it will automatically update it ***/
      if(dropDownType != 'salesPrice') {
        $scope.itemDataSchema[dropDownType]=$scope.newSelectedValue;
        $scope.instanceLoad[$scope.itemHotId].updateSettings({dataSchema: $scope.itemDataSchema});
      }
      $scope.instanceLoad[$scope.itemHotId].render();
    }
  }

  $scope.updateStateProvinceTaxRates = function (taxItems,isUpdateLintItem) {
    $scope.getVatGroupByProvinces(taxItems,$scope.deletedTaxItems);

    if(typeof $scope.partColumns != "undefined")
    {
      if(isUpdateLintItem)
      {
        angular.forEach($scope.spreadSheetParts, function (value,key) {
          value.taxItemId = 1;
        });
      }
      $scope.partColumns[4]['select2Options']['data'] = $scope.taxItemsGroupByProvinces;
      $scope.instanceLoad[$scope.partHotId].updateSettings({column: $scope.partColumns});
      $scope.instanceLoad[$scope.partHotId].render();
    }

    if(typeof $scope.itemColumns != "undefined")
    {
      if(isUpdateLintItem)
      {
        angular.forEach($scope.spreadSheetItems, function (value,key) {
          value.taxItemId = 1;
        });
      }
      $scope.itemColumns[5]['select2Options']['data'] = $scope.taxItemsGroupByProvinces;
      $scope.instanceLoad[$scope.itemHotId].updateSettings({column: $scope.itemColumns});
      $scope.instanceLoad[$scope.itemHotId].render();
    }
  };

  /*** Reload the spreadsheet data with hotId ***/
  $scope.reLoadSpreadsheet = function(spreadsheet, key, newValue, hotId) {

    for(var i = 0, l = spreadsheet.length; i < l; i++) {
      if(typeof spreadsheet[i][key] != 'undefined') {

        //# Remove the backend error CSS class name.
        $scope.removeErrorMessage(i, hotId, 'errorRowList');

        /*** Need to sum with average with new value only for sales price ***/
        if(key == "salesPrice") {
          $scope.calculation = (parseFloat(spreadsheet[i]['unitCost']) * parseFloat(newValue))/100;
          $scope.newCalculation = parseFloat(spreadsheet[i]['unitCost']) + parseFloat($scope.calculation);
          $scope.newCalculation = round($scope.newCalculation,4);
          spreadsheet[i][key] = $scope.newCalculation;
        } else {
          spreadsheet[i][key] = newValue;
        }
      }
    }
    return spreadsheet;
  }

  /*** Display the error rows ***/
  $scope.spreadSheetErrorRows = function(partInvalidRows, itemInvalidRows) {
    $scope.partScope = [];
    for(var i=0; i<partInvalidRows.length; i++) {
      var currentRow = partInvalidRows[i];
      $scope.displayErrorRows(currentRow, $scope.partHotId);
    }

    for(var j=0; j<itemInvalidRows.length; j++) {
      var currentRow = itemInvalidRows[j];
      $scope.displayErrorRows(currentRow, $scope.itemHotId);
    }
  }

  /** Display the error row line items in spreadsheet **/
  $scope.displayErrorRows = function(currentRow, hotId) {
    var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(currentRow);

    for(var j=1; j<currentMetaRow.length; j++ ) {
      $scope.instanceLoad[hotId].setCellMeta(currentRow,j,"className","errorRowList");
    }
    //$scope.instanceLoad[hotId].setCellMeta(currentRow,j,"className","errorRowList");
    $scope.instanceLoad[hotId].render();
  }

  /** Remove the error class from the line item **/
  $scope.removeErrorMessage = function(row, hotId, toRemove) {
    var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(row);
    var reg = new RegExp('(\\s|^)'+toRemove+'(\\s|$)');

    for(var j=0; j<currentMetaRow.length; j++ ) {
      var newCssClass = currentMetaRow[j].className;

      if(newCssClass) {
        newCssClass=newCssClass.replace(reg,'');
        $scope.instanceLoad[hotId].setCellMeta(row,j,"className", newCssClass);
      }
    }
    $scope.instanceLoad[hotId].render();
  }

  // Update the corresponding details in all line items in spreadsheet
  $scope.$on('default_update_spreadsheet_value', function(event, message){
    $scope.alterSave(message.value, message.dropDownType);
  });

  $scope.handleCollapse = function handleCollapse(e, section) {
    if(section == 'part'){
      $scope.part_section_open = !$scope.part_section_open;
    }else if(section == 'item'){
      $scope.item_section_open = !$scope.item_section_open;
    }

  }

  $scope.NonEmptyRowsCount = function NonEmptyRowsCount(colData){
    var nonEmptyRowsCount = colData.reduce(function (count, data) {
      return $scope.isColEmpty(data) ? count : count + 1;
    }, 0);
    return nonEmptyRowsCount;
  }
  $scope.isColEmpty = function isColEmpty(value){
    return typeof value == 'undefined' || value === null || value.length === 0;
  }

  $scope.changevatType = function changevatType(selectedVat){
    //$scope.vatType = selectedVat;
    $scope.getModifiedVat();
    $scope.sumTotal();
  }

  $scope.getModifiedVat = function getModifiedVat(){
      /***Render parts spreadsheet***/
    if($scope.spreadSheetParts.length > 0){
      $scope.instanceLoad[$scope.partHotId].updateSettings({data: $scope.spreadSheetParts});
      $scope.instanceLoad[$scope.partHotId].render();
    }
      /***Render item spreadsheet***/
    if($scope.spreadSheetItems.length > 0){
      $scope.instanceLoad[$scope.itemHotId].updateSettings({data: $scope.spreadSheetItems});
      $scope.instanceLoad[$scope.itemHotId].render();
    }
  }

  $scope.checkPartDelete = function checkPartDelete(poId, partid){
    return $http.get(prefix + '/check_part_available?poid=' + poId + '&partid= ' + partid).success(function (data){
      var result = data;
    });
  }
}

function spreadsheetScroll($scope) {

//# After scroll vertical without change need to update the class name
  $scope.scrollingSpreadsheet = function(hotId, instance) {
    //# Update the class name after spreadsheet loaded.
      instance.updateSettings({className: 'overflow_handsontable'});
      instance.render();
  }

  //# Used to scroll to the last row
  $scope.scrollToLastRow = function(row, col, instance) {
    if(row == instance.countRows()-2) {
      instance.scrollViewportTo(instance.countRows()-1);
    }
  }

  //# Update the column header with required field
  $scope.afterGetColHeader = function(col, TH) {
    if($scope.colHeadersRequired[this.getSettings().hotId][col]) {
      TH.className='col_header_bg';
    }
  }
}

function supplierAllocationSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, toastBox, $timeout)
{
  allocationCommonLogic.call(this, $scope, confirmationBoxHelper, toastBox, $timeout);

  //* Load the default data into the spreadsheet
  $scope.invoiceAfterInit = function() {

    //* Get the default handsontable settings
    $scope.updateSettings = this.updateSettings;
    $scope.supplier_Id = $state.params.id;

    $http.get($scope.getListUrl).success(function(response) {

      $scope.colHeadersInvoices = response.colHeaders;
      $scope.colHeadersRequired[$scope.allocationHotId] =response.colHeadersRequired;
      $scope.startColsInvoices = response.startColsInvoices;
      $scope.spreadSheetInvoice = response.spreadSheetInvoice;
      // $scope.invoiceNumberDropDown = response.invoiceNumberDropDown;
      $scope.invoiceNumberDropDown = [];
      // $scope.defaultInvoiceDetails = response.defaultInvoiceDetails;
      $scope.defaultInvoiceDetails = [];
      $scope.defaultInvoiceCount = response.defaultInvoiceCount;
      $scope.readOnlyInvoice = response.readOnlyInvoice;
      $scope.selectedInvoices = [];
      for(var row=0; row < $scope.spreadSheetInvoice.length; row++){
        $scope.selectedInvoices.push($scope.spreadSheetInvoice[row]['invoiceNumber']);
      }

      /*** Calculation the Total section ***/
      $scope.allocationSumTotal();

      //* Load the default value when new row created
      $scope.invoiceDataSchema= {id: '', invoiceDate: '', invoiceNumber: '', poNumber: '', invoiceAmount: 0.00, amountPaid: 0.00,
        amountToAllocate: 0.00, remainderAmount: 0.00, remainderToPay: 0.00, deleteAction: ""};
      $scope.invoiceColWidths = [1,120,150,200,140,140,140,140,140,70];

      //* Assign the spreadsheet columns and its cell type
      $scope.invoiceColumns = [
        {data: 'id', type: 'numeric', readOnly: true},
        {data: 'invoiceDate', type: 'text', readOnly: true, disableVisualSelection: true},
        {
          renderer:$scope.invoiceRenderer,
          className: 'overflow_handsontable select_icon',
          data: 'invoiceNumber',
          editor: 'select2',
          placeholder: 'Please select',
          validator: $scope.cellValidator,
          select2Options: {
            editable: true,
            //minimumInputLength:2, //COM-15971
            dropdownCssClass: "bigdrop",
            formatLoadMore: function(){
              return ' Loading...';
            },
            ajax: {
              quietMillis: 250,
              url: prefix + '/get_supplier_invoices_dropdown',
              delay: 250,
              type: 'post',
              dataType: 'json',
              data: function(query, page){
                if(_.isUndefined(query)) {
                  query='';
                }

                var params = {
                  'query': encodeURIComponent(query),
                  'supplierId': $scope.supplier_Id,
                  'selectedInvoices': $scope.selectedInvoices,
                  'page': page
                };

                //if(creditNoteId) {
                  //params.creditNoteId = creditNoteId;
                //}

                // if($state.params.paymentId) {
                //   //params.paymentId = $state.params.paymentId;
                // }
                return params;
              },
              results: function(data, page) {
                $scope.$apply(function(){
                  $scope.defaultInvoiceDetails = data.invoiceDetails;
                });
                var items = _.map(data.invoiceDetails, function(inv){ return {id:inv.invoiceNumber, text:inv.invoiceNumber}});
                if( !data.needLazyLoad && (page * 10) < data.invoiceTotalCount ) {
                  items.push({id: '0', text: 'Type to load more', disabled: true});
                }
                return {
                  results: items,
                  more: data.needLazyLoad && (page * 10) < data.invoiceTotalCount
                };
              }
            }
          }
        },
        /*{renderer: $scope.invoiceRenderer, className: 'overflow_handsontable select_icon',
          data: 'invoiceNumber',
          editor: 'select2',
          placeholder: 'Please select',
          validator: $scope.cellValidator,
          select2Options: {
            editable: true,
            data: $scope.invoiceNumberDropDown,
            dropdownAutoWidth: true,
            dropdownCssClass: "handsontable-select",
            width: 'resolve'
          }
        },*/
        {data: 'poNumber', type: 'numeric', readOnly: true, disableVisualSelection: true},
        {data: 'invoiceAmount', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
        {data: 'amountPaid', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true, validator: $scope.cellValidator,},
        {renderer: $scope.invoiceRenderer, data: 'amountToAllocate', type: 'numeric',
          allowInvalid: true, forceNumeric: true, allowEmpty: false},
        {data: 'remainderAmount', type: 'numeric', format: '0.00'},
        {data: 'remainderToPay', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
        {renderer: $scope.invoiceRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true}
      ];

      //* Column to hide in spreadsheet
      $scope.hideInvoiceColumns = {columns:[0, 7]};

      /*** Load the new settings into the spreadsheet and load them ***/
      $scope.updateSettings({colHeaders: $scope.colHeadersInvoices, fillHandle: {direction: 'vertical', autoInsertRow: true},
        rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsInvoices, afterGetColHeader: $scope.afterGetColHeader,
        colWidths: $scope.invoiceColWidths, columns: $scope.invoiceColumns, minSpareCols: 0, beforeValidate: $scope.beforeValidate,
        autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetInvoice,hiddenColumns:$scope.hideInvoiceColumns, stretchH: 'all',
        formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.invoiceDataSchema, minRows: $scope.minRows, viewportRowRenderingOffset : 1000}); /*dataSchema: $scope.dataSchema,*/

      $scope.displaySpreadsheet = true;
      $scope.show_invoice_spreadsheet = true;
    });
    $scope.section_open = true;
  }
}

function supplierCashAllocationSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, toastBox, $timeout)
{
  allocationCommonLogic.call(this, $scope, confirmationBoxHelper, toastBox, $timeout);

  //* Load the default data into the spreadsheet
  $scope.invoiceAfterInit = function() {

    //* Get the default handsontable settings
    $scope.updateSettings = this.updateSettings;
    $scope.supplier_Id = $state.params.id;
    $http.get($scope.getListUrl).success(function(response) {

      $scope.colHeadersInvoices = response.colHeaders;
      $scope.colHeadersRequired[$scope.allocationHotId] =response.colHeadersRequired;
      $scope.startColsInvoices = response.startColsInvoices;
      $scope.spreadSheetInvoice = response.spreadSheetInvoice;
      //$scope.invoiceNumberDropDown = response.invoiceNumberDropDown;
      $scope.invoiceNumberDropDown = [];
      //$scope.defaultInvoiceDetails = response.defaultInvoiceDetails;
      $scope.defaultInvoiceDetails = [];
      $scope.readOnlyInvoice = response.readOnlyInvoice;
      $scope.selectedInvoices = [];
      for(var row=0; row < $scope.spreadSheetInvoice.length; row++){
        //$scope.selectedInvoices.push($scope.spreadSheetInvoice[row]['invoiceNumber']);
      }

      /*** Calculation the Total section ***/
      $scope.allocationSumTotal();

      //* Load the default value when new row created
      $scope.invoiceDataSchema= {id: '', invoiceDate: '', invoiceNumber: '', poNumber: '', invoiceAmount: 0.00, amountPaid: 0.00,
        amountToAllocate: 0.00, remainderAmount: 0.00, remainderToPay: 0.00, selectAction: "", deleteAction: ""};
      $scope.invoiceColWidths = [1,120,140,200,140,140,140,140,140,70,70];

      //* Assign the spreadsheet columns and its cell type
      $scope.invoiceColumns = [
        {data: 'id', type: 'numeric', readOnly: true},
        {data: 'invoiceDate', type: 'text', readOnly: true, disableVisualSelection: true},
        {
          renderer: $scope.invoiceRenderer,
          className: 'overflow_handsontable select_icon',
          data: 'invoiceNumber',
          editor: 'select2',
          placeholder: 'Please select',
          validator: $scope.cellValidator,
          select2Options: {
            editable: true,
            dropdownCssClass: "bigdrop",
            formatLoadMore: function(){
              return ' Loading...';
            },
            ajax: {
              quietMillis: 250,
              url: prefix + '/get_supplier_invoices_dropdown',
              delay: 250,
              dataType: 'json',
              type: 'post',
              data: function(query, page){
                if(_.isUndefined(query)) {
                  query='';
                }

                var params = {
                  'query': encodeURIComponent(query),
                  'supplierId': $scope.supplier_Id,
                  'selectedInvoices': [],
                  'page': page,
                  'type': 'new_supplier_cash_allocation_invoices'
                };

                if($state.params.creditNoteId) {
                  //params.creditNoteId = $state.params.creditNoteId;
                }

                if($state.params.paymentId) {
                  //params.paymentId = $state.params.paymentId;
                }
                return params;
              },
              results: function(data, page) {
                $scope.$apply(function(){
                  $scope.defaultInvoiceDetails = data.invoiceDetails;
                });
                //var items = _.map(data.invoiceDetails, function(inv){ return {id:inv.invoiceNumber, text:inv.invoiceNumber}});
                var items = [];
                var existsInvoiceNumber = _.pluck($scope.spreadSheetInvoice,'invoiceNumber');
                _.forEach(data.invoiceDetails, function(item) {
                  if(!_.contains(existsInvoiceNumber, item.invoiceNumber)) {
                    items.push({id:item.invoiceNumber, text:item.invoiceNumber});
                  }
                });
                if( !data.needLazyLoad && (page * 10) < data.invoiceTotalCount ) {
                  items.push({id: '0', text: 'Type to load more', disabled: true});
                }
                return {
                  results: items,
                  more: data.needLazyLoad && (page * 10) < data.invoiceTotalCount
                };
              }
            }
          }

        },
        /*{renderer: $scope.invoiceRenderer, className: 'overflow_handsontable select_icon',
          data: 'invoiceNumber',
          editor: 'select2',
          placeholder: 'Please select',
          validator: $scope.cellValidator,
          select2Options: {
            editable: true,
            data: $scope.invoiceNumberDropDown,
            dropdownAutoWidth: true,
            dropdownCssClass: "handsontable-select",
            width: 'resolve'
          }
        },*/
        {data: 'poNumber', type: 'numeric', readOnly: true, disableVisualSelection: true},
        {data: 'invoiceAmount', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
        {data: 'amountPaid', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true, validator: $scope.cellValidator,},
        {renderer: $scope.invoiceRenderer, data: 'amountToAllocate', type: 'numeric',
          allowInvalid: true, forceNumeric: true, allowEmpty: false},
        {data: 'remainderAmount', type: 'numeric', format: '0.00'},
        {data: 'remainderToPay', type: 'numeric', readOnly: true, format: '0.00', disableVisualSelection: true},
        {renderer: $scope.invoiceRenderer, data: 'selectAction', readOnly: true, disableVisualSelection: true},
        {renderer: $scope.invoiceRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true}
      ];

      //* Column to hide in spreadsheet
      $scope.hideInvoiceColumns = {columns:[0, 7]};

      /*** Load the new settings into the spreadsheet and load them ***/
      $scope.updateSettings({colHeaders: $scope.colHeadersInvoices, fillHandle: {direction: 'vertical', autoInsertRow: true},
        rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsInvoices, afterGetColHeader: $scope.afterGetColHeader,
        colWidths: $scope.invoiceColWidths, columns: $scope.invoiceColumns, minSpareCols: 0, beforeValidate: $scope.beforeValidate,
        autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetInvoice,hiddenColumns:$scope.hideInvoiceColumns, stretchH: 'all',
        formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.invoiceDataSchema, minRows: $scope.minRows, viewportRowRenderingOffset : 1000}); /*dataSchema: $scope.dataSchema,*/

      $scope.displaySpreadsheet = true;
      $scope.show_invoice_spreadsheet = true;
    });
    $scope.section_open = true;
  }
}

function allocationCommonLogic($scope, confirmationBoxHelper, toastBox, $timeout)
{
  $scope.spreadSheetInvoice = [];
  $scope.invoiceNumberDropDown = [];
  $scope.defaultInvoiceDetails = [];
  $scope.defaultInvoiceCount = 0;
  $scope.readOnlyInvoice = [];
  $scope.isInvoiceSpreadSheetEmpty = true;
  $scope.displaySpreadsheet = false;
  $scope.allocationInstanceLoad='';
  $scope.rowToDelete= [];
  $scope.colHeadersRequired =[];
  spreadsheetScroll.call(this, $scope);

  /*** Spreadsheet default settings for both parts & supplier ***/
  $scope.rowHeaders = true;
  $scope.startRows = 1;
  /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
  $scope.minSpareRows = 0;
  $scope.minRows = 1;
  $scope.width = "fixed";
  $scope.autoWrapCol = true;
  $scope.autoWrapRow = true;

  $scope.invoiceRenderer = function(instance, td, row, col, prop, value, cellProperties) {

    var cellTitle = instance.getCellMeta(row, col);
    var cellCase = cellTitle.prop;

    //# Used to scroll to the last row
    if((typeof instance.getActiveEditor() != 'undefined') && (instance.getActiveEditor().row == row)) {
      $scope.scrollToLastRow(row, col, instance);
    }

    switch(cellCase) {
      case 'invoiceNumber':

        if($scope.readOnlyInvoice[value]) {
          if($scope.allocationHotId == 'cashAllocationSpreadSheet' && $scope.spreadSheetInvoice[row]['amountToAllocate'] != 0) {
            cellProperties.className='cashAllocation overflow_handsontable';
          } else {
            cellProperties.className='overflow_handsontable';
          }

          cellProperties.readOnly=true;
          cellProperties.disableVisualSelection= true;
        } else {
          if(_.has($scope.defaultInvoiceDetails, value) && $scope.defaultInvoiceDetails[value] && !_.contains($scope.selectedInvoices, value)) {
            $scope.spreadSheetInvoice[row]['invoiceDate'] = $scope.defaultInvoiceDetails[value]['invoiceDate'];
            $scope.spreadSheetInvoice[row]['poNumber'] = $scope.defaultInvoiceDetails[value]['poNumber'];
            $scope.spreadSheetInvoice[row]['invoiceAmount'] = $scope.defaultInvoiceDetails[value]['invoiceAmount'];
            $scope.spreadSheetInvoice[row]['amountPaid'] = $scope.defaultInvoiceDetails[value]['amountPaid'];
            if($scope.allocationHotId == 'creditNoteAllocationSpreadSheet') {
              $scope.allocateValue = 0.00, $scope.remainingToAllocate = $scope.remainingCreditAmount - $scope.amount_allocated;
              if($scope.remainingToAllocate > 0) {
                $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                if($scope.remainingToAllocate >= $scope.remainderAmounts) {
                  $scope.allocateValue = round($scope.remainderAmounts,2);
                } else if($scope.remainingToAllocate < $scope.remainderAmounts) {
                  $scope.allocateValue = round($scope.remainingToAllocate,2);
                }
              }
            }
            else {
              $scope.amountPaid = parseFloat($scope.defaultInvoiceDetails[value]['amountPaid']);
              $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
              $scope.remainderToPay = parseFloat($scope.defaultInvoiceDetails[value]['remainderToPay']);
              if(($scope.remainderAmounts == $scope.amountPaid) && ($scope.remainderToPay == 0)) {
                $scope.allocateValue = 0.00;
              } else {
                $scope.allocateValue = round($scope.remainderAmounts,2);
              }
            }
            $scope.spreadSheetInvoice[row]['amountToAllocate'] = $scope.allocateValue;
            $scope.spreadSheetInvoice[row]['remainderAmount'] = $scope.defaultInvoiceDetails[value]['remainderAmount'];
            $scope.spreadSheetInvoice[row]['remainderToPay'] = $scope.defaultInvoiceDetails[value]['remainderToPay'];

            if($scope.allocationHotId == 'cashAllocationSpreadSheet') {
              $scope.updateRowColor(row, 'cashAllocation overflow_handsontable');
            } else {
              cellProperties.className='overflow_handsontable';
            }
            cellProperties.readOnly=true;
            cellProperties.disableVisualSelection= true;

            if(!_.contains($scope.selectedInvoices, value)) {
              $scope.selectedInvoices.push(value);
            }
            //$scope.invoiceNumberDropDown.splice(m,1);
          }
          /*
          if($scope.invoiceNumberDropDown.length) {
            for (var m = 0; m < $scope.invoiceNumberDropDown.length; m++) {
              if (value === $scope.invoiceNumberDropDown[m].id) {
                $scope.spreadSheetInvoice[row]['invoiceDate'] = $scope.defaultInvoiceDetails[value]['invoiceDate'];
                $scope.spreadSheetInvoice[row]['poNumber'] = $scope.defaultInvoiceDetails[value]['poNumber'];
                $scope.spreadSheetInvoice[row]['invoiceAmount'] = $scope.defaultInvoiceDetails[value]['invoiceAmount'];
                $scope.spreadSheetInvoice[row]['amountPaid'] = $scope.defaultInvoiceDetails[value]['amountPaid'];

                //* Used calculation for to assign the allocated value while select the invoice number.
                if($scope.allocationHotId == 'creditNoteAllocationSpreadSheet') {
                  $scope.allocateValue = 0.00, $scope.remainingToAllocate = $scope.remainingCreditAmount - $scope.amount_allocated;
                  if($scope.remainingToAllocate > 0) {
                    $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                    if($scope.remainingToAllocate >= $scope.remainderAmounts) {
                      $scope.allocateValue = round($scope.remainderAmounts,2);
                    } else if($scope.remainingToAllocate < $scope.remainderAmounts) {
                      $scope.allocateValue = round($scope.remainingToAllocate,2);
                    }
                  }
                } else {
                  $scope.amountPaid = parseFloat($scope.defaultInvoiceDetails[value]['amountPaid']);
                  $scope.remainderAmounts = parseFloat($scope.defaultInvoiceDetails[value]['remainderAmount']);
                  $scope.remainderToPay = parseFloat($scope.defaultInvoiceDetails[value]['remainderToPay']);
                  if(($scope.remainderAmounts == $scope.amountPaid) && ($scope.remainderToPay == 0)) {
                    $scope.allocateValue = 0.00;
                  } else {
                    $scope.allocateValue = round($scope.remainderAmounts,2);
                  }
                }
                $scope.spreadSheetInvoice[row]['amountToAllocate'] = $scope.allocateValue;
                $scope.spreadSheetInvoice[row]['remainderAmount'] = $scope.defaultInvoiceDetails[value]['remainderAmount'];
                $scope.spreadSheetInvoice[row]['remainderToPay'] = $scope.defaultInvoiceDetails[value]['remainderToPay'];

                if($scope.allocationHotId == 'cashAllocationSpreadSheet') {
                  $scope.updateRowColor(row, 'cashAllocation overflow_handsontable');
                } else {
                  cellProperties.className='overflow_handsontable';
                }

                cellProperties.readOnly=true;
                cellProperties.disableVisualSelection= true;

                $scope.invoiceNumberDropDown.splice(m,1);
              }
            }
          }
          */
        }
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'amountToAllocate':
        //# Check and update with two decimal point in spreadsheet
        var amountToAllocate = value > 0 ? parseFloat(value) : 0;
        var allocatedAmount =0, remainderAmount =0, remainderToPay =0;
        allocatedAmount = parseFloat(amountToAllocate);
        remainderAmount = parseFloat($scope.spreadSheetInvoice[row]['remainderAmount']);
        remainderToPay = parseFloat(round(remainderAmount,2)) - parseFloat(round(allocatedAmount,2));
        $scope.spreadSheetInvoice[row]['remainderToPay'] = round(parseFloat(remainderToPay),2);
        $scope.spreadSheetInvoice[row]['amountToAllocate'] = round(allocatedAmount,2);
        $scope.allocationSumTotal();
        value =round(allocatedAmount,2);
        if($scope.allocationHotId == 'cashAllocationSpreadSheet' && allocatedAmount != 0) {
          $scope.updateRowColor(row, 'cashAllocation overflow_handsontable');
        }
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        break;
      case 'selectAction':
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        var innerText = 'Select';
        if($scope.spreadSheetInvoice[row]['amountToAllocate'] != 0) {
          var innerText = 'Unselect';
        }

        var div = document.createElement('DIV');
        var a = document.createElement('a');
        var linkText = document.createTextNode(innerText);
        a.appendChild(linkText);
        a.title = innerText+" Action";
        a.style= "cursor:pointer;"
        div.appendChild(a);
        $(td).empty().append(div).append(a);
        $(a).on('click', function(e) {
          $scope.invoiceSelectAction(row, col, innerText);
        });
        return td;
        break;
      case 'deleteAction':
        Handsontable.cellTypes.text.renderer.apply(this, arguments);
        var div = document.createElement('DIV');
        var a = document.createElement('a');
        var linkText = document.createTextNode("Delete");
        a.appendChild(linkText);
        a.title = "Delete Action";
        a.style= "cursor:pointer;"
        div.appendChild(a);
        $(td).empty().append(div).append(a);
        $(a).on('click', function(e) {
          $scope.invoiceDeleteAction(row, col);
        });
        return td;
        break;
    }
  }

  $scope.invoiceAfterChange = function(changes, src) {

    //* Load the spreadsheet Instance record to alter the spreadsheet
    $scope.allocationInstanceLoad = this;

    //* Get the current remaining to allocate amount only for credit note allocation screen.
    $scope.currentRemainingToAllocate = $scope.remainingCreditAmount - $scope.amount_allocated;
    $scope.allowInsertRow = (($scope.currentRemainingToAllocate > 0) || ($scope.allocationHotId == 'cashAllocationSpreadSheet')) ? true : false;

    //* Load an empty default data during
    //* $scope.spreadSheetInvoice[0].id checked, because in edit payment having only one record.
    if(src === 'loadData' && $scope.allowInsertRow && !_.isUndefined($scope.spreadSheetInvoice[0])) {
      //# Added condition for overdue invoice display with one empty row.
      if((($scope.spreadSheetInvoice.length > 1) || ($scope.spreadSheetInvoice[0].id != '')) || ($scope.allocationHotId == 'cashAllocationSpreadSheet' && $scope.spreadSheetInvoice[0].invoiceAmount != 0)) {
        this.alter('insert_row', this.countRows());
        this.render();
      }
    }else if(src === 'edit' && $scope.allowInsertRow) {
      $scope.insertRowInvoice(this, changes, src);
    }

    if((src === 'loadData') && $scope.spreadSheetInvoice.length) {
      $timeout(function() {
          $scope.scrollingSpreadsheet($scope.allocationHotId, $scope.allocationInstanceLoad);
      }, 1000);
    }

    //* Check with custom scope value validation
    for(var x=0; x<$scope.spreadSheetInvoice.length-1; x++) {
      if(this.getDataAtRowProp(x, "invoiceNumber") === '') {
        $scope.validateCell=true;
        break;
      } else {
        $scope.validateCell=false;
      }
    }

    //* If any cols is invalid then break the loop.
    if($scope.validateCell == true) {
      $scope.isInvoiceSpreadSheetEmpty=true;

    } else {
      $scope.isInvoiceSpreadSheetEmpty=false;
    }

    //# Move the cell to next cell or next row
    if(src == 'edit') {
      var activeEditor = this.getActiveEditor(),
          currentRow = activeEditor.row,
          totalRows = this.countRows()-1,
          moveRow = currentRow +1;

      if((activeEditor.prop == 'amountToAllocate') && (totalRows >= moveRow)) {
        //# In edit screen the invoice number is editable
        if(this.getCellMeta(moveRow, 2).readOnly) {
          this.selectCell(moveRow, 6);
        } else {
          this.selectCell(moveRow, 2);
        }
      }
    }

    //* Check with custom scope value validation
    var colData = this.getDataAtCol(1);
    $scope.nonEmptyRowsCount = $scope.NonEmptyRowsCount(colData);

    $scope.allocationInstanceLoad.render();
  }

  //# Check the validation once hit enter from cell.
  $scope.beforeValidate = function(value, row, prop, source) {
    if(value != '') {
      var activeEditor = this.getActiveEditor(),
          col= activeEditor.col,
          hotId = this.getSettings().hotId,
          cellType = activeEditor.cellProperties.type;

      if(prop == 'amountToAllocate') {
        $scope.validationWithLimit(value, row, col, prop, hotId, 10, cellType);
      }
    }
  }

  $scope.tabMoves= function (event) {
    if(event.shiftKey) {
      return {row: 2, col: 1};
    }
    else {
      return {row: 0, col: 1};
    }
  }

  //* Check the cell validation
  $scope.cellValidator = function (value, callback) {
    if(value != '') {
      callback(true);
    } else {
      toastBox.show('Empty cell not allowed', 1000);
      callback(false);
    }
  }

  //# Check validation with limit
  $scope.validationWithLimit = function(value, row, col, prop, hotId, limit, cellType) {

    if(cellType == 'numeric' && isNaN(value)) {
      toastBox.show('Invalid data', 1000);

      //# Disable the save button.
      $scope.isInvoiceSpreadSheetEmpty = true;
    } else {
      if(value < 0) {
        toastBox.show('Invalid data', 1000);

        //# Disable the save button.
        $scope.isInvoiceSpreadSheetEmpty = true;
      } else if(value > 0) {
        var convertToString = value.toString();
        var splitToArray = convertToString.split(".");
        if (splitToArray[0].length > limit) {
          $scope.allocationInstanceLoad.setCellMeta(row, col, "className", "errorRowList");
          toastBox.show('Invalid data length', 1000);

          //# Disable the save button.
          $scope.isInvoiceSpreadSheetEmpty = true;
        }
        else {
          $scope.allocationInstanceLoad.setCellMeta(row, col, "className", "");
          //# Disable the save button.
          $scope.isInvoiceSpreadSheetEmpty = false;
        }
      }else {
        $scope.allocationInstanceLoad.setCellMeta(row, col, "className", "");
        //# Disable the save button.
        $scope.isInvoiceSpreadSheetEmpty = false;
      }
    }
    $scope.allocationInstanceLoad.render();
  }

  $scope.NonEmptyRowsCount = function NonEmptyRowsCount(colData){
    var nonEmptyRowsCount = colData.reduce(function (count, data) {
      return $scope.isColEmpty(data) ? count : count + 1;
    }, 0);
    return nonEmptyRowsCount;
  }
  $scope.isColEmpty = function isColEmpty(value){
    return typeof value == 'undefined' || value === null || value.length === 0;
  }

  $scope.handleCollapse = function handleCollapse(e) {
    $scope.section_open = !$scope.section_open;
  }

  //* Delete the spreadsheet items
  $scope.invoiceDeleteAction = function(r, c) {
    var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
    if(c === ($scope.allocationInstanceLoad.getInstance().countCols() -1)) {
      //# Before delete confirm
      confirmationBoxHelper.getConfirmation(confirmation_message, this)
          .then(function() {

            //# Get the primary id of the edit screen, to delete from backend
            var rowId=$scope.allocationInstanceLoad.getSourceDataAtRow(r);
            if((typeof rowId != 'undefined') && rowId.id) {
              $scope.rowToDelete.push(rowId.id);
            }

            $scope.allocationInstanceLoad.alter('remove_row', r);
            $scope.allocationInstanceLoad.render();

            //* Push the outstanding invoice record, to reload into drop down
            if($scope.readOnlyInvoice[rowId.invoiceNumber] === false) {
              //$scope.invoiceNumberDropDown.push({id:rowId.invoiceNumber, text: rowId.invoiceNumber});

            }
            var idx = _.findIndex($scope.selectedInvoices,rowId.invoiceNumber);
            $scope.selectedInvoices.splice(idx,1);

            //* Get the current remaining to allocate amount only for credit note allocation screen.
            $scope.currentRemainingToAllocate = $scope.remainingCreditAmount - $scope.amount_allocated;
            $scope.allowInsertRow = (($scope.currentRemainingToAllocate > 0) || ($scope.allocationHotId == 'cashAllocationSpreadSheet')) ? true : false;

            var countRows=$scope.allocationInstanceLoad.countRows();
            var getLastRow = $scope.allocationInstanceLoad.getSourceDataAtRow(countRows-1);
            if(getLastRow.invoiceNumber && $scope.allowInsertRow) {
              $scope.allocationInstanceLoad.alter('insert_row', countRows);
              $scope.allocationInstanceLoad.render();
            }

            //# Validate each cell from the row to enable the save button
            $scope.instanceSaveRecords = $scope.allocationInstanceLoad.getData();
            $scope.allocationSpreadSheetValidation($scope.instanceSaveRecords);
            $scope.allocationSumTotal();

          }, function() {
            return false
          });
    }
  }

  //# Update the amount in amount to allocate cell
  $scope.invoiceSelectAction = function(r, c, innerText) {
    var getRowData = $scope.allocationInstanceLoad.getSourceDataAtRow(r);
    var className = '';
    if(innerText == 'Select') {
      if((getRowData['remainderAmount'] == getRowData['amountPaid']) && (getRowData['remainderToPay'] == 0)) {
        $scope.allocateValue = 0.00;
      } else {
        $scope.allocateValue = round(getRowData['remainderAmount'],2);
        className = 'cashAllocation';
      }
    } else {
      $scope.allocateValue = 0.00;
    }

    $scope.spreadSheetInvoice[r]['amountToAllocate'] = $scope.allocateValue;
    $scope.updateRowColor(r, className);
    $scope.allocationInstanceLoad.render();
    $scope.$apply();
  }

  //# Highlight the row with select /un select color.
  $scope.updateRowColor = function(r, className) {
    //# Get the current row meta details
    var currentMetaRow = $scope.allocationInstanceLoad.getCellMetaAtRow(r);
    if(typeof currentMetaRow != 'undefined') {
      for(var j=1; j<currentMetaRow.length; j++ ) {
        $scope.allocationInstanceLoad.setCellMeta(r,j,"className",className);
      }
    }
  }

  //# Select all / Select none work flow and update the spreadsheet.
  $scope.updateCashAllocationSpreadSheet = function(innerText) {
    for(var r=0; r<$scope.spreadSheetInvoice.length; r++) {
      var className = '';
      if(innerText == 'selectAll' && $scope.spreadSheetInvoice[r]['invoiceNumber']) {
        $scope.spreadSheetInvoice[r]['amountToAllocate'] = $scope.spreadSheetInvoice[r]['remainderAmount'];
        className = 'cashAllocation';
      } else {
        $scope.spreadSheetInvoice[r]['amountToAllocate'] = 0;
      }
      $scope.updateRowColor(r, className);
    }
    $scope.allocationInstanceLoad.render();
  }

  //* If edit the last row then need to insert a new row with default row
  $scope.insertRowInvoice = function(currentScope,changes, src) {

    //var invoiceNo = currentScope.getDataAtRow(currentScope.countRows()-1);
    var invoiceNo = currentScope.getDataAtRowProp(currentScope.countRows()-1,'invoiceNumber');
    if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && (src === 'edit') && invoiceNo != '') {
      currentScope.alter('insert_row', currentScope.countRows());
      //# Used to scroll to the last row
      $scope.scrollToLastRow(currentScope.getActiveEditor().row, currentScope.getActiveEditor().col, currentScope);

    }
  }

  $scope.allocationSpreadSheetValidation = function(instanceSaveRecords) {
    $scope.isInvoiceSpreadSheetEmpty = true;
    for(var i=0; i< instanceSaveRecords.length-1; i++) {
      //# Get the single row details
      //var row = instanceSaveRecords[i];
      var validateInvoiceNumber = $scope.allocationInstanceLoad.getDataAtRowProp(i,'invoiceNumber');
      var validateAmountToAllocate = $scope.allocationInstanceLoad.getDataAtRowProp(i,'amountToAllocate');

      //# Validate the invoice number & amount allocation cell is not empty or null
      if(validateInvoiceNumber === '' || validateInvoiceNumber == null || validateAmountToAllocate === '' || validateAmountToAllocate === 0) {
        $scope.validateCell=true;
        break;
      } else {
        $scope.validateCell=false;
      }
    }

    //# If any cols is invalid then break the loop.
    if($scope.validateCell == true) {
      $scope.isInvoiceSpreadSheetEmpty=true;
    } else {
      $scope.isInvoiceSpreadSheetEmpty=false;
    }
  }

  //* Sum the allocation details
  $scope.allocationSumTotal = function() {
    $scope.amount_allocated = 0.00;
    $scope.invoice_amount = 0.00;
    $scope.remainder_to_pay = 0.00;
    $scope.sumRemainingToAllocate = 0.00;

    for(var i = 0, l = $scope.spreadSheetInvoice.length; i < l; i++) {
      var amount_allocated = parseFloat($scope.spreadSheetInvoice[i]["amountToAllocate"]),
          invoice_amount = parseFloat($scope.spreadSheetInvoice[i]["invoiceAmount"]),
          remainder_to_pay = parseFloat($scope.spreadSheetInvoice[i]["remainderToPay"]);

      $scope.amount_allocated += parseFloat(round(amount_allocated,2));
      $scope.invoice_amount += parseFloat(round(invoice_amount,2));
      $scope.remainder_to_pay += parseFloat(round(remainder_to_pay,2));
    }

    $scope.amount_allocated = round(parseFloat($scope.amount_allocated),2);
    $scope.invoice_amount = round(parseFloat($scope.invoice_amount),2);
    $scope.remainder_to_pay = round(parseFloat($scope.remainder_to_pay),2);

    //* Get the current remaining to allocate amount
    if(typeof $scope.remainingCreditAmount != 'undefined') {
      $scope.sumRemainingToAllocate = $scope.remainingCreditAmount - ($scope.amount_allocated+$scope.receivedAmount);
    }
  }
}

function round(value, decimals) {
    //return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    return parseFloat(Math.round(value+'e'+decimals)+'e-'+decimals).toFixed(decimals);
}

function inArray(needle, haystack) {
  var length = haystack.length;
  for(var i = 0; i < length; i++) {
    if(haystack[i] == needle) return true;
  }
  return false;
}
