'use strict';

const packageArray = Array(3,4,7,11);
var selected_business = "";
/* Controllers for Directives */
function PrintAndPostCtrl($scope, $rootScope, $state, $timeout, prefix, $http, emailService) {
    $scope.active_slide = 1;

    $rootScope.$on('sidepanel:closed', function(e) {
        $scope.resetPanel();
    });

    /*==========================================================================================
        Panel slide logic
    ==========================================================================================*/
    $scope.hideAllSlides = function hideAllSlides() {
        $scope.active_slide = null;
    }

    $scope.slideInView = function slideInView(slide) {
        $scope.active_slide = slide;
    }

    $scope.resetPanel = function resetPanel() {
        $scope.active_slide = 1;
    }

    $scope.closePanel = function closePanel() {
        $rootScope.$broadcast('closeAllSidepanels');
        $scope.resetPanel();
    }
    $rootScope.$on('parent:updated', function(ev){
        $scope.resetPanel();
    });
    $rootScope.$on('sidepanel:closed', function(ev){
        $scope.resetPanel();
    });

    $rootScope.$on('parent:updated', function(ev){
        $timeout(function(){
            $scope.resetPanel();
        }, 500);
    });

    $scope.printPost = function() {
        if($scope.printpost_params == undefined){
            $scope.printpost_params = $scope.sidepanelData.printpost_params;
            if($scope.sidepanelData.category){
                $scope.category = $scope.sidepanelData.category;
            } else {
                $scope.category = '';
            }
        }

        // Used to enable the tick option for send to customer tab.
        if($scope.printpost_params.substring(10,11) == "5") {
            $rootScope.$broadcast('estimateSendToCustomer:status', true);
        }

        if($scope.printpost_params.substring(10,12) == '61') {
            $scope.printpost_params = $scope.printpost_params + '&group_customer_contact=' + $scope.group_customer_contact;
        }

        if($scope.printpost_params.substring(10,12) == '69')
        {
            $scope.printpost_params = $scope.printpost_params + '&pat_type=' + $scope.sidepanelData.pat_type;
        }

        $http.post(prefix + '/process_printpost', $scope.printpost_params).then(function(resp){

                var data = {category: 'job_certificates'};
                $scope.$emit('smart_tablefilter:newsearch', data);
        });

    }
    $scope.isCopy = false;
    $scope.checkSupportCopy = function(isCopy){
        $scope.isCopy = isCopy;
    }

    $scope.print = function() {
        const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        var url = $scope.pdf_url+ '?type=print&isCopy=' + $scope.isCopy + '&Timezone=' +browserTimezone;
        window.open(url,'_blank');
        $scope.closePanel();
        $rootScope.$emit('parent:updated');
    }

    $scope.sendCertificateToCustomer = function (){
        var overlay = document.getElementById('page-overlay');
        overlay.classList.remove('in-view');
        $scope.emailJobCertificate($scope.sidepanelData);
    }

    $scope.sendEmail = function sendEmail() {
        var overlay = document.getElementById('page-overlay');
        overlay.classList.remove('in-view');
        $scope.$emit("email:send", $scope.group_customer_contact);
    }

    $scope.$on('email:email_sent', function(event, data){

        var data = {category: 'job_certificates'};

        $scope.$emit('smart_tablefilter:newsearch', data);
    });

    $timeout(function() {
        $scope.resetPanel();
    }, 100);

    if($scope.$parent.printpost_params) {
        $scope.group_customer_contact = 'no';
        $scope.groupJobContact = true;
        if($scope.$parent.printpost_params.substring(10,12) == '64') {
            $scope.groupJobContact = false;
        }
    }
}

function fileUploadCtrl($rootScope, $scope) {
  $scope.checkIfUploadPossible = function(files, singleFile, newFiles, duplicateFiles, invalidFiles, evt) {
	 if (Array.isArray(newFiles)) {
		if (newFiles) {
			$scope.customUploadFileParams = {};

			if (Array.isArray(files)) {
			  // support for multiple files
			  angular.forEach(files, function(file, fileIndex) {
				  $scope.customUploadFileParams['file' + fileIndex] = files[fileIndex];
			  });
			}

			$rootScope.$broadcast('ngFileUpload', {
				'files': files,
				'customUploadObject': $scope.customUploadFileParams,
				'singleFile': singleFile,
				'newFiles': newFiles,
				'duplicateFiles': duplicateFiles,
				'invalidFiles': invalidFiles,
				'originalEvent': evt
			});
			return true;
		}
	}

	return false;
  }
};

function filePreviewCtrl($rootScope, $scope, tableCollection, $timeout) {
  $scope.previewLock = false;

  $rootScope.$on('$stateChangeStart', function(event, next, current) {
    $scope.filePreviewSubscription();
  });

  $scope.$on('smarttable:filepreview', function(evt, obj) {
        $scope.previewFile(obj.file);
  });

  $scope.$on('email_attachment:preview', function(evt, file) {
	$scope.previewFile(file);
  });

  $scope.currentRow = false;
  $scope.filePreviewSubscription = $rootScope.$on("file:preview", function(evt, data) {
    if (data) {
    	$scope.currentRow = data.index;
	var data = tableCollection.getSidepanelData(data.index, 'attached_files');
	if (data) {
		var file = {
		      'name': data['File name'],
		      'location': data['location'],
		      'mimeType': data['Type'],
		      'createdondatetime': data['createdondatetime'],
		      'type': data['Type'],
		      'id': data['id']
		};
		$scope.previewFile(file);
	}
    }
  });

  $scope.previewFile = function (fileObj) {
    if (fileObj) {
	    $scope.imageFile = false;
	    $scope.loadFromObject = false;
	    $scope.selectedRow = fileObj.id;
	    $scope.file = fileObj;

	    $scope.image360 = false;
	    $scope.videoFile = false;
	    $scope.viewableFile = false;
	    $scope.image360 = $scope.detect360Images(fileObj.location);
	    $scope.fileTypeDetectionFinished = false;

	    if (fileObj.type.toLowerCase() == 'jpg' || fileObj.type.toLowerCase() == 'jpeg') {
		    $scope.image360.then(function(isImage360) {
			    if (isImage360 == true) {
			      $scope.image360 = true;
			      $scope.viewableFile = true;

			      pannellum.viewer('panorama', {
				  'type': 'equirectangular',
				  'panorama': fileObj.location,
				  'autoLoad': true,
				  'autoRotate': -5,
				  'autoRotateStopDelay': 4000
			      });
			    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
			      $scope.imageFile = true;
			      $scope.viewableFile = true;
			    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
			      $scope.loadFromObject = true;
			    }

			    $scope.fileTypeDetectionFinished = true;

                $timeout(function() {
                    $scope.$apply();
                }, 10);
		    });
	    }
	    else {
		    if (fileObj.type.toLowerCase() == 'mp4' || fileObj.type.toLowerCase() == 'webm' || fileObj.type.toLowerCase() == 'ogm') {
		      $scope.videoFile = true;
		      $scope.viewableFile = true;
		      $scope.fileTypeDetectionFinisheed = true;
		    } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
		      $scope.imageFile = true;
		      $scope.viewableFile = true;
		    } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
		      $scope.loadFromObject = true;
		    }

		    $scope.fileTypeDetectionFinished = true;
	    }
	    $scope.fileSelected = true;
    }
  }

  $scope.detect360Images = function(fileUrl) {
    var element = {};
    element['src'] = fileUrl;

    var async = $q.defer();

    EXIF.enableXmp();
    EXIF.getData(element, function() {
	var make = EXIF.getTag(this, "Make");
	var model = EXIF.getTag(this, "Model");
        var panoramaFound = false;
        if (make) {
	  if (make.toLowerCase().indexOf('ricoh') > -1) {
              panoramaFound = true;
              async.resolve(true);
	  }
        }
        if (model && !panoramaFound) {
	  if (model.toLowerCase().indexOf('ricoh') > -1) {
              panoramaFound = true;
              async.resolve(true);
	  }
        }
	async.resolve(false);
    });

    return async.promise;
  }

  $scope.$on('file:closepreview', function(evt) {

     $scope.closePreview(evt);

  });

  $scope.closePreview = function (evt) {
    $scope.enablePreviewFiles = "";
    $scope.selectedRow = "";
    $scope.file = "";
    $scope.fileSelected = false;
    $scope.recordArgs = '';
    $rootScope.selection = '';
    $rootScope.$broadcast('closeall');

    if ($scope.currentRow) {
//	$scope.simpleSelectRow(evt, $scope.currentRow);
	$rootScope.$broadcast('smarttable:unselectAllRows');
    }
  }

  $scope.downloadFile = function (fileName, location) {
      DownloadFunction.call(this, $scope, location, fileName);
  }
}

function CSFreeOfChargeServiceCtrl($scope, $modalInstance, $http, prefix, $timeout){
    $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
    $scope.closebutton = true;

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.shouldBeOpen = false;
        $scope.$emit('modal:close');
        $modalInstance.close();
    }

    $scope.saveDiaryEventDetails = function(value) {
        $scope.$emit('freeofchargedata', angular.toJson(value));
        $scope.close();
    }

    $scope.button_text = "Next";
    $scope.step = 1;
    $scope.ServiceRecords = [];
    for(var i=0; i<$scope.ServiceReminderRecords.length; i++){
        $scope.ServiceRecords.push({'id':$scope.ServiceReminderRecords[i].id, 'diaryId':''});
    }

    $scope.ServiceRecords.map(function(service) {
        service.complete = false;
    });

    $scope.handleStep = function(step) {
        $scope.step = step;

        if (_.without(_.pluck($scope.ServiceRecords, 'complete'), true).length === 1) {
            $scope.button_text = "Save";
        }
    }

    $scope.handleNextStep = function() {
        var should_close_modal = _.map($scope.ServiceRecords, function(service, index) {
                                                             return service.complete
                                                         }).indexOf(false) === -1
        if (should_close_modal) {
            $scope.saveDiaryEventDetails($scope.ServiceRecords);
        } else {
            var next_service_date = _.map($scope.ServiceRecords, function(service, index) {
                                                                return service.complete
                                                            }).indexOf(false);

            $scope.handleStep(next_service_date + 1);
        }
    }

    $scope.handlePrevStep = function() {
        $scope.handleStep($scope.step - 1);
    }

    $scope.saveServiceType = function() {
        $scope.ServiceRecords[$scope.step - 1].complete = true;

        // $timeout(function() {
            $scope.handleNextStep();
        // });
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.$on('event:escape', function () {
        $scope.close();
    });

    $scope.setDiaryId = function(diaryId, serviceId){
        for(var i=0; i<$scope.ServiceReminderRecords.length; i++){
            if($scope.ServiceRecords[i].id == serviceId){
                $scope.ServiceRecords[i].diaryId = diaryId;
}
        }
    }

}
function OfficeTasksSidepanelCtrl($scope, $rootScope, $http, $compile, $document, $timeout, $location, $sce, tableCollection, clickEvents, prefix) {
    $scope.active_office_tasks_tab = 'assigned';

    $scope.tasksInfiniteScrollMessage = {
       officetasks_unassigned: false,
       officetasks_assigned: false
    };

    $scope.reachedEndOfTasks = {
       officetasks_unassigned: false,
       officetasks_assigned: false
    };

    $scope.tasksLoading = {
      officetasks_unassigned: true,
      officetasks_assigned: true
    };

    $scope.viewableTasks = {
      officetasks_unassigned: [],
      officetasks_assigned: []
    };

    $scope.viewableTasksPage = {
      officetasks_unassigned: 1,
      officetasks_assigned: 1
    };

    $scope.viewableTasksLimit = {
      officetasks_unassigned: 15,
      officetasks_assigned: 15
    };

    $scope.officetasks_counts = {
      officetasks_unassigned: 0,
      officetasks_assigned: 0
    };

    $scope.timer = false;
    $scope.disableInfiniteScroll = {
      officetasks_unassigned: false,
      officetasks_assigned: false
    };
    $scope.tasksSearchTimer = false;
    $scope.tasksSearchResults = angular.copy($scope.viewableTasks);
    $scope.tasksSearchResultsNotFound = {
      officetasks_unassigned: false,
      officetasks_assigned: false
    };

    $scope.searchQueries = {
      'officetasks_assigned': '',
      'officetasks_unassigned': ''
    };

    $scope.tasksSearchTimer = false;

    $scope.$on("officeTasks:openCountUpdated", function (event, message, data) {
      if(data) {
        $scope.$apply(function () {
          $scope.populateViewableTasks();
          // var officetasks_unassigned = _.filter(data, function(x){
          //   return x.complete !== true && !x.assigned_to_user_id
          // }).length;
          // var officetasks_assigned = _.filter(data, function(x){
          //   return x.complete !== true && x.assigned_to_user_id == $rootScope.userId
          // }).length;
          //$scope.officetasks_counts['officetasks_assigned'] = officetasks_assigned;
          //if(officetasks_unassigned > 0) {
            //$scope.officetasks_counts['officetasks_unassigned'] = officetasks_unassigned;
          //}
        });
      } else {
        $scope.$apply(function () {
          $scope.officetasks_counts['officetasks_unassigned'] = 0;
        });
      }
    });

    $rootScope.$on('event:office_task_notifications', function(event, data) {
        $scope.officetasks_counts['officetasks_assigned'] = parseInt(data.assigned);
        $scope.officetasks_counts['officetasks_unassigned'] = parseInt(data.unassigned);
        $scope.populateViewableTasks();
    });

    $scope.populateViewableTasks = function populateViewableTasks(refresh) {
        if (refresh) {
          $scope.viewableTasksPage['officetasks_unassigned'] = 1;
          $scope.viewableTasksPage['officetasks_assigned'] = 1;
        }

        var unassigned_settings  = {
                  page: $scope.viewableTasksPage['officetasks_unassigned'],
                  limit: $scope.viewableTasksLimit['officetasks_unassigned'],
                  userid: 0,
                  status: 'incomplete',
                  search: $scope.searchQueries['officetasks_unassigned'],
        };

        var assigned_settings = angular.copy(unassigned_settings);
        assigned_settings.userid = $rootScope.userId;
        assigned_settings.limit = $scope.viewableTasksLimit['officetasks_assigned'];
        assigned_settings.page = $scope.viewableTasksPage['officetasks_assigned'];
        assigned_settings.search = $scope.searchQueries['officetasks_assigned'];

        /* Fetch unassigned tasks */
        $http({
            url: prefix + '/office_task_notifications',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
            params: unassigned_settings
        })
        .then(function(response) {
            if (response.data.officetasknotifications.length == 0) {
              $scope.reachedEndOfTasks['officetasks_unassigned'] = true;
            }
            else {
              $scope.reachedEndOfTasks['officetasks_unassigned'] = false;
            }

            if ($scope.viewableTasksPage['officetasks_unassigned'] > 1) {
              $scope.viewableTasks['officetasks_unassigned'] = $scope.viewableTasks['officetasks_unassigned'].concat(angular.copy(response.data.officetasknotifications));
            }
            else {
              $scope.viewableTasks['officetasks_unassigned'] = angular.copy(response.data.officetasknotifications);
            }
            $scope.officetasks_counts['officetasks_unassigned'] = response.data.officetasknotificationscount;

            $scope.tasksSearchResultsNotFound['officetasks_unassigned'] = false;
            $scope.tasksInfiniteScrollMessage['officetasks_unassigned'] = false;
            $scope.disableInfiniteScroll['officetasks_unassigned'] = false;
            $scope.tasksLoading['officetasks_unassigned'] = false;
		    });

    		/* Fetch assigned tasks */
		    $http({
            url: prefix + '/office_task_notifications',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            },
      			params: assigned_settings
		    })
		    .then(function(response) {
            if (response.data.officetasknotifications.length == 0) {
              $scope.reachedEndOfTasks['officetasks_assigned'] = true;
            }
            else {
              $scope.reachedEndOfTasks['officetasks_assigned'] = false;
            }

            if ($scope.viewableTasksPage['officetasks_assigned'] > 1) {
              $scope.viewableTasks['officetasks_assigned'] = $scope.viewableTasks['officetasks_assigned'].concat(angular.copy(response.data.officetasknotifications));
            }
            else {
              $scope.viewableTasks['officetasks_assigned'] = angular.copy(response.data.officetasknotifications);
            }
            $scope.officetasks_counts['officetasks_assigned'] = response.data.officetasknotificationscount;

            $scope.tasksSearchResultsNotFound['officetasks_assigned'] = false;
            $scope.tasksInfiniteScrollMessage['officetasks_assigned'] = false;
            $scope.disableInfiniteScroll['officetasks_assigned'] = false;
            $scope.tasksLoading['officetasks_assigned'] = false;
		    });
    };

    $scope.areThereNoResultsFound = function areThereNoResultsFound(cat) {
            return $scope.tasksSearchResultsNotFound[cat];
    };

    $scope.increaseTaskLimit = function increaseTaskLimit() {
            $scope.unassignedLimit = 20;
    };
};
function SmartTableCtrl($scope, $rootScope, $http, $compile, $document, $timeout, $location, $sce, tableCollection, clickEvents, prefix, creditNoteData, warningModal, $translate, $state, confirmationBoxHelper){
    $scope.previewFile = function previewFile() {
        console.log("Im here");
        $scope.$emit("file:preview", { 'index': this.$index, 'table': $scope.unique_id});
    }

    $scope.downloadFile = function downloadFile(evt) {

  	 evt.stopPropagation();

        $scope.$emit("file:download", { 'index': this.$index});
    }

    $scope.deleteFile = function deleteFile(evt) {

	 evt.stopPropagation();

        $scope.$emit("file:delete", { 'index': this.$index });
    }

    $scope.triggerSidepanel = function triggerSidepanel(event, id, category){
        $scope.IsLoadingVisible = true;
      	$rootScope[category + 'SidepanelTriggered'] = false;
        $scope.category = category;
        // fetches the data from the smart table service to inject into our sidepanel
        // fetches the data from the smart table service to inject into our sidepanel
        $http.post(prefix + '/modalNotificationDetails', 'notificationId=' + tableCollection.collection[category].data[id]['notificationId']).
        success(function (data) {
            $scope.sidepanelData =data.notifications.data[0];
            $scope.$emit('triggerSidepanel', id, category);
        });
    };

    $scope.addToNotificationHistory = function addToNotificationHistory(entryId, action) {
        $http.post(prefix + '/auditNotificationView', 'notificationId=' + $scope.sidepanelData.notificationId + '&status=' + action).
            success(function(data) {
                $scope.sidepanelData.notification_history.unshift(data);
            })
    }

    $scope.cancelCost = function(reason, jobCostId) {
        $scope.$emit('smart_tablefilter:costcancel', angular.toJson({'reason':reason, 'jobCostId':jobCostId}));
    }

    $scope.completeNotification = function completeNotification(entryId){
        $http.post(prefix + '/completeNotification', 'notificationId=' + entryId).success(function (data) {
            $scope.$emit('smarttable_and_sidepanel:filtervalues', angular.toJson({'status':'Pending'}));
        });
        $scope.addToNotificationHistory(entryId, 'completed');
    }

    $scope.$on('triggerSidepanel', function(event, entryId, cat) {
        $scope.IsLoadingVisible = false;
        if($rootScope[cat + 'SidepanelTriggered'] != 'triggered'){
            $rootScope[cat + 'SidepanelTriggered'] = 'triggered';
            var cat = $scope.category,
                tablePosition = entryId,
                template = cat + '_template',
                index = entryId;
            if (cat == 'notificationsTopMenu') {
                var template = 'notifications_template';
            }
            //Audit Notification View
            if (cat == 'notifications' || cat == 'notificationsTopMenu') {
                  if($scope.sidepanelData.notificationId){
                    $http.post(prefix + '/auditNotificationView', 'notificationId=' + $scope.sidepanelData.notificationId).
                        success(function (data) {
                            $scope.sidepanelData.notification_history.unshift(data);
                        });
                  }
            }

            // close all open widgets
            $rootScope.$broadcast('closeall');

            var insertPanel = function insertPanel() {
                // close all open widgets
                $rootScope.$broadcast('closeall');
                /*====================================================
                                  Builds the panel element and insert it into the DOM,
                                  the panel is hidden in the CSS so that it can be
                                  animated with a CSS transition with the showPanel
                                  function.
                                  ====================================================*/
                var constructAndInsertPanel = (function() {
                    var panel_wrap = document.createElement('div'),
                        sidepanelTemplate = '';

                    panel_wrap.id = 'more-details-panel';
                    panel_wrap.className = 'smart-table-sidepanel'
                    document.getElementsByTagName('body')[0].appendChild(panel_wrap);

                    /*====================================================
                                              1. Fetches the sidepanel template to use
                                              2. Brings the sidepanel data into the sidepanel's scope
                                              3. Appends a compiled result of the data and template
                                              into the view
                                              ====================================================*/
                    var tpl_path = "/template/side_panels/" + template + ".html";
                    $scope.sidepanelTemplate = [];
                    var fetchsidepanelTemplate = function fetchsidepanelTemplate() {
                        $http.get(tpl_path).then(function(result){
                            sidepanelTemplate = result.data;
                            panel_wrap.appendChild($compile(result.data)($scope)[0]);
                            /*====================================================
                                                          After the panel is built, push it into view
                                                          and attach keyboard listeners
                                                          ====================================================*/
                            var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                            $timeout(function() {
                                panel[0].classList.add('in-view');
                            }, 100);
                            bindKeyboardEvents(index, tablePosition);
                            insertPageOverlay();
                        });
                    }
                    fetchsidepanelTemplate();
                })();
            }

            var insertPageOverlay = function insertPageOverlay() {
                /*====================================================
                                  Builds the overlay element and insert it into the DOM,
                                  the overlay is hidden in the CSS so that it can be
                                  animated with a CSS transition
                                  ====================================================*/
                var constructAndInsertOverlay = (function(){
                    var insertElm = function insertElm() {
                        var wrapper = document.createElement('div');
                        wrapper.id = 'page-overlay';
                        document.getElementsByTagName('body')[0].appendChild(wrapper);
                    }
                    if (document.getElementById('page-overlay') === null) {
                        insertElm();
                    }else {
                        // remove the existing one before inserting the new one
                        document.getElementById('page-overlay').remove();
                        insertElm();
                    }
                })();
                /*====================================================
                                  Show the overlay element and attach click event listeners
                                  to it.
                                  ====================================================*/
                var showAndAttachEvts = (function() {
                    var handleElm = function handleElm() {
                        $timeout(function() {
                            var overlay = document.getElementById('page-overlay');
                            overlay.className = overlay.className + 'in-view';
                        }, 10);

                        // attach click listeners
                        var overlay = document.querySelectorAll('#page-overlay');
                        var save_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #save-panel-btn');
                        var cancel_link = document.querySelectorAll('#more-details-panel.smart-table-sidepanel #cancel-panel-btn');
                        for (var i = 0; i < overlay.length; i++) {
                            overlay[i].addEventListener('click', $scope.removeBothElements);
                        }
                        for (var i = 0; i < save_link.length; i++) {
                            save_link[i].addEventListener('click', $scope.removeBothElements);
                        }
                        for (var i = 0; i < cancel_link.length; i++) {
                            cancel_link[i].addEventListener('click', $scope.removeBothElements);
                        }
                    }
                    handleElm();
                })();
            }
            /*====================================================
                              Bind keyboard event listeners
                              - 'esc' closes the panel
                              - 'up' & 'down' to navigate through the rows
                              ** gets the correct index for the data from the index
                              of the table using $index so that pinned rows
                              are respected.
                              ====================================================*/
            var bindKeyboardEvents = function bindKeyboardEvents(row, index) {
                $document.bind('keydown.sidepanelShortcuts', function(evt) {
                    var evt = evt || window.event;
                    var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                    if (tableCollection.getOrderedRows()) {
			    var totalRows = tableCollection.getOrderedRows().length - 1;
			    if (panel[0] != null) {
				var handleEscKey = (function() {
				    // esc keydown
				    if (evt.keyCode == 27) {
					$scope.removeBothElements();
				    }
				})();
			    }
                    }
                })
            }

            /*====================================================
                              Instantiate panel instance
                              ====================================================*/
            insertPanel();
        }

        $scope.removeBothElements = function removeBothElements(e){
                var anchor = 'A';
                var panel = document.querySelectorAll('#more-details-panel.smart-table-sidepanel');
                var overlay = document.getElementById('page-overlay');
                // if the target element is an anchor (ie the save the cancel link)
                // then prevent the link changing the URl.

                if (e != undefined && e.currentTarget.tagName === anchor) {
                    e.preventDefault();
                }

                panel[0].classList.remove('in-view');
                overlay.classList.remove('in-view');

                $timeout(function() {
                    overlay.parentNode.removeChild(overlay);
                    panel[0].parentNode.removeChild(panel[0]);
                }, 200);

                // remove keyboard events
                $scope.unbindKeyboardEvents();
            }


            $scope.unbindKeyboardEvents = function unbindKeyboardEvents() {
                      /*====================================================
                                        Unbind keyboard events
                                        ====================================================*/
                          $document.unbind('keydown.sidepanelShortcuts');
            }

    });

    /**For prefilling returns values when we raise credit note from returns and shows warning modal if supplier invoice was not raised for the corresponding PO**/
    $scope.setSupplierReturns = function setSupplierReturns(returnId, category){
        if(category == 'supplier_returns' || category == 'job_cost_supplier_returns'){
            var supplierReturns = [];
            var poStatus = [];
            var supplier_returns = tableCollection.collection[category].data;
            var jobId = supplier_returns[0].jobid;
            angular.forEach(supplier_returns, function(value, key){
                if(returnId == value.id){
                    supplierReturns[0] = value;
                    if(value.poStatus != 'Completed'){
                        poStatus.push(value.id);
                    }
                }
            });
            if(poStatus.length > 0){
                warningModal.clean('return_error');
                warningModal.show($translate('Please raise supplier invoice before raising credit note for reference ' + poStatus.join()), 'Error', 'return_error');
            }else{
                creditNoteData.setSelectedReturns(supplierReturns);
                $state.transitionTo("loggedin.list_supplier.view.new_credit_note_returns",({ id: supplierReturns[0].supplierid, returnId: returnId }));
            }
        }
    }
}
function CSPhoneWidgetCtrl($scope, $http, prefix, $state){
    //Delete Number
    $scope.deleteNumber = function deleteNumber(index){
        $scope.numbers_collection.splice(index, 1);
        $scope.$emit('numbersCollection', $scope.numbers_collection);
    }
    //Edit Number
    $scope.editNumber = function editNumber(index) {
        $scope.edit_number = $scope.numbers_collection[index];
        $scope.show_edit[index] = true;
    }
    //Save Number
    $scope.saveNumber = function saveNumber(number){
        $scope.numbers_collection['selected'] = ''; //modified for handling communication preference in editcustomer. with this we can assume it is edit call
        let phone_number = number.phone_number;
        $scope.$emit('GetErrorMessage', phone_number);
        if (phone_number == undefined || phone_number == "") {
            this.invalid_edit_number = true;
            return false;
        } else {
            $scope.numbers_collection['selected'] = number;
            this.invalid_edit_number = false;
            $scope.$emit('numbersCollection', $scope.numbers_collection);
            $scope.show_edit[this.$index] = false;
            return true;
        }
    }
}
function CSDateFilterCtrl($scope, $http, prefix, $state, dateRange, clickEvents, $rootScope){
    $scope.show_daterange == false;
    $scope.rangeArray = [];
    $scope.weekpickerValue = null;

    $scope.$watch('show_daterange', function() {
        if ($scope.show_daterange === true ) {
            clickEvents.registerIgnoreElements('daterange');
            $rootScope.$broadcast('closesmartbuttons');
        }
    });

    $scope.$on('daterange:changed', function(elem, data) {
        if (data.withCalendar === false) {
            $scope.selectRange(data);
        } else {
            $scope.start_date = data.startdate;
            $scope.end_date = data.enddate;
            $scope.applyFilter();
        }
    });

    $scope.selectRange = function selectRange(dateFilterOption) {
        $scope.show_apply_btn = true;

        if (dateFilterOption.withCalendar === false) {
            $scope.show_daterange = false;

            handleFilterBarView(true, false);
            $scope.selectedRange = (this.dateFilterOption && this.dateFilterOption.string) || (dateFilterOption && dateFilterOption.string);
            $scope.$emit('dateRangeFilter', this.dateFilterOption || dateFilterOption);
            setTimeout(function() {
                var filter_button = document.querySelector('.clear-filter');
                filter_button.classList.add('fade-in');
                $(filter_button).show();
            }, 30);
        }else {
            $scope.selected = dateFilterOption;
        }
    }

    $scope.applyFilter = function applyFilter() {
        handleFilterBarView(false, true);
        $scope.datefilter = { 'startdate' : moment(this.start_date).format('YYYY-MM-DD'), 'enddate' : moment(this.end_date).format('YYYY-MM-DD') };

        $scope.$emit('dateRangeFilter',  $scope.datefilter);
        setTimeout(function() {
            var filter_button = document.querySelector('.clear-filter');
            filter_button.classList.add('fade-in');
            $(filter_button).show();
        }, 30);
    }

    var handleFilterBarView = function handleFilterBarView(filter_name, date_range) {
        $scope.filter_name = filter_name;
        $scope.date_range = date_range;
        $scope.show_apply_btn = false;
        $scope.show_daterange = false;
        $scope.filtered_state = true;
    }

    $scope.$on('event:reset_date_filter', function() {
        $scope.resetFilter(false);
    });

    $scope.resetFilter = function (doEmit) {
        $scope.filter_name = false;
        $scope.date_range = false;
        $scope.filtered_state = false;
        $scope.show_apply_btn = false;
        $scope.datefilter = { 'startdate': '', 'enddate': '' };
        var filter_button = document.querySelector('.clear-filter');
        filter_button.classList.remove('fade-in');
        if(doEmit !== false) {
            $scope.$emit('dateRangeFilter', $scope.datefilter);
        }
        $scope.selected = '';
        $scope.rangeArray = [];
        $scope.thisFromDate = '';
        $scope.thisToDate = '';
        $('.clear-filter').fadeOut(250);
    }

    $rootScope.$on('closeallwithapply', function() {
        $scope.$apply(function() {
            $scope.show_daterange = false;
            $scope.show_apply_btn = false;
        });
    });
    $rootScope.$on('closeall', function() {
        $scope.show_daterange = false;
        $scope.show_apply_btn = false;
    });

    // Apply pre filters
    if ($scope.filtersConfig && $scope.filtersConfig.daterange instanceof Object && $scope.filtersConfig.daterange.dateRange) {

        var dateFilterOption = _.filter($scope.dateFilterOptions, function (elem) {
            return elem.string == $scope.filtersConfig.daterange.dateRange;
        })[0];

        if (dateFilterOption.string == 'Date Range') {
            handleFilterBarView(false, true);
            $scope.show_daterange = false;
        } else {
            handleFilterBarView(true, false);
            $scope.datefilter = {
                'startdate': moment(dateFilterOption.startdate).format('YYYY-MM-DD'),
                'enddate': moment(dateFilterOption.enddate).format('YYYY-MM-DD')
            };
        }

        setTimeout(function () {
            var filter_button = document.querySelector('.clear-filter');
            filter_button.classList.add('fade-in');
            $(filter_button).show();
        }, 30);
    }

    $scope.weekChanged = function(val) {
        if(val) {
            $scope.weekpickerValue = val;
            $scope.start_date = $scope.weekpickerValue[0];
            $scope.end_date = $scope.weekpickerValue[1];
        }
    }
}
function CSUserModalCtrl($scope, $http, prefix, $modalInstance){
    $scope = $scope.$parent;
    $scope.confirmDeleteValidity = true;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.deletebutton = true;
    $scope.confirmText = '';
    $scope.selectedUsername = '';
    $scope.selectedPassword = '';
    $scope.errorMessage = '';

    $scope['delete'] = function() {
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase() && this.deleteUserForm.$valid) {
            $scope.confirmText = "";
            $scope.confirmDeleteValidity = true;
            $scope.loading = true;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            $http.post(prefix + $scope.deleteUrl, $scope.deleteParams + $scope.selectedId +'&users[username]='+encodeURIComponent(this.selectedUsername)+'&users[password]='+encodeURIComponent(this.selectedPassword)).
                success(function(data, status) {
                    if (status == 200) {
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.deletebutton = true;
                        if(data != 'Deleted'){
                            $scope.errorMessage = data;
                        }
                        else{
                            $scope.close();
                            $scope.$emit('DeletedUser', data);
                        }
                    }
                }).error(function (data, status) {
                    if(status == 400 ){
                        $scope.completeAction();
                        if(data.errorMsg == "supplier_invoice_raised_bulk"){
                            warningModal.show('parts.cannot.cancelled.due.completed.po.description', 'parts.cannot.cancelled.due.completed.po.title', data.errorMsg);
                        } else if(data.errorMsg == "supplier_invoice_raised"){
                            warningModal.show('supplier.invoice.raised.description', 'supplier.invoice.raised.title', data.errorMsg);
                        } else if(data.errorMsg == "multiple_invoice_raised_po"){
                            warningModal.show('multiple.invoice.raised.po', 'supplier.invoice.raised.title', data.errorMsg);
                        }
                    }
                })
        } else {
            if(this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()){
                $scope.confirmDeleteValidity = true;
                $scope.loading = false;
                $scope.shouldBeOpenUser = false;
            }
            else{
                if(this.selectedUsername != '' && this.selectedPassword != '') {
                    $scope.confirmDeleteValidity = false;
                    $scope.loading = false;
                    $scope.shouldBeOpenUser = false;
                }
            }
        }
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.confirmDeleteValidity = true;
        $scope.loading = false;
        $scope.shouldBeOpenUser = false;
        $modalInstance.close();
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }
}
function CSLetterCtrl($scope, $http, $sce, prefix, $modalInstance) {
    $scope = $scope.$parent;
    $scope.previewDataTrusted = $sce.trustAsHtml($scope.previewData);

    $scope.close = function() {
        $scope.shouldBeOpenLetterPreview = false;
        $modalInstance.close();
    }
    //Close the Modal while pressing the Esc key
    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.shouldBeOpenLetterPreview = false;
            $modalInstance.close();
        }
    }

    if($scope.showPdfView == 'showPdfView'){
        $scope.pdfObjectView = true;
    }
}
function CSImageUploadModalCtrl ($scope, $http, prefix, $modalInstance){
    $scope = $scope.$parent;
    $scope.images = [];
    $scope.selectedId = '';
    $scope.selectedImage = '';
    $scope.loading = true;

    $scope.closeModal = function() {
        angular.element('.imgareaselect-outer').remove();
        angular.element('.imgareaselect-selection').parent().remove();
        $scope.shouldBeOpen = false;
        $scope.image_modal_open = false;
        $modalInstance.close();
    }

    $http.get(prefix + '/getClientImages').success(function (data) {
        $scope.loading = false;
        $scope.images = data.libImages;
    });

    $scope.changeViewModal = function (preview) {
        $scope.changeView = preview;
        if(preview) {
            angular.element('div.modal')._removeClass('image-upload-modal');
            angular.element('div.modal')._addClass('preview-modal');
        } else {
            angular.element('div.modal')._removeClass('preview-modal');
            angular.element('div.modal')._addClass('image-upload-modal');
        }
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function () {
        if($scope.changeView) {
            $scope.removeUploadedPicture();
        }
        $scope.changeViewModal(false);
        $scope.closeModal();
    }

    $scope.selectImage = function (imageObj) {
        $scope.selectedId = imageObj.id;
        $scope.selectedImage = imageObj.file;
        $scope.selectedImageSource = imageObj.imageSrc;
    }

    $scope.previewImage = function () {
        if ($scope.selectedImage != '') {
            $scope.loading = true;
            $http.post(prefix + '/getImageDetails', 'imageSrc=' + $scope.selectedImage + '&mode=' + $scope.mode).
                success(function (data, status) {
                    $scope.loading = false;
                    if(data.message.saved) {
                        $scope.saveResizedImage({ resp:data });
                        $scope.closeModal();
                        return;
                    } else {
                    	console.log('error saving image');
                    	//$scope.closeModal();
                    }
                    $scope.selectedImageSrc = data.message.file;
                    if(!$scope.imgOptions) {
                        $scope.imgOptions = {};
                    }
                    $scope.imgOptions.orgHeight = data.message.height;
                    $scope.imgOptions.orgWidth = data.message.width;
                    $scope.changeViewModal(true);
                    $scope.$broadcast('IMAGE_LOADED');
                });
        }
    }

    $scope.backTo = function () {
        $scope.$broadcast('IMAGE_REFRESH');
        $scope.removeUploadedPicture();
        $scope.selectedImageSrc = '';
        $scope.changeViewModal(false);
    }

    $scope.uploadSuccess = function (resp) {
        if(!resp.message.resize) {
            $scope.saveResizedImage({ resp:resp });
            $scope.closeModal();
            return;
        }
        if(!$scope.imgOptions) {
            $scope.imgOptions = {};
        }
        $scope.selectedImageSrc = resp.message.file;
        $scope.imgOptions.orgHeight = resp.message.height;
        $scope.imgOptions.orgWidth = resp.message.width;
        $scope.changeViewModal(true);
        $scope.$broadcast('IMAGE_LOADED');
    }

    $scope.removeUploadedPicture = function () {
        $scope.changeViewModal(false);
        if ($scope.selectedImageSrc != '') {
            $http.post(prefix + '/remove_uploaded_picture', 'imageSrc=' + $scope.selectedImageSrc).
                success(function (data, status) {
                });
        }
    }

    $scope.SaveResizedImage = function () {
        $scope.saving = true;
        $http.post(prefix + $scope.action, $.param({  mode: $scope.mode, imageSrc: $scope.selectedImageSrc, startX: this.startX, startY: this.startY, reqWidth: $scope.imgOptions.reqWidth, reqHeight: $scope.imgOptions.reqHeight, selectedWidth: this.selectedWidth, selectedHeight: this.selectedHeight })).
            success(function (data) {
                $scope.saveResizedImage({ resp:data });
                $scope.saving = false;
                $scope.changeViewModal(false);
                $scope.closeModal();
            });
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.$on("event:escape", function () {
        $scope.close();
    });

    $scope.saveImageWithoutResize = function () {
        $scope.saving = true;

        $http.post(prefix + '/save_bottom_letterhead_logo', 'imageSrc=' + $scope.selectedImageSource + '&mode=' + $scope.mode).
            success(function (data, status) {
                $scope.saveResizedImage({ resp:data });
                $scope.saving = false;
                $scope.closeModal();
            });
    }
}
function CSImageResigeModalCtrl($scope, $http, prefix, $modalInstance){
    $scope = $scope.$parent;

    $modalInstance.result.then(function() {
        $scope.close();
    }, function() {
        $scope.close();
    });

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }
    $scope.closeModal = function () {
        $modalInstance.close();
        $scope.openResizeModal = false;
    }

    $scope.close = function () {
        angular.element('.imgareaselect-selection').parent().remove();
        angular.element('.imgareaselect-outer').remove();
        $http.post(prefix + '/remove_uploaded_picture', $.param({  imageSrc: $scope.imageSrc })).
            success(function (data) {
            });
        $scope.closeModal();
        $scope.$emit('event:image_resize_modal_closed');
    }

    $scope.saveImage = function () {
        $scope.saving = true;
        $http.post(prefix + $scope.saveUrl, $.param({  imageSrc: $scope.imageSrc, startX: $scope.startX, startY: $scope.startY, reqWidth: $scope.imgOptions.reqWidth, reqHeight: $scope.imgOptions.reqHeight, selectedWidth: $scope.selectedWidth, selectedHeight: $scope.selectedHeight })).
            success(function (data) {
                $scope.saveResizedImage({ resp:data });
                $scope.saving = false;
                $scope.closeModal();
            });
    }
    setTimeout(function(){$scope.$broadcast('IMAGE_LOADED')},200);
}
function CSLandlordCtrl($scope, $http, prefix, $modalInstance, $rootScope){

    $modalInstance.result.then(function() {
        $scope.cancel();
    }, function() {
        $scope.cancel();
    });

    $scope.cancel = function(){
        $rootScope.shouldBeOpenLord = false;
        $modalInstance.close();
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.mainWindow = function() {
        $('#mainWindow').css({'top':'0px', 'overflow':'visible'});
    }

    $scope.$on("LANDLORDPOSTCODECLOSE", function (event, message) {
        $scope.shouldBeOpen = false;
    });

    $scope.searchLandPostcode = function () {
        if(this.postcode != '') {
            $http.get(prefix + '/search_postcode?postcode=' + this.postcode).success(function (data) {
                $scope.addressArray = data.address;
                $scope.error = data.error;
                $scope.errorMessage = data.errorMessage;
                $scope.shouldBeOpen = true;
            })
        }
    }

    $scope.outputVal = function(data) {
        this.addressline1 = data.addressLine1;
        this.addressline2 = data.addressLine2;
        this.addressline3 = data.addressLine3;
        this.town = data.town;
        this.county = data.county;
    }

    $scope.formSubmit = function (formElem) {
        $http.post(this.actionVal, formElem.serialize()).
            success(function (data, status) {
                $rootScope.shouldBeOpenLord = false;
                $scope.id = data.id;
                $scope.landlordName = data.landlordName;
                $scope.cancel();
                $scope.landlordReload({ id: $scope.id, landlordName: $scope.landlordName });
            })
    }
}
function CSAuditsModalCtrl($scope, $modalInstance) {
    $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent

    $scope.close = function () {
        $scope.shouldBeOpen = false;
        $modalInstance.close();
    }
}
function CSDynamicTagCtrl($scope, $modalInstance) {
    $scope = $scope.$parent;
    $scope.closebutton = true;
    $scope.savebutton = true;

    $scope['dynamictag'] = function () {
        $scope.closebutton = false;
        $scope.savebutton = false;

        var dynamicTags = $scope.dynamicTags;
        if (dynamicTags) {
            $scope.tags = [];
            angular.forEach(dynamicTags, function (val, key) {
                var tagName = dynamicTags[key].tageName;
                $scope.tags[tagName] = dynamicTags[key].tageValue;
            });
            $scope.$emit('dynamicTagValues', $scope.tags);
        }
        $scope.close();
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.shouldBeOpen = false;
        $modalInstance.close();
    }
}
function CSDeleteModalCtrl($scope, $http, prefix, $modalInstance) {
    $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
    $scope.closebutton = true;

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.shouldBeOpen = false;
        $modalInstance.close();
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.$on('event:escape', function () {
        $scope.close();
    });
}
function CSModalCtrl($scope, $http, prefix, $modalInstance, $rootScope, toastBox, warningModal) {
        $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
        $scope.confirmDeleteValidity = true;
        $scope.loading = false;
        $scope.closebutton = true;
        $scope.deletebutton = true;
        $scope.confirmText = '';

/*
        if($scope.listData) {
            $scope.newListData = angular.fromJson($scope.listData);
            $scope.selectedId = angular.fromJson($scope.selectedId);
        }
*/

        if ($scope.buttonValue == "Merge" || $scope.category == "job") {
            $scope.mergeHide = false;
        } else {
            $scope.mergeHide = true;
        }

        $scope['delete'] = function() {
            if ($scope.buttonValue == "Merge") {
                $scope.confirmText = "MERGE";
                $scope.deleteParams = "&advertisingtype[id]=" + $scope.selectedId + "&advertisingtype[mergeId]=" + $scope.mergeId + "&advertisingtype[description]=" + $scope.mergeDescription + "&";
            }
            if (this.buttonValue == "Save" && this.confirmText.toLowerCase() == "primary") {
                $scope.deleteParams = "&contact[id]=" + $scope.mergeId + "&contact[contactId]=";
            }
            $scope.moduleParams = "&moduleValue[moduleId]=" + $rootScope.clientJobType + "&moduleValue[jobtodos]=" + $rootScope.clientJobTodos;
            if($scope.category == "job") {
                $scope.confirmText = "CONFIRM";
                $scope.moduleParams = "&linkJob=" + $scope.linkJob;
            }
            if($scope.category == "job_parts" || $scope.category == "job_parts_requested") {
                $scope.moduleParams = "&jobId=" + $scope.mergeId;
            }

            if($scope.category == "job_cost_supplier_returns"){
                $scope.deleteParams = 'jobId=' + $scope.jobId + '&' + $scope.deleteParams;
            }
            if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
                $scope.confirmDeleteValidity = true;
                $scope.loading = true;
                $scope.closebutton = false;
                $scope.deletebutton = false;

                if($scope.preventDeleteAction) {
                    $scope.loading = false;
                    $scope.closebutton = true;
                    $scope.deletebutton = true;
                    $scope.close();

                    var valueToSubtract = 0;
                    if ($scope.totalEntries % $scope.limit == 1 && $scope.$parent.currentPage > 1) {
                        valueToSubtract = 1;
                    }
                    $scope.deleteFunc({page: $scope.$parent.currentPage - valueToSubtract});


                } else {
                    $http.post(prefix + $scope.deleteUrl, $scope.deleteParams + $scope.selectedId + $scope.moduleParams).
                    success(function(data, status) {
                        if(data == 'Estimate not Deleted'){
                            $scope.close();
                            $scope.message = 'You cannot delete this estimate because the customer has accepted it';
                            warningModal.show($scope.message, 'Delete estimate', 'delete');
                        }
                        else if (status == 200) {
                            $scope.loading = false;
                            $scope.closebutton = true;
                            $scope.deletebutton = true;
                            $scope.contactId = data.contactId;
                            $scope.mergeId = data.mergeId;
                            $scope.dataValue = { 'contactId': $scope.contactId, 'mergeId': $scope.mergeId };
                            $scope.$emit('contactTransfer', $scope.dataValue);
                            $scope.close();
                            if($scope.deleteUrl == '/mergeAdvertisingType') {
                                toastBox.show('Merged advertising type', 4000);
                            }
                            if(data.action && data.action === 'Cloned') {
                                $scope.deleteFunc({ cloneData: data });
                            }else {
                                var valueToSubtract = 0;
                                if($scope.totalEntries % $scope.limit == 1 && $scope.$parent.currentPage > 1) {
                                    valueToSubtract = 1;
                                }
                                $scope.deleteFunc({ page: $scope.$parent.currentPage - valueToSubtract });
                            }
                            if($scope.category == "job_parts" || $scope.category == "job_parts_requested") {
                                $rootScope.$broadcast('parts:countupdated', data);
                            }
                            $scope.$emit('confimration_modal:update', data);
                        }
                    })
                }

            } else {
                $scope.confirmDeleteValidity = false;
                $scope.loading = false;
            }
        }

        $scope.opts = {
            backdropFade: true,
            dialogFade: true
        }

        $scope.close = function() {
            // Raja: (19/12/2015) Commented below as otherwise scope set variable on local $scope and subsequent delete failed COM-4756
            //$scope.selectedId = '';
            $scope.confirmText = this.confirmText = '';
            $scope.shouldBeOpen = false;
            $scope.confirmDeleteValidity = true;
            $scope.$emit('modal:close');
            $modalInstance.close();
        }

        window.onkeydown = function (evt) {
            if (evt.keyCode == 27) {
                $scope.close();
            }
        }
        $rootScope.$on('event:escape', function () {
            $scope.close();
        });

    $scope.showDeleteButton = function(deletetext) {
        if (deletetext.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.confirmDeleteValidity = true;
        } else {
            $scope.confirmDeleteValidity = false;
        }
    }
}
function CSLinkJobModalCtrl($scope, $http, prefix, $modalInstance, $rootScope) {
        $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
        $scope.loading = false;
        $scope.closebutton = true;
        $scope.deletebutton = true;

        if ($scope.listData.length == 0) {
            $scope.deletebutton = true;
        }else{
            $scope.deletebutton = false;
        }

        $scope['delete'] = function() {

            $scope.moduleParams = "&linkJob=" + $scope.linkJob;

            $http.post(prefix + $scope.deleteUrl, $scope.deleteParams + $scope.selectedId + $scope.moduleParams).
            success(function(data, status) {
                if (status == 200) {
                    $scope.loading = false;
                    $scope.closebutton = true;
                    $scope.deletebutton = true;
                    $scope.close();
                    $scope.deleteFunc();
                }
            })
        }

        $scope.opts = {
            backdropFade: true,
            dialogFade: true
        }

        $scope.close = function() {
            // Raja: (19/12/2015) Commented below as otherwise scope set variable on local $scope and subsequent delete failed COM-4756
            //$scope.selectedId = '';
            $scope.shouldBeOpen = false;
            $scope.$emit('modal:close');
            $modalInstance.close();
        }

        window.onkeydown = function (evt) {
            if (evt.keyCode == 27) {
                $scope.close();
            }
        }
        $rootScope.$on('event:escape', function () {
            $scope.close();
        });
        $scope.showSendButton = function(value) {
            if (value) {
                $scope.deletebutton = true;
            } else {
                $scope.deletebutton = false;
            }
        }
}
function CSConfirmationModalCtrl($scope, $http, prefix, $modalInstance, $rootScope, warningModal) {
    $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
    $scope.confirmDeleteValidity = true;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.deletebutton = true;
    $scope.confirmText = '';
    $scope.mergeHide = true;

    $scope['delete'] = function () {
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.confirmDeleteValidity = true;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            if($scope.callUrl !== ''){
                $http.post(prefix + $scope.callUrl, $scope.callParams).success(function (data, status) {
                    if (status == 200) {
                        $scope.completeAction();
                    }
                }).error(function (data, status) {
                    if(status == 400 ){
                        $scope.completeAction();
                        if(data.errorMsg == "supplier_invoice_raised_bulk"){
                            warningModal.show('parts.cannot.cancelled.due.completed.po.description', 'parts.cannot.cancelled.due.completed.po.title', data.errorMsg);
                        } else if(data.errorMsg == "supplier_invoice_raised"){
                            warningModal.show('supplier.invoice.raised.description', 'supplier.invoice.raised.title', data.errorMsg);
                        } else if(data.errorMsg == "multiple_invoice_raised_po"){
                            warningModal.show('multiple.invoice.raised.po', 'supplier.invoice.raised.title', data.errorMsg);
                        }
                    }
                })
            }else{
                $scope.completeAction();
            }
        } else {
            $scope.confirmDeleteValidity = false;
        }
    }

    $scope.completeAction = function (){
        $scope.closebutton = true;
        $scope.deletebutton = true;
        $scope.close();
        if($scope.category =='customerSLA'){
            $rootScope.$broadcast('closeAllSidepanels');
        }
        $scope.completeFunc();
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function () {
        $scope.confirmText = this.confirmText = '';
        $scope.shouldBeOpen = false;
        $scope.confirmDeleteValidity = true;
        $scope.$emit('modal:close');
        $modalInstance.close();
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }
    $rootScope.$on('event:escape', function () {
        $scope.close();
    });
    $scope.showDeleteButton = function(deletetext) {
        if (deletetext.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.confirmDeleteValidity = true;
        } else {
            $scope.confirmDeleteValidity = false;
        }
    }
}
function CSEstimateModalCtrl($scope, $http, $state, prefix, $modalInstance) {
        $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
        $scope.confirmDeleteValidity = true;
        $scope.deletebuttondisabled = true;
        $scope.loading = false;
        $scope.closebutton = true;
        $scope.deletebutton = true;
        $scope.confirmText = '';

        var matches = $state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);

        $scope.cloneOption = function cloneOption() {
            $scope.additionalOptionButton = true;
            if ($scope.additional_option === false) {
                return;
            } else {
                if (matches[5] == 'additional_option') {
                    $scope.choose_option_to_clone = true;
                }
            }
        }

        $scope.makeNewOption = function makeNewOption() {
            $scope.additionalOptionButton = true;
            if ($scope.additional_option === false) {
                return;
            } else {
                $scope.choose_option_to_clone = false;
            }
        }

        if($scope.buttonValue == "Reject"){
            $scope.checkDropdownValidation = function checkDropdownValidation(confirmText,select_estimate_reject_reason) {
                if(confirmText.toLowerCase()=='reject' && select_estimate_reject_reason) {
                    $scope.deletebuttondisabled = false;
                } else {
                    $scope.deletebuttondisabled = true;
                }
            }
        } else if($scope.buttonValue == "Accept"){
            $scope.checkDropdownValidation = function checkDropdownValidation(confirmText) {
                if(confirmText.toLowerCase()=='accept') {
                    $scope.deletebuttondisabled = false;
                } else {
                    $scope.deletebuttondisabled = true;
                }
            }
        }

        $scope['delete'] = function() {
            if ($scope.deleteUrl == '/accept_or_reject') {
                $scope.deleteParams = "acceptReject[estimateOptionObject]=" + JSON.stringify(this.options) + "&acceptReject[additionalComments]=" + this.additionalComments + "&acceptReject[confirmText]=" + this.confirmText + "&acceptReject[select_estimate_reject_reason]=" + this.select_estimate_reject_reason;
            } else if ($scope.deleteUrl == '/additional_work_accept_or_reject'){
                $scope.deleteParams = "acceptReject[estimateOptionObject]=" + JSON.stringify(this.options) + "&acceptReject[additionalComments]=" + this.additionalComments + "&acceptReject[confirmText]=" + this.confirmText + "&acceptReject[select_estimate_reject_reason]=" + this.select_estimate_reject_reason + "&acceptReject[jobId]=" + $scope.$parent.jobId;
            } else {
                $scope.close();
                $scope.$emit('AdditionalOption', {
                    add_option_method: this.add_option_method,
                    select_estimate_options: this.select_estimate_options
                });
            }

            if($scope.modalToDisplay == 2) {
                $scope.checkDropdownValidation(this.confirmText,this.select_estimate_reject_reason);
            } else {
                $scope.deletebuttondisabled = false;
            }

            if (this.confirmText.toLowerCase() ==  (!angular.isUndefined($scope.textToConfirm) && $scope.textToConfirm.toLowerCase())) {
                if($scope.deletebuttondisabled == false) {
                    $scope.selectedId = '';
                    $scope.confirmText = this.confirmText = '';
                    $scope.shouldBeOpen = false;
                    $scope.confirmDeleteValidity = true;
                    $scope.loading = true;
                    $scope.closebutton = false;
                    $scope.deletebutton = false;
                    $http.post(prefix + $scope.deleteUrl, $scope.deleteParams).
                        success(function (data, status) {
                            if (status == 200) {
                                $scope.loading = false;
                                $scope.closebutton = true;
                                $scope.button = true;
                                $scope.close();
                                if(data.jobId){
                                    $scope.deleteFunc({selectedId: data.selectedId, jobId: data.jobId, bookDiaryEvent: $scope.bookDiaryEvent});
                                } else {
                                    $scope.deleteFunc({selectedId: data.selectedId});
                                }
                            }
                        })
                } else {
                    $scope.confirmDeleteValidity = true;
                }
            } else {
                $scope.confirmDeleteValidity = false;
                $scope.deletebuttondisabled = false;
                $scope.loading = false;
                $scope.closebutton = true;
                $scope.button = true;
            }
        }
        $scope['change_communication_preference'] = function() {
            $scope.close();
            $scope.$emit('changeCommunicationPreference', { automatic_statement : this.automatic_statement, automatic_debt_chasing: this.automatic_debt_chasing, after_sales_care :this.after_sales_care});
        }
        $scope['communication_preference'] = function() {
            $scope.close();
            $scope.$emit('communicationPreference', { PhoneCall :this.PhoneCall, Email :this.Email, Sms:this.Sms, Letter :this.Letter, PhoneRemove :this.PhoneRemove, EmailRemove :this.EmailRemove, SmsRemove:this.SmsRemove, LetterRemove :this.LetterRemove });
        }

        $scope.opts = {
            backdropFade: true,
            dialogFade: true
        }

        $scope.close = function() {
            $scope.selectedId = '';
            $scope.confirmText = this.confirmText = '';
            $scope.shouldBeOpen = false;
            $scope.confirmDeleteValidity = true;
            $scope.deletebuttondisabled = true;
            $modalInstance.close();
        }

        window.onkeydown = function (evt) {
            if (evt.keyCode == 27) {
                $scope.close();
            }
        }

        $scope.$on('event:escape', function () {
            $scope.close();
        });
}
function CSNewModalCtrl($scope, $http, prefix, $modalInstance){
    $scope = $scope.$parent;    // bootstrap modal creates a new scope instance, get its parent
    $scope.confirmDeleteValidity = true;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.deletebutton = false;
    $scope.invoiceSelectedValue = '';

    $scope.confirmText = '';
    $scope.selectCategories=false;

    $scope.hasOnlyOneItem = false;
    $http.get(prefix + $scope.dropdownlistUrl).success(function (data) {
        if ($scope.dropdownlistUrl == "/listInvoiceCategories?invoiceList=all") {
            $scope.hasOnlyOneItem  = data.invoiceCategories.length == 1;
        }else if ($scope.dropdownlistUrl == '/listInvoiceItemCategories?invoiceList=all') {
            $scope.hasOnlyOneItem  = data.invoiceItemCategories.length == 1;
        }else if ($scope.dropdownlistUrl == '/listUserGroups?userList=all') {
            $scope.hasOnlyOneItem  =  data.userGroups.length == 1;
        }else if ($scope.dropdownlistUrl == '/listCustomerTypes?customerList=all') {
            $scope.hasOnlyOneItem  = data.customertypeslist.length == 1;
        }else if ($scope.dropdownlistUrl == '/listApplianceGroups?applianceGroupList=all' + '&id=' + $scope.selectedId){
            $scope.hasOnlyOneItem = data.applianceGroups.length == 0;
        }else if ($scope.dropdownlistUrl == '/listApplianceType?applianceTypeList=all' + '&id=' + $scope.selectedId){
            $scope.hasOnlyOneItem = data.appliancetypes.length == 0;
        }else if($scope.dropdownlistUrl == '/listCustomerContacts/'+$scope.$parent.customerId+'?id='+$scope.selectedId){
            $scope.hasOnlyOneItem = data.contacts.length == 0;
        }else if($scope.dropdownlistUrl == '/list_tax_items?selectedId='+$scope.selectedId+'&page=&limit='){
            $scope.hasOnlyOneItem = data.taxItemResults == 0;
        }

        if ($scope.hasOnlyOneItem && $scope.screentype != 'appliance' && $scope.screentype != 'tax') {
            $scope.showUnassignInvoice();
        }else if ($scope.hasOnlyOneItem && $scope.screentype == 'appliance'){
            if($scope.canDelete == true){
                $scope.showDeleteAppliance();
            }
        }else if($scope.screentype == 'tax'&& $scope.hasOnlyOneItem && $scope.canDelete == true)
        {
            $scope.showDeleteTaxDescription();
        }
    });

    // load new tranfer invoice modal
    $scope.showTransferInvoice = function() {
        $scope.deletebutton = false;
        $scope.selectCategories = true;
        $scope.template = { url: 'template/delete/deletetransferinvoice_modal.html'};
        $scope.showMainInvoiceModal = true;
        $scope.showTransferInvoiceDelete = false;
        $http.get(prefix + $scope.dropdownlistUrl).success(function (data) {
            if ($scope.dropdownlistUrl == "/listInvoiceCategories?invoiceList=all") {
                $scope.dropdownlistValue = data.invoiceCategories;
            }else if ($scope.dropdownlistUrl == '/listInvoiceItemCategories?invoiceList=all') {
                $scope.dropdownlistValue = data.invoiceItemCategories;
            }else if ($scope.dropdownlistUrl == '/listUserGroups?userList=all') {
                $scope.dropdownlistValue = data.userGroups;
            }else if ($scope.dropdownlistUrl == '/listCustomerTypes?customerList=all') {
                $scope.dropdownlistValue = data.customertypeslist;
            }else if ($scope.dropdownlistUrl == '/listApplianceGroups?applianceGroupList=all' + '&id=' + $scope.selectedId){
                $scope.dropdownlistValue = data.applianceGroups;
            }else if ($scope.dropdownlistUrl == '/listApplianceType?applianceTypeList=all' + '&id=' + $scope.selectedId){
                $scope.dropdownlistValue = data.appliancetypes;
            }else if($scope.dropdownlistUrl == '/listCustomerContacts/'+$scope.$parent.customerId+'?id='+$scope.selectedId){
                $scope.dropdownlistValue = data.contacts;
            }else if($scope.dropdownlistUrl == '/list_tax_items?selectedId='+$scope.selectedId+'&page=&limit=') {
                $scope.dropdownlistValue = data.taxItemResults;
            }else if ($scope.dropdownlistUrl == '/listAssetGroups?assetGroupList=all' + '&id=' + $scope.selectedId){
                $scope.dropdownlistValue = data.assetGroups;
            }
        });
        $scope.changeList = function() {
            this.$parent.confirmText = '';
            $scope.invoiceSelectedValue = this.selectedItem;
            if($scope.invoiceSelectedValue >= 1){
                $scope.showTransferInvoiceDelete = true;
            }else{
                $scope.showTransferInvoiceDelete = false;
            }
        }
    }

    $scope.goBack = function() {
        this.$$childTail.selectedItem = '';
        this.$$childTail.confirmText = '';
        $scope.showMainInvoice();
    }

    $scope.deletes = function() {
        if(this.$$childTail.confirmText){
            this.confirmText = this.$$childTail.confirmText;
        }
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            //this.confirmText = "";
            $scope.confirmDeleteValidity = true;
            $scope.loading = true;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            $scope.deleteParams = $scope.formtypename+"[id]="+ $scope.selectedId +"&"+$scope.formtypename+"[mergeid]="+ $scope.invoiceSelectedValue;
            $http.post(prefix + $scope.deleteUrl, $scope.deleteParams).
                success(function(data, status) {
                    if (status == 200) {
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.deletebutton = true;
                        $scope.close();
                        var valueToSubtract = 0;
                        if($scope.totalEntries % $scope.limit == 1 && $scope.$parent.currentPage > 1) {
                            valueToSubtract = 1;
                        }
                        $scope.deleteFunc({ page: $scope.$parent.currentPage - valueToSubtract });
                    }
                })
        } else {
            $scope.confirmDeleteValidity = false;
            $scope.loading = false;
        }
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }
    $scope.close = function() {
        $scope.selectedId = '';
        $scope.shouldBeOpen = false;
        this.$parent.confirmText = '';
        $scope.confirmDeleteValidity = true;
        $scope.showMainInvoiceModal = false;
        $scope.selectCategories = false;
        $modalInstance.close();
    }

    // unassign invoice
    $scope.showUnassignInvoice = function() {
        $scope.deletebutton = false;
        $scope.selectCategories = true;
        $scope.template = { url: 'template/delete/deleteunassigninvoice_modal.html'};
        $scope.showMainInvoiceModal = true;
    }

    //delete appliance
    $scope.showDeleteAppliance = function() {
        $scope.deletebutton = false;
        $scope.selectCategories = true;
        $scope.type = 'applianceGroup';
        $scope.template = { url: 'template/delete/deleteappliance_modal.html'};
        $scope.showMainInvoiceModal = true;
    }

    //delete tax description
    $scope.showDeleteTaxDescription = function() {
        $scope.deletebutton = false;
        $scope.selectCategories = true;
        $scope.type = 'taxDescription';
        $scope.template = { url: 'template/delete/deletetaxdescription_modal.html'};
        $scope.showMainInvoiceModal = true;
    }

    $scope.showMainInvoice = function() {
        $scope.selectCategories = false;
        $scope.showTransferInvoiceDelete = false;
        $scope.deletebutton = false;
        $scope.confirmDeleteValidity = true;
        $scope.showMainInvoiceModal = false;
        this.$parent.confirmText = '';
    }
    $scope.showDeleteButton = function(deletetext) {
        if (deletetext.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.confirmDeleteValidity = true;
            $scope.deletebutton = true;
        }
    }
}
function CSPostCodeCtrl($scope, $http, prefix, $modalInstance){
    $scope = $scope.$parent;
    $scope.setAddressValue = function (address) {
        var addressArray = address.split(',');
        var data = {
            'addressLine1': addressArray[0].trim(),
            'addressLine2': addressArray[1].trim(),
            'addressLine3': addressArray[2].trim(),
            'town'  : addressArray[5].trim(),
            'county': addressArray[6].trim()
        };
        $scope.moveData({data:data});
        $scope.shouldBeOpen = false;
        $scope.close();
    }

    $modalInstance.result.then(function() {
        $scope.close();
    }, function() {
        $scope.close();
    });

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.shouldBeOpen = false;
        $scope.$emit('LANDLORDPOSTCODECLOSE', $scope.shouldBeOpen);
        $modalInstance.close();
    }
}
function CSAISetupModalCtrl($scope, $http, prefix, $modalInstance, $timeout, $rootScope, $filter, $q, toastBox, confirmationBoxHelper, accountingInterfaceValidation, tableCollection){
    $scope = $scope.$parent;
    $scope.display_account_doesnt_exist_showdiv = true;
    /*==========================================================================================================================
     The current API integration ~ this should be a GET from the API to return details
     of the users current API integration. If there isn't an integration then we show
     the no-results UI with help about how to set up an integration

     NOTE -- the code here is temporary to mockup reconnecting the package.
     -- it should be replaced with proper API endpoints from the backend
     -- the localstorage code is just for the mockup. Developers should replace
     -- it and get the oAuthToken from the backend.
     ==========================================================================================================================*/
    $scope.getAccountingPackage = function() {

        $scope.current_integration_loaded = false;

        $scope.accounting_integration_setup = false;

        $scope.account = tableCollection.collection['account'];

        if($scope.account) {

            $scope.last_sent_date =  $scope.account.last_sent_date;

            $scope.current_integration_loaded = true;

            $scope.chosen_accounting_package = _.findWhere($scope.accounting_packages, {id: parseInt($scope.account.packageid)});

            // If there's an auth token in the URL then continue the set up, else just store the package
            $scope.handleNewOrContinueSetup();

        }
        else {
            if ($scope.oAuth_token) {
                $scope.accounting_integration_setup = false;
                $scope.continueoAuthSetup();
            } else {
                $scope.handleNewOrContinueSetup();
            }

            $scope.accounting_integration_setup = false;
            $scope.current_integration_loaded = true;
        }
    }

    $scope.handleNewOrContinueSetup = function() {
        $scope.user_has_started_setup_but_not_finished = false;

        if ($scope.account) {
            if ($scope.account.cutoffdate) {
                $scope.accounting_integration_setup = true;
                //$scope.getIntegrationErrors();
            } else {
                $scope.user_has_started_setup_but_not_finished = true;
            }

            $scope.preferences = {
                "same_account_as_previous_integration": "no",
                "import_data_from_accounting_package_into_commusoft": "no",
                "import_invoice_category_from_accounting_package_into_commusoft": "no",
                "import_contacts_from_xero": "yes",
                "send_data_from": "specific_date",
                "send_data_preference": "automatically",
                "sales_nominal": "",
                "bank_nominal": "",
                "notificationEmail": "",
                "accountingEmail": "",
                "selected_business": ""
            };

            $scope.preferences.send_data_preference = $scope.account.senddataoption == 1 ? 'manually' : 'automatically';

            $scope.preferences.send_data_from_specific_date = ($scope.account.cutoffdate)?$scope.account.cutoffdate:new Date;

            $scope.last_sent_date =  $scope.account.last_sent_date;
            //get the business list for sage accounting
            if ($scope.account && $scope.account.details && $scope.account.details.details.businesses) {
                $scope.business_name = $scope.account.details.details.businesses;
            }

        } else {

            $scope.user_has_started_setup_but_not_finished = false;

            $scope.preferences = {
                "same_account_as_previous_integration": "no",
                "import_data_from_accounting_package_into_commusoft": "no",
                "import_invoice_category_from_accounting_package_into_commusoft": "no",
                "import_contacts_from_xero": "yes",
                "send_data_from": "all",
                "send_data_preference": "automatically",
                "sales_nominal": "",
                "bank_nominal": "",
                "notificationEmail": "",
                "accountingEmail": "",
                "selected_business": ""
            };
            $scope.preferences.send_data_from_specific_date = new Date;
        }
    }

    $scope.getQueryString = function ( field, url ) {
        var href = url ? decodeURIComponent(url) : decodeURIComponent(window.location.href),
            reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' ),
            string = reg.exec(href);

        return string ? string[1] : null;
    };

    $scope.oAuth_token = $scope.getQueryString('oAuthToken');

    $scope.oAuth_secret = $scope.getQueryString('oAuthSecret');

    $scope.oAuth_session_handle = $scope.getQueryString('oAuthSessionHandle');

    $scope.realmId = $scope.getQueryString('realmId');

    $scope.package_username = $scope.getQueryString('packageUsername');

    $scope.continueoAuthSetup = function() {
        /*==========================================================================================================================
         If the user is returning to the UI from an oAuth setup flow
         we carry on from where we left the user
         ==========================================================================================================================*/
        if ($scope.oAuth_token) {
            // $scope.chosen_accounting_package = $scope.accounting_packages[6];
            // $scope.setChosenAccountingPackageFromExistingPackage();

            var package_id = parseInt(localStorage.getItem('package_id')),
                user_is_reconnecting = localStorage.getItem('reconnecting_package') == 'true',
                accountingEmail = localStorage.getItem('accountingEmail');
            $scope.accountingEmail = accountingEmail;
            
            if (user_is_reconnecting) {
                $scope.reconnecting_package = true; // This is set to true to remove the unwanted tabs in the modal
                localStorage.removeItem('reconnecting_package');
                $scope.account_setup_successful = true;
                $scope.handleNewOrContinueSetup();
                $scope.setPackageAuthenticationRequirements();
                $scope.setupAccount();
                $scope.handleStep(13);
                //$scope.completeIntegrationSetup();
            } else {
                $scope.chosen_accounting_package = _.find($scope.accounting_packages, function(packages) {
                    return packages.id === package_id;
                });
                $scope.account_setup_successful = false;
                $scope.handleNewOrContinueSetup();
                $scope.setPackageAuthenticationRequirements();
                $scope.handleStep(5);
                $scope.setupAccount();
            }
        }
    }

    /*==========================================================================================================================
     Get the error logs from the API integration -
     NOTE -- this should also be replaced with a backend call to return the errors for the accounting integration
     ==========================================================================================================================*/


    $scope.continueIntegration = function() {
        $scope.chosen_accounting_package = _.find($scope.accounting_packages, function(packages) {
            return packages.id === $scope.account.packageid;
        });
        $scope.handleNewOrContinueSetup();
        if ($scope.chosen_accounting_package.show_import_screen === true) {
            $scope.account_setup_successful = true;
            $scope.handleStep(5);
        } else {
            if($scope.account.isvalid == false) {
                $scope.handleStep(7);
            }
            else if($scope.account.isvalid == true) {
                $scope.downloadInterface();
            }
        }
    }

    /*==========================================================================================================================
     Different package characteristics show different steps
     This function contains the logic to show which step when
     ==========================================================================================================================*/
    $scope.goToNextStep = function() {
        $scope.loading = true;
        var step = $scope.step;
        if (step !== 17) {
          var web_based = $scope.chosen_accounting_package.web_based,
            oAuth = $scope.chosen_accounting_package.oAuth,
            reconnecting_package = $scope.reconnecting_package;
        } 
        if (step === 1) {
            if (web_based !== true) {
                $scope.handleStep(3);
                if ($scope.chosen_accounting_package.id == 10) {
                    $scope.setupAccount();
                }
            }
            else if (web_based === true && oAuth === true) {
                $scope.oAuthAuthenticate();
            }
            else if (web_based === true && oAuth === false) {
                $scope.handleStep(3);
            }
        }
        else if (step === 3) {
            var should_validate_credentials = (
                        web_based === false &&
                        $scope.chosen_accounting_package.show_import_screen !== true &&
                        $scope.re_downloading_quickbooks_config !== true &&
                        $scope.chosen_accounting_package.id != 10
                    ) || (
                        web_based === true && oAuth === false
                    ),
                re_downloading_quickbooks_config = $scope.re_downloading_quickbooks_config === true,
                should_show_import_screen = $scope.chosen_accounting_package.show_import_screen === true;

            if (should_validate_credentials) {
                $scope.handleStep(4);
            }
            else if (re_downloading_quickbooks_config) {
                $scope.close();
            }
            else { // In the case of QBD
                if (should_show_import_screen) {
                    $scope.handleStep(5);
                }
                else {
                    $scope.handleStep(7);
                }
            }
        }
        else if (step === 4) {
            if (web_based === false) {
                $scope.setupAccount();
            }
            else if (web_based === true && oAuth === false) {
                $scope.setupAccount();
            }
        }
        else if (step === 5) {
            if ($scope.chosen_accounting_package.show_import_screen === true || web_based === true && reconnecting_package !== true) {
                $scope.handleStep(6);
            }
            else if (web_based === false && reconnecting_package !== true) {
                $scope.handleStep(7);
            }
            else if (reconnecting_package) {
                $scope.handleStep(13);
                if (web_based === false) {
                    $scope.pollDesktopInterface();
                }
            }
        }
        else if (step === 6) {
                $scope.setupImportFromPackage().then(function () {
                    $scope.handleStep((packageArray.includes($scope.chosen_accounting_package.id))?14:7);
                }, function () {
                    console.log('The request was invalid');
                    $scope.handleStep(5);
                });
         }
        else if (step === 7) {
            if($scope.preferences.send_data_from == 'specific_date') {
                $scope.chosen_specific_date = moment($scope.preferences.send_data_from_specific_date).format('dddd, Do MMMM, YYYY');
            }
            if($scope.preferences.send_data_from == 'all') {
                $scope.chosen_specific_date = moment($scope.account.commusoft_start_date).format('dddd, Do MMMM, YYYY');
            }
            if($scope.preferences.send_data_from == 'last_successful') {
                $scope.chosen_specific_date = moment($scope.account.last_sent_date).format('dddd, Do MMMM, YYYY');
            }
            if($scope.preferences.selected_business) {
                var selected_business_name = angular.fromJson($scope.preferences.selected_business);
                $scope.selected_business_name = selected_business_name.businessname;
            }

            console.log('Page title : ' + $scope.title);

            if($scope.title != 'Change cut off date') {
              $scope.validateAccount().then(function() {
                  $scope.handleStep(8);
              }, function() {
                  console.log('The request was invalid');
                  $scope.handleStep(7);
              });
            } else {
              $scope.handleStep(8);
            }
        }
        else if (step === 8) {
            $scope.setupCutoffDate().then(function() {
                if ($scope.changing_cut_off_date === true) {
                    toastBox.show($scope.chosen_accounting_package.name + ' cut off date saved', 5000);
                    $scope.close();
                } else {
                    $scope.handleStep(11);
                }
            }, function() {
                console.log('The request was invalid');
                $scope.handleStep(7);
            })
        }
        // else if (step === 9) {
        //    accountingInterfaceValidation.validateCredentials($scope.preferences).then(function() {
        //     		$scope.handleStep(10);
        //     	}, function(resp) {
        //     		$scope.preferences.errors = resp;
        //     	})
        //
        //
        //     }
        //else if (step === 10) {
        //     	$scope.handleStep(11);
        //}

        else if (step === 11) {
            $scope.handleStep(12);
        }
        else if (step === 12) {
            $scope.handleStep(13);
            $scope.setupSendDataPreferences().then(function() {
                if ($scope.chosen_accounting_package.id != 10) {
                    // User setting up sage -> validate
                    $scope.pollDesktopInterface();
                } else {
                    // User setting up quickbooks desktop -> don't need to validate
                    $scope.setting_up_desktop_interface = false;
                    $scope.desktop_integration_successful = true;
                    $scope.completeIntegrationSetup();
                }
            }, function() {
                // On error
                console.log('Something went wrong');
                // $scope.handleStep(11);

                // REMOVE THIS WHEN IMPLEMENTATION OF PUT IS CORRECT
                if ($scope.chosen_accounting_package.id != 10) {
                    // User setting up sage -> validate
                    $scope.pollDesktopInterface();
                } else {
                    // User setting up quickbooks desktop -> don't need to validate
                    $scope.setting_up_desktop_interface = false;
                    $scope.desktop_integration_successful = true;
                    $scope.completeIntegrationSetup();
                }
            })
        }
        else if (step === 13) {
            // User is trying to install the sage interface again
            if ($scope.desktop_integration_successful === false ||
                $scope.re_downloading_interface === true) {
                $scope.handleStep(13);
                $scope.pollDesktopInterface();
            }
            else {
                $scope.close();
                $scope.$emit('DeletedAccount', null);
            }
        }
        else if (step === 14) {
            if ($scope.chosen_accounting_package.show_import_screen === true || web_based === true && reconnecting_package !== true) {
                $scope.handleStep(15);
            }
            else if (web_based === false && reconnecting_package !== true) {
                $scope.handleStep(7);
            }
            else if (reconnecting_package) {
                $scope.handleStep(13);
                if (web_based === false) {
                    $scope.pollDesktopInterface();
                }
            }
        }
        else if (step === 15) {
            $scope.setupImportInvoiceCategoryFromPackage().then(function() {
                $scope.handleStep(7);
            }, function() {
                console.log('The request was invalid');
                $scope.handleStep(14);
            });
        }
        else if (step === 16) {
            $scope.setupEditSettings().then(function() {  //change settings module function
                if ($scope.edit_settings === true) {
                    toastBox.show('Edited changes saved', 5000);
                     $scope.close();
                }
            }, function() {
                console.log('The request was invalid');
            });
        }
        else if (step === 17) {
          $scope.setupAccountingEmail().then(function() {
            $scope.step = 1;
            $scope.loading = false;
          }, function() {
             console.log('The request was invalid Step 17');
          });
        }
    }
    
    $scope.setupAccountingEmail = function() {
      console.log('Checking integration email already exist at AI ' + $scope.preferences.accountingEmail);
      $scope.loading = true;
      $scope.checkAlreadyExists = true;
      var deferred = $q.defer();
      $http.post(prefix + '/check_accountsetupemail', 'email=' + $scope.preferences.accountingEmail)
          .success(function (resp) {
            $scope.accountingEmail = $scope.preferences.accountingEmail;
              $scope.loading = false;
              $scope.checkAlreadyExists = false;
              $scope.emailAlreadyExist = false;
              deferred.resolve();
          }).error(function(resp) {
              $scope.loading = false;
              $scope.checkAlreadyExists = true;
              deferred.reject();
          });
        return deferred.promise;
    }

    /*==========================================================================================================================
     Confirmations are made after each form. The idea is that the user will
     not be able to return to a previous step once the confirmation is made
     ==========================================================================================================================*/
    $scope.resetConfirmations = function() {
        $scope.confirmation = {};
        $scope.confirmation.user_has_confirmed_package_details = false;
        $scope.confirmation.user_has_installed_interface = false;
        $scope.confirmation.user_has_confirmed_import = false;
        $scope.confirmation.user_has_confirmed_cut_off = false;
    }

    /*==========================================================================================================================
     Take the user to the next stage
     This function handles showing and hiding the footer,
     showing loading states and changing the button text.
     ==========================================================================================================================*/
    $scope.enableNextButton = function($event, formStatus) {
        if($scope.step != 1 && $scope.step != 17) {
            if ($scope.chosen_accounting_package.id == 5 && ($scope.title == "Adjust transfer settings" || $scope.title == "Change cut off date")) {
                $scope.button_disabled = false;
            } else if( $scope.step == 7 && $scope.chosen_accounting_package.id == 7 ) {
                if ($scope.preferences.notificationEmail !== '' && $scope.preferences.notificationEmail != undefined){
                    if( $scope.preferences.same_account_as_previous_integration == "no" && (
                        $scope.preferences.selected_business != "" &&
                        $scope.preferences.selected_business != undefined )){
                        $scope.button_disabled = false;
                    } else {
                        if ($scope.preferences.same_account_as_previous_integration == "yes") {
                            $scope.button_disabled = false;
                        } else {
                            $scope.button_disabled = true;
                        }
                    }
                } else {
                    $scope.button_disabled = true;
                }
            } else {
                $scope.button_disabled = (formStatus) ? false : true;
            }
        } else if($scope.step == 17) {
           $scope.button_disabled = (formStatus) ? false : true;
        }
    }
    $scope.disableNextButton = function(flag) {
        $scope.button_disabled = flag;
    }
    $scope.handleStep = function(step) {
        $scope.loading = true;
        // Set defaults
        $scope.show_back_link = false;
        $scope.button_disabled = false;
        $scope.hide_footer = false;
        $scope.resetConfirmations();

        console.log("The current step is: " + step);
        $scope.step = step;

        if (step !== 1) {
            var web_based = $scope.chosen_accounting_package.web_based,
                oAuth = $scope.chosen_accounting_package.oAuth,
                reconnecting_package = $scope.reconnecting_package;
        }

        if (step === 1) {
            $scope.userHasGoneBackToStep1();
            $scope.resetAccountingPackageAuthenticationErrors();
            // The above is needed if the user enters incorrect information, then chooses another package
        }
        else if (step === 3) {
            if ($scope.reconnecting_package !== true && $scope.re_downloading_quickbooks_config !== true) {
                $scope.show_back_link = true;
            }
            else {
                $scope.show_back_link = false;
            }

            if (web_based === true && oAuth === true) {
                $scope.hide_footer = true;
            }

            if (web_based === false || (web_based === true && oAuth === false)) {
                $scope.button_text = "Next step"
            }
            else {
                $scope.button_text = "Choose package"
            }
            $scope.button_disabled = true;

            if($scope.chosen_accounting_package.id == 5 && $scope.title == "Edit package credentials") {
                $scope.button_disabled = false;
            }

            if($scope.chosen_accounting_package.id == 1 && $scope.title == "Edit package credentials") {
                $scope.button_disabled = ($scope.accounting_package_authentication.username != '' && $scope.accounting_package_authentication.password != '')?false:true;
            }
        }
        else if (step === 4) {
            if (web_based === false || (web_based === true && oAuth === false)) {
                $scope.show_back_link = true;
            }
            if (web_based === false) {
                $scope.button_text = "Next step"
            }
            else if (web_based === true &&
                oAuth === false) {
                $scope.button_text = "Next step"
            }

            $scope.button_disabled = true;
        }
        else if (step === 5) {
            $scope.button_text = "Next step";

            if (web_based === false && $scope.chosen_accounting_package.show_import_screen !== true) {
                $scope.button_disabled = true;
            }
        }
        else if (step === 6) {
            $scope.show_back_link = true;
            $scope.button_disabled = true;
            $scope.button_text = "Next step"
        }
        else if (step === 7) {
            if($scope.title != 'Change cut off date') {
              $scope.button_disabled = true;
            }
            $scope.created_on_date_time = moment($scope.account.commusoft_start_date).format('dddd, Do MMMM, YYYY');
            $scope.button_text = "Next step"
            if($scope.preferences.notificationEmail !== '') {
              $scope.button_disabled = false;
            }
        }
        else if (step === 8) {
            $scope.show_back_link = true;
            $scope.button_disabled = true;
            $scope.button_text = "Next step"
        }
        // else if (step === 9) {
            // 	$scope.button_text = "Next step"
            // } else if (step === 10) {
            // 	$scope.show_back_link = true;
            // 	$scope.button_disabled = true;
            // 	$scope.button_text = "Next step" }
       else if (step === 11) {
            $scope.button_text = "Next step"
       }
       else if (step === 12) {
            $scope.show_back_link = true;
            $scope.button_disabled = true;
       }
       else if (step === 13) {
            if ($scope.reconnecting_package) {
                $scope.button_disabled = true;
                $scope.hide_footer = true;
            }
            $scope.button_text = "Close"
       }
        else if (step === 14) {
            $scope.button_text = "Next step";

            if (web_based === false && $scope.chosen_accounting_package.show_import_screen !== true) {
                $scope.button_disabled = true;
            }
        }
        else if (step === 15) {
            $scope.show_back_link = true;
            $scope.button_disabled = true;
            $scope.button_text = "Next step"
        }
        else if (step === 16) {
            $scope.button_text = "Save"
        } 
        else if (step === 17) {
          if($scope.preferences.accountingEmail !== '') {
            $scope.button_disabled = false;
          }
        }
        $scope.loading = false;
    }

    /*==========================================================================================================================
     Take the user back a stage. This is used when the user
     clicks 'go back' from the confirmation UIs
     ==========================================================================================================================*/
    $scope.handlePreviousStep = function() {
        if($scope.step > 1) {
          var step_to_go_to = $scope.step - 1;
        } else {
          var step_to_go_to = 1;
        }
        if ($scope.step === 3) {
            step_to_go_to = 1;
        }
        else if ($scope.step === 11) {
            step_to_go_to = 8;
        }

        if (step_to_go_to === 1) {
            $scope.handleStep(step_to_go_to);
            $scope.button_text = "Choose package";
            $scope.button_disabled = true;
        }
        else {
            $scope.handleStep(step_to_go_to);
        }
    }

    $scope.userHasGoneBackToStep1 = function() {
        $scope.chosen_accounting_package = undefined;
        _.findWhere($scope.accounting_packages, {chosen: true}).chosen = false;
    }

    /*==========================================================================================================================
     Functions for setting up the integration
     ==========================================================================================================================*/
    $scope.chooseAccountingPackage = function(accounting_package) {
        for (var i = 0, x = $scope.accounting_packages.length; i < x; i ++) {
            $scope.accounting_packages[i].chosen = false;
        }

        accounting_package.chosen = true;
        $scope.button_disabled = false;
        $scope.chosen_accounting_package = accounting_package;

        $scope.setup_confirmation_message = "You have chosen to integrate with " + $scope.chosen_accounting_package.name + ".";

        if ($scope.chosen_accounting_package.interface === true) {
            $scope.setup_confirmation_message += " Click confirm to setup the integration."
        }
        else {
            $scope.setup_confirmation_message += " Click confirm to authenticate " + $scope.chosen_accounting_package.name
        }
        $scope.setPackageAuthenticationRequirements();
    }

    /*==========================================================================================================================
     Allow the user to confirm their form inputs, disable the button if the user hasn't confirmed.
     ==========================================================================================================================*/
    $scope.handleConfirmation = function(confirmation) {
        confirmation = !confirmation;
        $scope.button_disabled = true;

        if (confirmation === true) {
            $scope.button_disabled = false;
        }
    }
    $scope.showHideLastSuccessful = false;
    $scope.showBusinessList = true;

    $scope.showImportOption = function(type) {
      if(type == 'same') {
        $scope.showHideLastSuccessful = true;
        $scope.showBusinessList = false;
        $scope.button_disabled = true;
        if ($scope.preferences.notificationEmail !== '' && $scope.preferences.notificationEmail != undefined) {
            $scope.button_disabled = false;
        }
      } else if(type == 'different') {
        $scope.showHideLastSuccessful = false;
        $scope.showBusinessList = true;
        $scope.button_disabled = true;
        if ( $scope.preferences.notificationEmail !== '' && $scope.preferences.notificationEmail != undefined){
            if( $scope.chosen_accounting_package.id == 7 &&
                $scope.preferences.selected_business != '' &&
                $scope.preferences.selected_business != undefined) {
                $scope.button_disabled = false;
            } else if($scope.chosen_accounting_package.id != 7) {
                $scope.button_disabled = false;
            }
        }
      }
    }


    /*==========================================================================================================================
     Set up the defaults such as username, password, db location on a per-package basis
     ==========================================================================================================================*/
    $scope.setPackageAuthenticationRequirements = function() {
        $scope.accounting_package_id = '';

        if ($scope.chosen_accounting_package) {
            $scope.accounting_package_id = $scope.chosen_accounting_package.id
        }
        else {
            $scope.accounting_package_id = ($scope.account)?$scope.account.packageid:'';
        }

        // Set up default preferences
        $scope.accounting_package_authentication = {
            "username": ($scope.account)?$scope.account.details.details.username:"",
            "password": ($scope.account)?$scope.account.details.details.password:""
        };

        // Sage specific inputs
        if ($scope.accounting_package_id == 5) {
            var vatMethod = "";
            if ($scope.account) {
                vatMethod = ($scope.account.details.details.vatMethod === 'Standard') ? "invoice" : "cash";
            }
            $scope.accounting_package_authentication = {
                "username": ($scope.account)?$scope.account.details.details.username:"",
                "password": ($scope.account)?$scope.account.details.details.password:"",
                "db_location": ($scope.account)?$scope.account.details.details.dbLocation:"",
                "vat_method": vatMethod,
                "20_VAT_code": ($scope.account)?$scope.account.details.details.vatTwnety:"",
                "5_VAT_code": ($scope.account)?$scope.account.details.details.vatFive:"",
                "0_VAT_code": ($scope.account)?$scope.account.details.details.vatZero:"",
                "Domestic_20_VAT_code": ($scope.account)?$scope.account.details.details.vatDomesticTwnety:"",
                "Domestic_5_VAT_code": ($scope.account)?$scope.account.details.details.vatDomesticFive:"",
            }
        }
    }

    /*==========================================================================================================================
     Setup a package that not oAuth
     ==========================================================================================================================*/
    $scope.setupAccount = function() {
        $scope.hide_footer = true;
        $scope.account_setup_successful = false;
        $scope.setting_up_account = true;
        $scope.resetAccountingPackageAuthenticationErrors();

        var setupUrl = ($scope.reconnecting_package == true)?'reconnect_ai_account':'setup_ai_account';
        $http.post(prefix + '/' + setupUrl, 'chosen_accounting_package=' + angular.toJson($scope.chosen_accounting_package)
            + '&accounting_package_authentication=' + angular.toJson($scope.accounting_package_authentication)
            + '&oauth_token=' + $scope.oAuth_token
            + '&oauth_secret=' + $scope.oAuth_secret
            + '&oauth_session_handle=' + $scope.oAuth_session_handle
            + '&realmId=' + $scope.realmId
            + '&package_username=' + $scope.package_username
            + '&accounting_email_id=' + $scope.accountingEmail
        )
            .success(function (accounting_package) {
                if(accounting_package && accounting_package.package && accounting_package.package.businesses != null) {
                    //get the business list for sage accounting
                    $scope.business_name = accounting_package.package.businesses;
                }
                if(accounting_package && accounting_package.package.details && accounting_package.package.details.details.packageCompanyName != null) {
                    document.getElementById("accounting_interface_thirdparty_company").innerHTML = accounting_package.package.details.details.packageCompanyName;
                }
                if(accounting_package.errors && accounting_package.errors.db_location) {
                    $scope.setting_up_account = false;
                    $scope.handleStep(3);

                    $scope.accounting_package_authentication.errors = accounting_package.errors.db_location;

                    $scope.show_invalid_db_location = true;
                }
                else if(accounting_package.errors == 'Email is already used') {
                    $scope.setting_up_account = false;
                    $scope.handleStep(3);

                    $scope.accounting_package_authentication.errors = accounting_package.errors+", please change email address";

                    $scope.show_invalid_email = true;

                    $scope.button_disabled = true;
                }
                else if(accounting_package.errors == 'Invalid account used') {
                    $scope.setting_up_account = false;
                    $scope.handleStep(3);
                    //$scope.show_invalid_email = true;
                    $scope.accounting_package_authentication.errors = accounting_package.errors;

                    $scope.show_invalid_organisation = true;

                    $scope.button_disabled = true;
                }
                else if(accounting_package.errors == 'Invalid username/password') {
                    $scope.setting_up_account = false;
                    $scope.handleStep(3);

                    $scope.accounting_package_authentication.errors = accounting_package.errors;

                    $scope.show_invalid_username_or_password = true;

                }
                else if(accounting_package.errors == 'Account doesnt exist') {
                    $scope.setting_up_account = false;
                    $scope.handleStep(3);

                    $scope.accounting_package_authentication.errors = accounting_package.errors;

                    $scope.show_account_doesnt_exist = true;
                }
                else if(accounting_package.errors == 'account is not valid') {
                    $scope.setting_up_account = false;
                    $scope.handleStep(3);

                    $scope.accounting_package_authentication.errors = accounting_package.errors;

                    $scope.show_invalid_account = true;
                }
                else {
                    $scope.account = accounting_package.package;
                    $scope.last_sent_date =  $scope.account.last_sent_date;
                    if ($scope.reconnecting_package) {
                        $scope.close();
                        if($scope.chosen_accounting_package.id != 5) {
                            $scope.$emit('ai:setup', $scope.account);
                            $scope.setting_up_account = false;
                            $scope.confirmSuccessfulReconnection();
                        }
                        else {
                            $scope.$emit('DeletedAccount', null);
                        }
                    }
                    else if ($scope.chosen_accounting_package.id != 10) {
                        $scope.sage_download_url_token = $scope.account.token;
                        $scope.setting_up_account = false;
                        $scope.hide_footer = false;
                        $scope.account_setup_successful = true;

                        if($scope.account.ismigratedata == 1) {
                            $scope.chosen_accounting_package.show_import_screen = false;
                        }
                        $scope.$emit('ContinueAccount', $scope.account);
                        if ($scope.chosen_accounting_package.show_import_screen === false) {
                            $scope.handleStep(7);
                        } else {
                            $scope.handleStep(5);
                        }
                    }
                    else {

                        $scope.quickbooks_download_url_token = $scope.account.token;
                        $scope.handleGettingQuickbooksPassword();
                        $scope.$emit('ContinueAccount', $scope.account);
                    }
                }
            });
    }

    /*==========================================================================================================================
     Get the quickbooks password that the user must type in to the web connector
     ==========================================================================================================================*/
    $scope.handleGettingQuickbooksPassword = function() {
        $scope.setting_up_account = false;
        $scope.hide_footer = false;
        $scope.account_setup_successful = true;
        $scope.loading = true;
        $http.get(prefix + "/account_password").success(function(resp) {
                $scope.quickbooks_web_connector_password = resp.details.password;
                $scope.loading = false;
                $scope.button_disabled = false;
            });
    }

    /*==========================================================================================================================
     Remove the errors from the authentication used in step 3 for non-oauth integrations
     ==========================================================================================================================*/
    $scope.resetAccountingPackageAuthenticationErrors = function() {
        $scope.accounting_package_authentication.errors = undefined;
        $scope.show_invalid_username_or_password = false;
        $scope.show_invalid_email = false;
	$scope.show_invalid_organisation = false;
        $scope.show_account_doesnt_exist = false;
        $scope.show_invalid_db_location = false;
    }

    /*==========================================================================================================================
     Save the cut off date and handle resetting records
     ==========================================================================================================================*/
    $scope.saving_cutoff_date = false;
    $scope.checkExists = false;
    $scope.setupCutoffDate = function() {
        $scope.saving_cutoff_date = true;
        $scope.checkExists = true;
        var deferred = $q.defer(),
            selected_cut_off_date = new Date; // NOTE that this will need to be last successful import OR the date that the commusoft account started

        if($scope.preferences.send_data_from == 'specific_date') {
            selected_cut_off_date = new Date($scope.preferences.send_data_from_specific_date);
        }

        if($scope.preferences.send_data_from == 'all') {
            selected_cut_off_date = new Date($scope.account.commusoft_start_date);
        }

        if($scope.preferences.send_data_from == 'last_successful') {
            var last_sent_date = $scope.account.last_sent_date.split(" ");
            last_sent_date = last_sent_date[0].split("/");
            selected_cut_off_date = new Date(last_sent_date[2]+ "-" +last_sent_date[1]+ "-" +last_sent_date[0]);
        }

        if($scope.preferences.selected_business) {
            if(typeof($scope.preferences.selected_business) == "object") {
                selected_business = angular.toJson($scope.preferences.selected_business);
            } else {
                selected_business = $scope.preferences.selected_business;
            }
        }
        var date = selected_cut_off_date.getFullYear() + "-" + parseInt(selected_cut_off_date.getMonth() + 1) + "-" + selected_cut_off_date.getDate(),
            save = function () {
                $scope.loading = true;
                $http.post(prefix + '/accounting_packages', 'chosen_accounting_package=' + angular.toJson($scope.chosen_accounting_package)
                    + '&senddataoption=' + 0
                    + '&cutoffdate=' + date
                    + '&selected_business='+selected_business)
                    .success(function (resp) {
                      $scope.loading = false;
                      if(resp == 'Account doesnt exist') {
                        $scope.handleStep(8);
                        $scope.display_account_doesnt_exist = true;
                        $scope.display_account_doesnt_exist_showdiv = false;
                        $scope.show_back_link = false;
                      } else {
                        $scope.saving_cutoff_date = false;
                        $scope.account.cutoffdate = selected_cut_off_date;
                        $scope.account.selected_business = selected_business;
                        $scope.$emit('ai:setup', $scope.account);
                        $scope.last_sent_date =  $scope.account.last_sent_date;
                        $scope.loading = false;
                      }
                        deferred.resolve();
                    }).error(function(resp) {
                        $scope.saving_cutoff_date = false;
                        $scope.loading = false;
                        deferred.reject();
                    });
            };
        console.log('Should call reset_records API #SamePackage: ' + $scope.preferences.same_account_as_previous_integration);
        if ($scope.chosen_accounting_package.previously_integrated === true && $scope.preferences.send_data_from != 'last_successful' && $scope.account.ismigratedata != 1 && $scope.title == "Setup accounting integration") {
            var samepackage = ($scope.preferences.same_account_as_previous_integration == 'yes')?1:0;
            $scope.loading = true;
            $http.post(prefix + '/reset_records', 'date=' + date +'&samepackage=' + samepackage)
                .success(function (resp) {
                    save();
                    $scope.loading = false;
                    deferred.resolve();
                }).error(function(resp) {
                    $scope.saving_cutoff_date = false;
                    $scope.loading = false;
                    deferred.reject();
                });
        } else {
          console.log('Send data from : ' + $scope.preferences.send_data_from);
          // Migrate records at AI if same account and las successful
          var samepackage = ($scope.preferences.same_account_as_previous_integration == 'yes')?1:0;
          if($scope.preferences.send_data_from == 'last_successful' && samepackage == 1) {
            $scope.loading = true;
            $http.post(prefix + '/reset_records', 'sendDataFrom=' + $scope.preferences.send_data_from +'&samepackage=' + samepackage) 
                .success(function (resp) {
                    save();
                    $scope.loading = false;
                    deferred.resolve();
                }).error(function(resp) {
                    $scope.saving_cutoff_date = false;
                    $scope.loading = false;
                    deferred.reject();
                });
          }
          save();
        }
        console.log('Notification Email: '+$scope.preferences.notificationEmail);
        if ($scope.title == "Setup accounting integration") {
            console.log('Should save notification email at AI');
            $http.post(prefix + '/save_notificationemail', 'email=' + $scope.preferences.notificationEmail)
              .success(function (resp) {
                  $scope.checkExists = false;
                  deferred.resolve();
              }).error(function(resp) {
                  $scope.saving_cutoff_date = false;
                  $scope.checkExists = true;
                  deferred.reject();
              });
        }
        return deferred.promise
    }

    $scope.validateAccount = function() {
      var deferred = $q.defer();
      $scope.checkExists = true;
      $scope.errorOccurred = true;
      $scope.errorEmailMsg = "";
      
        console.log('In validate account #title : ' + $scope.title);
        if ($scope.title == "Setup accounting integration" || $scope.title == "Setup accounting integration email ID" ) {
          $http.post(prefix + '/accounting_packages', 'samepackage=' + $scope.preferences.same_account_as_previous_integration
             + '&selected_business='+$scope.preferences.selected_business
          )
          .success(function (resp) {
              if(resp && resp != '') {
                  $scope.preferences.selected_business = resp;
                  $scope.selected_business_name = resp.businessname;
              }
              $scope.errorOccurred = false;
              console.log('Notification Email: '+$scope.preferences.notificationEmail);
              $http.post(prefix + '/save_notificationemail', 'email=' + $scope.preferences.notificationEmail+"&action=validate")
                  .success(function (resp) {
                    $scope.checkExists = false;
                    deferred.resolve();
                  }).error(function(resp) {
                    $scope.checkExists = true;
                    $scope.errorEmailMsg = resp;
                    $scope.loading = false;
                    deferred.reject();
              });
          }).error(function(resp) {
              $scope.errorOccurred = true;
              $scope.errorMsg = resp;
              $scope.loading = false;
              deferred.reject();
          });
        }
        return deferred.promise;
    }

    /*============================================================
     * edit setting code- set preferance ON/OFF
     * ===========================================================*/
    $scope.handleEditSettings = function(edit_pref, import_pref, sent_pref) {
        $scope.preferences = {
            "edit_value" : edit_pref,
            "import_value" : import_pref,
            "mark_as_sent" : sent_pref,
        };
    }
    /*==========================================================================================
     * edit setting code- send ON/OFF value to db
     * =========================================================================================*/
    $scope.saving_edit_settings = false;
    $scope.setupEditSettings = function() {
        $scope.saving_edit_settings = true;
        var deferred = $q.defer();
        $scope.loading = true;

        $http.post(prefix + '/save_edit_settings', 'edit_value='+$scope.preferences.edit_value + '&import_value='+$scope.preferences.import_value + '&mark_as_sent='+$scope.preferences.mark_as_sent)
            .success(function (resp) {
                $scope.saving_edit_settings = false;
                $scope.loading = false;
                deferred.resolve(resp);
            }).error(function(resp) {
                $scope.saving_edit_settings = false;
                $scope.loading = false;
                deferred.reject(resp);
            });
        return deferred.promise;
    }
    /*==========================================================================================================================
     Save the import details
     ==========================================================================================================================*/

    $scope.saving_import_from_package = false;

    $scope.setupImportFromPackage = function() {
        // api/v1/importpackagedata needs to be called
        // when this returns as successful, a PUT to admin/api/v1/accountingpackages
        // with ismigratedata: 2 needs to be made. See
        // https://docs.google.com/document/d/1oCW7Z0_0JVUpFQYg6jmOz-1ZFvseAcSVVAxkOFtNyRY/edit?ts=57063fac

        $scope.saving_import_from_package = true;

        var deferred = $q.defer();
        $scope.loading = true;
        $http.post(prefix + "/import_package_data", 'chosen_accounting_package=' + angular.toJson($scope.chosen_accounting_package)
            + '&preferences=' + angular.toJson($scope.preferences)).success(function(resp) {
            $scope.saving_import_from_package = false;
            $scope.loading = false;
            deferred.resolve(resp);
        });

        return deferred.promise;
    }
    /*==========================================================================================================================
     import the ivoice categories currently for xero only
     ==========================================================================================================================*/

    $scope.setupImportInvoiceCategoryFromPackage = function() {
        // api/v1/invoicecategories needs to be called

        var deferred = $q.defer();
        $scope.loading = true;
        $http.post(prefix + "/import_invoice_category", 'chosen_accounting_package=' + angular.toJson($scope.chosen_accounting_package)
            + '&import_invoice_category=' + $scope.preferences.import_invoice_category_from_accounting_package_into_commusoft).success(function(resp) {
            $scope.loading = false;
            deferred.resolve(resp);
        });

        return deferred.promise;
    }

    /*==========================================================================================================================
     Save the send data preferences
     ==========================================================================================================================*/
    $scope.saving_send_data_preferences = false;

    $scope.setupSendDataPreferences = function() {

        $scope.saving_send_data_preferences = true;

        var deferred = $q.defer(),
            senddataoption = 2;

        if ($scope.preferences.send_data_preference === 'manually') {
            senddataoption = 1;
        }
        $scope.loading = true;
        $http.post(prefix + '/accounting_packages', 'senddataoption='+senddataoption+'&title='+$scope.title)
            .success(function (resp) {
                $scope.saving_send_data_preferences = false;
                $scope.loading = false;
                $scope.account.senddataoption = senddataoption;

                if(senddataoption == 2) {
                    var automatically_send_at = new Date;
                    var nextTime = null;
                    if (automatically_send_at.getHours() == 0 || automatically_send_at.getHours() == 1) {
                        nextTime = '2:00am';
                    }
                    else if (automatically_send_at.getHours() == 2 || automatically_send_at.getHours() == 3) {
                        nextTime = '4:00am';
                    }
                    else if (automatically_send_at.getHours() == 4 || automatically_send_at.getHours() == 5) {
                        nextTime = '6:00am';
                    }
                    else if(automatically_send_at.getHours() == 6 || automatically_send_at.getHours() == 7)
                    {
                        nextTime = '8:00am';
                    }
                    else if(automatically_send_at.getHours() == 8 || automatically_send_at.getHours() == 9)
                    {
                        nextTime = '10:00am';
                    }
                    else if(automatically_send_at.getHours() == 10 || automatically_send_at.getHours() == 11)
                    {
                        nextTime = '12:00pm';
                    }
                    else if(automatically_send_at.getHours() == 12 || automatically_send_at.getHours() == 13)
                    {
                        nextTime = '2:00pm';
                    }
                    else if(automatically_send_at.getHours() == 14 || automatically_send_at.getHours() == 15)
                    {
                        nextTime = '4:00pm';
                    }
                    else if(automatically_send_at.getHours() == 16 || automatically_send_at.getHours() == 17)
                    {
                        nextTime = '6:00pm';
                    }
                    else if(automatically_send_at.getHours() == 18 || automatically_send_at.getHours() == 19)
                    {
                        nextTime = '8:00pm';
                    }
                    else if(automatically_send_at.getHours() == 20 || automatically_send_at.getHours() == 21)
                    {
                        nextTime = '10:00pm';
                    }
                    else if(automatically_send_at.getHours() == 22 && automatically_send_at.getHours() == 23)
                    {
                        nextTime = '12:00am';
                    }

                    $scope.account.automatically_send_at = 'Today at ' + nextTime;
                }

                $scope.$emit('ai:setup', $scope.account);
                deferred.resolve(resp);
            }).error(function(resp) {
                $scope.saving_send_data_preferences = false;
                $scope.loading = false;
                deferred.reject(resp);
            });

        return deferred.promise;
    }

    /*==========================================================================================================================
     Take the user to the oAuth authentication screen
     ==========================================================================================================================*/
    $scope.oAuthAuthenticate = function() {

        localStorage.setItem('package_id', $scope.chosen_accounting_package.id);
        localStorage.setItem('accountingEmail', $scope.accountingEmail);

        window.open('https://'+$rootScope.AIHost+'/connect/' + $scope.chosen_accounting_package.id, "_self");

        $scope.handleStep(3);

        $scope.hide_footer = true;
    }

    /*==========================================================================================================================
     This function is fired when the user should have installed the desktop interface
     for quickbooks or sage line 50. It should poll the desktop interface and handle
     the next step when the interface has been found.

     If the interface is not found we show a warning message explaining what has happened
     and how to re-connect
     ==========================================================================================================================*/
    $scope.pollDesktopInterface = function() {
        $scope.setting_up_desktop_interface = true;
        $scope.desktop_integration_successful = undefined;
        $scope.hide_footer = true;
        $scope.desktop_integration_error = undefined;

        if (($scope.chosen_accounting_package.id == '5' ||
            $scope.chosen_accounting_package.id == '10') && $scope.title != 'Adjust transfer settings' && $scope.title.toLowerCase() != 'download interface') {

            $timeout(function() {
                $scope.loading = true;
                $http.get(prefix + '/validate_sage_service')
                    .success(function (resp) {
                        if (resp.status === "FAIL") {
                            $scope.desktop_integration_error = resp.error;
                            $scope.desktop_integration_successful = false;
                            $scope.setting_up_desktop_interface = false;
                            $scope.button_text = "Next step";
                            $scope.button_disabled = true;
                            $scope.hide_footer = false;
                            $scope.loading = false;
                        }
                        else {
                            $scope.desktop_integration_error = undefined;
                            $scope.setting_up_desktop_interface = false;
                            $scope.desktop_integration_successful = true;
                            $scope.hide_footer = false;
                            $scope.completeIntegrationSetup();
                            $scope.loading = false;
                        }
                    });
            }, 1000);
        } else {
            if($scope.chosen_accounting_package.id == '5' ||
                $scope.chosen_accounting_package.id == '10') {
                $scope.desktop_integration_error = undefined;
            }
            $scope.setting_up_desktop_interface = false;
            $scope.desktop_integration_successful = true;
            $scope.hide_footer = false;
            $scope.completeIntegrationSetup();
        }
    }

    /*==========================================================================================================================
     Handle the UI when the setup is complete - this should load the dashboard in the front-end
     ==========================================================================================================================*/
    $scope.completeIntegrationSetup = function() {
        //$scope.getAccountingPackage();
        if($scope.title == 'Adjust transfer settings') {
            toastBox.show($scope.chosen_accounting_package.name + ' data transfer settings saved', 5000);
            $scope.close();
        }
        if($scope.title.toLowerCase() == 'download interface') {
            //toastBox.show($scope.chosen_accounting_package.name + ' data transfer settings saved', 5000);
            //$scope.close();
            $scope.re_downloading_quickbooks_config = true;
            $scope.re_downloading_interface = false;
            $scope.downloading_interface_screen_one = false;
            $scope.downloading_interface_screen_two = true;
        }
    }

    /*==========================================================================================================================
     If the user clicks 'reconnect package', take the user to the appropriate set up screen
     ==========================================================================================================================*/
    $scope.reconnectPackage = function() {
        $scope.title = "Edit package credentials";
        $scope.reconnecting_package = true;
        localStorage.setItem('reconnecting_package', true);

        $scope.setPackageAuthenticationRequirements(); // Reset the authentication requirements

        var web_based = $scope.chosen_accounting_package.web_based, oAuth = $scope.chosen_accounting_package.oAuth;

        if (web_based === true && oAuth === true) {
            $scope.oAuthAuthenticate();
        }
        else if (web_based === true) {
            $scope.handleStep(3);
        }
        else {
            $scope.handleStep(3);
        }
    }

    /*==========================================================================================================================
     Show a toast message saying that the reconnection has been successful
     ==========================================================================================================================*/
    $scope.confirmSuccessfulReconnection = function() {
        toastBox.show($scope.chosen_accounting_package.name + ' setup successful', 5000);
    }

    /*==========================================================================================================================
     When the user reconnects the package - this is called when commusoft can't connect
     to the accounting package.
     NOTE -- developers should replace the localStorage code here with proper storage in the DB
     ==========================================================================================================================*/

    $scope.downloadInterface = function() {
        $scope.downloading_interface_screen_one = false;
        $scope.downloading_interface_screen_two = false;
        $scope.setChosenAccountingPackageFromExistingPackage();
        $scope.handleStep(13);

        $scope.button_text = "Next step";
        $scope.setting_up_desktop_interface = false;
        $scope.button_disabled = true;
        $scope.hide_footer = false;
        $scope.sage_download_url_token = $scope.account.token;
        if ($scope.account.isvalid == true) {
            $scope.title = "Setup accounting integration";
            $scope.desktop_integration_successful = false;
            $scope.desktop_integration_error = ($scope.account.accounting_integration_errors && $scope.account.accounting_integration_errors.length)?$scope.account.accounting_integration_errors[0].message:undefined;
        }
        else {
            $scope.title = "Download interface";
            $scope.re_downloading_interface = true;
            $scope.downloading_interface_screen_one = true;
            $scope.downloading_interface_screen_two = false;
            $scope.accounting_package_authentication = {
                "username": ($scope.account)?$scope.account.details.details.username:"",
                "password": ($scope.account)?$scope.account.details.details.password:"",
                "db_location": ($scope.account)?$scope.account.details.details.dbLocation:"",
                "vat_method": ($scope.account)?$scope.account.details.details.vatMethod : "",
                "20_VAT_code": ($scope.account)?$scope.account.details.details.vatTwnety : "",
                "5_VAT_code": ($scope.account)?$scope.account.details.details.vatFive : "",
                "0_VAT_code": ($scope.account)?$scope.account.details.details.vatZero : "",
                "Domestic_20_VAT_code": ($scope.account)?$scope.account.details.details.vatDomesticTwnety:"",
                "Domestic_5_VAT_code": ($scope.account)?$scope.account.details.details.vatDomesticFive:"",
            }
        }
    };



    /*==========================================================================================================================
     Show the download quickbooks config modal screen
     ==========================================================================================================================*/
    $scope.showDownloadQuickbooksDesktopScreen = function() {
        $scope.setChosenAccountingPackageFromExistingPackage();

        $scope.setting_up_account = true;
        $scope.re_downloading_quickbooks_config = true;
        $scope.quickbooks_download_url_token = $scope.account.token;

        $scope.handleGettingQuickbooksPassword();
        $scope.handleStep(3);

        $scope.title = "Download QuickBooks Desktop interface";
        $scope.button_text = "Close";
        $scope.button_disabled = false;
    }

    /*==========================================================================================================================
     Set the chosen accounting package scope variable bases on the GET from the API
     ==========================================================================================================================*/
    $scope.setChosenAccountingPackageFromExistingPackage = function() {
        $scope.chosen_accounting_package = _.find($scope.accounting_packages, function(packages) {
            return packages.id === $scope.account.packageid;
        });
    }

    /*==========================================================================================================================
     All packages ~ this should also be a GET from the API
     ==========================================================================================================================*/

    $scope.accounting_packages = tableCollection.collection['accounting_packages'];
    $scope.emailAlreadyExist = tableCollection.collection['emailAlreadyExist'];

    $scope.account = tableCollection.collection['account'];

    /*==========================================================================================================================
     Form defaults for the integration setup
     This should be passed to the backend after successfully setting up the package
     The backend should handle POST requests to the API for data import etc.
     ==========================================================================================================================*/
    if($rootScope.AISetup == 'new') {
        $scope.button_text = "Choose package";
        $scope.button_disabled = true;
        $scope.show_back_link = false;
        if($scope.emailAlreadyExist === true) {
          $scope.step = 17;
          $scope.button_text = "Next step";
          $scope.title = "Setup accounting integration email ID";
        } else {
          $scope.step = 1;
          $scope.button_text = "Choose package";
          $scope.title = "Setup accounting integration";
        }
        $scope.hide_footer = false;
        $scope.account_setup_successful = false;
    }
    if($rootScope.AISetup == 'transfer') {
        $scope.handleNewOrContinueSetup();
        $scope.setChosenAccountingPackageFromExistingPackage();
        $scope.adjusting_transfer_settings = true;
        $scope.title = "Adjust transfer settings";
        $scope.handleStep(11);
    }
    if($rootScope.AISetup == 'cutoffdate') {
        $scope.showBusinessList = false;
        $scope.handleNewOrContinueSetup();
        $scope.setChosenAccountingPackageFromExistingPackage();
        $scope.changing_cut_off_date = true;
        $scope.title = "Change cut off date";
        $scope.handleStep(7);
    }
    /*==========================================================================================
     * edit setting code- add link at quick link tab
     * =========================================================================================*/
    if($rootScope.AISetup == 'edit_settings') {
        $scope.hoverTooltip = function(){
            var timeout = setTimeout(function() {
                $(".control-setting-help").find(".tooltip").find(".tooltip-inner").css({
                    'border-radius' :'4px',
                    'padding' :'3px 8px',
                    'color' : '#ffffff',
                    'background-color' : '#000000',
                    'text-align' : 'left',
                });
                $(".control-setting-help").find(".tooltip").find(".tooltip-arrow").css({
                    'top' :'30%',
                });
                $(".control-setting-help").find(".tooltip").find(".tooltip-inner").removeClass("tooltip-inner");
            }, 1);
        };
        $scope.setChosenAccountingPackageFromExistingPackage();
        $scope.edit_settings = true;
        $scope.title = "Edit settings";
        $scope.handleStep(16);
        $http.get(prefix + '/save_edit_settings?action=getOnOffVal').success(function(data) {
            $scope.handleEditSettings(data.edit_value, data.import_value, data.mark_as_sent);
        }).error(function(resp) {
            $scope.handleEditSettings(0, 0, 0);
        });
    }
    /*==========================================================================================================================
     Get the accounting package from the API
     ==========================================================================================================================*/
    if($rootScope.AISetup == 'new') {
        $scope.getAccountingPackage();
    }
    if($rootScope.AISetup == 'continue') {
        $scope.title = "Setup accounting integration";
        $scope.continueIntegration();
    }
    if($rootScope.AISetup == 'reconnect') {
        $scope.handleNewOrContinueSetup();
        $scope.setChosenAccountingPackageFromExistingPackage();
        $scope.reconnectPackage();
        if($scope.account.packageid == 1 || $scope.account.packageid == 8) {
            if ($scope.setting_up_account === false) {
                $scope.setting_up_account = undefined;
            }
            $scope.show_account_doesnt_exist = false;
        }
    }
    if($rootScope.AISetup == 'info_reconnect') {
        $scope.title = "Edit package credentials";
        $scope.reconnecting_package = true;
        localStorage.setItem('reconnecting_package', true);
        $scope.handleNewOrContinueSetup();
        $scope.setChosenAccountingPackageFromExistingPackage();
        if ($scope.account.packageid == '5') {
            $scope.reconnectPackage();
        }
        else if($scope.account.packageid == '10') {
            $scope.showDownloadQuickbooksDesktopScreen();
        }
        else {
            $scope.continueoAuthSetup();
        }
    }
    if($rootScope.AISetup == 're_download_interface') {
        $scope.downloadInterface();
    }
    if($rootScope.AISetup == 'download_quickbooks_config') {
        $scope.showDownloadQuickbooksDesktopScreen();
    }
    $scope.resetConfirmations();
    $scope.modal_confirmation_done = false;
    /*==========================================================================================================================
     Close modal
     ==========================================================================================================================*/
    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        //$scope.confirmDeleteValidity = true;
        //$scope.loading = false;
        //$scope.shouldBeOpenSetup = false;
        //$timeout(function() {
        //    $scope.reconnecting_package = false;
        //    $scope.adjusting_transfer_settings = false;
        //    $scope.re_downloading_interface = false;
        //    $scope.re_downloading_quickbooks_config = false;
        //    $scope.changing_cut_off_date = false;
        //}, 1000);
        //$modalInstance.close();
        if ($scope.modal_confirmation_done  === false &&
            $scope.reconnecting_package !== true &&
            $scope.changing_cut_off_date !== true &&
            $scope.adjusting_transfer_settings !== true &&
            $scope.re_downloading_interface !== true &&
            $scope.re_downloading_quickbooks_config !== true &&
            $scope.step !== 13 && $scope.step !== 16) {
            var confirmation_message = "You have not finished setting up your accounting integration. Are you sure you want to close?"

            confirmationBoxHelper.getConfirmation(confirmation_message, $scope).then(function () {
                $scope.modal_confirmation_done = true;
                $scope.confirmDeleteValidity = true;
                $scope.loading = false;
                $scope.shouldBeOpenSetup = false;
                $timeout(function () {
                    $scope.chosen_accounting_package = {};
                    $scope.step = 1;
                    $modalInstance.close();
                    $timeout(function () {
                        $scope.modal_confirmation_done = false;
                    }, 500);
                }, 50);
            });
        }
        else {
            $scope.confirmDeleteValidity = true;
            $scope.loading = false;
            $scope.shouldBeOpenSetup = false;
            $timeout(function() {
                $scope.reconnecting_package = false;
                $scope.adjusting_transfer_settings = false;
                $scope.re_downloading_interface = false;
                $scope.re_downloading_quickbooks_config = false;
                $scope.changing_cut_off_date = false;
            }, 1000);
            $modalInstance.close();
        }
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }
    $scope.triggerDeleteAISetup = function () {
        $scope.close();
        $scope.$emit('ai:delete_integration', $scope.account);
    }
}

function CSAIDeleteModalCtrl($scope, $http, prefix, $modalInstance, toastBox){
    $scope = $scope.$parent;
    $scope.confirmDeleteValidity = true;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.deletebutton = true;
    $scope.confirmText = '';
    $scope.selectedUsername = '';
    $scope.selectedPassword = '';
    $scope.errorMessage = '';
    $scope.confirmDeleteImportPayment = $scope.$parent.deleteImportPaymentModal;
    $scope.confirmResendData          = $scope.$parent.resendDataModal;
    $scope.confirmRetryImportPayment  = $scope.$parent.retryImportPaymentModal;
    $scope.showResendModal = true;
    $scope.confirmMarkDonotSend = $scope.$parent.markdonotsendModal;;
    
    $scope['delete'] = function() {
        if (this.deleteAIForm.$valid) {
            $scope.confirmText = "";
            $scope.confirmDeleteValidity = true;
            $scope.loading = true;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            var deleteParams = (angular.isObject($scope.accountId))?angular.toJson($scope.accountId).replace(/&/g, '%26'):$scope.accountId;

            //show processing image till resend action will complete
            var resendUrl = 'send_record/successful';

            if ($scope.deleteUrl.includes(resendUrl) || $scope.deleteUrl.includes('send_record/failed')) {
                $scope.resendProcessStatus = false;
                $scope.loading             = false;
                $scope.showResendModal     = false;
           }

            $http.post(prefix + $scope.deleteUrl, $scope.deleteParams + deleteParams +'&users[username]='+this.selectedUsername+'&users[password]='+this.selectedPassword+'&screenName='+$scope.screenName).
                success(function(data, status) {
                    if (status == 200) {
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.deletebutton = true;
                        if(data == 'Deleted') {
                            $scope.close();
                            $scope.$emit('DeletedAccount', data);
                        }
                        else if(data == 'MarkDoNotSend') {
                            //hide processing image after resend action has completed and show confirmation message
                            if ($scope.deleteUrl.includes(resendUrl) || $scope.deleteUrl.includes('send_record/failed')) {
                                $scope.resendProcessStatus = true;
                                toastBox.show('The records you re-sent have now been processed.', 5000);
                            }

                            $scope.close();
                            $scope.$emit('MarkDoNotSend', data);
                        }
                        else if(data == 'ReSend') {
                            $scope.close();
                            $scope.$emit('ReSend', data);
                        }
                        else {
                            $scope.errorMessage = data;
                        }
                    }
                })
        } else {

                if(this.selectedUsername != '' && this.selectedPassword != '') {
                    $scope.confirmDeleteValidity = false;
                    $scope.loading = false;
                    $scope.shouldBeOpenAiDelete = false;
                }

        }
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.confirmDeleteValidity = true;
        $scope.loading = false;
        $scope.shouldBeOpenAiDelete = false;
        $modalInstance.close();
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }
}

function CSApplianceModalCtrl($scope, $http, prefix, $modalInstance, warningModal){
    $scope = $scope.$parent;
    $scope.selectModel = false;
    $scope.closebutton = true;
    $scope.showConfirmationModal = false;
    $scope.confirmDeleteValidity = true;
    $scope.confirmText = '';

    $scope.select2OptionsMake = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                return prefix + '/list_ApplianceMakes';
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.select2OptionsModel = {
        minimumInputLength: 2,
        ajax: {
            url: function() {
                var selectedModal = ($scope.selectedModel) ? $scope.selectedModel : '';
                var currentScope = angular.element('.delete-category-modal').scope();
                var selectedMake = (currentScope && currentScope.selectedMake) ? currentScope.selectedMake.id : '';
                return prefix + '/get_appliance_models?makeId=' + selectedMake + '&selectedModel=' + selectedModal;
            },
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }
    $scope.selectedGroupDescription = function(selectedId, appliancetypeslist) {
        angular.forEach(appliancetypeslist, function (value, key) {
            angular.forEach(value.typeresults, function(value, key) {
                if(value.typesId == selectedId) {
                    $scope.mergeDescription = value.typesDescription;
                }
            })

        });
    }
    $scope.selectedFuelDescription = function(selectedId, fueltypelist) {
        angular.forEach(fueltypelist, function(value, key) {
            if(value.fuelId == selectedId) {
                $scope.mergeFuelDescription = value.fuelDescription;
            }
        });
    }

    $http.get(prefix + $scope.dropdownlistUrl).success(function (data) {
        $scope.appliancemodel = data.appliancemodels;
        $scope.appliancetypeslist = data.appliancetypeslist;
        $scope.appliancemakeslist = data.appliancemakeslist;
        $scope.fueltypelist = data.fueltypelist;
    });

    $scope.close = function() {
        $scope.shouldBeOpen = false;
        this.$parent.confirmText = '';
        $scope.showConfirmationModal = false;
        $scope.selectModel = false;
        $scope.selectedModel = [];
        $scope.$emit('selected_models', $scope.selectedModel);
        $modalInstance.close();
    }

    $scope.showMainAppliance = function() {
        $scope.selectModel = false;
        $scope.showConfirmationModal = false;
    }

    $scope.showConfirmation = function(){
        $scope.showConfirmationModal = true;
        $scope.selectModel = true;
        $scope.type = 'applianceModel';
        $scope.template = { url: 'template/delete/deleteappliance_modal.html'};
    }
    $scope.goBack = function() {
        $scope.showMainAppliance();
    }
    $scope.merge = function(selectedGroup, selectedFuelType, selectedMake, selectedApplianceModel) {
        if(this.$$childTail.confirmText){
            this.confirmText = this.$$childTail.confirmText;
        }
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            var selectedMake = selectedMake ? selectedMake.id : '';
            var selectedModel = selectedApplianceModel ? selectedApplianceModel.id : '';

            $scope.confirmDeleteValidity = true;
            $scope.loading = true;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            $http.post(prefix + $scope.deleteUrl + '?selectedIds='+ $scope.selectedModel +'&selectedApplianceGroup='+ selectedGroup + '&selectedFuelType=' + selectedFuelType + '&selectedApplianceMake='+ selectedMake + '&selectedApplianceModel=' + selectedModel).
                success(function(data, status) {
                    if (status == 200) {
                        if(data.warning.warning == true) {
                            warningModal.show(data.warning.message, data.warning.title, data.warning.id);
                        }
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.deletebutton = true;
                        $scope.close();
                        $http.get(prefix + '/list_ApplianceModels?page=' + $scope.page + '&limit='+ $scope.limit + '&screenType=merge').success(function (data) {
                            $scope.$emit('updated_appliance_models', data);
                        })
                    }
                })
        } else {
            $scope.confirmDeleteValidity = false;
            $scope.loading = false;
        }
    }
    $scope.showDeleteButton = function(deletetext) {
        $scope.confirmText = deletetext;
        if (deletetext.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            $scope.confirmDeleteValidity = true;
            $scope.deletebutton = true;
        }
    }

    $scope.changeApplianceGroupType = function(id, modelName, entityName, filterTypeField) {
        if(filterTypeField == 'settingsMakes') {
            $scope.makeval ="&settingsMakesid=" +id;
        }
        if(id != null) {
            $http.post(prefix + '/listApplianceGroupTypeValue', 'modelName=' + modelName + "&entityName=" + entityName + $scope.makeval).success(function(data) {
                $scope[modelName] = data;
            });
        } else {
            $http.get(prefix + '/list_ApplianceModels').success(function(data) {
                $scope[modelName] = data.appliancemodels;
            })
        }
    }
    $scope.deletes = function(selectedGroup, selectedFuelType, selectedMake, selectedApplianceModel) {
        if(this.$$childTail.confirmText){
            this.confirmText = this.$$childTail.confirmText;
        }
        if (this.confirmText.toLowerCase() == $scope.textToConfirm.toLowerCase()) {
            var selectedMake = selectedMake ? selectedMake.id : '';
            var selectedModel = selectedApplianceModel ? selectedApplianceModel.id : '';
            $scope.confirmDeleteValidity = true;
            $scope.loading = true;
            $scope.closebutton = false;
            $scope.deletebutton = false;
            $http.post(prefix + $scope.deleteUrl + '?selectedIds='+ $scope.selectedModel +'&selectedApplianceGroup='+ selectedGroup + '&selectedFuelType=' + selectedFuelType + '&selectedApplianceMake='+ selectedMake + '&selectedApplianceModel=' + selectedModel).
                success(function(data, status) {
                    if (status == 200) {
                        $scope.loading = false;
                        $scope.closebutton = true;
                        $scope.deletebutton = true;
                        $scope.close();
                        $http.get(prefix + '/list_ApplianceModels?page=' + $scope.page + '&limit='+ $scope.limit +'&screenType=merge').success(function (data) {
                            $scope.$emit('updated_appliance_models', data);
                        })
                        var valueToSubtract = 0;
                        if($scope.totalEntries % $scope.limit == 1 && $scope.$parent.currentPage > 1) {
                            valueToSubtract = 1;
                        }
                        $scope.deleteFunc({ page: $scope.$parent.currentPage - valueToSubtract });
                    }
                })
        } else {
            $scope.confirmDeleteValidity = false;
            $scope.loading = false;
        }
    }
}

function CSDuplicateCustomerModalCtrl($scope, $modalInstance,  toastBox, confirmationBoxHelper){
    $scope = $scope.$parent;

    $scope.title = "List of properties matching postcode: "+ $scope.postcode;
    $scope.modal_confirmation_done = false;

    $scope.goToNextStep = function(customerId) {
        $scope.loading = true;
        $scope.$emit('postcode:matched_property', customerId);
        $scope.modal_confirmation_done = true;
        $scope.close();
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        if ($scope.modal_confirmation_done  === false) {
            var confirmation_message = "Are you sure you want to close?";

            confirmationBoxHelper.getConfirmation(confirmation_message, $scope).then(function () {
                $scope.loading = false;
                $scope.shouldBeOpenDuplicate = false;
                $modalInstance.close();
            });
        }
        else {
            $scope.loading = false;
            $scope.shouldBeOpenDuplicate = false;
            $modalInstance.close();
        }
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }

    $scope.triggerCloseModalBox = function () {
        $scope.close();
        $scope.shouldBeOpenDuplicate = false;
    }
}

function CSLinkInvoicePaymentModalCtrl($scope, $http, prefix, $modalInstance, $rootScope) {
    $scope = $scope.$parent;
    $scope.loading = false;
    $scope.closebutton = true;
    $scope.deletebutton = true;
    $scope.emailValue ='';
    $scope.smsValue='';
    var smsNumber=[];

    angular.forEach($scope.propertySms,function(value,key){
        smsNumber.push({id:value.telephoneid,text:value.telephonenumber})
    });

    $scope.select2Options = {
        placeholder: 'Please enter email address',
        multiple: true,
        data: $scope.propertyEmail,
        createSearchChoice: function (term, data) {
            let exists = false;
            data.forEach((idText) => {
                if(idText.text == term)
                    exists = true;
            });
            let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$','g');
            return term && !exists && !term.match(regex)? null :
                {
                    id: term ,
                    text: term
                };
        }
    };

    $scope.selectSMS ={
        placeholder: 'Please select SMS number',
        multiple: false,
        data: smsNumber
    };

    $scope['delete'] = function() {
       if($scope.emailValue || $scope.smsValue){
           var emailAddress = [];
           var mobileId='';
           var mobilenumber='';
           angular.forEach($scope.emailValue, function(item) {
               emailAddress.push(item.text);
           });
           if($scope.smsValue) {
               mobileId=$scope.smsValue.id;
               mobilenumber=$scope.smsValue.text;
           };
           $scope.moduleParams = "&params[email]=" + emailAddress + '&params[sms][id]='+  mobileId + '&params[sms][number]=' + mobilenumber ;
       }

        $http.post(prefix + $scope.deleteUrl, $scope.deleteParams + $scope.selectedId + $scope.moduleParams).
        success(function(data, status) {
            if (status == 200 && (data.email || data.sms)) {
                $scope.loading = false;
                $scope.closebutton = true;
                $scope.deletebutton = true;
                $scope.close();
                $scope.deleteFunc();
            }else{
                $scope.loading = false;
                $scope.closebutton = true;
                $scope.close();
            }
        })
    }

    $scope.opts = {
        backdropFade: true,
        dialogFade: true
    }

    $scope.close = function() {
        $scope.shouldBeOpen = false;
        $scope.$emit('modal:close');
        $modalInstance.close();
    }
    $scope.closeDonSend=function(){
        $scope.shouldBeOpen = false;
        $scope.$emit('modal:close');
        $modalInstance.close();
        $scope.deleteFunc();
    }

    window.onkeydown = function (evt) {
        if (evt.keyCode == 27) {
            $scope.close();
        }
    }
    $rootScope.$on('event:escape', function () {
        $scope.close();
    });

    $scope.onChangeValue = function(emailValue) {
        if(emailValue.length > 0){
            $scope.deletebutton = false;
        }
        $scope.emailValue=emailValue;

    };
    $scope.onChangeSMS = function(smsValue) {
        if(smsValue!=null ){
            $scope.deletebutton = false;
        }
        $scope.smsValue=smsValue;
    };
}

/* Directives */


