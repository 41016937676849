/**
 * Created by sivachandran on 21/5/19.
 */

function EstimateSubNavCtrl($scope, $state, $stateParams, prefix, $http, stepAnimate, getIdData, $rootScope, $timeout, estimateOptions, estimateDiaryEvents, warningModal) {
    if(getIdData.deleteOrNull== 'Deleted' ) {
        var message= getIdData.deleteOrNull;
        warningModal.show('This Estimate has been '+message, 'Estimates', 'Deleted');
        $state.transitionTo('loggedin.customer_list.view.property', {'id': $state.params.id,'type': $state.params.type },{reload: true});
    }
    if(getIdData.deleteOrNull== 'DeletedOption' ) {
        var message= 'Deleted';
        warningModal.show('This estimate additional option has been '+message, 'Estimates', 'Deleted');
        $state.transitionTo('loggedin.customer_list.view.project_estimate_details.survey',{ 'id': $state.params.id, 'type': $state.params.type, 'estimateId': $state.params.estimateId },{reload: true});
    }

    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.hideSubNavCustomers = true;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateStatus = getIdData.estimateStatus;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');
    $scope.optionId = $state.params.optionId || getIdData.optionId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.estimateOptionCount = getIdData.estimateOptionCount;
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.estimateOptions = getIdData.estimate_options;
    $scope.moduleAccess = getIdData.moduleAccess;
    $scope.notesCount = getIdData.notesCount;
    $scope.filesCount = getIdData.filesCount;
    $scope.futureRemindersCount = getIdData.futureRemindersCount;
    $scope.officeTaskCount = getIdData.officeTaskCount;

    $scope.removeEstimateReserveListShouldBeOpen = false;

    $rootScope.$on("event:changeEstimateScope", function (event, message) {
        var name = message.name;
        var value = message.value;
        $scope[name] = value;
    });
    $rootScope.$on('update:estimateInfo', function (evt, data){
        $scope.information = data.information;
        $scope.communication_information = data.communicationInformation;

        $scope.estimateStatus = data.estimateStatus;
        $scope.historicalEstimate = data.estimateStatus &&
            (data.estimateStatus == 'accepted' || data.estimateStatus == 'rejected');
    });
    $scope.$on("officeTasks:openCountUpdated", function (event, message) {
        if(message && message > 0) {
            $scope.$apply(function () {
                $scope.officeTaskCount = message;
            });
        } else {
            $scope.$apply(function () {
                $scope.officeTaskCount = 0;
            });
        }
    });

    CommonEstimateSubNavigation.call(this, $scope, $rootScope, getIdData);

    $scope.information = getIdData.information;
    $scope.communication_information = getIdData.communicationInformation;
    $scope.jobId = getIdData.jobId;

    $scope.$on("informationbar:accepted_estimate", function (event, message) {
        $state.transitionTo("loggedin.customer_list.view.job.details", { id: $scope.selectedId, type: $scope.customerTabName, jobId: $scope.jobId });
    });

    $scope.$on('event:sentStatus', function(event, data) {
        var id = data.id;
        var tableType = data.type;
        if(tableType == 117) {
            $http.get(prefix + '/getInfoBarDetails?id=' + id + '&type=' + tableType).success(function(data) {
                $scope.communication_information = data;
                $scope.send_to_customer_tick = 1;
            });
        }
    });

    $scope.show_communication_information = false;
    if(getIdData.leftNavCount) {
        if((getIdData.leftNavCount.invoiceScheduleTotal != 0) &&(getIdData.leftNavCount.invoiceScheduleTotal  == getIdData.leftNavCount.priceTotal)) {
            $scope.show_communication_information = true;
        }
    }

    $rootScope.$on('communicaion_info:update', function(event, data) {
        $scope.show_communication_information = data;
    });

    $scope.fetchDeleteEstimateAndProject = function () {
        $scope.flashMessage = $scope.dynamicMainState.substr(0,1).toUpperCase()+ $scope.dynamicMainState.substr(1)+' Deleted';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.customer_list.view.property', {'id': $scope.selectedId, 'type': $scope.customerTabName});
    }
    $scope.triggerEstimateAndProjectClone = function () {
        $scope.add_option_method = false;
        $scope.estimateCloneShouldBeOpen = true;
    }
    $scope.fetchCloneEstimateAndProject = function (cloneData) {
        $rootScope.flashMessage = $scope.dynamicMainState.substr(0,1).toUpperCase()+ $scope.dynamicMainState.substr(1)+' Cloned';
        $state.transitionTo('loggedin.customer_list.view.' + $scope.dynamicState + '.survey', {'id': $scope.selectedId, 'estimateId': cloneData.newEstimateId, 'type': $scope.customerTabName});
    }

    $scope.jobno = $state.params.jobno;
    $scope.additionalOptionButton = false;
    $scope.additional_option = false;
    $scope.choose_option_to_clone = false;
    $scope.edit_additional_option_view = false;
    $scope.slide_edit_additional_option_leftnav = false;
    $scope.estimate_jobno = false;
    $scope.new_estimate = false;
    $scope.reserve_list = false;
    $scope.can_add_milestone = true;
    $scope.can_add_pricing_category = true;
    $scope.estimate_options = [];
    $scope.diary_events = estimateDiaryEvents.getDiaryEvents('estimates');

    $scope.cancelAdditionOption = function(){
        $("#add-additional-option").modal('hide');
    }


    var matches = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);

    if (matches[5] == "additional_option" || $scope.estimateOptionCount > 1) {
        $scope.additional_option = true;
    }

    $rootScope.$on('estimateOption:check', function(event, message) {
        $scope.additional_option = message;
    });
    if (matches[5] != "edit_option.body") {
        $scope.$on('leftSlide:animation', function (event, data) {
            $scope.edited_option = data.estimate_option_id;
            $scope.optionId = $scope.option_no = data.estimate_option_no;
            $scope.dynamic_state = data.dynamic_state;
            $scope.edit_additional_option_view = true;

            $state.transitionTo('loggedin.customer_list.view.' + $scope.dynamic_state + '.edit_option.body', {
                id: $scope.selectedId,
                type: $scope.customerTabName,
                estimateId: $scope.estimateId,
                optionId: $scope.option_no
            });
        });
    }

    /*==========================================================================================
     Handle States
     ==========================================================================================*/

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
        var tagging_modal = document.querySelector('#details-dynamic-tagging');

        if (toState.url === '/body') {
            $timeout(function() {
                $(tagging_modal).modal('show');
            }, 800);
        }else {
            $(tagging_modal).modal('hide');
        }

        if (toState.name.search('additional_option') > 0) {
            $scope.additional_option = true;
        }

        // hide the subnav and left sidebar in pages with forms
        if (toState.url === '/edit' || toState.url === '/new') {
            $scope.hideSubNav = true;

            if (toState.url === '/edit') {
                var pos = toState.name.search('edit');
                $scope.prev_state = toState.name.substring(0, pos-1) + '.view';
            }else if (toState.url === '/new') {
                var pos = toState.name.search('new');
                $scope.prev_state = toState.name.substring(0, pos-1) + '.view';
            }

            // construct the url so that the customer id state params can be
            // added to this in the breadcrumbs
            var state = 'estimates';
            $scope.prev_url = $scope.prev_state
                .substring(state.length + 1)
                .split('.')
                .join('/');

        }else {
            $scope.hideSubNav = false;
        }

        // in the edit view for additional options get the object from local storage
        if (toState.name.search('additional_option.edit') > 0) {
            $scope.hideSubNav = true;
            $scope.edit_additional_option_view = true;
            $scope.option_id = toParams.option_id;
            var edited_option_str = localStorage.getItem('additional_option');
            $scope.edited_option = JSON.parse(edited_option_str);
            $scope.prev_state = 'estimates.estimate_details.additional_option.view';
            $scope.prev_url = 'estimate_details/additional_option/view';
        };

        if (toState.name.search('additional_option.view') > 0) {
            $scope.edit_additional_option_view = false;
        }
    });

    $scope.$on('AdditionalOption', function(event, data) {
        $scope.addOptionToEstimate(data.add_option_method, data.select_estimate_options);
    });

    /*==========================================================================================
     Add Additional Option
     ==========================================================================================*/

    $scope.addOptionToEstimate = function addOptionToEstimate(method_type, select_estimate_options) {
        if (method_type) {
            $("#add-additional-option").modal('hide');
            $scope.estimate_options = $scope.estimate_options;
            if (select_estimate_options != undefined) {
                $scope.select_estimate_options = select_estimate_options.estimateOptionId;
            }


            // only need the merging animation to happen the first time
            // an option is cloned/created
            if ($scope.additional_option === false) {
                var side_panel = document.querySelector('#estimates-shelf');
                stepAnimate.mergeSteps(method_type, $scope.advanced_estimate);

                side_panel.addEventListener('merge_animation_complete', function (e) {
                    $scope.additional_option = true;
                }, false);
                $scope.redirectToAdditionalOptionState(method_type);
            } else {
                $scope.redirectToAdditionalOptionState(method_type, $scope.select_estimate_options);
            }

            // reset model and scope variables
            this.add_option_method = '';
            $scope.choose_option_to_clone = false;
        }
    }

    $scope.redirectToAdditionalOptionState = function redirectToAdditionalOptionState(method_type, select_estimate_options) {
        $scope.selectedOption = select_estimate_options;
        $scope.methodType = method_type;
        $scope.Url = '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/' + $scope.dynamicState + '/additional_option/view';
        $http.post(prefix + $scope.Url, "clone[type]=" + $scope.methodType + "&clone[selectedOptionId]=" + $scope.selectedOption).success(function (data) {
            $rootScope.$broadcast("estimateOptionsSelected", { 'selectedId': $scope.selectedId, 'estimateId': $scope.estimateId, 'customerTabName': $scope.customerTabName, 'estimateObjects': data.estimateObject, 'estimateOptionCount': data.estimateOptionCount});
            var state_str = 'loggedin.customer_list.view.' + $scope.dynamicState + '.additional_option';
            $scope.$state.transitionTo(state_str, {
                id: $scope.selectedId,
                type: $scope.customerTabName,
                estimateId: $scope.estimateId,
                methodType: method_type.replace(/"/g, "")
            });
        });
    }

    /*==========================================================================================
     Delete Additional Option
     ==========================================================================================*/

    $scope.setOptionToDelete = function setOptionToDelete() {
        $scope.option_to_delete = this.$index;
    }

    $rootScope.unmerge_animation_in_progress = false;
    $rootScope.$on('estimateOptionDesign:change', function(event, message) {

        var side_panel = document.querySelector('#estimates-shelf');
        var delete_modal = document.querySelector('#delete-additional-option');

        $rootScope.unmerge_animation_in_progress = true;
        $timeout(function() {
            $scope.estimate_options.splice($scope.option_to_delete, 1);

            if ($scope.estimate_options.length < 2) {
                stepAnimate.unmergeSteps();

                side_panel.addEventListener('un_merge_animation_complete', function (e) {
                    $rootScope.unmerge_animation_in_progress = false;
                    $scope.additional_option = false;
                }, false);
                $scope.$state.transitionTo('loggedin.customer_list.view.' + $scope.dynamicState + '.survey', {
                    id: $scope.selectedId, type: $scope.customerTabName, estimateId: $scope.estimateId
                });
            }
        }, 1000);
    });

    /*==========================================================================================
     Logic for add spreadsheet side panel
     ==========================================================================================*/
    $rootScope.$on('spreadsheet:cant_generate_spreadsheet', function() {
        $scope.can_add_milestone = false;
        $scope.can_add_pricing_category = false;
    });

    $rootScope.$on('spreadsheet:can_generate_spreadsheet', function() {
        $scope.can_add_milestone = true;
        $scope.can_add_pricing_category = true;
    });

    /*==========================================================================================
     Milestone logic ~ used in plan view if the estimate
     type is a project
     ==========================================================================================*/

    $scope.addMilestone = function addMilestone() {
        $rootScope.$emit('spreadsheet_panel:open');

        $timeout(function(){
            document.querySelector("#input-milestone-name").focus();
        });
    }

    /*==========================================================================================
     Pricing
     ==========================================================================================*/

    $scope.addPricingCategory = function addPricingCategory() {
        $rootScope.$emit('spreadsheet_panel:open');

        $timeout(function(){
            document.querySelector("#input-pricing-category-name").focus();
        });
    }

    $scope.optionDecision = function optionDecision(option_index, decision) {
        $scope.estimate_options[option_index].status.decision = decision;
    }

    $scope.triggerRemoveEstimateReserveList = function(){
        $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
        $scope.removeEstimateReserveListShouldBeOpen = true;
    }
    $scope.removeEstimateReserveList = function (){
        $scope.flashMessage = 'Removed.the.estimate.from.reserve.list.status';
        $scope.estimateStatus = 'ongoing';
        $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
        $state.transitionTo('loggedin.customer_list.view.' + $scope.dynamicState + '.survey', {
            id: $scope.selectedId, type: $scope.customerTabName, estimateId: $scope.estimateId
        },{reload:true});
    }

}

function EstimateSurveyCtrl($scope, $state, prefix, $http, getIdData, datasets, $rootScope, $sce, $compile, warningModal) {
    if(getIdData.deleteOrNull== 'Deleted') {
        var message = getIdData.deleteOrNull;
        $state.transitionTo('loggedin.customer_list.view.property', {'id': $state.params.id, 'type': $state.params.type},{reload: true});
    }
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    PermissionCheck.call(this, $scope);
    $scope.estimateSubNavStatic = false;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateid || getIdData.estimateId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.option = getIdData.option;
    $scope.selectedTab = "Estimate";
    $scope.estimateTitle = getIdData.estimateContactTitle;
    $scope.estimateName = getIdData.estimateContactName;
    $scope.estimateSurname = getIdData.estimateContactSurname;
    $scope.customerReference = getIdData.customerReference;
    $scope.engineerNote = getIdData.engineerNotes;
    $scope.createdOnDateTime = getIdData.createdOnDateTime;
    $scope.estimateOptionCount = getIdData.estimateOptionCount;
    $scope.modifiedDateTime = getIdData.modifiedDateTime;
    $scope.userName = getIdData.userName;
    $scope.estimateTemplate = getIdData.estimateTemplate;
    $scope.estimateProjectName = getIdData.estimateProjectName;
    $scope.accessMethod = getIdData.accessMethod;
    $scope.accessNotes = getIdData.accessNotes;
    $scope.estimateDate = getIdData.estimateDate;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');
    $scope.hideSubNavCustomers = true;
    $scope.jobId = getIdData.jobId;
    $scope.timeline_results = true;
    $scope.startDateTime = false;
    $scope.endDateTime = false;
    if(getIdData.hasOwnProperty('startdatetime')) {
        $scope.startDateTime = getIdData.startdatetime;
        $scope.endDateTime = getIdData.enddatetime;
    }

    $scope.userGroupDescription = getIdData.userGroupDescription;

    $scope.diaryModuleAccess = getIdData.diaryModuleAccess;
    if($scope.diaryModuleAccess) {
        $http.get(Routing.generate('estimate_diary_details', { id: $scope.selectedId, type: $scope.customerTabName, estimateId: $scope.estimateId })).success(function (data) {
            $scope.estimate = data.estimateDetails;
            $scope.estimate.diary_engineers = data.diaryEngineers;
            $scope.estimate.timeline_sections = data.timelineSections;
            $scope.allDiaryStatus = data.allDiaryStatus;
            $scope.feedback = getIdData.feedback;
            angular.forEach(data.feedback, function(feedback) {
                var diaryIdInFeedback = feedback[0].diaryId;
                var inDiary = _.findWhere($scope.estimate.diaryEvents, { id: parseInt(diaryIdInFeedback) });
                if (inDiary) {
                    inDiary.feedback['leave_site'] = { 'questions':  feedback};
                    inDiary.engineer_feedback = true;
                }
            });
            $scope.estimate.diary_event_view = 'list';
        });
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    if ($scope.estimateOptionCount == 1) {
        $scope.additional_option = false;
        $rootScope.$emit('estimateOption:check', false);
    }

    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.$emit('tabCustomer:selected', getIdData);

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    // This condition for to display the flash message after cloned.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // Function for view the diary event details
    $scope.handleViewDiaryEvent = function handleViewDiaryEvent(diary_event_id) {
        var side_panel_tpl = $compile('<span id="view_diary_event" ' +
            'sidepanel template="estimate_view_diary_event" ' +
            'title="view" ' +
            'remove_on_close="true" ' +
            'index="' + this.$index + '"' +
            'collection="' + diary_event_id + '"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
    }

    $scope.handleEditDiaryEvent = function handleEditDiaryEvent(diary_event_id, diary_event_completed) {
        if(diary_event_completed) {
            warningModal.show("You cannot edit this diary event because of its status", "Edit diary event");
        } else {
            var side_panel_tpl = $compile('<span id="view_diary_event" ' +
                'sidepanel template="estimate_view_diary_event" ' +
                'title="edit" ' +
                'remove_on_close="true" ' +
                'index="' + this.$index + '"' +
                'collection="' + diary_event_id + '"></span>')($scope);

            angular.element('body').append(side_panel_tpl);
        }
    }

    // Call for to reload timeline view
    $scope.$on("estimateTimeline:reload", function (event) {

        $http.get(Routing.generate('estimate_diary_details', { id: $scope.selectedId, type: $scope.customerTabName, estimateId: $scope.estimateId })).success(function (data) {
            // Get the view options
            $scope.estimateDiaryView = $scope.estimate.diary_event_view;
            $scope.estimate = data.estimateDetails;
            $scope.estimate.diary_event_view = $scope.estimateDiaryView;
            $scope.estimate.diary_engineers = data.diaryEngineers;
            $scope.estimate.timeline_sections = data.timelineSections;
            $scope.allDiaryStatus = data.allDiaryStatus;
            $scope.feedback = data.feedback;
        })

    });
    $scope.chatChannelExists = false;
    $scope.isMember = 0;
    $scope.checkChatEnable = function checkChatEnable() {
    var channelName = "estimate"+$scope.estimateId;
    $http.get(prefix + '/checkChatEnable?channelName='+channelName).success(function (data) {
          if(data.isEnable) {
              $scope.showChatIcon = true;
              
              var channelName = "estimate"+$scope.estimateId;
              $scope.chatTeamName = data.chatDetails.chatTeamId;
              $scope.chatUserId = data.chatDetails.chatUserId;
              $scope.chatToken = data.chatDetails.chatAuthToken;
              $scope.chatLoginKey = data.chatLoginKey;
              $scope.channelAdmin = data.chatDetails.channelAdminUser;
              $scope.channelPwd = data.chatDetails.channelAdminPassword;
              if( data.chatDetails.channelId ) {
                $scope.channelId = data.chatDetails.channelId;
                $scope.chatChannelExists = true;
              } else {
                $scope.chatChannelExists = false;
              }
              if( data.chatDetails.isMember ) {
                $scope.isMember = 1;
              } else {
                $scope.isMember = 0;
              }

              $rootScope.enableJobEstimateChatFeature = false;
              if( data.chatDetails.features ) {
                  if( data.chatDetails.features[1]) {
                    $rootScope.enableJobEstimateChatFeature = true;
                  }
              }
              //$scope.chatChannelUrl = data.chatUrl + "/" + data.chatDetails.chatTeamName + "/load_channel/" + channelName +'/' + data.chatDetails.chatUserId+'?commusoft='+data.chatLoginKey;
              $scope.chatChannelUrl = data.chatUrl+ "/login?commusoft="+data.chatLoginKey+'&redirect_to=/'+data.chatDetails.chatTeamName+"/"+'channels/'+channelName;
          } else {
              $scope.showChatIcon = false;
          }
          //$scope.checkChannelExist($scope.chatToken, $scope.chatTeamName);
      });
    }
    
    $scope.checkChatEnable();
    
    $scope.$watch('enableJobEstimateChatFeature', function (newValue) {
        $scope.$emit('enableJobEstimateChatFeature', newValue);
    });

    $scope.handleChatClick = function(diaryId,chatChannelUrl,chatTeamName,chatUserId,chatToken,channelAdmin,channelPwd, channelId) {
      $scope.loading = true;
      if( !$scope.chatChannelExists || !$scope.isMember ) {
        $http({
          method  : 'POST',
          url     : prefix + '/createChatChannel',
          data    :  $.param({
              'channelType': 'estimate',
              'id': $scope.estimateId,
              'diaryId': diaryId,
              'chatChannelUrl': chatChannelUrl,
              'chatTeamName': chatTeamName,
              'chatUserId': chatUserId,
              'chatToken': chatToken,
              'channelAdmin':channelAdmin,
              'channelPwd': channelPwd,
              'channelId': channelId
          }),
          headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).
        success(function (data, status) {
            
            window.open(chatChannelUrl);
        }).
        error(function (data, status) {
            $scope.data = data;
        });
      } else {
        window.open(chatChannelUrl);
      }
      
    }
}

function EstimateSurveyEditCtrl($scope, $state, prefix, $http, getIdData, $rootScope, formPanelCollection, $timeout){
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    formPanelCollection.setFilterData(getIdData.contactCreateObject.contactCreate);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateid || getIdData.estimateId;
    $scope.jobId = getIdData.jobId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('displayScreen', false);
    $scope.shouldBeOpen = false;
    $scope.engineerNotes = getIdData.engineerNotes;
    $scope.estimate = getIdData.estimateDetails;
    $scope.contactid = getIdData.contactId;
    $scope.customerReference = getIdData.customerReference;
    $scope.isDurationEditable = getIdData.isDurationEditable;
    $scope.contacts = getIdData.contacts;
    if($scope.contactid == null && $scope.contacts.length != 0){
        $scope.contactid = $scope.contacts[0].contactid;
    }
    if($scope.engineerNotes){
        $scope.engineerNotes = $scope.engineerNotes.replace(/<br\s*[\/]?>/gi, "\n");
    }
    $scope.description = getIdData.description;
    $scope.estimateDate = getIdData.date.date;
    $scope.accessMethod = getIdData.accessMethod;
    $scope.accessNotes = getIdData.accessNotes;
    $scope.userGroupId = getIdData.userGroupId;
    $scope.userGroups = getIdData.userGroups;

    $scope.estimateSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData.customerRec);
    $scope.$emit('estimateId:selected', $scope.estimateId);

    $scope.accessMethodValues = getIdData.accessMethods;

    if (getIdData.date != null) {
        $scope.selectedDate = moment(getIdData.date.date);
    } else {
        $scope.selectedDate = '';
    }


    $scope.jobDurationSettings = getIdData.setupJobDuration;
    $scope.jobDurationTime = getIdData.jobDurationTime;
    $scope.dateError = false;
    $scope.endDateError = false;
    $scope.setJobDuration = false;
    $scope.durationWarning = false;
    $scope.handleSpecialEventValues = function () {
        return;
    }
    if($scope.jobDurationSettings != null) {
        $scope.setJobDuration = true;
        if ($scope.estimate.startdatetime != null) {
            $scope.startDateTime = moment($scope.estimate.startdatetime);
            $scope.endDateTime =  moment($scope.estimate.enddatetime);
            $scope.diaryDate = $scope.startDateTime;
            $scope.selectedDate = $scope.startDateTime;
            DiaryDateTimeInputs.call(this, $scope, $timeout);
        }
        else{
            var duration = parseInt($scope.jobDurationTime);
            $scope.startDateTime = moment().add(15 - (moment().minute() % 15), "minutes");
            $scope.demodate = $scope.startDateTime;
            $scope.endDateTime = moment($scope.demodate).add(duration, "minutes");
            $scope.diaryDate = $scope.startDateTime;
            $scope.selectedDate = $scope.startDateTime;
            DiaryDateTimeInputs.call(this, $scope, $timeout);
        }
    }

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.backTo = function () {
        $state.transitionTo("loggedin.customer_list.view." + $scope.dynamicState + ".survey", {'id': $scope.selectedId, 'estimateId': $scope.estimateId, 'type': $scope.customerTabName});
    }

    $scope.editSurvey = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.saving = true;
        $scope.current = this;
        var params = { 'id': $scope.selectedId, 'type': $scope.customerTabName, 'estimateId':$scope.estimateId };
        var startDateTime = ($scope.startDateTime != undefined) ? moment($scope.startDateTime).subtract(moment($scope.startDateTime).second(),"seconds").toISOString() : null;
        var endDateTime = ($scope.endDateTime != undefined) ? moment($scope.endDateTime).subtract(moment($scope.endDateTime).second(),"seconds").toISOString() : null;

        $http.post(prefix + '/estimate_survey_update', "survey[engineerNotes]=" + encodeURIComponent(this.engineerNotes)
            + "&survey[description]=" + encodeURIComponent(this.description)
            + "&survey[estimateDate]=" + encodeURIComponent(this.estimateDate)
            + "&survey[estimateId]=" + encodeURIComponent($scope.estimateId)
            + "&survey[customerReference]=" + encodeURIComponent(this.customerReference)
            + "&survey[id]=" + encodeURIComponent($scope.selectedId)
            + "&survey[type]=" + encodeURIComponent($scope.customerTabName)
            + "&survey[contactId]=" + encodeURIComponent(this.contactid)
            + "&survey[accessMethod]=" + encodeURIComponent(this.accessMethod)
            + "&survey[accessNotes]=" + encodeURIComponent(this.accessNotes)
            + "&survey[startdatetime]=" + startDateTime
            + "&survey[enddatetime]=" + endDateTime
            + "&survey[usergroupsid]=" + encodeURIComponent(this.userGroupId)).
        success(function (data, status) {
            if (status == 200) {
                $scope.estimateViewDetails(data.id, data.estimateId, data.type, $scope.dynamicState);
            }
        });
    }

    $rootScope.$on('panelwithform:form_save_data', function (evt, data) {
        $scope.contactid = data.contactId;
        $scope.contacts.unshift({contactid:data.contactId, surname: data.contactFullName});
    });

    $scope.triggerTransfer = function (customerId, estimateId, selectedIdCustomerId, customerName) {
        $scope.transferfunction = '/transfer_estimate';
        $scope.selectedCustomerId = selectedIdCustomerId;
        $scope.estimateId = estimateId;
        $scope.selectedId = customerId;
        $scope.customerName = customerName;
        $scope.textToConfirm = 'TRANSFER';
        $scope.shouldBeOpen = true;
        if($scope.dynamicState == 'project_estimate_details') {
            $scope.validationMessage = "TRANSFER.to.transfer.this.project";
            $scope.buttonValue = 'Confirm.transfer.project';
            $scope.titleName = 'Transfer.project';
        } else if($scope.dynamicState == 'estimate_details') {
            $scope.validationMessage = "TRANSFER.to.Transfer.this.Estimate";
            $scope.buttonValue = 'Confirm.Transfer.Estimate';
            $scope.titleName = 'Transfer.Estimate';
        }
    }

    $scope.transferEstimate = function (selectedId, estimateId, selectedCustomerId, customerTabName) {
        if($scope.dynamicState == 'estimate_details') {
            $rootScope.flashMessage = "Estimate.Transferred";
        } else {
            $rootScope.flashMessage = "Project.transferred";
        }
        $state.transitionTo("loggedin.customer_list.view.property", { 'id': selectedCustomerId, 'type': customerTabName });
    }

    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_customers',
            data: function (term, page) {
                return { 'q': term, 'excludeCustomers': $scope.selectedId};
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }

    $scope.canShowTransferButton=function() {
        return ($scope.transferCustomerId == null || $scope.transferCustomerId.id == null ||
            $scope.transferCustomerId.id == '');
    }
}

function EstimateIntroductionCtrl($scope, $state, $http, datasets, getIdData, $rootScope, fullScreenEditorCollection, prefix, $location, confirmationBoxHelper) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope,prefix);
    PermissionCheck.call(this, $scope);
    fullScreenEditorCollection.setPanelData(getIdData.notesCollection);
    $scope.estimateSubNavStatic = false;
    $scope.selectedTab = "Estimate";
    $scope.estimateIntroduction = getIdData.estimateIntroductionBody['introduction'];
    $scope.estimateDate = getIdData.date;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');
    $scope.originalIntro = getIdData.estimateIntroductionBody['introduction'];

    $scope.hideSubNavCustomers = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);


    $scope.tinymceOptions = {
        plugins: "link,paste,textcolor,legacyoutput",
        menubar: false,
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        auto_focus: "estimate_intro",
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        fontsize_formats: "1 2 3 4 5 6 7",
        resize: false
    };

    $scope.init = function (formReturnName) {
        $scope.estimateOptions = {
            emailMessage: formReturnName + '[estimateIntroduction]'
        }
    }

    $scope.submitEstimateIntroduction = function(estimateIntroduction){
        $scope.saving = true;
        var postUrl = $location.path();
        var autoSaveValues = "type" + "=" + $scope.customerTabName + "&" + "id" + "=" + $scope.selectedId + "&" + "estimateId" + "=" + $scope.estimateId + "&" + "estimates[estimateIntroduction]" + "=" + encodeURIComponent(estimateIntroduction);
        $http.post(prefix + postUrl, autoSaveValues + "&" + "autoSave" + "=" + 'autosave').
        success(function(data, status) {
            if (status == 200) {
                $scope.originalIntro = $scope.estimateIntroduction;
                $scope.saving = false;
                $scope.flashMessage = 'Estimate.Introduction.Saved';
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
            }
        });
    }

    $scope.actualEditor = function(){
        var tinyInstance = tinymce.get('estimate_intro').getContent();
        $scope.$broadcast('estimateContent', tinyInstance)
    }

    $scope.$on("estimateFullScreenView", function (event, message) {
        var tinyInstance = tinymce.get('estimate_intro');
        if(tinyInstance){
            tinyInstance.setContent(message);
        }
    });

    var off =  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        if (fromState.name == 'loggedin.customer_list.view.estimate_details.introduction' ||
            fromState.name == 'loggedin.customer_list.view.project_estimate_details.introduction') {
            if ($scope.estimateIntroduction != $scope.originalIntro) {
                if (fromParams.skipSomeAsync) {
                    return;
                }

                event.preventDefault();
                return confirmationBoxHelper.getConfirmation("Text changed. Are you sure you want to continue?", $scope)
                    .then(function() {
                        fromParams.skipSomeAsync = true;
                        $state.go(toState.name, toParams);
                    }, function() {

                    });
            }
        }
    });
    $scope.$on('$destroy', off);
}

function EstimateBodyCtrl($scope, $state, $http, datasets, getIdData, $rootScope, fullScreenEditorCollection, $timeout, prefix, $location, confirmationBoxHelper) {
    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope, prefix);
    PermissionCheck.call(this, $scope);
    fullScreenEditorCollection.setPanelData(getIdData.notesCollection);
    $scope.estimateBody = getIdData.estimateIntroductionBody['body'];
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.dynamicTags = getIdData.dynamicArray;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.optionId = $state.params.optionId || getIdData.optionId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.hideSubNavCustomers = true;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');
    $scope.originalBody = getIdData.estimateIntroductionBody['body'];


    $rootScope.$emit('estimateDetail:value', getIdData.leftNavCount);

    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if(rootMatch[5] == 'edit_option.body'){
        $scope.estimateSubNavStatic = true;
        $scope.selectedTab = "Edit_Additional_Option";
    }
    if(rootMatch[5] == 'body'){
        $scope.estimateSubNavStatic = false;
        $scope.selectedTab = "Estimate";
    }

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    if($scope.dynamicTags){
        $scope.shouldBeOpen = true;
    }

    $scope.tinymceOptions = {
        plugins: "paste,link,textcolor,legacyoutput",
        menubar: false,
        content_css: window.location.origin + '/css/public/stylesheets/mce-editor.css',
        auto_focus: "estimate_body",
        toolbar: "fontselect fontsizeselect | forecolor backcolor bold italic underline | bullist numlist | alignleft alignright",
        paste_as_text: true,
        browser_spellcheck : true,
        paste_block_drop: false,
        fontsize_formats: "1 2 3 4 5 6 7",
        resize: false
    };

    $scope.init = function (formReturnName) {
        $scope.estimateOptions = {
            emailMessage: formReturnName + '[estimateBody]'
        }
    }

    $scope.actualEditor = function(){
        var tinyInstance = tinymce.get('estimate_body').getContent();
        $scope.$broadcast('estimateContent', tinyInstance)
    }

    $scope.$on("estimateFullScreenView", function (event, message) {
        var tinyInstance = tinymce.get('estimate_body');
        if(tinyInstance){
            tinyInstance.setContent(message);
        }
    });

    $scope.submitEstimateBody = function(estimateBody){
        $scope.saving = true;
        var postUrl = $location.path();
        var autoSaveValues = "type" + "=" + $scope.customerTabName + "&" + "id" + "=" + $scope.selectedId + "&" + "estimateId" + "=" + $scope.estimateId + "&" + "estimates[estimateBody]" + "=" + encodeURIComponent(estimateBody);
        if($state.params.optionId){
            var autoSaveValues =  autoSaveValues + "&" + "optionId" + "=" + $state.params.optionId;
        }
        $http.post(prefix + postUrl, autoSaveValues + "&" + "autoSave" + "=" + 'autosave').
        success(function(data, status) {
            if (status == 200) {
                $scope.originalBody = $scope.estimateBody;
                $scope.saving = false;
                $scope.flashMessage = 'Estimate.Body.Saved';
                $scope.$emit('tabCustomer:successMessage', $scope.flashMessage);
            }
        });
    }

    $scope.$on("dynamicTagValues", function (event, message) {
        $scope.dynamicValue = message;

        angular.forEach($scope.dynamicTags, function (val, key) {
            var tagName = $scope.dynamicTags[key].tageName;
            var tagValue = $scope.dynamicTags[key].tageValue;

            var find = '{'+tagName+'}';
            if (tagValue != '') {
                $scope.estimateBody = $scope.estimateBody.replaceAll(find, tagValue);
            }

        });
        var tinyInstance = tinymce.get('estimate_body');
        if(tinyInstance){
            tinyInstance.setContent($scope.estimateBody);
        }
    });

    var off =  $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        if (fromState.name == 'loggedin.customer_list.view.estimate_details.body' ||
            fromState.name == 'loggedin.customer_list.view.estimate_details.edit_option.body' ||
            fromState.name == 'loggedin.customer_list.view.project_estimate_details.body' ||
            fromState.name == 'loggedin.customer_list.view.project_estimate_details.edit_option.body') {
            if ($scope.estimateBody != $scope.originalBody) {
                if (fromParams.skipSomeAsync) {
                    return;
                }

                event.preventDefault();
                return confirmationBoxHelper.getConfirmation("Text changed. Are you sure you want to continue?", $scope)
                    .then(function() {
                        fromParams.skipSomeAsync = true;
                        $state.go(toState.name, toParams);
                    }, function() {

                    });
            }
        }
    });
    $scope.$on('$destroy', off);
}

function EstimateTodosCtrl($scope, $state, prefix, $http, getIdData, $rootScope, spreadSheetData) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.optionId = $state.params.optionId || getIdData.optionId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.hideSubNavCustomers = true;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    /*When click on the check box in price tab for merge & click on the invoice schedule tab without merge cell,
     all the cells in invoice schedule are disable. So set the scope for as default cell.*/
    $rootScope.$emit('defaultSpreadsheet:value', "all");

    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if(rootMatch[5] == 'edit_option.todos'){
        $scope.estimateSubNavStatic = true;
        $scope.selectedTab = "Edit_Additional_Option";
    }
    if(rootMatch[5] == 'todos'){
        $scope.estimateSubNavStatic = false;
        $scope.selectedTab = "Estimate";
    }
    if (getIdData.moduleAccess == false) {
        $scope.noPermission = true;
    }
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.milestoneDetails = getIdData.milestoneDetails;

    // Allow option to add multiple milestone in estimate project plan screen.
    $scope.can_add_milestone = true;

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabCustomer:successMessage', data);

    });

    // Initialize the scope value, because when click on delete in modal box all the time we get only the 1st milestone values.
    // Todo's instant of pass the scope value, need to get the values from current scope.
    $rootScope.$on('delete_milestone', function(e, direct_scope) {

        $scope.categoryId = direct_scope.categoryId;
        $scope.category = direct_scope.category;
        $scope.spreadsheet_data = direct_scope.spreadsheet_data;
        $scope.element = direct_scope.element;
        $scope.confirmText = '';

    });

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/'+$scope.estimateId+'/todoslists';
    $scope.addUrl = prefix + '/customers/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/todos/new';
    $scope.updateUrl = prefix + '/customers/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/todos/update';
    $scope.deleteUrl = prefix + '/customers/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/todos/delete';
    $scope.categoryId = '';

    // Scope URL for estimate project screens.
    $scope.getPlanListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/project/'+$scope.estimateId+'/planlists/milestone';
    $scope.addPlanUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/new/milestone';
    $scope.updatePlanUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/update/milestone';
    $scope.deletePlanUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/delete/milestone';
    $scope.addMilestoneUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/milestone/new';
    $scope.editMilestoneUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/milestone/update';
    $scope.deleteMilestoneUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/milestone/delete';
    $scope.checkMilestoneUrl = prefix + '/customers/' + $scope.selectedId + '/project/' + $scope.estimateId + '/plan/milestone/check';

    if($scope.optionId) {
        $scope.getListUrl = $scope.getListUrl + '/additional_option/' + $scope.optionId;
        $scope.addUrl = $scope.addUrl + '/additional_option/' + $scope.optionId;

        $scope.getPlanListUrl = $scope.getPlanListUrl + '/additional_option/' + $scope.optionId;
        $scope.addPlanUrl = $scope.addPlanUrl + '/additional_option/' + $scope.optionId;
        $scope.addMilestoneUrl = $scope.addMilestoneUrl + '/additional_option/' + $scope.optionId;
        $scope.checkMilestoneUrl = $scope.checkMilestoneUrl + '/additional_option/' + $scope.optionId;

    }

}

function EstimateBudgetCostCtrl($scope, $state, prefix, $rootScope, getIdData){
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.optionId = $state.params.optionId || getIdData.optionId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.hideSubNavCustomers = true;
    $scope.todoDescription = getIdData.todoDescription;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');


    $scope.milestoneDetails = getIdData.milestoneDetails;

    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if(rootMatch[5] == 'edit_option.cost'){
        $scope.estimateSubNavStatic = true;
        $scope.selectedTab = "Edit_Additional_Option";
    }
    if(rootMatch[5] == 'cost'){
        $scope.estimateSubNavStatic = false;
        $scope.selectedTab = "Estimate";
    }

    /*When click on the check box in price tab for merge & click on the invoice schedule tab without merge cell,
     all the cells in invoice schedule are disable. So set the scope for as default cell.*/
    $rootScope.$emit('defaultSpreadsheet:value', "all");

    $rootScope.$emit('estimateDetail:value', getIdData.leftNavCount);

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/'+$scope.estimateId+'/estimate_details/costlist';
    $scope.addUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/estimate_details/cost/new';
    $scope.updateUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/estimate_details/cost/update';
    $scope.deleteUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/estimate_details/cost/delete';

    // Scope URL for estimate project screens.
    $scope.getPlanListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/project/'+$scope.estimateId+'/cost/milestone';
    $scope.addPlanUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/project/' + $scope.estimateId + '/cost/new/milestone';
    $scope.updatePlanUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/project/' + $scope.estimateId + '/cost/update/milestone';
    $scope.deletePlanUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/project/' + $scope.estimateId + '/cost/delete/milestone';


    if($scope.optionId) {
        $scope.getListUrl = $scope.getListUrl + '/additional_option/' + $scope.optionId;
        $scope.addUrl = $scope.addUrl + '/additional_option/' + $scope.optionId;
        $scope.updateUrl = $scope.updateUrl + '/additional_option/' + $scope.optionId;

        $scope.getPlanListUrl = $scope.getPlanListUrl + '/additional_option/' + $scope.optionId;
        $scope.addPlanUrl = $scope.addPlanUrl + '/additional_option/' + $scope.optionId;
        $scope.updatePlanUrl = $scope.updatePlanUrl + '/additional_option/' + $scope.optionId;
    }


    $scope.categoryId = '';

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabCustomer:successMessage', data);
    });

    $scope.grand_totals = [];

    $rootScope.$on('update:costs:spreadsheetGrandTotals', function() {
        $scope.total_cost = 0;
        $scope.total_price = 0;

        var grand_totals_str = localStorage.getItem('costs:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = grand_totals.length > 1;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.total_cost += grand_totals[i][key]["Total Cost"];
            $scope.total_price += grand_totals[i][key]["Total Price"];
        }

        $scope.total_cost = parseFloat($scope.total_cost.toFixed(2));
        $scope.total_price = parseFloat($scope.total_price.toFixed(2));

        $rootScope.$emit('leftnav:update_cost', $scope.total_cost);
    });

}

function EstimatePriceCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal,$translate,toastBox,confirmationBoxHelper) {
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.optionId = $state.params.optionId || getIdData.optionId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.priceView = $scope.priceViewOption = getIdData.priceViewOption;
    $scope.moduleAccess = getIdData.moduleAccess;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.hideSubNavCustomers = true;
    $scope.InvoiceItemCategoryButton = false;
    $scope.historicalEstimate = getIdData.estimateStatus && (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');
    $scope.addPartsLineItems = getIdData.partsLineItems;
    $scope.timelineSections = getIdData.partsLineItems;
    $scope.invoiceItem = getIdData.invoiceItemCategory;
    $scope.invoiceItemCategory = getIdData.categoryRec;
    $scope.invoiceItemCategoryLists = getIdData.invoiceItemCategoryLists;
    $scope.estimateOptionId = getIdData.optionId;
    $scope.partsTaxItemLists = getIdData.partTaxItemLists;

    if($scope.optionId)
    {
        $scope.getDataRequestURL = '/customers/'+$scope.customerTabName +'/'+$scope.selectedId+'/estimate/'+$scope.estimateId+'/estimate_details/additional_option/'+$scope.optionId+'/edit/part/list';
    }else{
        $scope.getDataRequestURL = '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/estimate_part_details/price';
    }

    if ($scope.moduleAccess == false) {
        $scope.advanced_estimate = false;
    } else {
        $scope.advanced_estimate = true;
    }

    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if(rootMatch[5] == 'edit_option.price'){
        $scope.estimateSubNavStatic = true;
        $scope.selectedTab = "Edit_Additional_Option";
    }
    if(rootMatch[5] == 'price'){
        $scope.estimateSubNavStatic = false;
        $scope.selectedTab = "Estimate";
    }
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $rootScope.$emit('estimateDetail:value', getIdData.leftNavCount);

    // Get the config what column customer see
    $scope.customisable_spreadsheet_columns = getIdData.customisableSpreadsheetColumns;
    $scope.show_vat_in_grand_totals = getIdData.showVatGrantTotal.selected;
    $scope.isHideVat = getIdData.isHideVat;
    $scope.taxItems = getIdData.taxItems;
    $scope.deletedTaxItems = getIdData.deletedTaxItems;
    $scope.overAllVatRate = getIdData.overAllVatRate;

    $scope.taxLabel = $rootScope.taxLabel;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;
    $scope.lineItemCount  = getIdData.lineItemCount;

    $scope.categoryId = '';

    $scope.estimateStatus = getIdData.estimateStatus;
    var isEstimatePortalEnabled = getIdData.isEstimatePortalEnabled;

    if($scope.estimateStatus === "waiting_for_customer" && isEstimatePortalEnabled && getIdData.communication_email_information == true)
    {
        var warningMessage = $translate('Estimate.portal.price.tab.validation.message');
        warningModal.show(warningMessage, "Estimate price", "Estimate_price_warning_massage");
    }

    internalizationTaxSettings.call(this,$scope);
    getEstimatePriceSpreadsheet.call(this,$scope,toastBox,prefix,$http,confirmationBoxHelper);
    CommonPriceViewsFunction.call(this, $rootScope, $scope, prefix, $http);
}

function EstimateInvoiceScheduleCtrl($scope, $state, prefix, $http, getIdData, $rootScope, spreadSheetData,warningModal,$translate) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.optionId = $state.params.optionId || getIdData.optionId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.hideSubNavCustomers = true;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');


    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if(rootMatch[5] == 'edit_option.invoice_schedule'){
        $scope.estimateSubNavStatic = true;
        $scope.selectedTab = "Edit_Additional_Option";
    }
    if(rootMatch[5] == 'invoice_schedule'){
        $scope.estimateSubNavStatic = false;
        $scope.selectedTab = "Estimate";
    }

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    CommonInvoiceScheudleViewFunction.call(this, $rootScope, $scope, prefix, $http);

    $scope.getListUrl = prefix + '/customers/' + $scope.customerTabName + '/' + $scope.selectedId + '/estimate/'+$scope.estimateId+'/invoiceSchedulelists';

    if($scope.optionId) {
        $scope.getListUrl = $scope.getListUrl + "/additional_option/" +$scope.optionId;
    }

    $scope.updateUrl = prefix + '/customers/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/invoiceSchedule/update';

    var estimateStatus = getIdData.estimateStatus,isEstimatePortalEnabled = getIdData.isEstimatePortalEnabled;

    if(estimateStatus === "waiting_for_customer" && isEstimatePortalEnabled && getIdData.communication_email_information == true)
    {
        var warningMessage = $translate('Estimate.portal.invoice.schedule.tab.validation.message');
        warningModal.show(warningMessage, "Estimate invoice", "Estimate_invoice_warning_massage");
    }
}

function EstimateRemindersListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    ViewReminderListCtrl.call(this, $scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope)

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("EstimateReminders", function (event, action, data) {
        var myObject = eval('(' + data + ')');
    });


}

function EstimateNewReminderCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    CreateNewReminder.call(this, $scope, $state, $http, prefix, getIdData, $rootScope)
}

function EditEstimateReminderCtrl($scope, $state, getIdData, $http, prefix, $rootScope) {
    ViewEditReminder.call(this, $scope, $state, getIdData, $http, prefix, $rootScope)
}

function EstimateUploadedFilesCtrl($scope, $state, getIdData, $http, prefix, defaultPagingValue, $rootScope, tableCollection) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.selectedId = $state.params.id;
    $scope.estimateId = $state.params.estimateId;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.estimateSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.shouldBeOpen = false;
    $scope.fileSelected = false;
    $scope.imageTypes = {'jpeg': true, 'jpg': true, 'png': true, 'gif': true};
    $scope.$emit('displayScreen', false);
    tableCollection.setData('attached_files', getIdData.attached_files);

    var message = {name: 'filesCount', value: getIdData.attached_files.count};
    $rootScope.$emit('event:changeEstimateScope', message);

    // This condition for to display the flash message.
    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("EstimatesAttachedFiles", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "preview") {
            $scope.previewFile(myObject);
        } else if (action == "delete") {
            $scope.deleteFile(myObject.id, myObject.name);
        } else if (action == "download") {
            DownloadFunction.call(this, $scope, myObject.location, myObject.name);
        }

    });

    // This condition true when pagination.
    if ($rootScope.selection != '') {
        $scope.selectedUser = $rootScope.selection.rowObject.id;
    }

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    }
    else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    }
    else {
        $scope.currentPage = 1;
    }

    $scope.fetchAttachedFiles = function (pageNum) {

        $rootScope.$broadcast('file:closepreview');

        $http.get(Routing.generate('get_estimate_files', { id: $scope.selectedId, estimateId: $scope.estimateId, type: $scope.customerTabName }) + '?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.$emit('tabCustomer:selected', data.customerDetails);
            var message = {name: 'filesCount', value: data.attached_files.count};
            $rootScope.$emit('event:changeEstimateScope',message);
            tableCollection.setData('attached_files', data.attached_files);
            $scope.$broadcast('smart_tablefilter:newsearch',{'category': 'attached_files', currentPage:pageNum});
        })
    }

    $scope.deleteFile = function (id, name) {
        $scope.selectedFileId = id;
        $scope.selectedFileName = name;
        $scope.shouldBeOpen = true;
    }

    $scope.$on("file:delete", function(evt, data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        $scope.deleteFile(data['id'], data['File name']);
    });

    $scope.$on("file:download", function(evt,data) {
        var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        DownloadFunction.call(this, $scope, data['location'], data['File name']);
    });
    /*
      $scope.$on("file:preview", function(evt, data) {
          var data = tableCollection.getSidepanelData(data.index, 'attached_files');
        var file = {
            'name': data['File name'],
            'location': data['location'],
            'mimeType': data['Type'],
            'createdondatetime': data['createdondatetime'],
            'type': data['Type'],
            'id': data['id']
        };
        $scope.previewFile(file);
      });

      $scope.previewFile = function (fileObj) {

        $scope.enablePreviewFiles = "EstimatesAttachedFiles";
        $scope.imageFile = false;
        $scope.loadFromObject = false;
        $scope.file = fileObj;
        $scope.selectedRow = fileObj.id;

        $scope.image360 = false;
        $scope.videoFile = false;
        $scope.viewableFile = false;
        $scope.image360 = $scope.detect360Images(fileObj.location);
        $scope.fileTypeDetectionFinished = false;

        if (fileObj.type.toLowerCase() == 'jpg' || fileObj.type.toLowerCase() == 'jpeg') {
            $scope.image360.then(function(isImage360) {
                if (isImage360 == true) {
                  $scope.image360 = true;
                  $scope.viewableFile = true;

                  pannellum.viewer('panorama', {
                  'type': 'equirectangular',
                  'panorama': fileObj.location,
                  'autoLoad': true,
                  'autoRotate': -5,
                  'autoRotateStopDelay': 4000
                  });
                } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
                  $scope.imageFile = true;
                  $scope.viewableFile = true;
                } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
                  $scope.loadFromObject = true;
                }

                $scope.fileTypeDetectionFinished = true;

                $scope.$apply();
            });
        }
        else {
            if (fileObj.type.toLowerCase() == 'mp4' || fileObj.type.toLowerCase() == 'webm' || fileObj.type.toLowerCase() == 'ogm') {
              $scope.videoFile = true;
              $scope.viewableFile = true;
              $scope.fileTypeDetectionFinisheed = true;
            } else if ($scope.imageTypes.hasOwnProperty(fileObj.type.toLowerCase())) {
              $scope.imageFile = true;
              $scope.viewableFile = true;
            } else if (fileObj.type.toLowerCase() == 'txt' || fileObj.type.toLowerCase() == 'pdf' ) {
              $scope.loadFromObject = true;
            }

            $scope.fileTypeDetectionFinished = true;
        }
        $scope.fileSelected = true;
      }

      $scope.detect360Images = function(fileUrl) {
        var element = {};
        element['src'] = fileUrl;

        var async = $q.defer();

        EXIF.enableXmp();
        EXIF.getData(element, function() {
        var make = EXIF.getTag(this, "Make");
        var model = EXIF.getTag(this, "Model");
            var panoramaFound = false;
            if (make) {
          if (make.toLowerCase().indexOf('ricoh') > -1) {
                  panoramaFound = true;
                  async.resolve(true);
          }
            }
            if (model && !panoramaFound) {
          if (model.toLowerCase().indexOf('ricoh') > -1) {
                  panoramaFound = true;
                  async.resolve(true);
          }
            }
        async.resolve(false);
        });

        return async.promise;
      }

      $scope.closePreview = function () {
        $scope.enablePreviewFiles = "";
        $scope.selectedRow = "";
        $scope.file = "";
        $scope.fileSelected = false;
        $scope.recordArgs = '';
        $rootScope.selection = '';
        $rootScope.$broadcast('closeall');
      }
    */
}

function AddNewEstimateFileCtrl($scope, $state, $http, prefix, $upload, $rootScope, getIdData, NgConfigKBUrlService) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.selectedId = $state.params.id;
    $scope.supportedFilesLink = NgConfigKBUrlService.getData('uploadSupportedFilesUrl');
    $scope.errorMsg = null;
    $scope.estimateId = $state.params.estimateId;
    $scope.selectedTab = 'Files';
    $scope.$emit('tabCustomer:selected', getIdData.customerDetails);
    $scope.customerTabName = $state.params.type;
    $scope.error = false;
    $scope.testDrag = true;
    $scope.estimateSubNavStatic = true;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName ,'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.excludedeTypes = ['exe', 'msi', 'dmg'];
    $scope.action = '/upload_estimate_file?mode=estimate&estimateId=' + $scope.estimateId;
    $scope.uploadAction = $scope.action;
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.$emit('displayScreen', false);

    $scope.uploadCallback = function() {
        $scope.viewEstimateFiles($scope.selectedId, $scope.estimateId, $scope.customerTabName);
    };

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
            $scope.errorMsg = _.has(resp,'message') && resp.message ? resp.message : null;
        } else {
            $scope.error = false;
            $rootScope.flashMessage = "File.Uploaded";
            $scope.viewEstimateFiles($scope.selectedId, $scope.estimateId, $scope.customerTabName);
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);
}

function EstimateAdditionOptionCtrl($scope, $state, $http, datasets, getIdData, $rootScope, $timeout, prefix, toastBox) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.advanceEstimate = getIdData.advanceEstimate;
    $scope.estimate_options = getIdData.estimateObject;
    $rootScope.$emit('leftnav:update_pricing', getIdData.priceTotal);
    $scope.estimateOptionCount = getIdData.estimateOptionCount;
    $scope.show_option_total_in_pdf = getIdData.showOptionTotal;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.additional_option = true;
    $scope.hideSubNavCustomers = true;
    $scope.selectedTab = "Estimate";
    $scope.estimateSubNavStatic = false;
    $scope.slide_edit_additional_option_leftnav = true;
    $scope.historicalEstimate = getIdData.estimateStatus &&
        (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    $rootScope.$emit('estimateSendToCustomer:status', getIdData.sendToCustomerResult);

    if ($scope.estimateOptionCount == 1) {
        $state.transitionTo("loggedin.customer_list.view." + $scope.dynamicState + ".survey", {'id': $scope.selectedId , 'estimateId': $scope.estimateId, 'type': $scope.customerTabName});
    }

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.setOptionToDelete = function(esimateOptionId) {
        $scope.estimateOptionId = esimateOptionId;
        $scope.estimateOptionShouldBeOpen = true;
    }

    $scope.estimateDetails = { 'estimateOptionCount' : $scope.estimateOptionCount, 'estimateOptions' : $scope.estimate_options }
    $rootScope.$broadcast('estimateOption:count', $scope.estimateDetails);

    $scope.editAdditionalOption = function editAdditionalOption(estimate_option_id, estimate_option_no) {
        $scope.estimateOption = { 'estimate_option_id': estimate_option_id, 'estimate_option_no': estimate_option_no, 'dynamic_state' : $scope.dynamicState };
        $rootScope.$broadcast('leftSlide:animation', $scope.estimateOption);
    }

    // Update display the sum of options total in pdf
    $scope.updateShowPdf = function updateShowPdf() {
        var optionURL = prefix + '/updateShowOptionTotalInPdf/' + $scope.estimateId,
            data = {'showOptionTotalInPdf' : $scope.show_option_total_in_pdf};
        $http.put(optionURL, data).
        success(function (data, status) {
            if (status == 200) {
                if($scope.show_option_total_in_pdf) {
                    toastBox.show('Estimate PDF will show Grand Total for all options', 4000);
                } else {
                    toastBox.show('Estimate PDF will not show Grand Total for all options', 4000);
                }
                // success message
            }
        });
    }

    $scope.fetchEstimateOptionDelete = function () {
        $http.get(prefix + '/getEstimateOptions?id=' + $scope.selectedId + '&type=' + $scope.customerTabName+ '&estimateId=' + $scope.estimateId).success(function (data) {
            $scope.selectedId = data.id;
            $scope.estimateId = data.estimateId;
            $scope.customerTabName = data.customerTabName;
            $scope.estimate_options = data.estimateObject;
            $scope.estimateOptionCount = data.estimateOptionCount;
            $scope.estimateDetails = { 'estimateOptionCount' : $scope.estimateOptionCount, 'estimateOptions' : $scope.estimate_options }
            $rootScope.$emit('leftnav:update_pricing', data.priceTotal);
            $rootScope.$broadcast('estimateOption:count', $scope.estimateDetails);
            if($scope.estimateOptionCount == 1){
                $rootScope.$emit('estimateOptionDesign:change', false);
            }
        })
    }

    $rootScope.$on("estimateOptionsSelected", function (event, message) {
        $scope.selectedId = message.selectedId;
        $scope.estimateId = message.estimateId;
        $scope.customerTabName = message.customerTabName;
        $scope.estimate_options = message.estimateObjects;
        $scope.estimateOptionCount = message.estimateOptionCount;
        $scope.estimateDetails = { 'estimateOptionCount' : $scope.estimateOptionCount, 'estimateOptions' : $scope.estimate_options }
        $rootScope.$broadcast('estimateOption:count', $scope.estimateDetails);
    });
}

function EstimateSendToCustomerCtrl($scope, $state, prefix, $http, $timeout, getIdData, $rootScope, formService, emailService, $compile) {
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.selectedTab = "Estimate";
    $scope.isEstimateAttachToEmail = getIdData.isEstimateAttachToEmail;
    $scope.estimateSubNavStatic = false;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.printAndPostModuleAccess = getIdData.printAndPostModuleAccess;

    CommonSendToCustomerViewFunction.call(this, $rootScope, $scope, prefix, $http, getIdData, $state, $timeout, formService, emailService);

    $scope.showEstimatePDF = function showEstimatePDF(){
        var side_panel_tpl = $compile('<span id="view_pdf" ' +
            'sidepanel template="print_and_post" ' +
            'print_and_post_message="Your estimate is on its way to the customer"'+
            'supports_print_and_post="'+$scope.printAndPostModuleAccess+'"'+
            'title="Print and Post Estimate"'+
            'pdf_url="'+prefix+'/customers/'+$state.params.id+'/estimate/'+$state.params.estimateId+'/loadSendToCustomer"'+
            'printpost_params="tableType=117&tablePKID='+$state.params.estimateId+'&type=print_post"></span>')($scope);

        angular.element('body').append(side_panel_tpl);
        $timeout(function() {
            angular.element('#view_pdf').scope().$$childTail.initialise();
        }, 600);
    }

    $rootScope.$on('parent:updated', function(evt) {
        if (angular.element('#view_pdf')) {
            angular.element('#view_pdf').remove();
        };
    });
}

function EstimateAcceptRejectCtrl($scope, $state, prefix, $http, getIdData, $rootScope, warningModal) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    PermissionCheck.call(this, $scope);
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.advanceEstimate = getIdData.advanceEstimate;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.hideSubNavCustomers = true;
    $scope.selectedTab = "Estimate";
    $scope.estimateSubNavStatic = false;
    $scope.acceptRejectSubmitButton = false;
    $scope.estimate_options = getIdData.estimateObject;
    $scope.canWork = getIdData.canWork;
    $scope.estimateRejectReasons = getIdData.estimateRejectReasons;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.acceptButton = true;
    $scope.unselectedParts = getIdData.unselectedParts;

    $scope.leftNavCount = getIdData.leftNavCount;
    if ($scope.leftNavCount.priceTotal == $scope.leftNavCount.invoiceScheduleTotal) {
        $scope.invalid_invoice_schedule = false;
    } else {
        $scope.invalid_invoice_schedule = true;
        $scope.estimate_total = $scope.leftNavCount.priceTotal;
        $scope.invoice_total = $scope.leftNavCount.invoiceScheduleTotal;
        $scope.invoice_difference = $scope.estimate_total - $scope.invoice_total;
    }
    $scope.selectedOptions = [];

    for(var i = 0, l = $scope.estimate_options.length; i < l; i++){
        var option = $scope.estimate_options[i];
        if (option.confirmText === "Accepted") {
            $scope.acceptButton = false;
        }

        if (option.confirmText != null) {
            $rootScope.$emit('acceptRejectTabCheck', 1);
        }
    }

    $scope.estimateOptionsSuccess = function (selectedId) {
        $http.get(prefix + '/customers/' + $scope.customerTabName + '/' + selectedId + '/estimate/'+$scope.estimateId+'/'+ $scope.dynamicState +'/accept_or_reject/view').success(function (data, status) {
            if (status == 200) {
                $scope.estimate_options = data.estimateObject;
                $scope.estimateRejectReasons = data.estimateRejectReasons;
                $scope.acceptRejectSubmitButton = false;

                $scope.estimateInfo = { 'information': data.information, 'communicationInformation':data.communicationInformation, 'estimateStatus' : data.estimateStatus };
                $scope.$emit('update:estimateInfo', $scope.estimateInfo);

                for(var i = 0, l = $scope.estimate_options.length; i < l; i++){
                    var option = $scope.estimate_options[i];
                    $rootScope.$emit('acceptRejectTabCheck', 1);
                    if (option.confirmText === "Accepted") {
                        $scope.acceptButton = false;

                        // Check whether Job screen have permission or not.
                        var featureName = 'Jobs', featureAccess = 'readaccess', featureId = features[featureName];
                        var jobPermissions = $rootScope.hasPermission(featureId, featureAccess);

                        if(jobPermissions) {
                            $state.transitionTo('loggedin.customer_list.view.job.details', {'id': selectedId, 'type': data.customerTabName, 'jobId': data.jobId});
                        } else {
                            $state.transitionTo('loggedin.customer_list.view.property', {'id': selectedId, 'type': data.customerTabName});
                        }
                    }
                }
            }
        });
    }

    /* Accept reject loop function for common */
    CommonAcceptRejectLoopCtrl.call(this, $scope, warningModal);

    $scope.handleOption = function handleOption(index) {
        $scope.estimate_options[index].estimate_option_allow_check = false;
    }

    $scope.selectOption = function selectOption(index) {
        $scope.estimate_options[index].estimate_option_allow_check = !$scope.estimate_options[index].estimate_option_allow_check;
        $scope.handleSelectedOptions();
    }

    $scope.handleSelectedOptions = function handleSelectedOptions() {
        $scope.selectedOptions = [];
        $scope.acceptRejectSubmitButton = false;
        for(var i = 0, l = $scope.estimate_options.length; i < l; i++){
            var option = $scope.estimate_options[i];
            if (JSON.parse(option.estimate_option_allow_check) === true) {
                $scope.acceptRejectSubmitButton = true;
                $scope.selectedOptions.push(option['estimate_option_id']);
            }
        }
    }

    $scope.optionDecision = function optionDecision(decision) {
        for(var i = 0, l = $scope.estimate_options.length; i < l; i++){
            var original_option = $scope.estimate_options[i];
            for(var x = 0, c = $scope.selectedOptions.length; x < c; x++){
                var selected_option = $scope.selectedOptions[x];
                if (original_option.title === selected_option.title) {
                    $scope.estimate_options[i].status.decision = decision;
                }
            }
        }
        $scope.deselectAllOptions();
    }
    $scope.deselectAllOptions = function deselectAllOptions() {
        for(var i = 0, l = $scope.estimate_options.length; i < l; i++){
            $scope.estimate_options[i].selected = false;
        }
    }
}

function EstimateCommunicationsCtrl($scope, $state, $http, prefix, getIdData, $rootScope, dateRange, emailService) {
    $scope.hideEstimateSubNav = true;
    $scope.selectedId = $state.params.id;
    $scope.customerId = $scope.selectedId;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.selectedTab = "Estimates";
    $scope.customerSubNavStatic = false;
    $scope.show_daterange = false;
    $scope.dateFilterOptions = dateRange.getFilterOptions();
    $scope.startDate = '';
    $scope.endDate = '';
    $scope.searchText = '';
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.contacts = getIdData.contacts;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'customerSubNavStatic':$scope.customerSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.smsWriteAccess = getIdData.smsWriteAccess;

    $scope.action = "";
    
    $scope.handleActionFilter = function(action) {
      if( $scope.action == action ) {
          $scope.action = "";
      } else {
          $scope.action = action;
      }
      $scope.$broadcast('event:communicationNotesFilter',{'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action});
    }

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.customerId + '&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=5&id=' + $scope.estimateId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 5;        // For Estimate
        clone_obj.relatedObjectId = $scope.estimateId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on("event:communicationNotesFilter", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if( message.action ) {
            $scope.action = message.action;
        }
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        var filters = {'searchText': $scope.searchText, 'startDate': $scope.startDate, 'endDate': $scope.endDate, contactId: $scope.contactId, 'action': $scope.action};

        $scope.$broadcast('filterValue', filters);
    });

    var baseRoute = prefix + '/customers/' + $state.params.id + '/estimate/' + $state.params.estimateId;
    handlePrintAndDownloadOptions.call(this, $scope, baseRoute);
    handleNotesAndCommunicationFilter.call(this, $scope);
}

function EstimateNotesListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope, canLoad, $q, $sce, emailService) {
    EstimateDynamicRouteChangeCtrl.call(this, $scope, $state);
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.hideEstimateSubNav = true;
    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.customerTabName = getIdData.customerTabName;

    $scope.hideSubNavCustomers = true;
    $scope.customerOffices = getIdData.navBarDetails;
    $scope.customerId = $scope.customerOffices.id;
    $scope.notesDetails = getIdData.notesDetails;
    $scope.contacts = getIdData.contacts;
    $scope.selectedTab = "Notes";
    $scope.estimateSubNavStatic = false;
    $scope.$emit('displayScreen', false);
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.contactId = $scope.$parent.contactId;
    $scope.searchText = $scope.$parent.searchText;
    $scope.startDate = $scope.$parent.startDate;
    $scope.endDate = $scope.$parent.endDate;
    $scope.shouldBeOpen = false;
    $scope.smsReadAccess = getIdData.smsReadAccess;
    $scope.smsWriteAccess = getIdData.smsWriteAccess;
    $scope.printAndPostModule = getIdData.printAndPostModule;

    if($scope.contactId == '' && $scope.searchText == '' && $scope.startDate == '' && $scope.endDate == '') {
        $scope.notes = getIdData.notesDetails.notesDetails;
        $scope.notesCount = $scope.notesDetails.notesCount;
        $scope.notesCountMain = $scope.notesDetails.notesCountMain;
        $scope.smsMessages = getIdData.smsMessages;
        $scope.smsMessagesCount = getIdData.smsMessagesCount;
        $scope.phoneCalls = getIdData.phoneCalls;
        $scope.phoneCallsCount = getIdData.phoneCallsCount;
        $scope.emailMessages = getIdData.emailMessages;
        $scope.emailMessagesCount = getIdData.emailMessagesCount;
        $scope.printLetters = getIdData.printLetters;
        $scope.printLettersCount = getIdData.printLettersCount;
    }

    if ($rootScope.flashMessage) {
        $scope.$emit('tabCustomer:successMessage', $rootScope.flashMessage);
        $rootScope.flashMessage = '';
    }

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("EstimateNotes", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "delete") {
            $scope.triggerDelete(myObject.id);
        }
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("NotesPrintandDownload", function (event, action, data) {
        var myObject = eval('(' + data + ')');

        if (action == "download") {
            $scope.recordId = $scope.customerId;
            $scope.pageName = 'Estimate Notes List';
            $scope.excelRouteName = 'downloadnotes';
            var download = prefix + '/' + $scope.excelRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            DownloadFunction.call(this, $scope, download, $scope.pageName);
        } else if (action == "print") {
            $scope.recordId = $scope.customerId;
            $scope.pageName = 'Estimate Notes List';
            $scope.pdfRouteName = 'printnotes';
            var print = prefix + '/' + $scope.pdfRouteName + '/' + $scope.recordId + '?page=' + $scope.pageName;
            PrintPDFFunction.call(this, $scope, print, $scope.pageName);
        }

    });

    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }

    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }

    if ($state.current.data.limitPhoneCalls) {
        $scope.limitPhoneCalls = parseInt($state.current.data.limitPhoneCalls);
    } else {
        $scope.limitPhoneCalls = defaultPagingValue;
    }

    if ($state.current.data.pageNumPhoneCalls) {
        $scope.currentPagePhoneCalls = parseInt($state.current.data.pageNumPhoneCalls);
    } else {
        $scope.currentPagePhoneCalls = 1;
    }

    if ($state.current.data.limitSmsMessages) {
        $scope.limitSmsMessages = parseInt($state.current.data.limitSmsMessages);
    } else {
        $scope.limitSmsMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumSmsMessages) {
        $scope.currentPageSmsMessages = parseInt($state.current.data.pageNumSmsMessages);
    } else {
        $scope.currentPageSmsMessages = 1;
    }

    if ($state.current.data.limitEmailMessages) {
        $scope.limitEmailMessages = parseInt($state.current.data.limitEmailMessages);
    } else {
        $scope.limitEmailMessages = defaultPagingValue;
    }

    if ($state.current.data.pageNumEmailMessages) {
        $scope.currentPageEmailMessages = parseInt($state.current.data.pageNumEmailMessages);
    } else {
        $scope.currentPageEmailMessages = 1;
    }

    if ($state.current.data.limitPrintLetters) {
        $scope.limitPrintLetters = parseInt($state.current.data.limitPrintLetters);
    } else {
        $scope.limitPrintLetters = defaultPagingValue;
    }

    if ($state.current.data.pageNumPrintLetters) {
        $scope.currentpagePrintLetters = parseInt($state.current.data.pageNumPrintLetters);
    } else {
        $scope.currentpagePrintLetters = 1;
    }

    $scope.$watchCollection('[limit, currentPage]', function (newVal, oldVal) {
        $state.current.data.pageNum = $scope.currentPage;
        $state.current.data.limit = $scope.limit;
    });

    $scope.$on('email:email_sent', function(evt) {
        $scope.fetchEmailMessages($scope.currentPageEmailMessages);
    });

    // Send Email
    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.customerId +'&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=5&id=' + $scope.estimateId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 5;        // For Estimate
        clone_obj.relatedObjectId = $scope.estimateId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }

    $scope.$on("filterValue", function (event, message) {
        $scope.searchText = message.searchText;
        $scope.startDate = message.startDate;
        $scope.endDate = message.endDate;
        $scope.contactId = '';
        if(message.contactId) {
            $scope.contactId = message.contactId;
        }
        $scope.searchCommunications(1);
    });

    var canceler = null;
    $scope.fetchNotes = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (canceler) {
            canceler.resolve();
        }
        canceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/estimate/' + $scope.estimateId + '/listNotes?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: canceler.promise}).success(function (data) {
            $scope.notes = data.notesDetails;
            $scope.notesCount = data.notesCount;
            $scope.notesCountMain = data.notesCountMain;
            var message = {name: 'notesCount', value: $scope.notesCount};
            $scope.$emit('event:changeEstimateScope', message);
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var phoneCallCanceler = null;
    $scope.fetchPhoneCalls = function (pageNumPhoneCalls) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (phoneCallCanceler) {
            phoneCallCanceler.resolve();
        }
        phoneCallCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/estimate/' + $scope.estimateId + '/listPhoneCalls?pagePhoneCalls=' + pageNumPhoneCalls + '&limitPhoneCalls=' + $scope.limitPhoneCalls + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: phoneCallCanceler.promise}).success(function (data) {
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var smsCanceler = null;
    $scope.fetchSmsMessages = function (pageNumSmsMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (smsCanceler) {
            smsCanceler.resolve();
        }
        smsCanceler = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/estimate/' + $scope.estimateId + '/listSms?pageSmsMessages=' + pageNumSmsMessages + '&limitSmsMessages=' + $scope.limitSmsMessages + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: smsCanceler.promise}).success(function (data) {
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
        })
    }

    var emailCanceller = null;
    $scope.fetchEmailMessages = function (pageNumEmailMessages) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (emailCanceller) {
            emailCanceller.resolve();
        }
        emailCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/estimate/' + $scope.estimateId +  '/listEmail?pageEmailMessages=' + pageNumEmailMessages + '&limitEmailMessages=' + $scope.limitEmailMessages + '&searchText=' + encodeURIComponent(searchText) + '&contactId=' + $scope.contactId + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: emailCanceller.promise}).success(function (data) {
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }

    var printCanceller = null;
    $scope.fetchPrintLetters = function (pageNum) {
        canLoad.setLoadValue(false);  // Turn off AJAX
        if (printCanceller) {
            printCanceller.resolve();
        }
        printCanceller = $q.defer();
        pageNum = (pageNum == undefined) ? 1 : pageNum;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/estimate/' + $scope.estimateId + '/listPrint?pagePrintLetters=' + pageNum + '&limitPrintLetters=' + $scope.limitPrintLetters + '&startDate=' + startDate + '&endDate=' + endDate, {timeout: printCanceller.promise}).success(function (data) {
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
        })
    }

    $scope.triggerDelete = function (id) {
        $scope.selectedId = id;
        $scope.shouldBeOpen = true;
    }

    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }

    var searchCanceller = null;
    $scope.searchCommunications = function (pageNum) {
        canLoad.setLoadValue(false);
        if (searchCanceller) {
            searchCanceller.resolve();
        }
        searchCanceller = $q.defer();
        var searchText = ($scope.searchText == undefined) ? '' : $scope.searchText;
        var startDate = ($scope.startDate == undefined) ? '' : $scope.startDate;
        var endDate = ($scope.endDate == undefined) ? '' : $scope.endDate;
        $http.get(prefix + '/customers/customer_list/' + $scope.customerId + '/estimate/' + $scope.estimateId + '/view/table?page=' + pageNum + '&limit=' + $scope.limit + '&searchText=' + encodeURIComponent(searchText) + '&startDate=' + startDate + '&endDate=' + endDate + '&contactId=' + $scope.contactId, {timeout: searchCanceller.promise}).success(function (data) {
            $scope.notesDetails = data.notesDetails;
            $scope.notes = data.notesDetails.notesDetails;
            $scope.notesCount = data.notesDetails.notesCount;
            $scope.notesCountMain = data.notesDetails.notesCountMain;
            $scope.phoneCalls = data.phoneCalls;
            $scope.phoneCallsCount = data.phoneCallsCount;
            $scope.emailMessages = data.emailMessages;
            $scope.emailMessagesCount = data.emailMessagesCount;
            $scope.smsMessages = data.smsMessages;
            $scope.smsMessagesCount = data.smsMessagesCount;
            $scope.printLetters = data.printLetters;
            $scope.printLettersCount = data.printLettersCount;
            $scope.selectedId = data.navBarDetails.id;
            $scope.$emit('tabCustomer:selected', data.navBarDetails);

            if(searchText != '' || startDate != '' || endDate != '' || $scope.contactId > 0) {
                $scope.searchResult = true;
            } else {
                $scope.searchResult = false;
            }
        })
    }
    $scope.downloadCallRecording = function(fileLink){
        let link = document.createElement('a');
        link.href = fileLink;

        if (link.download !== undefined) {
            link.download = 'call-recording.wav';
        }
        if (document.createEvent) {
            let e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
        }
    };
    EmailAttachedFileSidePanelCtrl.call(this,$scope, $rootScope);
}

function EstimateNotesTimeViewCtrl($scope, $state, $http, prefix, getIdData, $timeout, $rootScope, canLoad, $q, $sce, emailService) {
    $scope.selectedId = $state.params.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.selectedTab = "Notes";
    $scope.hideSubNavCustomers = true;
    $scope.estimateSubNavStatic = false;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('displayScreen', false);
    $scope.$emit('estimateId:selected', $scope.estimateId);

    // This function for Keyboard shortcut functionality.
    ShortCutsFunctions.call(this, $scope, $rootScope);

    // This steps used only for keyboard shotcuts screen.
    $scope.$on("EstimateNotes", function (event, action, data) {
        if (action == "sendEmail") {
            $scope.sendEmail();
        }
    });

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);

        // get attachments and send to emailservice
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + $scope.selectedId + '&type=all_email';
        clone_obj.attachments_url = '/get_attached_files_list?tableType=5&id=' + $scope.estimateId;
        clone_obj.subject = '';
        clone_obj.message = '';
        clone_obj.relatedObjectType = 5;        // For Estimate
        clone_obj.relatedObjectId = $scope.estimateId;

        $rootScope.$broadcast('email:createNew', clone_obj);
    }
    $rootScope.$on('enableJobEstimateChatFeature', function (event, value) {
        $scope.enableJobEstimateChatFeature = value;
    });

    var url = '/customers/customer_list/' + $state.params.id + '/estimate/' + $state.params.estimateId + '/notes/view/timeline';
    HandleAllTimeLineViews.call(this, $scope, canLoad, $http, $q, getIdData, $sce, $timeout, $rootScope, url, prefix);
}

function EstimateNewNotesCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.selectedId = $state.params.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.selectedTab = "Notes";
    $scope.estimateSubNavStatic = true;
    $scope.modeChange = $state.params.mode;

    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('displayScreen', false);
    $scope.$emit('estimateId:selected', $scope.estimateId);

    $scope.$emit('tabCustomer:selected', getIdData);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.saving = false;
            $rootScope.flashMessage = data.success;
            $state.transitionTo("loggedin.customer_list.view.estimate.notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'estimateId' : $state.params.estimateId });
        })
    }
}

function EstimateEditNotesCtrl($scope, $state, $http, prefix, getIdData, $rootScope) {
    $scope.selectedId = $state.params.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.noteDetails = getIdData.notesDetails;
    $scope.title = $scope.noteDetails.title;
    $scope.notesid = $scope.noteDetails.notesid;
    $scope.customerOffices = getIdData.navBarDetails;
    $scope.customerId = $scope.customerOffices.id;
    $scope.estimateSubNavStatic = true;
    $scope.$emit('tabCustomer:selected', getIdData.navBarDetails);
    $scope.selectedStopwork = $scope.noteDetails.stopwork;
    $scope.selectedImportant = $scope.noteDetails.important;
    $scope.selectedSendtomobile = $scope.noteDetails.sendtomobile;
    $scope.selectedNote = $scope.noteDetails.note;
    $scope.selectedTilte = $scope.noteDetails.title;
    $scope.modeChange = $state.params.mode;
    $scope.selectedTab = "Notes";
    $scope.$emit('displayScreen', false);
    $scope.$emit('estimateId:selected', $scope.estimateId);
    NotesCheckBoxCtrl.call(this, $scope);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.editNotes = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.updating = true;
        $scope.current = this;
        $http.post(prefix + '/edit_note', "notes[id]=" + $scope.notesid + "&notes[title]=" + encodeURIComponent(this.selectedTilte) + "&notes[note]=" + encodeURIComponent(this.selectedNote)
            + "&notes[selectedStopwork]=" + encodeURIComponent(this.selectedStopwork) + "&notes[selectedImportant]=" + encodeURIComponent(this.selectedImportant) + "&notes[selectedSendtomobile]=" + this.selectedSendtomobile).
        success(function (data, status) {
            if (status == 200) {
                $scope.editNoteForm.$setPristine();
                $rootScope.flashMessage = data;
                if ($scope.modeChange == 'timeline') {
                    $scope.estimateNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.estimateId);
                } else if ($scope.modeChange == 'table') {
                    $scope.estimateNotesTableList($scope.selectedId, $scope.customerTabName, $scope.estimateId);
                }
                $scope.updating = false;
            }
        });
    }
}

function EstimateNewSmsMessageCtrl($scope, $http, $state, datasets,  prefix, smsTemplates, $rootScope, formService, getIdData, sidePanel, $sce) {
    EmailMessageControllers.call(this, $scope, $http, $state, datasets, $rootScope);
    $scope.notes = getIdData.customerOffices;
    smsTemplates.setTemplatesValue(getIdData.templateDetails);
    $scope.smsTemplates = getIdData.templateDetails;
    $scope.selectedId = $state.params.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.selectedTab = "Notes";
    $scope.estimateSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('estimateId:selected', $scope.estimateId);

    $scope.$on('templateAccordion', function(event, message) {
        tinyMCE.activeEditor.execCommand('mceInsertContent', false, message);
    });

    SMSMessageControllers.call(this, $scope);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.saving = false;
            $rootScope.flashMessage = data.success;
            $state.transitionTo("loggedin.customer_list.view.estimate.notes." + $state.params.mode, { 'id': $state.params.id, 'type': $state.params.type, 'estimateId' : $state.params.estimateId });
        })
    }
    $scope.renderHtml = function (html_code) {
        return $sce.trustAsHtml(html_code);
    }
}

function EstimateNewPhoneCallCtrl($scope, $state, $http, prefix, $rootScope, formService, getIdData) {
    $scope.notes = getIdData.customerOffices;
    $scope.selectedId = $state.params.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.selectedTab = "Notes";
    $scope.estimateSubNavStatic = true;
    $scope.modeChange = $state.params.mode;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.estimateSubNavStatic };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('estimateId:selected', $scope.estimateId);

    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newPhoneCallForm.$setPristine();
            $rootScope.flashMessage = data.success;
            if ($scope.modeChange == 'timeline') {
                $scope.estimateNotesTimeLineList($scope.selectedId, $scope.customerTabName, $scope.estimateId);
            } else if ($scope.modeChange == 'table') {
                $scope.estimateNotesTableList($scope.selectedId, $scope.customerTabName, $scope.estimateId);
            }
            $scope.saving = false;
        })
    }
}

//
function EstimateDynamicRouteChangeCtrl($scope, $state) {
    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if (rootMatch[4] == 'project_estimate_details' || rootMatch[4] == 'project') {
        $scope.dynamicState = 'project_estimate_details';
        $scope.dynamicMainState = 'project';
        $scope.hasPermissionName = 'Projects';
        $scope.cloneRoute = 'clone_project';
        $scope.deleteRoute = 'delete_project';
    }
    if (rootMatch[4] == 'estimate_details' || rootMatch[4] == 'estimate') {
        $scope.dynamicState = 'estimate_details';
        $scope.dynamicMainState = 'estimate';
        $scope.hasPermissionName = 'Estimates';
        $scope.cloneRoute = 'clone_estimate';
        $scope.deleteRoute = 'delete_estimate';
    }
}

function CommonEstimateSubNavigation($scope, $rootScope, getIdData)
{
    if ($scope.moduleAccess == true) {
        $scope.advanced_estimate = true;
    } else {
        $scope.advanced_estimate = false;
    }
    $scope.advanced_parts =  (getIdData.partsModuleAccess == true) ? true :false;

    $scope.checkIfUserHasFilledOutBudgetAndCost = function() {
        $scope.has_filled_out_budget_and_cost = false;
        if($scope.estimate_options_count > 1){
            let priceIsSet = true;
                angular.forEach($scope.estimateOptions, function (option) {
                    if (typeof option.price == 'undefined' || option.price === null) {
                        priceIsSet = false;
                        return;
                    }
                });
            if(priceIsSet){
                $scope.has_filled_out_budget_and_cost = true;
            }
        } else {
            if (parseFloat($rootScope.estimate_total_price) > 0) {
                $scope.has_filled_out_budget_and_cost = true;
            }
        }
    }

    $scope.acceptRejectCheck = function() {
        if($scope.invoice_difference == 0) {
            $scope.allowAccept = 0;
        } else {
            $scope.allowAccept = 1;
        }
    }

    $scope.todos_count = getIdData.leftNavCount.todosCount;
    $scope.estimate_total_price = getIdData.leftNavCount.priceTotal;
    $scope.estimate_total_cost = getIdData.leftNavCount.costTotal;
    $scope.estimate_options_count = getIdData.leftNavCount.optionsCount;
    $rootScope.estimate_total_price = getIdData.leftNavCount.priceTotal;
    $scope.checkIfUserHasFilledOutBudgetAndCost();

    //$rootScope.$emit('update:over-all-vat-rate', 500);

    $scope.intro_complete_tick = getIdData.tickStatus.introductionStatus;
    if(getIdData.tickStatus.bodyStatus || getIdData.tickStatus.todosStatus || getIdData.tickStatus.budgetStatus || getIdData.tickStatus.priceStatus) {
        $scope.options_tick = true;
    }
    $scope.body_complete_tick = getIdData.tickStatus.bodyStatus;
    $scope.parts_complete_tick = getIdData.tickStatus.partsStatus;
    $scope.todos_complete_tick = getIdData.tickStatus.todosStatus;
    $scope.budget_complete_tick = getIdData.tickStatus.budgetStatus;
    $scope.price_complete_tick = getIdData.tickStatus.priceStatus;
    $scope.send_to_customer_tick = getIdData.tickStatus.sendToCustomerResult;
    $scope.accept_complete = getIdData.tickStatus.acceptRejectStatus;

    $rootScope.$on('estimateOption:count', function(event, data) {
        $scope.estimate_options_count = data.estimateOptionCount;
        $scope.estimateOptions = data.estimateOptions;
        $scope.checkIfUserHasFilledOutBudgetAndCost();
    });

    $rootScope.$on('estimateSendToCustomer:status', function(event, data) {
        $scope.send_to_customer_tick = data;
    });

    $rootScope.$on('estimateDetail:value', function(event, data) {
        // $scope.todos_count = data.todosCount; // 2016-01-19 -- JOSEPH commented this out due to todosCount being incorrect. Todos count is now broadcast from the number of valid todos in the spreadsheet directive
        if(data) {
            $scope.estimate_total_price = data.priceTotal;
            $scope.estimate_total_cost = data.costTotal;
            $scope.estimate_options_count = data.optionsCount;
            $rootScope.estimate_total_price = data.priceTotal;

            $rootScope.$emit('update:over-all-vat-rate', data.overAllVatRate);

            $scope.checkIfUserHasFilledOutBudgetAndCost();
        }

    });

    /*When click on the check box in price tab for merge & click on the invoice schedule tab without merge cell,
     all the cells in invoice schedule are disable. So set the scope for as default cell.*/
    $rootScope.$on('defaultSpreadsheet:value', function(event, data) {
        $rootScope.spreadsheet_instance_id = data;
    });


    // Enable the tick icon for invoice schedule tab.
    if(getIdData.tickStatus.invoiceScheduleStatus) {
        $scope.invoice_difference = 0;
    }

    // Show the error message when invoice amount not equal to price amount.
    $scope.invalid_invoice_schedule = getIdData.tickStatus.invalid_invoice_schedule;
    $rootScope.$on('acceptRejectTabCheck', function(event, data) {
        $scope.accept_complete = data;
    });


    $scope.triggerEstimateAndProjectDelete = function () {
        $scope.estimateDeleteShouldBeOpen = true;
    }

    $scope.triggerAdditionalOption = function () {
        $scope.additionalOptionShouldBeOpen = true;
    }

    /*==========================================================================================
     Left nav data
     - when totals are set / changed show the value in the left nav
     ==========================================================================================*/
    $rootScope.$on('leftnav:update_pricing', function(e, price) {
        $scope.estimate_total_price = price;
        $rootScope.estimate_total_price = price;
    });

    $rootScope.$on('leftnav:update_cost', function(e, cost) {
        $scope.estimate_total_cost = cost;
        $scope.checkIfUserHasFilledOutBudgetAndCost();
    });

    $rootScope.$on('leftnav:todos_count_updated', function(e, count) {
        $scope.todos_count = count;
    });

    /*==========================================================================================
     Invoice Schedule
     ==========================================================================================*/
    $rootScope.$on('invoice_schedule:updated', function(e, schedule) {
        $scope.invalid_invoice_schedule = schedule.status === 'not-valid';
        $scope.invoice_difference = schedule.difference;

        // set as scope variables so that the values can be used in the help
        // text in send to customer and accept / reject views
        if($scope.invalid_invoice_schedule) {
            $scope.estimate_total = schedule.estimate_total;
            $scope.invoice_total = schedule.invoice_total;
        }

        if($scope.invalid_invoice_schedule == true) {
            $scope.allowAccept = 1;
        }
    });
}

function CommonPriceViewsFunction($rootScope, $scope, prefix, $http)
{
    $scope.show_grand_totals = true;
    if($scope.priceViewOption == 1) {
        $scope.active_option = 'no_breakdown';
    }else if($scope.priceViewOption == 2){
        $scope.active_option = 'category_breakdown';
    }else if($scope.priceViewOption == 3){
        $scope.active_option = 'full_breakdown';
    }else if($scope.priceViewOption == 4 || $scope.priceViewOption == null){
        $scope.active_option = 'full_breakdown_by_category';
        $scope.priceView     = 4;
        $scope.show_grand_totals = false;
        $scope.InvoiceItemCategoryButton = ($scope.moduleAccess == false) ? true : $scope.InvoiceItemCategoryButton;
    }
    $scope.getSpreadsheetInitialParams();
    $scope.spreadsheet_hide_loading = false;

    /*==========================================================================================
     Setup the data for the pricing section
     ==========================================================================================*/
    $scope.grand_totals = [];
    $scope.tempInvoiceItemCategories = '';

    /*==========================================================================================
     Handle manipulating the data
     ==========================================================================================*/

    // NOTE!!!!!! This needs to be replaced with logic that checks if the pricing breakdowns should be disabled
    $scope.disable_pricing_breakdown_options = false;
    var should_show_confirmation = true;

    $scope.noBreakdown = function noBreakdown() {
      $scope.spreadsheet_hide_loading = false;
        if($scope.active_option != 'no_breakdown') {
            if ($scope.historicalEstimate) {
                var modal = document.getElementById('cannot-switch-estimate-pricing');
                $(modal).modal('show');
                return;
            }
            if((parseInt($scope.total_price) != 0) && ($scope.advanced_estimate == false)) {
                if (should_show_confirmation) {
                    var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');
                    $scope.deferred_category_breakdown_function = $scope.noBreakdown;
                    $(modal).modal('show');
                } else {
                    $scope.breakdown_text = "no breakdown";
                    $scope.handleShowingCannotSwitchBreakdown();
                }
            } else if (!((parseFloat($scope.total_price) > 0) && ($scope.advanced_estimate == false))) {
                $scope.InvoiceItemCategoryButton = false;
                $scope.active_option = 'no_breakdown';
                $scope.priceView     = 1;
                $scope.resetLocalStorageTotals();
                $scope.getSpreadsheetInitialParams();
            }
        }
    }

    $scope.categoryBreakdown = function categoryBreakdown() {
      $scope.spreadsheet_hide_loading = false;
        if($scope.active_option != 'category_breakdown') {
            if ($scope.historicalEstimate) {
                var modal = document.getElementById('cannot-switch-estimate-pricing');
                $(modal).modal('show');
                return;
            }

            if((parseInt($scope.total_price) != 0) && ($scope.advanced_estimate == false)) {
                if (should_show_confirmation) {
                    var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');
                    $scope.deferred_category_breakdown_function = $scope.categoryBreakdown;
                    $(modal).modal('show');
                } else {
                    $scope.breakdown_text = "category breakdown";
                    $scope.handleShowingCannotSwitchBreakdown();
                }

            } else if (!((parseFloat($scope.total_price) > 0) && ($scope.advanced_estimate == false))) {
                $scope.InvoiceItemCategoryButton = false;
                $scope.active_option = 'category_breakdown';
                $scope.priceView     = 2;
                $scope.resetLocalStorageTotals();
                $scope.getSpreadsheetInitialParams();
            }
        }
    }

    $scope.fullBreakdown = function fullBreakdown() {
      $scope.spreadsheet_hide_loading = false;
        if($scope.active_option != 'full_breakdown') {
            if ($scope.historicalEstimate) {
                var modal = document.getElementById('cannot-switch-estimate-pricing');
                $(modal).modal('show');
                return;
            }

            if((parseInt($scope.total_price) != 0) && ($scope.advanced_estimate == false)) {
                if (should_show_confirmation) {
                    var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');
                    $scope.deferred_category_breakdown_function = $scope.fullBreakdown;
                    $(modal).modal('show');
                } else {
                    $scope.breakdown_text = "full breakdown";
                    $scope.handleShowingCannotSwitchBreakdown();
                }
            } else if (!((parseFloat($scope.total_price) > 0) && ($scope.advanced_estimate == false))) {
                $scope.InvoiceItemCategoryButton = false;
                $scope.active_option = 'full_breakdown';
                $scope.priceView = 3;
                $scope.resetLocalStorageTotals();
                $scope.getSpreadsheetInitialParams();
            }
        }
    }

    $scope.fullBreakdownByCategory = function fullBreakdownByCategory(fromConfirmation) {
        $scope.spreadsheet_hide_loading = false;
        if($scope.active_option != 'full_breakdown_by_category') {
            if ($scope.historicalEstimate) {
                var modal = document.getElementById('cannot-switch-estimate-pricing');
                $(modal).modal('show');
                return;
            }

            if((parseInt($scope.total_price) != 0) && ($scope.advanced_estimate == false)) {
                if (should_show_confirmation) {
                    var modal = document.querySelector('#show-confirmation-of-line-item-breakdown');
                    $scope.deferred_category_breakdown_function = $scope.fullBreakdownByCategory;
                    $(modal).modal('show');
                } else {
                    $scope.breakdown_text = "full breakdown by category";
                    $scope.handleShowingCannotSwitchBreakdown();
                }
            } else if (!((parseFloat($scope.total_price) > 0) && ($scope.advanced_estimate == false))) {
                if ($scope.moduleAccess == false) {
                    $scope.InvoiceItemCategoryButton = true;
                }
                if (fromConfirmation) {
                    $scope.priceView = 4;
                    $scope.active_option = 'full_breakdown_by_category';
                    $scope.resetLocalStorageTotals();
                    $scope.getSpreadsheetInitialParams();
                } else {
                    if ($scope.optionId) {
                        $scope.url = '?estimate[customerId]=' + $scope.selectedId + '&estimate[estimateId]=' + $scope.estimateId + '&estimate[optionId]=' + $scope.optionId;
                    } else {
                        $scope.url = '?estimate[customerId]=' + $scope.selectedId + '&estimate[estimateId]=' + $scope.estimateId;
                    }
                    $http.get(prefix + '/estimatePriceSwitchBreakdown' + $scope.url).success(function (data) {
                        $scope.estimateValues = data.leftNavCount;
                        $scope.invoiceItemCategory = data.categoryRec;
                        $scope.invoiceItem = data.invoiceItemCategory;
                        $scope.$emit('estimateDetail:value', $scope.estimateValues);
                        $scope.priceView = 4;
                        $scope.active_option = 'full_breakdown_by_category';
                        $scope.resetLocalStorageTotals();
                        $scope.getSpreadsheetInitialParams();
                    });
                }
            }
        }
    };

    $scope.handleEstimatePriceSwitchBreakdown = function() {
        if($scope.optionId) {
            $scope.url = '?estimate[customerId]='+ $scope.selectedId + '&estimate[estimateId]='+ $scope.estimateId + '&estimate[optionId]='+ $scope.optionId;
        }
        else {
            $scope.url = '?estimate[customerId]='+ $scope.selectedId + '&estimate[estimateId]='+ $scope.estimateId;
        }

        $http.get(prefix + '/estimatePriceSwitchBreakdown' + $scope.url).success(function (data) {
            $scope.estimateValues = data.leftNavCount;
            $scope.$emit('estimateDetail:value', $scope.estimateValues);
            $scope.deferred_category_breakdown_function(true);
            $scope.user_has_already_confirmed_breakdown = true;
            $scope.confirmActiveOptionChange = false;
            $scope.invoiceItemCategory = data.categoryRec;
            $scope.invoiceItem = data.invoiceItemCategory;
            $('#show-confirmation-of-line-item-breakdown').modal('hide');
        });
    }

    $scope.handleShowingCannotSwitchBreakdown = function() {
        var modal = document.getElementById('cannot-switch-breakdown');

        $(modal).modal('show');
    }
    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        if(!$scope.total_price)
        {
            $scope.total_price = 0;
            $scope.advanced_estimate == false;
            $scope.resetLocalStorageTotals();
        }
    });
    $scope.confirmBreakdown = function() {
        $scope.confirmActiveOptionChange = true;
        $scope.total_price = 0;
        $scope.advanced_estimate == false;
        $scope.resetLocalStorageTotals();
        // If select the add line item and then change the Price Breakdown type without saving, then we need to rest the checked box.
        $scope.$broadcast('removeCheckedInAddLineItems', $scope.confirmActiveOptionChange);

        $scope.handleEstimatePriceSwitchBreakdown();

    }

    $scope.closeModal = function(modalName) {
        $scope.confirmActiveOptionChange = false;
        $(modalName).modal('hide');
    }

    // Only used to swap the call when unmerge in full breakdown by category.
    $scope.unmergeFullBreakdownByCategory = function unmergeFullBreakdownByCategory() {
        $scope.active_option = 'unmerge_full_breakdown_by_category';
        $scope.resetLocalStorageTotals();
    }

    $scope.resetLocalStorageTotals = function resetLocalStorageTotals() {
        // clear grandtotals from localstorage
        var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;
        if(grand_totals_exist) {
            localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
        }
    }

    /*==========================================================================================
     Grand Totals
     ==========================================================================================*/

    $rootScope.$on('update:estimates:price:spreadsheetGrandTotals', function() {

        $scope.total_price = $scope.total_tax = $scope.total_with_tax = 0;
        var grand_totals_str = localStorage.getItem('estimates:price:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);
        $scope.show_grand_totals = true;

        for(var i = 0, l = grand_totals.length; i < l; i++) {
            var key = Object.keys(grand_totals[i]);
            $scope.total_price += parseFloat(grand_totals[i][key]["Total"]);
            $scope.total_tax += parseFloat(grand_totals[i][key]["Total VAT"]);
        }

        $scope.total_price = parseFloat($scope.total_price.toFixed(2));
        if(($scope.total_price > 0) && ($scope.advanced_estimate == false)) {
            $scope.disable_pricing_breakdown_options = false;
        }
        if($scope.total_tax) {
            $scope.total_tax = parseFloat($scope.total_tax.toFixed(2));
        }
        $scope.grandTotal = $scope.total_price + $scope.total_tax;
        $rootScope.$emit('leftnav:update_pricing', $scope.total_price);
    });

    /*==========================================================================================
     Configurable spreadsheet options in full breakdown and full breakdown by category
     ==========================================================================================*/

    $scope.$on('estimate_price:columns_configured', function(e, data) {
        if($scope.additionalWorkId || $scope.estimateId){
            $scope.show_vat_in_grand_totals = data.show_vat_in_grand_totals;
            var handle_spreadsheet_columns = $scope.active_option === 'full_breakdown' ||
                $scope.active_option === 'full_breakdown_by_category';

            if(handle_spreadsheet_columns) {
                $scope.handleSpreadsheetColumns(data.columns);
                $scope.customisable_spreadsheet_columns = data.columns;
            }
            var priceColumnUrl, paramPriceConfig;
            if($scope.additionalWorkId) {
                priceColumnUrl = prefix + '/jobAdditionalWorkPriceConfigColumn';
                paramPriceConfig = "additionalWork[columns]=" + angular.toJson(data) + "&additionalWork[propertyId]=" + $scope.selectedId
                    + "&additionalWork[jobId]=" + $scope.jobId + "&additionalWork[additionalWorkId]=" +$scope.additionalWorkId;
            } else {
                priceColumnUrl = prefix + '/estimatePriceConfigColumn';
                paramPriceConfig = "estimate[columns]=" + angular.toJson(data) + "&estimate[propertyId]=" + $scope.selectedId
                    + "&estimate[estimateId]=" + $scope.estimateId + "&estimate[optionId]=" +$scope.optionId;
            }

            $http.post(priceColumnUrl, paramPriceConfig).
            success(function (data, status) {
                if (status == 200) {
                    $scope.$emit('update_config_columns', data);
                    $scope.flashMessage = "Updated";
                }
            });
        } else {
            $scope.show_vat_in_grand_totals = true;
            $scope.$emit('update_config_columns', $scope.show_vat_in_grand_totals);
        }
    });

    $scope.handleSpreadsheetColumns = function handleSpreadsheetColumns(column_settings) {
        $rootScope.$emit('spreadsheet:updatePricingColumns', column_settings);
    }

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabCustomer:successMessage', data);

    });

    // Assign the continues selected invoice item category values from sidepanel dropdown for basic estimate screen.
    $scope.$on('assignTempInvoiceCategoryIds', function(e, data) {
        $scope.tempInvoiceItemCategories = data;
    });

    $scope.$on('remove:added-invoice-item', function(e, data) {
        $scope.invoiceItem = data;
    });

    $rootScope.$on('update:over-all-vat-rate', function(e, data) {
        $scope.overAllVatRate = data;
    });

    $scope.$on('update:full-breakdown-category', function(e, data) {
        $scope.invoiceItemCategory.push(data);
    });

}

function CommonInvoiceScheudleViewFunction($rootScope, $scope, prefix, $http)
{
    if($scope.advanced_estimate) {
        $scope.has_milestones = $scope.estimate_costs.length > 1;
    }

    /*When click on the check box in price tab for merge & click on the invoice schedule tab without merge cell,
     all the cells in invoice schedule are disable. So set the scope for as default cell.*/
    $rootScope.$emit('defaultSpreadsheet:value', "all");

    /*==========================================================================================
     If estimate has milestones the estimate total needs calculating
     ==========================================================================================*/
    $scope.handleMilestoneData = function handleMilestoneData() {
        $scope.milestones = [];
        $scope.estimate_total = 0;

        for(var i = 0, l = $scope.estimate_costs.length; i < l; i++) {
            var milestone_name = _.keys($scope.estimate_costs[i])[0],
                milestone_price = $scope.estimate_costs[i][milestone_name]['Total Price'],
                tmp_milestone = {};

            tmp_milestone.name = milestone_name;
            tmp_milestone.amount = milestone_price;

            $scope.milestones.push(tmp_milestone);
            $scope.estimate_total += milestone_price;
        }
    }
    /*==========================================================================================
     Handle grand totals
     ==========================================================================================*/
    $rootScope.$on('update:invoice_schedule:spreadsheetGrandTotals', function() {
        var grand_totals_str = localStorage.getItem('invoice_schedule:spreadsheetGrandTotals');
        var grand_totals = JSON.parse(grand_totals_str);

        $scope.invoice_total = grand_totals[0]['Invoice schedule']['Amount'];
        $scope.show_grand_totals = true;

        if($scope.estimate_total) {
            $scope.difference = parseFloat(parseFloat($scope.estimate_total - $scope.invoice_total).toFixed(2));
            $scope.handleScheduleStates();
        }

        $scope.handleNextSteps();
    });

    /*==========================================================================================
     Steps 8 and 9 in the left nav should be disabled if the difference is not 0 as
     the estimate can't be sent to a customer or accepted / rejected if the invoice
     schedule isn't correct
     ==========================================================================================*/
    $scope.handleNextSteps = function handleNextSteps() {
        if($scope.difference === 0) {
            $rootScope.$emit('invoice_schedule:updated', {
                status: 'valid',
                difference: $scope.difference
            });
        }else {
            $rootScope.$emit('invoice_schedule:updated', {
                status: 'not-valid',
                estimate_total: $scope.estimate_total,
                invoice_total: $scope.invoice_total,
                difference: $scope.difference
            });
        }
    }

    $scope.handleScheduleStates = function handleScheduleStates() {
        if($scope.difference > 0) {
            $scope.invoice_schedule_state = 'under';
        }else if($scope.difference < 0) {
            $scope.invoice_schedule_state = 'over';
            $scope.amount_to_deduct = Math.abs($scope.difference);
        }else {
            $scope.invoice_schedule_state = 'matched';
            $scope.$emit('communicaion_info:update', true);
        }
    }

    /*==========================================================================================
     Setup estimate total and milestones if present
     ==========================================================================================*/
    var initialise = (function() {
        if($scope.has_milestones) {
            $scope.handleMilestoneData();
        }else {
            $scope.estimate_total = $rootScope.estimate_total_price;
        }
    })();

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabCustomer:successMessage', data);
    });
}

function ViewReminderListCtrl($scope, $state, $http, prefix, defaultPagingValue, getIdData, $rootScope) {
    $scope.selectedId = $scope.customerSelectedId = $state.params.id;
    $scope.selectedTab = "Reminders";
    $scope.hideSubNavCustomers = true;
    $scope.reminders = getIdData.reminders;
    $scope.remindersCount = getIdData.remindersCount;
    $scope.futureRemindersCount = getIdData.futureRemindersCount;
    $scope.$emit('tabCustomer:selected', getIdData.customerNav);
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if ( rootMatch[4] == 'project' || rootMatch[4] == 'estimate') {
        $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
        $scope.estimateSubNavStatic = false;
        $scope.$emit('displayScreen', false);
        $scope.$emit('estimateId:selected', $scope.estimateId);
        $scope.commonId =$scope.estimateId;
        $scope.commonName ='estimate';
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':false };
    }else if(rootMatch[4] == 'job' ){
        $scope.jobId = $state.params.jobId;
        $scope.jobSubNavStatic = false;
        $scope.$emit('jobId:selected',  $state.params.jobId);
        $scope.commonId =$scope.jobId;
        $scope.commonName ='job';
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':false };
    }

    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);


    if ($state.current.data.limit) {
        $scope.limit = parseInt($state.current.data.limit);
    } else {
        $scope.limit = defaultPagingValue;
    }
    if ($state.current.data.pageNum) {
        $scope.currentPage = parseInt($state.current.data.pageNum);
    } else {
        $scope.currentPage = 1;
    }
    $scope.deleteReminder = function (id) {
        $scope.reminderId = id;
        $scope.shouldBeOpen = true;
    }
    $scope.fetchReminders = function (pageNum) {
        $http.get(prefix + '/customers/'+$scope.customerTabName+'/' + $scope.customerSelectedId + '/'+$scope.commonName+'/' + $scope.commonId +'/reminders/view?page=' + pageNum + '&limit=' + $scope.limit).success(function (data) {
            $scope.reminders = data.reminders;
            $scope.remindersCount = data.remindersCount;
            $scope.futureRemindersCount = data.futureRemindersCount;
            var message = {name: 'futureRemindersCount', value: $scope.futureRemindersCount};
            if($scope.commonName == 'job'){
                $rootScope.$emit('event:changeJobScope',message);
            }
            else if($scope.commonName == 'estimate'){
                $rootScope.$emit('event:changeEstimateScope',message);
            }

            $scope.$emit('tabCustomer:selected', data.customerNav);
        })
    }
}

function CreateNewReminder($scope, $state, $http, prefix, getIdData, $rootScope){
    $scope.selectedId = $state.params.id;
    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);
    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.selectedTab = "Reminders";
    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if ( rootMatch[4] == 'project' || rootMatch[4] == 'estimate') {
        $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
        $scope.estimateSubNavStatic = true;
        $scope.displayError = false;
        $scope.$emit('estimateId:selected', $scope.estimateId);
        $scope.$emit('displayScreen', false);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.jobSubNavStatic };
    }else if(rootMatch[4] == 'job' ){
        $scope.jobId = $state.params.jobId;
        $scope.jobSubNavStatic = true;
        $scope.$emit('jobId:selected',  $state.params.jobId);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    }
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('estimateId:selected', $scope.estimateId);

    ReminderDateCtrl.call(this, $scope, getIdData, '', '', 'reminderAdd', $state);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$watch('userDetails', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }
        }
    });
    $scope.formSubmit = function (formElem) {
        $http.post($scope.actionVal, formElem.serialize()).
        success(function (data, status) {
            $scope.newReminderForm.$setPristine();
            $scope.saving = false;
            $rootScope.flashMessage = "Estimate.Reminder.Saved";
            if ( rootMatch[4] == 'project' || rootMatch[4] == 'estimate') {
                $scope.viewEstimateReminders($scope.selectedId, $scope.customerTabName, $scope.estimateId);
                $rootScope.flashMessage = "Estimate Reminder Saved";
            }else if(rootMatch[4] == 'job'){
                $scope.viewJobReminders($scope.selectedId, $scope.customerTabName, $scope.jobId);
                $rootScope.flashMessage = "Job Reminder Saved";
            }
        })
    }
}

function ViewEditReminder($scope, $state, getIdData, $http, prefix, $rootScope){
    $scope.selectedId = $state.params.id;
    $scope.$emit('tabCustomer:selected', getIdData.customerAddress);
    $scope.customerTabName = $state.params.type || getIdData.customerAddress.customerTabName;
    $scope.selectedTab = "Reminders";
    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);
    if ( rootMatch[4] == 'project' || rootMatch[4] == 'estimate') {
        $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
        $scope.estimateSubNavStatic = true;
        $scope.displayError = false;
        $scope.$emit('estimateId:selected', $scope.estimateId);
        $scope.$emit('displayScreen', false);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'estimateSubNavStatic':$scope.jobSubNavStatic };
    }else if(rootMatch[4] == 'job' ){
        $scope.jobId = $state.params.jobId;
        $scope.jobSubNavStatic = true;
        $scope.$emit('jobId:selected',  $state.params.jobId);
        $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'selectedTab': $scope.selectedTab, 'jobSubNavStatic':$scope.jobSubNavStatic };
    }
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.reminderId = $state.params.reminderId;
    $scope.userDetails = getIdData.selectedUsers;

    angular.forEach(getIdData.reminderDetails, function (key, val) {
        $scope.editReminderNote = key.remindernote;
        $scope.reminderDate = key.reminderdate;
    });

    ReminderDateCtrl.call(this, $scope, getIdData, '', $scope.reminderDate, 'edit', $state);

    $rootScope.$on("datepicker_selected", function (event, message) {
        $scope[message.modelName] = message.date;
    });

    $scope.$watch('userDetails', function (newVal, oldVal){
        if(newVal != undefined && newVal != '') {
            $scope.errorModules = false;
            $scope.displayError = false;
            $scope.checkedUser = true;
        }
        else {
            $scope.errorModules = true;
            if($scope.checkedUser == true) {
                $scope.displayError = true;
                $scope.checkedUser = false;
            }
        }
    });

    $scope.editReminder = function ($event, formStatus) {
        $event.preventDefault();
        if (!formStatus) {
            return;
        }
        $scope.app = [];
        $scope.updating = true;
        $scope.current = this;
        if (this.userDetails != '') {
            $scope.selectedUsers = new Array(); var i=0;
            angular.forEach(this.userDetails, function (val, key) {
                $scope.selectedUsers[i] = val.id;
                i++;
            });
        }

        $http.post(prefix + '/edit_reminder', "reminder[remindernote]=" + encodeURIComponent($scope.editReminderNote)
            + "&reminder[reminderId]=" + $scope.reminderId + "&reminder[jobId]=" + $scope.jobId
            + "&reminder[remindersettings][reminderusers]=" + $scope.selectedUsers
            + "&reminder[reminderdate]=" + moment(this.reminderSelectedDate, 'L').format('YYYY-MM-DD')).
        success(function (data, status) {
            if (status == 200) {
                if (data.error) {
                    $scope.errorDateMessage = true;
                } else {
                    if ( rootMatch[4] == 'project' || rootMatch[4] == 'estimate') {
                        $scope.viewEstimateReminders($scope.selectedId, $scope.customerTabName, $scope.estimateId);
                        $rootScope.flashMessage = data;
                    }else if(rootMatch[4] == 'job'){
                        $scope.viewJobReminders($scope.selectedId, $scope.customerTabName, $scope.jobId);
                        $rootScope.flashMessage = data;
                    }

                }
                $scope.updating = false;
            }
        });
    }
}

function CommonSendToCustomerViewFunction($rootScope, $scope, prefix, $http, getIdData, $state, $timeout, formService, emailService)
{
    $scope.prefix = prefix;
    $scope.estimateTermsAndConditions = getIdData.estimateTermsAndConditions;
    $scope.leftNavCount = getIdData.leftNavCount;
    $scope.sendToCustomerContents = getIdData.sendToCustomerContents;
    $scope.viewOption = getIdData.viewOption;
    $scope.estimateDefaultSubject = getIdData.estimateDefaultSubject;
    $scope.estimateDefaultMessage = getIdData.estimateDefaultMessage;
    $scope.hideSubNavCustomers = true;

    if ($scope.leftNavCount.priceTotal == $scope.leftNavCount.invoiceScheduleTotal) {
        $scope.invalid_invoice_schedule = false;
    } else {
        $scope.invalid_invoice_schedule = true;
        $scope.estimate_total = $scope.leftNavCount.priceTotal;
        $scope.invoice_total = $scope.leftNavCount.invoiceScheduleTotal;
        $scope.invoice_difference = $scope.estimate_total - $scope.invoice_total;
    }

    if($scope.jobId && $scope.additionalWorkId) {
        $scope.pdfFilePath = $scope.prefix + '/customers/' + $scope.selectedId + '/jobs/' + $scope.jobId +  '/additional_works/' + $scope.additionalWorkId + '/loadSendToCustomer?' + (new Date().getTime());
    } else {
        $scope.pdfFilePath = $scope.prefix + '/customers/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/loadSendToCustomer?' + (new Date().getTime());
    }

    if ($scope.viewOption == 1) {
        $scope.active_option = 'email';
    } else if ($scope.viewOption == 2) {
        $scope.active_option = 'print';
    } else if ($scope.viewOption == 3) {
        $scope.active_option = 'printPost';
    }

    $scope.email = function email() {
        $scope.active_option = 'email';
        $scope.viewOption = 1;
        SendToCustomerOption.call(this, $scope, prefix, $http, $scope.viewOption);
    }

    $scope.print = function print() {
        $scope.active_option = 'print';
        $scope.viewOption = 2;
        SendToCustomerOption.call(this, $scope, prefix, $http, $scope.viewOption);
    }

    $scope.printPost = function printPost() {
        $scope.active_option = 'printPost';
        $scope.viewOption = 3;
        SendToCustomerOption.call(this, $scope, prefix, $http, $scope.viewOption);
    }

    $scope.$on('estimateTerms', function(event, data) {
        $scope.estimateTermsRecs = [];
        angular.forEach(data, function (key, value) {
            if (JSON.parse(key.estimate_terms_check) == true) {
                $scope.estimateTermsRecs.push({'id': key.id});
            }
        });

        if($scope.jobId && $scope.additionalWorkId) {
            $scope.termsPath = prefix + '/customers/' + $scope.selectedId + '/jobs/' + $scope.jobId +  '/additional_works/' + $scope.additionalWorkId + '/printselectedTermsConditions';
        } else {
            $scope.termsPath = prefix + '/customers/' + $scope.selectedId + '/estimate/' + $scope.estimateId + '/printselectedTermsConditions';
        }

        $http.post($scope.termsPath, "estimate[selectedTermsAndConditions]=" + angular.toJson($scope.estimateTermsRecs) + "&estimate[sendToCustomerContents]=" + $scope.sendToCustomerContents).
        success(function (data, status) {
            if (status == 200) {
                if($scope.jobId && $scope.additionalWorkId) {
                    $state.transitionTo('loggedin.customer_list.view.job.additional_works.view.send_to_customer', {'id':  $scope.selectedId , 'jobId': $scope.jobId, 'type': $scope.customerTabName, 'additionalWorkId': $scope.additionalWorkId}, {reload:true})
                } else {
                    $state.transitionTo('loggedin.customer_list.view.estimate_details.sendtocustomer', {'id':  $scope.selectedId , 'estimateId': $scope.estimateId, 'type': $scope.customerTabName}, {reload:true});
                }
            }
        });
    });

    $scope.updateSendTOCustomerStatus = function updateSendTOCustomerStatus() {
        $rootScope.$emit('estimateSendToCustomer:status', true);
    }

    $scope.sendEmail = function() {
        var new_email = emailService.generateBlankEmailData(),
            new_obj = {},
            clone_obj = _.extend(new_obj, new_email);
        var isAddEstimateAttachment = !!($scope.selectedTab === "Estimate" && $scope.isEstimateAttachToEmail);

        // get attachments and send to emailservice
        var propertyId = getIdData.customerType == 'workaddress' ? getIdData.parentPropertyId : $scope.selectedId;
        clone_obj.from_addresses_url = '/get_from_emails';
        clone_obj.recipient_addresses_url = '/get_customer_emailaddresses?id=' + propertyId;
        clone_obj.attachments_url = '/get_attached_files_list?tableType=5&id=' + $scope.estimateId;
        clone_obj.subject = $scope.estimateDefaultSubject;
        clone_obj.message = $scope.estimateDefaultMessage;
        clone_obj.relatedObjectType = 117;
        clone_obj.relatedObjectId = $scope.estimateId;
        if(isAddEstimateAttachment)
        {
            clone_obj.attached_files = [
                {
                    id: "ES|" + $scope.selectedId + "|" + $scope.estimateId + "|" +117,
                    file_name: 'Estimate No. ' + $scope.estimateId,
                    file_size: 0,
                    type: 'generated'
                }
            ];
        }
        $rootScope.$broadcast('email:createNew', clone_obj);
    }
}

function CommonAcceptRejectLoopCtrl($scope, warningModal) {

    $scope.estimateAccept = function (estimateOptionRec) {
        if($scope.unselectedParts == true) {
            warningModal.show("You cannot accept this estimate because you have not selected a price for one or more parts", "Accept option", "estimate_parts_unselected_warning");
        }
        else {
            $scope.estimateOptionRecs = [];
            angular.forEach(estimateOptionRec, function (key, value) {
                if(key.estimate_option_allow_check == true) {
                    $scope.estimateOptionRecs.push({'estimateId': key.estimate_id, 'estimateOptionId': key.estimate_option_id});
                    $scope.shouldBeOpenAccept = true;
                }
            });
        }
    }
    $scope.estimateReject = function (estimateOptionRec) {
        $scope.estimateOptionRecs = [];
        angular.forEach(estimateOptionRec, function (key, value) {
            if(key.estimate_option_allow_check == true) {
                $scope.estimateOptionRecs.push({'estimateId': key.estimate_id, 'estimateOptionId': key.estimate_option_id});
                $scope.shouldBeOpenReject = true;
            }
        });
    }
}










