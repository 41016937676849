'use strict';

function EstimatePartsCtrl($scope, $state, $http, datasets, getIdData, $rootScope,prefix) {

    RootCtrl.call(this, $scope, $http, $state, datasets, $rootScope,prefix);

    PermissionCheck.call(this, $scope);

    $scope.selectedId = $state.params.id || getIdData.id;
    $scope.estimateId = $state.params.estimateId || getIdData.estimateId;
    $scope.customerTabName = $state.params.type || getIdData.customerTabName;
    $scope.customCurrency = $rootScope.CustomCurrency;
    $scope.$emit('tabCustomer:selected', getIdData);

    $scope.historicalEstimate = getIdData.estimateStatus && (getIdData.estimateStatus == 'accepted' || getIdData.estimateStatus == 'rejected');

    $rootScope.$emit('estimateDetail:value', getIdData.leftNavCount);

    var rootMatch = $scope.$state.current.name.match(/^(.*?)\.(.*?)\.(.*?)\.(.*?)\.(.*?)$/);

    if(rootMatch[5] == 'edit_option.parts'){
        $scope.estimateSubNavStatic = true;
        $scope.selectedTab = "Edit_Additional_Option";
        $scope.optionsId = getIdData.optionId;
    }
    if(rootMatch[5] == 'parts'){
        $scope.estimateSubNavStatic = false;
        $scope.selectedTab = "Estimate";
        $scope.optionsId = null;
    }
    $scope.estimateCompleted = getIdData.estimateCompleted;
    $scope.suppliersModule = getIdData.suppliersModule;
    $scope.partListIds = getIdData.partListIds;
    $scope.permissions = getIdData.permissions;
    $scope.estimateDetails = { 'estimateId': $scope.estimateId, 'optionId':$scope.optionId }
    $scope.$emit('estimateId:selected', $scope.estimateDetails);
    $scope.hideSubNavCustomers = true;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'estimateSubNavStatic':$scope.estimateSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
}

function AddLineItemsToEstimate($scope, $rootScope, $timeout, prefix, $http,  $compile)
{
    $scope.removeChecked = false;
    $scope.addPartAccess = $scope.$parent.$parent.historicalEstimate;
    $scope.optionId = $scope.$parent.$parent.optionId;
    if(_.isUndefined($scope.timeline_sections)) {
      $scope.timeline_sections=[];
    }
    if(_.isUndefined($scope.all_line_items)) {
      $scope.all_line_items = [];
    }
    $scope.handleFilter = function() {
        $scope.line_items = [];
        /*for (var i = 0, x = $scope.timeline_sections.length; i < x; i++) {
            $scope.timeline_sections[i].showing = false;
        }*/
        for (var i = 0, x = $scope.all_line_items.length; i < x; i++) {
            var line_item = $scope.all_line_items[i],
                description = line_item.engineer_name || line_item.description || line_item.part || line_item.title,
                in_search_filter = description.toLowerCase().search($scope.search_events.toLowerCase()) > -1;

            if (in_search_filter || line_item.selected === true) {

                if($scope.removeChecked == true) {
                    line_item.selected = false;
                    line_item.already_selected = false;
                }
                line_item.CustomCurrency = $rootScope.CustomCurrency;
                $scope.line_items.push(line_item);

                /*if(i == 0) {
                    $scope.timeline_sections[line_item.timeline_id].showing = true;
                }*/
            }
        }
        $scope.removeChecked = false;
    }

    $scope.selectAllLineItems = function selectAllLineItems(){
        for (var i = 0, x = $scope.all_line_items.length; i < x; i++)
        {
            var line_item_select = $scope.all_line_items[i];
            if(line_item_select.selected != $scope.selectAllParts && line_item_select.already_selected != true)
            {
                line_item_select.selected = $scope.selectAllParts;
            }
        }
        $scope.createSelectedLineItemsArray();
    }

    $scope.handleSelectedLineItem = function(line_item) {

        if (line_item.already_selected !== true && !$scope.addPartAccess) {
            line_item.selected = !line_item.selected;
        }
        if($scope.selectAllParts && line_item.already_selected !== true)
        {
            $scope.selectAllParts = false;
        }

        $scope.createSelectedLineItemsArray();
    }

    $scope.createSelectedLineItemsArray = function() {
        $scope.selected_line_items = [];
        $scope.selected_expenses_without_category = [];
        $scope.user_has_selected_line_items = false;
        for (var i = 0, x = $scope.line_items.length; i < x; i ++) {
            var line_item = $scope.line_items[i];

            if (line_item.selected === true) {
                $scope.user_has_selected_line_items = true;
                $scope.selected_line_items.push(line_item);
            }
        }
    }

    $scope.saveAndAddLineItems = function() {
        $scope.user_has_selected_line_items = false;

        // While change the invoice breakdown type we need to update it.
        $scope.active_breakdown = $scope.$parent.$parent.active_option;

        $scope.getSpreadsheet = [];

        for (var i = 0, x = $scope.selected_line_items.length; i < x; i ++) {

            var line_item = $scope.selected_line_items[i];

            var categoryId = 2, category = '';

            $scope.getSpreadsheet.push($scope.decorateSpreadsheet(line_item, $scope.active_breakdown, categoryId, category));

        }
        $scope.getSpreadsheetData($scope.getSpreadsheet);
    }

    $scope.decorateSpreadsheet = function(lineItems, activeBreakdown, categoryId, category) {

        // Handsontable spreadsheet
        var hotId = (activeBreakdown === 'full_breakdown_by_category') ? activeBreakdown+'_'+categoryId : activeBreakdown;
        var defaultTaxItemId = $scope.$parent.$parent.default_tax_item_id;
        var listOfTaxItems   = $scope.$parent.$parent.listOfTaxItems;
        var check            = _.where(listOfTaxItems, {id: lineItems.taxItemId});
        var taxItemId        = check.length ? lineItems.taxItemId : defaultTaxItemId;

        var line_item = {
            lineItemId : lineItems.line_item_id,
            partListId : lineItems.part_list_id,
            partId : lineItems.part_id,
            description : lineItems.description,
            part : lineItems.part,
            quantity : lineItems.quantity,
            unit_price : lineItems.markup,
            total_exc_vat : lineItems.total_exc_vat,
            vatRate : lineItems.vat_rate,
            taxItemId : taxItemId,
            type : lineItems.type,
            activeBreakdown : activeBreakdown,
            categoryId : categoryId,
            category : category,
            optionId : $scope.optionId,
            hotId    : hotId
        }
        return line_item;
    }

    $scope.getSpreadsheetData = function(getSpreadsheet) {

        var decorated_line_items = {
            labour: [],
            parts: []
        };

        $http.post(prefix + '/customers/' + $scope.$parent.$parent.customerTabName + '/' + $scope.$parent.$parent.selectedId + '/estimate/' + $scope.$parent.$parent.estimateId + '/generate_estimate_price_line_item_spreadsheet', "lineItems=" + encodeURIComponent(angular.toJson(getSpreadsheet))).then(function (response) {

            decorated_line_items = response.data;

            $scope.estimateValues = decorated_line_items.leftNavCount;

            //$rootScope.$broadcast('spreadsheet:todoupdate');
            $rootScope.$broadcast('spreadsheet:addData',decorated_line_items.estimateData);
            $rootScope.$broadcast('estimateDetail:value', $scope.estimateValues);
            $scope.commonExecution();

        });
    }

    $scope.resetLocalStorageTotals = function resetLocalStorageTotals() {
        // clear grandtotals from localstorage
        var grand_totals_exist = localStorage.getItem('estimates:price:spreadsheetGrandTotals') !== null;
        if(grand_totals_exist) {
            localStorage.removeItem('estimates:price:spreadsheetGrandTotals');
        }
    }

    $scope.commonExecution = function() {
        $scope.selected_line_items = [];
        for (var i = 0, x = $scope.line_items.length; i < x; i ++) {
            if ($scope.line_items[i].selected === true) {
                $scope.line_items[i].selected = false;
                $scope.line_items[i].already_selected = true;
                for (var c = 0, v = $scope.all_line_items.length; v < x; c ++) {
                    if ($scope.all_line_items[c].id === $scope.line_items[i].id) {
                        $scope.all_line_items[c].selected = false;
                        $scope.all_line_items[c].already_selected = true;
                    }
                }
            }
        }

        $scope.active_slide = 1;
        $scope.closePanel();
    }
    $scope.clearData = function clearData(){
        $scope.line_items = {};
        $scope.timeline_sections = {};
        $scope.selectAllParts = false;
        $scope.all_line_items = {};
        for (var i = 0, x = $scope.timeline_sections.length; i < x; i++) {
            $scope.timeline_sections[i].showing = false;
        }
    };

    $rootScope.$on('parent:updated',function(){
        $scope.clearData();
    });
    $scope.closePanel = function() {
        $scope.search_events='';
        $scope.clearData();
        $scope.handleFilter();
        $rootScope.$broadcast('closeAllSidepanels');
    }

    $scope.active_slide = 1;

    $scope.user_has_selected_line_items = false;

    // If select the add line item and then change the Invoice Breakdown type without saving, then we need to rest the checked box.
    $scope.$on('removeCheckedInAddLineItems', function(e,checkedStatus) {
        $scope.removeChecked = checkedStatus;
        $scope.user_has_selected_line_items = false;
        $scope.handleFilter();
    });

    $scope.$on('loadSidePanelData', function() {
        $scope.line_items = [];
        //$scope.timeline_sections = $scope.$parent.$parent.timelineSections;
        $scope.active_breakdown = $scope.$parent.$parent.active_option;
        $scope.in_category_breakdown = $scope.active_breakdown === 'full_breakdown_by_category' || $scope.active_breakdown === 'category_breakdown';
        $scope.all_line_items = JSON.parse($scope.$parent.panel_data);
        $scope.search_events = '';
        $scope.handleFilter();
        $scope.project_estimate = false;
        $scope.$parent.loading=true;
        $scope.selectAllParts = false;
    });
}

function addpartSpreadsheet($scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $timeout, $compile, $translate)
{
    $scope.spreadSheetParts = [];
    $scope.isPartSpreadSheetEmpty = true;
    $scope.displaySpreadsheet = false;
    $scope.instanceLoad=[];
    $scope.partScope = [];
    $scope.currentParts = [];

    spreadsheetScroll.call(this, $scope);

    /*** Spreadsheet default settings for both parts & supplier ***/
    $scope.rowHeaders = true;
    $scope.startRows = 1;
    /*** Set spare row as 0, because when load default data it automatically insert an another empty row ***/
    $scope.minSpareRows = 0;
    $scope.minRows = 1;
    $scope.width = "auto";
    $scope.autoWrapCol = true;
    $scope.autoWrapRow = true;
    $scope.partRowCount = 0;
    var screenType = 'add_part_kit';
    /*** Load the default data into parts spreadsheet ***/
    $scope.partsAfterInit= function() {

        /*** Get the default handsontable settings ***/
        $scope.updateSettings = this.updateSettings;

        $http.get($scope.getPartSpreadsheetURL).success(function(response) {

            $scope.colHeadersParts = response.colHeaders;
            $scope.startColsParts = response.startColsParts;
            $scope.spreadSheetParts = response.parts;
            $scope.selectedPartsDetails = response.selectedPartsDetails;

            /*** Load the default value when new row created ***/
            $scope.partDataSchema= {id: '', partsId: '', quantity: 1, deleteAction: ""};
            $scope.partColWidths = [1,300,100,70];

            /*** Assign the spreadsheet columns and its cell type ***/
            $scope.partColumns = [
                {data: 'id', type: 'numeric', readOnly: true},
                { renderer: $scope.partRenderer, data: 'partsId', editor: 'select2', placeholder: 'Please select',
                    select2Options: {
                        minimumInputLength:2,
                        ajax: { url: prefix + '/get_add_parts_dropdown/'+$state.params.id,
                            data: function (query, page) {
                                if(query == '') {
                                    return false;
                                }
                                return { 'query': encodeURIComponent(query)};
                            },
                            results: function (data, page) {

                                var currentParts = [];

                                angular.forEach(data.parts, function (part, key) {

                                    var selected = false;

                                    angular.forEach($scope.spreadSheetParts, function (selectedPart, key1) {
                                        if(selectedPart.partsId == part.id) {
                                            selected = true;
                                        }
                                    });

                                    if(selected == false) {
                                        $scope.partScope.push(part);
                                        currentParts.push(part.id);
                                    }

                                });

                                // grouping parts
                                var formatResult = _.groupBy(data['parts'],'id');

                                var parts = [];
                                angular.forEach(formatResult, function(groupedParts,key) {
                                    parts[key] = groupedParts[0];
                                    var supplierRef = [];
                                    angular.forEach(groupedParts, function(part) {
                                        if(part['supplierpartnumber'] != null) {
                                            supplierRef.push({'supplierpartnumber' : part['supplierpartnumber'], 'suppliername' : part['suppliername']});
                                        }
                                    });
                                    parts[key]['supplierpartnumber'] = supplierRef;
                                });

                                $scope.currentParts = currentParts;
                                $scope.defaultPartsData = data.defaultPartsData;
                                return { results: Object.values(parts) };
                            }
                        },
                        formatResult: function(item, container, query, escapeMarkup) {
                            var splitSearchTerm = query.term.split(" ");
                            angular.forEach(splitSearchTerm, function (v,k) {
                                splitSearchTerm[k] = "(" + v + ")";
                            });
                            let regex = new RegExp(splitSearchTerm.join("|"), 'gi');
                            var htmlresult = item.text.replace(regex, function (i) { return '<u>' + i + '</u>'; });

                            if(item.manufacturepartnumber){
                                htmlresult += ', '+ $translate('MPN') + ' - ' + item.manufacturepartnumber.replace(regex, function (i) { return '<u>' + i + '</u>'; });
                            }

                            if(item.supplierpartnumber.length > 0) {
                                var spns = item.supplierpartnumber;

                                htmlresult += '<br>';
                                angular.forEach(spns, function (spn) {
                                    if(spn['suppliername'] != null && regex.test(spn['supplierpartnumber'])) {
                                        spn['supplierpartnumber'] = spn['supplierpartnumber'].replace(regex, function(i) { return '<u>' + i + '</u>' });
                                        htmlresult += '<div class="select2 supplier-product-code cs-text-muted"><span>' + $translate('product.code', { supplierName : spn['suppliername'] }) + '</span>: ' + spn['supplierpartnumber']+ '</div>';
                                    }
                                });

                            }
                            return $compile('<div class="select2 parts-list-dropdown">' + htmlresult + '</div>')($scope);
                        },
                        dropdownCssClass: "handsontable-select",
                        dropdownAutoWidth: true, width: 'resolve'
                    },
                    strict: true, allowInvalid: false
                },
                {renderer: $scope.partRenderer, data: 'quantity', type: 'numeric', allowInvalid: false, validator: $scope.cellValidator},
                {renderer: $scope.partRenderer, data: 'deleteAction', readOnly: true, disableVisualSelection: true}
            ];

            $scope.hidePartColumns = {columns:[0]};
            /*** Load the new settings into the spreadsheet and load them ***/
            $scope.updateSettings({colHeaders: $scope.colHeadersParts, fillHandle: false,
                rowHeaders: $scope.rowHeaders, startRows: $scope.startRows, startCols: $scope.startColsParts,
                colWidths: $scope.partColWidths, columns: $scope.partColumns, minSpareCols: 0,
                autoWrapRow:$scope.autoWrapRow,data: $scope.spreadSheetParts,hiddenColumns:$scope.hidePartColumns,
                formulas: true, minSpareRows: $scope.minSpareRows, dataSchema: $scope.partDataSchema, minRows: $scope.minRows}); /*dataSchema: $scope.dataSchema,*/

            $scope.displaySpreadsheet = true;
            $scope.show_part_handsontable_spreadsheet = true;
        });
    }
    $scope.selectedDropDownValues= [];
    /*** Renderer for parts spreadsheet ***/
    $scope.partRenderer = function(instance, td, row, col, prop, value, cellProperties) {
        var cellTitle = instance.getCellMeta(row, col);
        var cellCase = cellTitle.prop;
        switch(cellCase) {

            case 'partsId':
                //# Check its active editor
                $scope.isPartActiveEditor = $scope.checkActiveEditor(instance, col, row, true, false);

                //# Avoid while delete line items, the previous part description stored in empty line item.
                if(value == '') {
                    instance.getCell(row,col).innerText = '';
                    instance.getCell(row,col).innerHTML = '';
                }

                var partsId;
                if($scope.selectedPartsDetails != null && $scope.partScope.length === 0) { //# works only in edit section
                    partsId = value;
                    value = $scope.selectedPartsDetails[value];
                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                 }
                else if($scope.partScope.length) { //# In edit during load didn't get this scope value
                    if($scope.selectedPartsDetails != null){
                        var selectedPartKeys = Object.keys($scope.selectedPartsDetails);
                        if(this.inArray(value, selectedPartKeys)){
                            $scope.partScope.push({'id': value, 'text': $scope.selectedPartsDetails[value] })
                        }
                    }
                    for (var index = 0; index < $scope.partScope.length; index++)
                    {
                        if (parseInt(value) === $scope.partScope[index].id)
                        {
                            partsId = $scope.partScope[index].id;
                            value = $scope.partScope[index].text;
                            if($scope.isPartActiveEditor && this.inArray(partsId, $scope.currentParts)) {
                                /*** Load the default value into the row once added in parts table ***/
                                $scope.spreadSheetParts[row]['quantity'] = 1;
                                $scope.selectedDropDownValues[partsId] = value;
                            }
                            Handsontable.cellTypes.text.renderer.apply(this, arguments);
                            break;
                        }
                    }
                }
                else if($scope.selectedDropDownValues.length) { //# Will execute only validation error from backend call
                    partsId = value;

                    if(typeof $scope.selectedDropDownValues[parseInt(value)] != 'undefined') {
                        value = $scope.selectedDropDownValues[parseInt(value)];
                    } else if($scope.selectedPartsDetails != null) { //# Execute during edit screen and having error rows.
                        value = $scope.selectedPartsDetails[value];
                    }

                    Handsontable.cellTypes.text.renderer.apply(this, arguments);
                }
                break;
            case 'quantity':

                //# Check and update with two decimal point in spreadsheet
                $scope.newQuantity = value > 0 ? parseFloat(value) : 1;
                $scope.spreadSheetParts[row]['quantity'] = $scope.newQuantity.toFixed(2);
                value = $scope.newQuantity.toFixed(2);
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                break;
            case 'deleteAction':
                Handsontable.cellTypes.text.renderer.apply(this, arguments);
                var div = document.createElement('DIV');
                var a = document.createElement('a');
                var linkText = document.createTextNode("Delete");
                a.appendChild(linkText);
                a.title = "Delete Action";
                a.style= "cursor:pointer;"
                div.appendChild(a);
                $(td).empty().append(div).append(a);
                $(a).on('click', function(e) {
                    $scope.deleteAction(row, col, $scope.partHotId);
                });
                return td;
                break;
        }
    }

    /*** After change action from parts spreadsheet ***/
    $scope.partsAfterChange=function(changes, src) {

        //# Load the spreadsheet Instance record to alter the spreadsheet
        $scope.instanceLoad[$scope.partHotId] = this;

        //# If the current src is edit mode, then call the insert function
        if(src === 'edit') {
            $scope.insertRow(this, changes, src);

            if($scope.isErrorReturn) {
                var row = this.getActiveEditor().row;
                $scope.removeErrorMessage(row, $scope.partHotId, 'errorRowList');
            }

        }
        //# Load an empty default data during edit section
        else if((src === 'loadData' && $scope.spreadSheetParts.length)) {
            var recordLength = $scope.spreadSheetParts.length-1;
            //# Check the last record having id value
            if($scope.spreadSheetParts[recordLength].id != '') {
                this.alter('insert_row', this.countRows());
                this.render();
            }

            $timeout(function() {
                $scope.scrollingSpreadsheet($scope.partHotId, $scope.instanceLoad[$scope.partHotId]);
            }, 1000);
        }

        //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
        if($scope.spreadSheetParts.length > 1) {
            $scope.partsSaveRecord = this.getData();

            //# Validate each cell from the row to enable the save button
            for(var i=0; i< $scope.partsSaveRecord.length-1; i++) {

                //# Get the single row details
                var row = $scope.partsSaveRecord[i];

                //# j=1 assigned to avoid the first column of 'primaryID' of the row.
                //# length-1 used to avoid the last column of 'delete' of the row
                for(var j=1; j<row.length-1; j++ ) {

                    //# Cell all the cells are valid or not
                    if(row[j] === '' || row[j] == null ) {
                        $scope.validateCell=true;
                        break;
                    } else {
                        $scope.validateCell=false;
                    }
                }
                //# If any cols is invalid then break the loop.
                if($scope.validateCell == true) {
                    $scope.isPartSpreadSheetEmpty=true;
                    break;
                } else {
                    $scope.isPartSpreadSheetEmpty=false;
                }
            }
        }

        if(screenType == 'add_part_kit'){
            var validationCond = $scope.spreadSheetParts.length > 0;
            var emptyRow = false;
        }else{
            var validationCond = $scope.spreadSheetParts.length > 1;
            var emptyRow = true;
        }

        //# Check with custom scope value, by default in spreadsheet it having 5 empty rows
        if(validationCond) {
            $scope.partsSaveRecord = this.getData();
            if(emptyRow == true){
                var limit = $scope.partsSaveRecord.length-1;
            }else if(emptyRow == false){
                var limit = $scope.partsSaveRecord.length;
            }

            //# Validate each cell from the row to enable the save button
            $scope.spreadSheetValidation($scope.partsSaveRecord, $scope.partHotId, limit);
            var colData = this.getDataAtCol(1);
            $scope.partRowCount = $scope.NonEmptyRowsCount(colData);
        }
    }
    $scope.NonEmptyRowsCount = function NonEmptyRowsCount(colData){
        var nonEmptyRowsCount = colData.reduce(function (count, data) {
            return $scope.isColEmpty(data) ? count : count + 1;
        }, 0);
        return nonEmptyRowsCount;
    }
    $scope.isColEmpty = function isColEmpty(value){
        return typeof value == 'undefined' || value === null || value.length === 0;
    }
    $scope.spreadSheetValidation = function(saveRecords, isSpreadSheetEmpty, limit) {
        $scope[isSpreadSheetEmpty] = true;

        //# Relax the cell validation.
        var releaseCells = [7];

        for(var i=0; i< limit; i++) {

            //# Get the single row details
            var row = saveRecords[i];

            //# j=1 assigned to avoid the first column of 'primaryID' of the row.
            //# length-1 used to avoid the last column of 'delete' of the row
            for(var j=1; j<row.length-1; j++ ) {

                //# Relax the validation for particular cell
                var cellRelax = releaseCells.indexOf(j);

                //# Cell all the cells are valid or not
                if((row[j] === '' || row[j] == null) && cellRelax == -1 ) {
                    $scope.validateCell=true;
                    break;
                } else {
                    $scope.validateCell=false;
                }
            }
            //# If any cols is invalid then break the loop.
            if($scope.validateCell == true) {
                $scope[isSpreadSheetEmpty]=true;
                break;
            } else {
                $scope[isSpreadSheetEmpty]=false;
            }
        }
    }

    $scope.deleteAction = function(r, c, hotId) {
        var confirmation_message = 'This row has not been saved yet, are you sure you want to delete it?';
        if(c === ($scope.instanceLoad[hotId].getInstance().countCols() -1)) {
            //# Before delete confirm
            confirmationBoxHelper.getConfirmation(confirmation_message, this)
                .then(function() {

                    //# Get the primary id of the edit screen, to delete from backend
                    var rowId=$scope.instanceLoad[hotId].getSourceDataAtRow(r);
                    if((typeof rowId != 'undefined') && rowId.id) {
                        $scope.rowToDelete.push(rowId.id);
                    }
                    var ColData = $scope.instanceLoad[hotId].getDataAtCol(1);
                    $scope.partRowCount = $scope.NonEmptyRowsCount(ColData);

                    $scope.instanceLoad[hotId].alter('remove_row', r);
                    $scope.instanceLoad[hotId].render();

                }, function() {
                    return false
                });
        }
    }

    $scope.tabMoves= function (event) {
        if(event.shiftKey) {
            return {row: 2, col: 1};
        }
        else {
            return {row: 0, col: 1};
        }
    }

    /** Check the cell validation ***/
    $scope.cellValidator = function (value, callback) {

        if(value != '') {
            if(this.type == 'numeric' && isNaN(value)) {
                callback(false);
            }
            callback(true);
        } else {
            callback(false);
        }
    }

    /*** If edit the last row then need to insert a new row with default row ***/
    $scope.insertRow = function(currentScope,changes, src) {
        var description = currentScope.getDataAtRow(currentScope.countRows()-1);
        if (changes != null && changes[0][0] === (currentScope.countRows() - 1) && src === 'edit' && description[1] != '') {
            currentScope.alter('insert_row', currentScope.countRows());
            currentScope.render();
        }
    }

    /*** Check the valid html tag ***/
    $scope.strip_tags = function(input, allowed) {
        var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
            commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

        // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
        allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

        return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
    }

    /*** Get the current active editor and validate ***/
    $scope.checkActiveEditor = function(instance, col, row, checkCol, checkRow) {
        var activeEditor = instance.getActiveEditor();

        if(typeof activeEditor != 'undefined') {
            var cellTitle = instance.getCellMeta(activeEditor.row, activeEditor.col);
            var cellCase = cellTitle.prop;

            //# Used to update the default quantity/unit cost for changing the parts.
            var data = instance.getData();
            if(data[row][0] === '' && cellCase === 'partsId') {
                return true;
            }
        }
        return false;
    }

    /*** Once select the value from dropdown update the value in spreadsheet line items ***/
    $scope.alterSave = function(value, dropDownType) {

        $scope.isPartSpreadsheet = false;
        $scope.newSelectedValue = '';

        if($scope.isPartSpreadsheet) {
            $scope.spreadSheetParts = $scope.reLoadSpreadsheet($scope.spreadSheetParts, dropDownType, $scope.newSelectedValue, $scope.partHotId);

            /*** Replace the default schema value, when new screen it will automatically update it ***/
            $scope.partDataSchema[dropDownType]=$scope.newSelectedValue;
            $scope.instanceLoad[$scope.partHotId].updateSettings({dataSchema: $scope.partDataSchema});
            $scope.instanceLoad[$scope.partHotId].render();
        }
    }

    /*** Reload the spreadsheet data with hotId ***/
    $scope.reLoadSpreadsheet = function(spreadsheet, key, newValue, hotId) {

        for(var i = 0, l = spreadsheet.length; i < l; i++) {
            if(typeof spreadsheet[i][key] != 'undefined') {
                spreadsheet[i][key] = newValue;
            }
        }
        return spreadsheet;
    }

    /*** Display the error rows ***/
    $scope.spreadSheetErrorRows = function(partInvalidRows) {

        for(var i=0; i<partInvalidRows.length; i++) {
            var currentRow = partInvalidRows[i];
            $scope.displayErrorRows(currentRow, $scope.partHotId);
        }
    }

    /** Display the error row line items in spreadsheet **/
    $scope.displayErrorRows = function(currentRow, hotId) {
        var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(currentRow);

        for(var j=0; j<currentMetaRow.length; j++ ) {
            $scope.instanceLoad[hotId].setCellMeta(currentRow,j,"className","errorRowList");
            $scope.instanceLoad[hotId].render();
        }
    }

    /** Remove the error class from the line item **/
    $scope.removeErrorMessage = function(row, hotId, toRemove) {
        var currentMetaRow = $scope.instanceLoad[hotId].getCellMetaAtRow(row);
        var reg = new RegExp('(\\s|^)'+toRemove+'(\\s|$)');

        for(var j=0; j<currentMetaRow.length; j++ ) {
            var newCssClass = currentMetaRow[j].className;

            if(newCssClass) {
                newCssClass=newCssClass.replace(reg,'');
                $scope.instanceLoad[hotId].setCellMeta(row,j,"className", newCssClass);
            }
        }
        $scope.instanceLoad[hotId].render();
    }

    $scope.inArray = function(needle, haystack) {
        const length = haystack.length;
        for (let i = 0; i < length; i++) {
            if (haystack[i] == needle) { return true; }
        }
        return false;
    }

}
