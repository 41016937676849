/**
 * Created by ragavi on 14/10/17.
 */
'use strict';

function NewPartPurchaseOrderCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData, jobData, spreadSheetData, warningModal,confirmationBoxHelper, $translate, toastBox, $compile, CS_LANGUAGE) {
    $scope.fromOperationalList = false;
    if(localStorage.getItem('operational_part_add_po') == 'true'){
        $scope. fromOperationalList = true;
        localStorage.setItem('operational_part_add_po', false);
    }

    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId;
    $scope.customerMode = $state.params.type;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.customerTabName = getIdData.customerTabName;
    $scope.supplierList = getIdData.supplierList;
    $scope.enableEmailCheckBox = getIdData.enableEmailCheckBox;
    $scope.supplierEmailValue = false;
    $scope.readAccess = getIdData.readAccess;
    $scope.saveSpreadsheet_parts = true;
    $scope.saveSpreadsheet_items = true;
    $scope.selectedTab = "Costs";
    $scope.selectedSupplier = {'supplierId': ''};
    if($scope.jobId){
        $scope.selectedTypes = 'customers';
    }
    $scope.vatType = getIdData.vatType;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;
    $scope.isHideVat = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);

    $scope.supplierEmail = false;
    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_suppliers',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        },
        initSelection : function (element, callback) {
            callback($(element).data('$ngModelController').$modelValue);
        }
    }

    $scope.selectedDate = moment();
    $scope.selctedParts = jobData.getSelectedPart();

    var isFromEstimatedCost = $state.current.name == 'loggedin.customer_list.view.job.details.new_job_cost_purchase_order' || $state.current.name == 'loggedin.customer_list.view.job.details.new_job_cost_purchase_order_milestone';

    if($scope.selctedParts.length > 0 && !isFromEstimatedCost){
        $scope.prepopulatePurchaseOrder = true;
        $scope.getPartSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=new_job_purchase_order_parts_prepopulated&mode=parts';
        $scope.screenType = 'new_purchase_order_parts_prepopulated'
    } else if ($scope.selctedParts.length > 0 && isFromEstimatedCost) {
        var selectedSupplier = $scope.selctedParts[0].supplierId;
        var supplierObj = _.findWhere($scope.supplierList, {id: parseInt(selectedSupplier)});
        if(supplierObj) {
            $scope.selectedSupplier.supplierId = {id: selectedSupplier, text: supplierObj.companyname};
        }
        $scope.prepopulatePurchaseOrderItems = true;
        $scope.getItemSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=new_job_purchase_order_items_prepopulated&mode=items&selectedParts=' + angular.toJson($scope.selctedParts) + '&readAccess=' + $scope.readAccess;
        $scope.screenType = 'new_job_purchase_order_items_prepopulated'
    } else {
        $scope.prepopulatePurchaseOrder = false;
        jobData.setSelectedPart([]);
        $scope.getPartSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=new_job_purchase_order_parts&mode=parts&selectedParts=' + '';
        $scope.getItemSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=new_purchase_order_items&mode=items';
        $scope.screenType = 'new_purchase_order_parts';
    }

    $scope.$watch('supplierEmailValue', function(newVal, oldVal){
        if (newVal != undefined) {
            $scope.supplierEmailValue=newVal;
        }
    });

    $scope.addUrl = prefix + '/save_job_parts_purchase_order';

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabSupplier:successMessage', data);
    });

    $scope.numberOfRowsAdded = 0;
    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
        $scope.numberOfRowsAdded = rows.length;
        validateSpreadSheetRows.call(this, $scope, category, rows);
    });

    $scope.$on("validate:save-spreadsheet-data", function(event, is_valid) {
        if(is_valid.type == 'new_job_purchase_order_parts_prepopulated'){
            $scope.saveSpreadsheet_parts = is_valid.isValid;
            $scope.saveSpreadsheet_items = is_valid.isValid;
        }
    });

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
        if(message.name === 'alterDeliveryDate') {
            $scope.alterSave(message.date, 'deliveryDate');
        }
    });

    $scope.previousProvinceId = '';
    $scope.changeSupplier = function (supplierId){
        $scope.selectedSupplierId = supplierId;
        if(supplierId){
            $http.post(prefix + '/get_supplier_data', "supplierId=" + supplierId).
            success(function (data, status) {
                if (status == 200) {
                    if (data.emailOption === false) {
                        $scope.supplierEmailValue = false;
                    }
                    $scope.supplierEmail = data.emailOption;
                    var defaultNominalCode = data.defaultNominalCode.id,
                        supplierBranches = data.supplierBranches;

                    if(supplierBranches.length == 0)
                    {
                        $scope.deliveryMethodList[2].children = [];
                    }else{
                        //when supplier is choosen, branches of that supplier are loaded to delivery method dropdown in spreadsheet.
                        for(var count=0; count < supplierBranches.length ; count++){
                            $scope.deliveryMethodList[2].children[count] = {'id':'', 'text':''};
                            $scope.deliveryMethodList[2].children[count].id = 2+':'+supplierBranches[count].id;
                            $scope.deliveryMethodList[2].children[count].text = supplierBranches[count].description;
                        }
                    }

                    // update supplier province
                    if(!data.sameTaxRateAcrossProvinces && $scope.previousProvinceId != data.provinceId && typeof $rootScope.accessDetails['513'] != 'undefined')
                    {
                        $scope.previousProvinceId = data.provinceId;
                        $scope.updateStateProvinceTaxRates(data.taxLists,!!data.provinceId);
                        if(data.provinceId)
                        {
                            var title = $translate('Supplier');
                            var message = $translate('supplier.province.change.warning');
                            warningModal.show(message, title, "supplier");
                        }
                    }

                    //when supplier is choosen, default nominal code for the supplier should be set to all the rows
                    if(defaultNominalCode != ""){
                        $scope.alterSave(defaultNominalCode,'nominalCodeId');
                    }
                     // Need to reset Delivery method when changing supplier
                    $scope.alterSave('','deliveryMethod');

                    $scope.supplierthirdpartyreference = data.thirdPartyReference;
                    $scope.supplierIntegrationStatus = !!(typeof $scope.supplierthirdpartyreference !== 'undefined' && $scope.supplierthirdpartyreference !== '' && $scope.supplierthirdpartyreference !== null);

                    $scope.confirmSupplierPriceUpdate();
                }
            });
        }
    }

    $scope.addPurchaseOrder = function () {
        // $scope.saving = true;
        var note = ($scope.note == undefined) ? '' : $scope.note;
        var date = moment($scope.poDate).format('YYYY-MM-DD');
        var partDetails = '';
        var partString = 'selectedParts';
        if($scope.selctedParts.length > 0 && !isFromEstimatedCost) {
            partDetails = "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts));
        } else if($scope.selctedParts.length > 0 && isFromEstimatedCost) {
            partDetails = "items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems));
            partString = 'selectedItems';
        } else {
            partDetails = "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems));
        }
        $http.post($scope.addUrl, partDetails + "&type=" + $scope.screenType + "&date=" + date + "&note=" + encodeURIComponent(note) + "&total=" + $scope.total_cost + "&vat=" + $scope.total_VAT + "&grandTotal=" + $scope.grand_total + "&"+partString+"=" + angular.toJson($scope.selctedParts) + "&clientJobType=" + $rootScope.clientJobType + "&jobToDos=" + $rootScope.clientJobTodos + "&supplierId=" + $scope.selectedSupplier.supplierId.id + "&milestoneId=" + $scope.milestoneId
            + "&jobId=" + $scope.jobId + "&supplierEmailValue=" + $scope.supplierEmailValue + "&vatType=" + encodeURIComponent($scope.vatType)+"&mergeJobPartIds="+ angular.toJson(jobData.getMergeJobParts())).
        success(function (data, status) {
            if (status == 200) {
                // $scope.saving = false;
                if(data.error) {
                    $scope.saving = false;
                    warningModal.clean('supplier_po_error');
                    warningModal.show($translate(data.message), 'Error', 'supplier_po_error');
                    $scope.isErrorReturn = true;
                    $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                } else {
                    if($scope.fromOperationalList){
                        $state.transitionTo('loggedin.reporting.operational', {category: 'reporting_operational_list_parts', subcategory: 'parts', type: 'requested'});
                    }else{
                        if($scope.milestoneId){
                            $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId , milestoneId : $scope.milestoneId });
                        }else{
                            $state.transitionTo("loggedin.customer_list.view.job.viewcost", { 'id': $state.params.id, 'type': $state.params.type, 'jobId' : $state.params.jobId });
                        }
                    }
                }
            }
        });
    }
    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, $scope.partCat);
    jobData.setSelectedPart('');

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "job_part_new_purchase_order";
    $scope.itemHotId = "job_item_new_purchase_order";
    $scope.showDeliveryDetails = true;
    $scope.isErrorReturn = false;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["job_part_new_purchase_order"] = true;
    $scope["job_item_new_purchase_order"] = true;
    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, $scope.screenType, warningModal, $timeout, $compile, CS_LANGUAGE);
}

function CustomerEditPurchaseOrderCtrl($scope, $state, $http, prefix, $rootScope, $timeout, getIdData, confirmationBoxHelper, $translate, toastBox, warningModal, $compile, CS_LANGUAGE) {
    $scope.customerSubNavDetails = getIdData.customerSubNavDetails;
    $scope.customerMode = $state.params.type;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.jobSubNavStatic = false;
    $scope.customerSubNavDetails = true;
    $scope.customerSupplierSubNavDetails = true;
    $scope.selectedTab = "Costs";
    $scope.isFromEstimatedCost = getIdData.isFromEstimatedCost;
    $scope.selectedSupplierId = $state.params.id;

    $scope.taxBreakdownType = $rootScope.taxBreakdownType;
    $scope.vatType = getIdData.poData.vatType;
    $scope.isHideVat = false;

    $scope.editUrl = prefix + '/suppliers/' + $state.params.id + '/purchase_order/' + $state.params.purchaseOrderId + '/updateSpreadsheet';

    handleEditPo.call(this, $scope, $state, $http, prefix, $rootScope, getIdData);

    $scope.$emit('purchaseOrderId:selected', getIdData.poData.id);
    $scope.$emit('customerMode:selected', getIdData.customerSubNavDetails.customerType);
    $scope.$emit('supplierId:selected', getIdData.navBarDetails.id);
    $scope.$emit('jobId:selected', $state.params.jobId);
    $scope.tabRouteCustomer = { 'customerSubNavDetails': $scope.customerSubNavDetails, 'supplierDetails': $scope.supplierDetails, 'jobSubNavStatic': $scope.jobSubNavStatic, 'poData': $scope.poData, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.addPurchaseOrder = function () {
        // $scope.saving = true;
        var note = ($scope.note == undefined) ? '' : $scope.note;
        var date = moment($scope.purchaseOrderDate).format('YYYY-MM-DD');
        var rowsToDelete = ($scope.rowToDelete && $scope.rowToDelete.length) ? $scope.rowToDelete.join() : '';
        $http.post($scope.editUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) +
            "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date +
            "&note=" + encodeURIComponent(note) + "&total=" + $scope.total_cost + "&vat=" + $scope.total_VAT +
            "&grandTotal=" + $scope.grand_total + "&rowsToDelete=" + rowsToDelete + "&vatType=" + encodeURIComponent($scope.vatType)).
        success(function (data, status) {
            if (status == 200) {
                // $scope.saving = false;
                if(data.error) {
                    $scope.saving = false;
                    warningModal.clean('supplier_po_error');
                    warningModal.show($translate(data.message), 'Error', 'supplier_po_error');
                    $scope.isErrorReturn = true;
                    $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                }else if ($scope.milestoneId) {
                    $state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order_milestone", { 'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'milestoneId': $state.params.milestoneId, 'purchaseOrderId': $state.params.purchaseOrderId }, {reload:true});
                }
                else{$state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order", { 'id': $state.params.id, 'type': $scope.customerMode, 'jobId': $state.params.jobId, 'purchaseOrderId': $state.params.purchaseOrderId }, {reload:true});
                }
            }
        });
    }
    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'edit_purchase_order')

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_edit_purchase_order";
    $scope.itemHotId = "item_edit_purchase_order";
    $scope.showDeliveryDetails = true;
    $scope.isErrorReturn = false;
    $scope.getPartSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=edit_purchase_order_parts&mode=parts&purchaseOrderId=' + $state.params.purchaseOrderId +'&supplierId='+getIdData.navBarDetails.id;
    $scope.getItemSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=edit_purchase_order_items&mode=items&purchaseOrderId=' + $state.params.purchaseOrderId +'&supplierId='+getIdData.navBarDetails.id;

    if($scope.isFromEstimatedCost == true){
        $scope.screenType =  'edit_purchase_order_parts_estimatedCost';
    }else{
        $scope.screenType =  'edit_purchase_order_parts';
    }

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["part_edit_purchase_order"] = true;
    $scope["item_edit_purchase_order"] = true;
    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, $scope.screenType, warningModal, $timeout, $compile, CS_LANGUAGE);
}

function CustomerCompletePurchaseOrderCtrl($scope, $state, $http, prefix, $rootScope, excludedFileTypes, getIdData, $upload, confirmationBoxHelper, $translate, toastBox, warningModal, $timeout) {
    $scope.navBarDetails = getIdData.navBarDetails;
    $scope.note = getIdData.poData.note;
    $scope.supplierId = getIdData.navBarDetails.id;
    $scope.customerMode = $state.params.type;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.poData = getIdData.poData;
    $scope.purchaseOrderId = getIdData.poData.id;
    $scope.jobSubNavStatic = false;
    $scope.customerSupplierSubNavDetails = true;
    $scope.prefix = prefix;
    $scope.customerSubNavDetails = true;
    $scope.selectedTab = "Costs";
    $scope.checkExistUrl = '/suppliers/' + $scope.supplierId + '/check_exist/invoice?value=';

    $scope.vatType = getIdData.vatType;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;
    $scope.isHideVat = false;

    handleCompletePo.call(this, $scope, $state, $http, prefix, $rootScope, excludedFileTypes, getIdData, $upload, confirmationBoxHelper, $translate, toastBox, warningModal, $timeout);
    $scope.$emit('purchaseOrderId:selected', getIdData.poData.id);
    $scope.$emit('supplierId:selected', getIdData.navBarDetails.id);
    $scope.$emit('jobId:selected', $state.params.jobId);
    $scope.tabRouteCustomer = { 'customerSubNavDetails': $scope.customerSubNavDetails, 'navBarDetails': $scope.navBarDetails, 'jobSubNavStatic': $scope.jobSubNavStatic, 'poData': $scope.poData, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);

    $scope.addPurchaseOrder = function () {
        // $scope.saving = true;
        var date = moment($scope.poDate).format('YYYY-MM-DD');
        $http.post($scope.addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&items=" + encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date + "&sendToAccounts=" + $scope.sendToAccounts + "&total=" + $scope.total_cost + "&vat=" + $scope.total_VAT + "&grandTotal=" + $scope.grand_total + "&invoiceNumber=" + $scope.invoiceNumber + "&fileLocation=" + encodeURI($scope.fileLocation) +  "&vatType=" + encodeURIComponent($scope.vatType)).
        success(function (data, status) {
            if (status == 200) {
                // $scope.saving = false;
                if(data.error) {
                    $scope.saving = false;
                    warningModal.clean('supplier_po_error');
                    warningModal.show($translate(data.message), 'Error', 'supplier_po_error');
                    $scope.isErrorReturn = true;
                    $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);

                }
                else if ($scope.milestoneId) {
                    $state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order_milestone", {
                        'id': $state.params.id,
                        'type': $scope.customerMode,
                        'jobId': $state.params.jobId,
                        'milestoneId': $state.params.milestoneId,
                        'purchaseOrderId': $state.params.purchaseOrderId
                    }, {reload: true});
                } else {
                    $state.transitionTo("loggedin.customer_list.view.job.details.view_purchase_order", {
                        'id': $state.params.id,
                        'type': $scope.customerMode,
                        'jobId': $state.params.jobId,
                        'purchaseOrderId': $state.params.purchaseOrderId
                    }, {reload: true});
                }
            }
        });
    }
}

function handleEditPo($scope, $state, $http, prefix, $rootScope, getIdData) {
    $scope.supplierDetails = getIdData.navBarDetails;
    $scope.supplierDetails.poData = getIdData.poData;
    $scope.note = getIdData.poData.note;
    $scope.supplierDetails.showPoMiniNav = true;
    $scope.readAccess = getIdData.readAccess;
    $scope.poData = getIdData.poData;

    if($scope.supplierDetails.poData.ponumber) {
        $scope.supplierDetails.poData.poid = $scope.supplierDetails.poData.ponumber;
    } else {
        $scope.supplierDetails.poData.poid = $state.params.purchaseOrderId;
    }

    $scope.canAddNewRows = true;
    $scope.hasExistingData = false;
    if(getIdData.poData.job_number > 0) {
        $scope.canAddNewRows = false;
        var selectedType = 'customers';
    } else {
        $scope.hasExistingData = true;
        var selectedType = 'suppliers';
    }
    $scope.selectedTypes = selectedType;
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showPoDetailNav', value: true});
    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: false});

    $scope.$on('$viewContentLoaded', function(){
        $scope.$broadcast('event:change_default_selected_date', {date: getIdData.poData.date});
        $scope.selectedDate = getIdData.poData.date.date;
        $scope.purchaseOrderDate = getIdData.poData.date.date;
    });

    var poId = $state.params.purchaseOrderId;
    $scope.deleteUrl = prefix + '/suppliers/' + $state.params.id + '/purchase_order/' + poId + '/delete';

    $scope.$on('flash', function (event, data) {
        $scope.$emit('tabSupplier:successMessage', data);
    });

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    $rootScope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
        if(message.name === 'alterDeliveryDate') {
            $scope.alterSave(message.date, 'deliveryDate');
        }
    });
    HandleDeleteRowFromSpreadsheet.call(this, $scope);
}

function handleCompletePo($scope, $state, $http, prefix, $rootScope, excludedFileTypes, getIdData, $upload, confirmationBoxHelper, $translate, toastBox, warningModal, $timeout, $compile,CS_LANGUAGE) {

    $scope.invoiceNumber = '';
    $scope.sendToAccounts = false;
    $scope.excludedeTypes = excludedFileTypes;
    $scope.action = "/upload_file_to_temp";
    $scope.fileLocation = '';
    $scope.readAccess = getIdData.readAccess;

    $scope.canAddNewRows = true;
    $scope.hasExistingData = false;
    if(getIdData.poData.job_number > 0) {
        $scope.canAddNewRows = false;
        var selectedType = 'customers';
    } else {
        $scope.hasExistingData = true;
        var selectedType = 'suppliers';
    }
    $scope.selectedTypes = selectedType;

    $scope.$emit('event:changePOScope', {name: 'showSupplierNav', value: false});
    $scope.$emit('event:changePOScope', {name: 'showMiniNav', value: true});
    var poId = $state.params.purchaseOrderId;
    $scope.addUrl = prefix + '/suppliers/' + $scope.supplierId + '/purchase_order/' + poId + '/complete_purchase_order_invoice';

    $scope.$on("event:save-spreadsheet-data", function (event, message) {
        var category = message.category;
        var rows = message.rows;
        $scope[category] = rows;
    });

    $rootScope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
        if(message.name === 'alterDeliveryDate') {
            $scope.alterSave(message.date, 'deliveryDate');
        }
    });
    HandleDeleteRowFromSpreadsheet.call(this, $scope);
    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'complete_purchase_order');

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.fileObject = resp.message;
            $scope.fileLocation = resp.fileLocation;
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileObject});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.partHotId = "part_complete_purchase_order_job";
    $scope.itemHotId = "item_complete_purchase_order_job";
    $scope.showDeliveryDetails = false;

    $scope.getPartSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=complete_purchase_order&mode=parts&purchaseOrderId=' + poId;
    $scope.getItemSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=complete_purchase_order&mode=items&purchaseOrderId=' + poId + '&selectedType='+ $scope.selectedTypes;

    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'complete_purchase_order', warningModal, $timeout, $compile, CS_LANGUAGE);
}

function JobNewSupplierInvoiceCtrl($scope, $state, $http, prefix, $rootScope, jobData, getIdData, $upload, excludedFileTypes, canLoad, $q, confirmationBoxHelper, $translate, toastBox, warningModal, $timeout, $compile, CS_LANGUAGE) {
    $scope.selectedTab = "Costs";
    $scope.selectedId = $state.params.id;
    $scope.jobId = $state.params.jobId;
    $scope.customerMode = $state.params.type;
    $scope.milestoneId = $state.params.milestoneId;
    $scope.$emit('tabCustomer:selected', getIdData);
    $scope.$emit('displayScreen', false);
    $scope.jobSubNavStatic = true;
    $scope.customerTabName = getIdData.customerTabName;
    $scope.supplierList = getIdData.supplierList;
    $scope.invoiceNumber = '';
    $scope.excludedeTypes = excludedFileTypes;
    $scope.action = "/upload_file_to_temp";
    $scope.fileSrc = '';
    $scope.fileLocation = '';
    $scope.uploadedFileName = '';
    $scope.checkExists = false;
    var milestoneId = ($state.params.milestoneId) ? $state.params.milestoneId : 0;
    $scope.readAccess = getIdData.readAccess;
    $scope.selectedTypes = 'customers';
    $scope.selectedDropdownSupplier = {'supplierId': ''};

    $scope.vatType = getIdData.vatType;
    $scope.taxBreakdownType = $rootScope.taxBreakdownType;
    $scope.isHideVat = false;

    $scope.tabRouteCustomer = { 'customerTabName': $scope.customerTabName, 'jobSubNavStatic':$scope.jobSubNavStatic, 'selectedTab': $scope.selectedTab };
    $scope.$emit('tabRouteCustomer:selected', $scope.tabRouteCustomer);
    $scope.$emit('jobId:selected', $scope.jobId);

    $scope.supplierEmail = false;
    $scope.select2Options = {
        minimumInputLength: 2,
        ajax: {
            url: prefix + '/get_all_suppliers',
            data: function (term, page) {
                return { 'q': term};
            },
            results: function (data, page) {
                return { results: data };
            }
        }
    }

    var canceler = null;
    $scope.$watchCollection('[supplierId,invoiceNumber]', function (newVal, oldVal) {
        if (newVal) {
            if($scope.selectedDropdownSupplier.supplierId && $scope.invoiceNumber != '') {
                var checkExistUrl = prefix + '/suppliers/' + $scope.selectedDropdownSupplier.supplierId.id + '/check_exist/invoice?value=' + encodeURIComponent($scope.invoiceNumber);
                canLoad.setLoadValue(false);
                if (canceler) {
                    canceler.resolve();
                }
                canceler = $q.defer();
                $http.get(checkExistUrl, {timeout: canceler.promise}).
                success(function(data, status) {
                    if (status == 200) {
                        if (data == "true") {
                            $scope.checkExists = true;
                        } else {
                            $scope.checkExists = false;
                        }
                    }
                })
            }
        }
    });

    $scope.onUploadSuccess = function (resp) {
        if (resp.error) {
            $scope.error = true;
        } else {
            $scope.fileObject = resp.message;
            $scope.fileLocation = resp.fileLocation;
            $scope.uploadedFileName = resp.message.name;
            $scope.$broadcast('event:spreadSheetFileUploaded', {fileObject: $scope.fileObject});
        }
    }

    handleUploads.call(this, $scope, prefix, $upload, $scope.action, $scope.excludedeTypes);

    $scope.$on('datepicker:datepicker_selected', function(event, message){
        $scope[message.name] = message.date;
        if(message.name === 'alterDeliveryDate') {
            $scope.alterSave(message.date, 'deliveryDate');
        }
    });

    $scope.previousProvinceId = '';
    $scope.changeSupplier = function (supplierId){
        if(supplierId){
            $http.post(prefix + '/get_supplier_data', "supplierId=" + supplierId).
            success(function (data, status) {
                if (status == 200) {
                    if (data.emailOption === false) {
                        $scope.supplierEmailValue = false;
                    }
                    $scope.supplierEmail = data.emailOption;
                    var defaultNominalCode = data.defaultNominalCode.id,
                        supplierBranches = data.supplierBranches;

                    //when supplier is choosen, default nominal code for the supplier should be set to all the rows
                    if(defaultNominalCode != ""){
                        $scope.alterSave(defaultNominalCode,'nominalCodeId');
                    }
                    $scope.$broadcast('spreadsheet:supplierData',{'category': '' , 'optionData':data.supplierBranches, 'optionName': 'supplier_branches', 'defaultNominalCode': data.defaultNominalCode});

                    // update supplier province
                    if(!data.sameTaxRateAcrossProvinces && $scope.previousProvinceId != data.provinceId && typeof $rootScope.accessDetails['513'] != 'undefined')
                    {
                        $scope.previousProvinceId = data.provinceId;
                        $scope.updateStateProvinceTaxRates(data.taxLists,!!data.provinceId);
                        if(data.provinceId)
                        {
                            var title = $translate('Supplier');
                            var message = $translate('supplier.province.change.warning');
                            warningModal.show(message, title, "supplier");
                        }
                    }
                }
            });
        }
    }

    $scope.addSupplierInvoice = function() {
        $scope.saving = true;
        var addUrl = prefix + '/suppliers/' + $scope.selectedDropdownSupplier.supplierId.id + '/invoice/insert';
        var dontSendToAccounts = ($scope.dontSendToAccounts == undefined) ? '' : $scope.dontSendToAccounts;
        var date = moment($scope.supplierInvoiceDate).format('YYYY-MM-DD');
        $http.post(addUrl, "parts=" + encodeURIComponent(angular.toJson($scope.spreadSheetParts)) + "&items=" +
            encodeURIComponent(angular.toJson($scope.spreadSheetItems)) + "&date=" + date + "&invoiceNumber=" + encodeURIComponent($scope.invoiceNumber) +
            "&dontSendToAccounts=" + dontSendToAccounts + "&total=" + $scope.total_cost + "&tax=" + $scope.total_VAT + "&grandTotal=" + $scope.grand_total +
            "&fileLocation=" + encodeURI($scope.fileLocation) + "&jobId=" + $state.params.jobId + "&milestoneId=" + milestoneId + "&fileName=" + encodeURI($scope.uploadedFileName) + "&vatType=" + encodeURIComponent($scope.vatType)).
        success(function (data, status) {
            if (status == 200) {
                if(data.error && data.error == 'Exists') {
                    $scope.supplierInvoiceForm.invoiceNumber.$error.checkExists = true;
                }else if(data.error) {
                    $scope.saving = false;
                    warningModal.clean('supplier_new_invoice_error');
                    warningModal.show($translate(data.message), 'Error', 'supplier_new_invoice_error');
                    $scope.isErrorReturn = true;
                    $scope.spreadSheetErrorRows(data.partInvalidRows, data.itemInvalidRows);
                } else {
                    if(milestoneId > 0) {
                        $state.transitionTo("loggedin.customer_list.view.job.cost.projectCostView", { id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId, milestoneId: milestoneId });
                    } else {
                        $state.transitionTo("loggedin.customer_list.view.job.viewcost", { id: $state.params.id, type: $state.params.type, jobId: $state.params.jobId });
                    }
                }
                $scope.saving = false;
            }
        });
    }

    handleSpreadsheetGrandTotal.call(this, $scope, $rootScope, 'new_invoice');

    /*** Routing URL for to load the default data ***/
    $scope.getItemSpreadsheetURL = prefix + '/get_job_purchase_order_spreadsheet?type=new_purchase_order_items&mode=items';

    $scope.show_part_handsontable_spreadsheet = false;
    $scope.show_item_handsontable_spreadsheet = false;
    $scope.itemHotId = "item_new_supplier_invoice";
    $scope.showDeliveryDetails = false;

    //# Assign for enable/disable the save button from spreadsheet.
    $scope["item_new_supplier_invoice"] = true;
    /*** Loaded new spreadsheet ***/
    supplierSpreadsheet.call(this, $scope, prefix, $http, $rootScope, $state, confirmationBoxHelper, $translate, toastBox, 'new_supplier_invoice', warningModal, $timeout, $compile, CS_LANGUAGE);
}
